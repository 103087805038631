import React from 'react'

import {
  Button,
  CircularProgress,
} from '@material-ui/core'

import useFormStyles from '../styles/formStyles'

function ProgressButton({text, backgroundRunning = false}) {
  const formStyles = useFormStyles()
  return <Button
      variant='contained'
      color='primary'
      type='submit'
      className={formStyles.smallTextField}
      disabled={backgroundRunning}
      >
        { backgroundRunning
          ? <CircularProgress size={'1.7em'}
            className={formStyles.buttonProgress} />
          : <span>{text}</span>
        }
    </Button>
}

export default ProgressButton
