import useEditStyles from "../components/ServiceCompanyViewStyles"
import useListStyles from '../components/ListServiceCompanyStyles'
import ListServiceCompany from "../components/ListServiceCompany"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchChekhubOrgList, fetchIndyTechsAll,
  fetchServiceCompanyList2,
  fetchServiceCompanyListAll,
  resetServiceCompanyListState
} from "../store/actions/service-company.actions"
import { CUSTOMER_COMPANY_USER_TYPE } from "../services/identity-provider.service"
import React, { Suspense, useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import { useDimensionsContextStyles } from '../components/DimensionsContext'
import useLandingViewStyles from '../components/LandingViewStyles'
import { getUserOrg } from '../util/users.util'
import { useAuthUser } from '../hooks/useAuthUser'
import { useParams } from 'react-router-dom'
import { Card, Typography } from '@material-ui/core'
import useAccessToken from '../store/actions/useAccessToken'
import { fetchInvitedTeams } from '../services/addToTeam.service'
import { getInvitedTeams } from '../store/actions/invited-teams-list.actions'
import { INDEPENDENT_TECH_USER_TYPE } from '../constants/user.constants'
import { getRequiredDocs } from '../store/actions/addRequiredDocs.actions'
import { sendChekhubOrgInvite } from '../store/actions/AddToTeams.actions'
import { isServiceErrorResult } from '../services/is-service-error-result.util'
import toastShow, { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '../store/actions/toast-show'
import { closeDrawerAndTransition } from '../components/ServiceCompanyMemberDrawer.util'

const ListServiceCompanyView = () => {
  const dimensionsContextStyles = useDimensionsContextStyles()
  const styles = useLandingViewStyles()
  const editStyles = useEditStyles()
  const listStyles = useListStyles()
  const dispatch = useDispatch()
  const {
    serviceCompanyList,
    providerList,
    invitedTeamsList,
  } = useSelector(
      ({
        serviceCompanyList,
        providerList,
         invitedTeamsList,
        auth : {
          user,
          userType,
          activeOrg}}) => ({
        serviceCompanyList,
        invitedTeamsList,
        user,
        userType,
        activeOrg,
        providerList
        }))

  const fuse = new Fuse(serviceCompanyList, {
    keys: [
      'profile_data.company_name',
      'profile_data.full_name',
      'profile_data.first_name',
      'profile_type',
      'company_name',
    ]
  })
  const [open, setDrawerOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [sentDocs, setSentDoc] = useState([])

  const requiredDocs = useSelector(({requiredDocsList}) => requiredDocsList)
  const [indy, setIndy] = useState(false)
  const [selectedId, setSelectedId] = useState()
  const authorizedUser = useAuthUser()
  const userDefaultOrg = getUserOrg(authorizedUser)
  const chekhubChecker = userDefaultOrg?.chekhub_member_id
  //const invitedTeams = useSelector(({invitedTeams}) => invitedTeams)
  const [query, setQuery] = useState('')
  const fuseResults = fuse.search(query)
  const orgId = localStorage.getItem("orgId")?.replace(/['"]+/g, '') || useParams()

   const scResults = !indy ? query ? fuseResults.map(results => results.item).filter(
     item => !(providerList.some(e =>
      e.service_provider_id === item._id)) && !(invitedTeamsList.some(e => e.service_provider_id === item._id))) :
     serviceCompanyList.filter(item => !(providerList.some(e => e.service_provider_id === item._id)) && !(invitedTeamsList.some(e => e.service_provider_id === item._id))) :
     serviceCompanyList.filter(item => !(providerList.some(e => e.service_provider_id === item._id))  && !(invitedTeamsList.some(e => e.service_provider_id === item._id)))


  const onChange = async (e) => {
    await dispatch(resetServiceCompanyListState())
    e.target.value === 'independent' ? setIndy(true) : setIndy(false)
  }

  useEffect(async () => {
    orgId && chekhubChecker && await dispatch(fetchServiceCompanyList2({
      chekhub_orgId: orgId
    }))
    orgId && chekhubChecker && await dispatch(getInvitedTeams({
      chekhub_org_id: orgId
    }))
    !indy && orgId && await dispatch(fetchServiceCompanyListAll())
    indy && orgId && await dispatch(fetchIndyTechsAll({
       orgId,
        orgType: 'independent_tech'
      }
     ))
    await dispatch(getRequiredDocs({
      chekhub_org_id: orgId
    }))
  }, [])

  const handleInviteSent = async ({sentDocs}) => {
    console.log(sentDocs)
    const result = await dispatch(sendChekhubOrgInvite({
      requirements: sentDocs,
      chekhub_org_id: orgId,
      service_provider_id: selectedId._id
    }))

    const isError = isServiceErrorResult(result)
    dispatch(toastShow(
      isError ? TOAST_TYPE_ERROR : TOAST_TYPE_SUCCESS,
      isError ?
        'Invite to Team Failure -> contact support@connect.com' :
        'Invite to Team Success'
    ))
    setOpenModal(false)
    setSentDoc([])
  }
  const handleInvitePreset = (team) => {
    setSelectedId(serviceCompanyList.find(t => t._id === team._id))
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
    setSentDoc([])
  }

  const handleCheckBoxClick = (e) => {
    const selected = e.target.value
    setSentDoc([...sentDocs, {
      filename: selected,
      name: e.target.name
    }])
  }


  return scResults && scResults.length < 0 ? <Card className={
      `${styles.membersListCard} ${dimensionsContextStyles.minHeight} ${dimensionsContextStyles.card}`
    } ><Typography variant={'h6'}>No teams available</Typography></Card> :
  <>
    <Suspense fallback={<div>Loading....</div>}>
      <ListServiceCompany {... {
        serviceCompanyList: scResults,
        onChange,
        indy,
        editStyles,
        listStyles,
        setQuery,
        query,
        dimensionsContextStyles,
        styles,
        openModal,
        setDrawerOpen,
        handleClose,
        requiredDocs,
        setSentDoc,
        handleCheckBoxClick,
        handleInviteSent,
        sentDocs,
        handleInvitePreset
      }}/>
    </Suspense>
  </>
}

export default ListServiceCompanyView
