import axios from 'axios'

import appConfig from '../../config'
import useAccessToken from './useAccessToken'
import {setBackendWorking, setBackendWorkingDone} from './backend-working.actions'

const ticketFetch = ({
  orgId,
  ticketId = 0,
}) => async (dispatch, getState, getFirebase) => {
  const { chekhub: { baseURI, defaultHeaders, operations } } = appConfig
  const { ticketFetchMany, ticketFetchOne } = operations
  const { path, method, authRequired } = ticketId ? ticketFetchOne : ticketFetchMany

  const token = authRequired ? await useAccessToken() : null

  dispatch(setBackendWorking())

  try {
    const axiosParams = {
      baseURL: baseURI,
      url: path + (ticketId ? `/${ticketId}` : ''),
      method,
      headers: {
        ...defaultHeaders,
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
      params: {
        ...(!ticketId ? { organization_id: orgId } : {}),
      },
    }

    const { status, data: body } = await axios(axiosParams)
    if (status !== 200) throw new Error('unknown fetchError')

    dispatch({
      type: `TICKET/FETCH${!ticketId ? '-LIST' : ''}-OK`,
      payload: {
        data: body.data,
      },
    })
  } catch (err) {
    const errorMessage = err?.response?.data?.message
    dispatch({
      type: 'TOAST/TRIGGER-NOTIFICATION',
      payload: {
        type: 'error',
        message: `There was an error loading the
          ${ticketId ? 'ticket' : 'list of tickets'}:
          ${errorMessage || 'Unexpected Error'}`,
      },
    })
    dispatch({ type: 'TASK/FETCH-ERROR', payload: err })
  }
  dispatch(setBackendWorkingDone())
}

export default ticketFetch


{/*TODO: REMOVE THIS FILE AND ALL FILES PERTAINING TO IT AND REWRITE A NEW VERSION OF REDUX
CONTROLS FOR THE TICKET'ING SYSTEM. */}
