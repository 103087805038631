import { Box, Card, FormControl, FormControlLabel, Grid, RadioGroup, } from '@material-ui/core'
import React from "react"
import { FuzzySearchBar } from './FuzzySearchBar'
import { ServiceProviderDrawerUtil } from '../pages/ServiceProviderDrawerUtil'
import { withRouter } from 'react-router-dom'
import { ProviderList } from './ProviderList'
import { useSelector } from 'react-redux'
import { RadioButton } from 'react-control-library'
import { StyledRadio } from './LandingPage'
import { ServiceProviderCardList } from './ServiceProviderCardList'

const ListServiceCompany = withRouter(({
  editStyles,
                                         onChange,
  serviceCompanyList = [],
  indy,
  query,
  setQuery,
  dimensionsContextStyles,
  styles,
  match,
  setDrawerOpen,
                                         handleInvitePreset,
                                         sentDocs,
                                         handleClose,
                                         openModal,
                                         uploadOpen,
                                         setUploadOpen,
                                         openUpload,
                                         closeUpload,
                                         removeUploadConfirmOpen,
                                         setRemoveUploadConfirmOpen,
                                         openRemoveConfirm,
                                         closeRemoveConfirm,
                                         requiredDocs,
                                         setSentDoc,
                                         handleCheckBoxClick,
                                         handleInviteSent
}) => {

  const {backgroundRunning} = useSelector(
    ({auth: {backgroundRunning}}) => ({
      backgroundRunning
    })
  )
 return <Box {... {
    border: 0,
    className: dimensionsContextStyles.scrollContainer
  }}>
    <Card
      className={
        `${styles.membersListCard} ${dimensionsContextStyles.minHeight} ${dimensionsContextStyles.card}`
      }>
      <Grid
        container
        justify='center'
        className={editStyles.editProfileGrid}>
        <Grid item xs={7} style={{
          padding: '10px',
          paddingBottom: '0px'
        }}>
          <FuzzySearchBar {... {
            label: 'Search Service Providers',
            setQuery,
            query,
          }
                          }/>
        </Grid>
        <Grid item xs={5} style={{
          paddingTop: '10px'
        }}>
          <FormControl component="fieldset">
            <RadioGroup defaultValue={'service_company'} aria-label="gender" name="customized-radios" onChange={onChange}>
              <FormControlLabel value="service_company" control={<StyledRadio/>}
                                label="I want to view service companies"/>
              <FormControlLabel value="independent" control={<StyledRadio/>}
                                label="I want to view independent contractors "/>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{
          padding: '10px',
          paddingTop: '20px'
        }}>
          <ServiceProviderCardList {... {
            teamOrProviderList: serviceCompanyList,
            editStyles,
            indy,
            backgroundRunning,
            handleClose,
            openModal,
            uploadOpen,
            setUploadOpen,
            openUpload,
            closeUpload,
            removeUploadConfirmOpen,
            setRemoveUploadConfirmOpen,
            openRemoveConfirm,
            closeRemoveConfirm,
            requiredDocs,
            setSentDoc,
            handleCheckBoxClick,
            handleInviteSent,
            sentDocs,
            handleInvitePreset
          }} />
        </Grid>
      </Grid>
    </Card>
  </Box>
})
export default ListServiceCompany
