import firebase from 'firebase/app'
import 'firebase/auth'
import {Headers, Methods, MimeTypes} from 'http-headers-js'

const {CONTENT_TYPE, ACCEPT} = Headers
const {GET, POST, PUT, DELETE} = Methods
const {Application: {JSON: APPLICATION_JSON}} = MimeTypes
console.log(process.env)

const { NODE_ENV, REACT_APP_STAGE } = process.env
const productionEnv = REACT_APP_STAGE === 'production'

const developFirebaseConfig = {
    apiKey: 'AIzaSyCEI1M9TCb2FVjKkQ2qyVN0aZlpR8kljbk',
    authDomain: 'chekhub-development.firebaseapp.com',
    databaseURL: 'https://chekhub-development.firebaseio.com',
    projectId: 'chekhub-development',
    storageBucket: 'chekhub-development.appspot.com',
    messagingSenderId: '722856791248',
    appId: '1:722856791248:web:d15615ec0eaf6b8acc22d5',
    measurementId: 'G-GLJ3XH206Y',
}
const productionFirebaseConfig = {
  apiKey:'AIzaSyBspOSJCLY9_HgAgR2TawHGfmJjrevfdbI',
  authDomain: "chekhub-production.firebaseapp.com",
  databaseURL: "https://chekhub-production.firebaseio.com",
  projectId: "chekhub-production",
  storageBucket: "chekhub-production.appspot.com",
  messagingSenderId: "165733283129",
  appId: "1:165733283129:web:15ad5d7250951a8ba024e8",
  measurementId: "G-E99N2VQDCM"
}
console.log(productionEnv)
firebase.initializeApp(productionEnv ? productionFirebaseConfig : developFirebaseConfig)

const appConfig = {
  firebase,
  stripeKey: productionEnv
    ? 'pk_live_5y4pATdkjFgeF9cTeuE1YP0I00aQrbvBnD'
    : 'pk_test_L0Jxak4BhhtL90GS7Q7r7UKD00w8je2vFW',
  googlePlaces: {
    baseURI: 'https://maps.googleapis.com/maps/api/place',
    key: 'AIzaSyCfg3WC-2Nq2-XhW7qNfrNQbA6j87f00Tg',
    operations: {
      placeDetails: {
        path: '/details/json',
        method: GET,
      },
    },
  },
  chekhubFrontend: {
    baseURI: `https://${productionEnv ? 'app' : 'dev'}.chekhub.com`,
  },
  chekhub: {
    baseURI2: `https://api${productionEnv ? '' : '.dev'}.chekhub.com`,
    defaultHeaders: {
      [ACCEPT]: APPLICATION_JSON,
      [CONTENT_TYPE]: APPLICATION_JSON,
    },
    operations: {
      ticketFetchMany: {
        path: '/v3/tickets',
        method: GET,
        authRequired: true,
      },
      ticketFetchOne: {
        path: '/v3/tickets',
        method: GET,
        authRequired: true,
      },
      logCreate: {
        path: '/v2/logs',
        method: POST,
        authRequired: true,
      },
    },
  },
    chekhubConnect: {
    baseURI: `https://services.connect${productionEnv ? '' : '-dev'}.chekhub.com`,
    newURI: `https://159.203.86.113:3404/connect`,
    intergrationURI: productionEnv ? `https://api.chekhub.com/integrations/connect` :  `https://api.dev.chekhub.com/integrations/connect`,
    defaultHeaders: {
      [ACCEPT]: APPLICATION_JSON,
      [CONTENT_TYPE]: APPLICATION_JSON,
    },
    operations: {
      serviceCompanyProfileCreate: {
        path: '/v1/service-companies',
        method: POST,
        authRequired: true,
      },
      serviceCompanyProfileFetchOne: {
        path: '/v1/service-companies',
        method: GET,
        authRequired: true,
      },
      techProfileFetchOne: {
        path: '/v1/techs',
        method: GET,
        authRequired: true,
      },
      techProfileFetchMany: {
        path: '/v1/techs',
        method: GET,
        authRequired: true,
      },
      unifiedProfileFetchMany: {
        path: '/v1/profiles',
        method: GET,
        authRequired: true,
      },
      tokenIssue: {
        path: '/v1/token',
        method: GET,
        authRequired: false,
      },
      users: {
        path: '/v1/users',
        create: {
          method: POST,
          authRequired: false
        },
        update: {
          method: PUT,
          authRequired: false
        },
        get: {
          method: GET,
          authRequired: true
        }
      },
      usersCreate: {
        path: '/v1/users',
        method: POST,
        authRequired: false,
      },
      usersUpdate: {
        path: '/v1/users',
        method: PUT,
        authRequired: false,
      },
      usersFetchOne: {
        path: '/v1/users',
        method: GET,
        authRequired: true,
      },
      addTeam: {
        path: '/v1/teams',
        method: PUT,
        authRequired: true,
      },
      removeTeam: {
        path: '/v1/teams',
        method: DELETE,
        authRequired: true,
      },
    },
    paths: {
      serviceCompanyProfileService: '/v1/service-companies',
      profilesService: '/v1/profiles',
      storageService: '/v1/storage',
      techProfileService: '/v1/techs',
      userService: '/v1/users',
      passwordReset: '/v1/password',
      walletService: '/v1/wallet',
      authTokenService: '/v1/token',
      backgroundCheckService: '/v1/background-check',
      connectedAccounts: '/v1/connected-accounts',
      industriesService: '/v1/service-companies/industries',
      paymentService: '/v1/payment',
      connectTeamService: `/teams`,
      connectWalletService: `/wallet`,
      connectStripeService: `/stripe`,
      connectTicketPayment: `/tickets/payment`,
      connectGetPaidTickets: '/tickets',
      onboardingPath: '/onboarding_documents',
      onboardingRequestsPath: '/onboarding_requests'
    },
  },
}

export default appConfig
