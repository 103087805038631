import {
  Box,
  duration,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { SectionHeader } from '../components/SectionHeader'
import Grid from '@material-ui/core/Grid'
import { FieldDisplay } from '../components/FieldDisplay'
import PhoneNumbersDisplay from '../components/PhoneNumbersDisplay'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { FormSubmitButton } from '../components/FormSubmitButton'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServiceCompany, resetServiceCompanyState } from '../store/actions/service-company.actions'
import { deleteToChekhubTeams } from '../store/actions/AddToTeams.actions'
import { getUserOrg } from '../util/users.util'
import toastShow, { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '../store/actions/toast-show'
import { isServiceErrorResult } from '../services/is-service-error-result.util'
import { closeDrawerAndTransition } from '../components/ServiceCompanyMemberDrawer.util'
import { CloudDownloadLink } from '../components/CloudDownloadLink'
import { Form } from 'formik'
import CheckIcon from '@material-ui/icons/Check'
import { CloseSharp } from '@material-ui/icons'
import { checkProperties } from '../components/CurrentInvitedTeamsTemplate'
import { OnboardingDocumentUploadSendInvite } from '../components/OnboardingDocumentUploadSendInvite'
import { getRequiredDocs, uploadRequiredDocsChange } from '../store/actions/addRequiredDocs.actions'
import { useMultiBusy } from 'react-multi-busy'
import { getInvitedTeams } from '../store/actions/invited-teams-list.actions'


export const TeamProviderSummary = ({
                                          teamOrProviderList,
                                          setOpen,
                                          backRoutePath,
                                          routeTransitionDelay = duration.leavingScreen,
                                          setBlurred,
                                          onAcceptance

                                        }) => {
  const {isBusy, startBusy, endBusy} = useMultiBusy()
  const [open, setOpenModal] = useState(false)
  const [sentDocs, setSentDoc] = useState([])
  const {teamId} = useParams()
  const {orgId} = localStorage.getItem("orgId")?.replace(/['"]+/g, '') || useParams()
  const dispatch = useDispatch()
  const user = useSelector(({auth}) => auth.user)
  const memberId = getUserOrg(user, orgId)
  const history = useHistory()
  const {pathname} = useLocation()
  const serviceProvider = useSelector(({serviceCompany}) => serviceCompany)
  const localizedTeam = teamOrProviderList?.find(item => item.service_provider_id === teamId) ||
    teamOrProviderList?.find(item => item._id === teamId)

  console.log(localizedTeam)
  const allowAccept = checkProperties(localizedTeam?.requirements)
  const [uploadOpen, setUploadOpen] = useState(false)
  const [removeUploadConfirmOpen, setRemoveUploadConfirmOpen] = useState(false)
  const requiredDocs = useSelector(({requiredDocsList}) => requiredDocsList)

  useEffect( async () => {
    teamId && await dispatch(fetchServiceCompany(teamId))
    orgId && await dispatch(getRequiredDocs({
      chekhub_org_id: orgId
    }))
  }, [])

  useEffect(() => {
    dispatch(resetServiceCompanyState())
  }, [])


  const handleInvitePreset = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
    setSentDoc([])
  }

  const openUpload = () => setUploadOpen(true)
  const closeUpload = () => {
    setUploadOpen(false)
    setSentDoc([])
  }
  const openRemoveConfirm = () => setRemoveUploadConfirmOpen(true)
  const closeRemoveConfirm = () => {
    setSentDoc([])
    setRemoveUploadConfirmOpen(false)
  }

  const handleCheckBoxClick = (e) => {
    const selected = e.target.value
    setSentDoc([...sentDocs, {
      filename: selected,
      name: e.target.name
    }])
  }

  const handleDeleteToChekhubOrg = async () => {
    const result = await dispatch(deleteToChekhubTeams(
      localizedTeam,
      orgId
    ))
    const isError = isServiceErrorResult(result)
    dispatch(toastShow(
      isError ? TOAST_TYPE_ERROR : TOAST_TYPE_SUCCESS,
      isError ?
        'Remove from Team Failed' :
        'Remove from Team Success'
    ))
    closeDrawerAndTransition(
      setOpen,
      backRoutePath,
      history,
      routeTransitionDelay,
      setBlurred
    )
  }

  const handleRequirementChange = async (e) => {
    const result = await dispatch(uploadRequiredDocsChange({
      requirements: sentDocs,
      chekhub_org_id: chekhubOrgId,
      service_provider_id: serviceProvider._id,
      requirement_id: localizedTeam._id
      }
    ))
    const isError = isServiceErrorResult(result)
    dispatch(toastShow(
      isError ? TOAST_TYPE_ERROR : TOAST_TYPE_SUCCESS,
      isError ?
        'Updated onboarding documents failed' :
        'Updated onboarding documents sucess'
    ))
    closeDrawerAndTransition(
      setOpen,
      backRoutePath,
      history,
      routeTransitionDelay,
      setBlurred
    )
  }

  return serviceProvider && <Box style={{
    padding: '10px'
  }}>
    <SectionHeader>
      Service Provider Information
    </SectionHeader>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FieldDisplay {...{
          label: 'Provider Name'
        }}/>
        <Typography variant={'h6'}>
          {serviceProvider.company_name || serviceProvider.full_name}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <FieldDisplay {...{
          label: 'Provider EIN'
        }}/>
        <Typography variant={'h6'}>
          {serviceProvider.ein}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <FieldDisplay {...{
          label: 'Provider Postal Code'
        }}/>
        <Typography variant={'h6'}>
          {serviceProvider.location.address.postal_code}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FieldDisplay {...{
          label: 'Provider Address'
        }}/>
        <Typography variant={'h6'}>
          {serviceProvider.location.formatted_address}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FieldDisplay {...{
          label: 'Provider Industries'
        }}/>
        { serviceProvider.industries ? serviceProvider.industries.map(industry => {
          return <Typography variant={'h6'}>
            {industry}
          </Typography>
        }): 'No Industries Available'}
      </Grid>
      <Grid item xs={6}>
        <FieldDisplay {...{
          label: 'Provider Phone Numbers'
        }}/>
        <PhoneNumbersDisplay {...{
          phones: serviceProvider.phones
        }}/>
      </Grid>
      {localizedTeam?.requirements?.length > 0  && <TableContainer component={Box} style={{
        paddingTop: '10px'
      }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>{'Document Name'}</TableCell>
              <TableCell align={'right'}>{'Uploaded Document'}</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {localizedTeam?.requirements?.length > 0 && localizedTeam?.requirements.map((row, index) => (
              <TableRow key={row.filename}>
                <TableCell component="th" scope="row">
                  <CloudDownloadLink {...{
                    cloudFilename: row?.filename,
                    isBusy,
                    docName: row.name,
                    style: {
                      color: '#77C043'
                    }
                  }}>
                    <Typography style={{
                      width: '90px'
                    }}>
                      {row.name}
                    </Typography>
                  </CloudDownloadLink>
                </TableCell>
                <TableCell align={'right'}>
                  {row?.upload ? <CloudDownloadLink {...{
                    cloudFilename: row?.upload,
                    docName: row?.name,
                    style: {
                      color: '#F5882F',
                      paddingTop: '10px',
                      alignContent: 'center'
                    }
                  }}>
                    <CheckIcon style={{
                      paddingRight: '5px',
                      fontSize: 'xx-large'
                    }}/>
                  </CloudDownloadLink> : `File not Uploaded`}
                </TableCell>
                <TableCell align={'left'} style={{
                  width: '150px'
                }}>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
      {/*<Grid item xs={12} justify={'flex-end'}>*/}
      {/*  <FormSubmitButton onClick={() => setOpenModal(true)}>*/}
      {/*    Change onboarding documents*/}
      {/*  </FormSubmitButton>*/}
      {/*</Grid>*/}
      {!pathname.match('/current-teams') && <FormSubmitButton {... {
        onClick: () => onAcceptance(localizedTeam),
        useFab: true,
        disabled: !allowAccept
      }}>
        Accept Documents
      </FormSubmitButton>}
      {!pathname.match('/invited-teams') && <FormSubmitButton {... {
        onClick: handleDeleteToChekhubOrg,
        useFab: true,
      }}>
        {'Remove From Team'}
      </FormSubmitButton>}
    </Grid>
    <OnboardingDocumentUploadSendInvite {...{
      handleClose,
      open,
      uploadOpen,
      setUploadOpen,
      openUpload,
      closeUpload,
      removeUploadConfirmOpen,
      setRemoveUploadConfirmOpen,
      openRemoveConfirm,
      closeRemoveConfirm,
      requiredDocs,
      setSentDoc,
      handleCheckBoxClick,
      handleInviteSent: handleRequirementChange
    }} />
  </Box>
}
