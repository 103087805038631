import noop, {noopThunk} from '../util/noop'
import {Typography} from '@material-ui/core'
import {InlineConfirm} from './InlineConfirm'
import {CertificateForm} from './CertificateForm'
import {makeStyles} from '@material-ui/core/styles'
import {useState} from 'react'

const useCertificationEditStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'space-between'
  }
}))

export const CertificationEdit = ({
  title = 'Edit Certification',
  cert = {},
  onSubmit = noopThunk,
  onCancel = noop,
  onChange = noop,
  onUploadSave = noop,
  onRemoveUpload = noop
}) => {
  const [hasFieldErrors, setHasFieldErrors] = useState(false)
  const styles = useCertificationEditStyles()
  return <>
    <Typography {...{
      className: styles.title,
      variant: 'h5',
      gutterBottom: true
    }}>
      {title} <InlineConfirm {...{
      onConfirm: onSubmit(cert),
      onCancel,
      disableConfirm: hasFieldErrors || !cert.issued_by || !cert.certification_title
    }} />
    </Typography>
    <CertificateForm {...{
      cert,
      onSubmit,
      onChange,
      onUploadSave,
      onRemoveUpload,
      setHasFieldErrors
    }} />
  </>
}
