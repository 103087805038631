import {data as paymentSources} from './mock-data/payment-sources.json'
import {PaymentChooser} from '../../../PaymentChooser'

export const PaymentChooserExample = () => {
  console.log(`paymentSources => `, paymentSources)
  const handlePurchaseSubmit = token => {
    console.log('Purchase using this token => ', token)
  }
  return <PaymentChooser {...{
    paymentSources,
    onSubmit: handlePurchaseSubmit
  }} />
}

