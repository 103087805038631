import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { Suspense } from "react"
import { Link, withRouter } from 'react-router-dom'
import { useBusyIndicatorStyles } from './BusyIndicator'
import Grid from '@material-ui/core/Grid'

export const ProviderList = withRouter(({
                                                       teamOrProviderList,
                                                       match,
                                                        indy
                                                     }) => {
  const styles = useBusyIndicatorStyles()
  return teamOrProviderList.length > 0 ? <Suspense fallback={<div>Loading...</div>}>
  <TableContainer component={Box}>
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell>Service Provider Name</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {teamOrProviderList.map((row) => (
          <TableRow key={row.service_provider_name}>
            <TableCell component="th" scope="row">
              {row.company_name || row.profile_data.full_name}
            </TableCell>
            <TableCell align="right">
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  </Suspense> : <Grid
    container
    justify={'center'}
  >
    <CircularProgress {...{
    size: '2em',
    className: styles.spinner,
    color: 'primary'
  }} />
  </Grid>
})

