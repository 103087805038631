import { AppBar, Box, Tab, Tabs } from '@material-ui/core'
import { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { TicketListView } from '../pages/TicketList.view'
import { useParams, withRouter } from 'react-router-dom'
import { TicketPaymentDrawerUtil } from '../pages/TicketPaymentDrawerUtil'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthUser } from '../hooks/useAuthUser'
import { getUserOrg } from '../util/users.util'
import { TeamProviderDrawerUtil } from '../pages/TeamProviderDrawerUtil'
import { CurrentTeamOrProviderListEndUser } from '../components/CurrentTeamOrProviderListEndUser'
import { CurrentTeamOrProviderListIndy } from '../components/CurrentTeamOrProviderListIndy'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const EngagementTabsIndy = withRouter(({
                                            labels,
                                            editStyles,
                                            match,
                                            teamOrProviderList = [],
                                            ticketsList = [],
                                            open,
                                            setDrawerOpen
                                          }) => {
  const [tabValue, setTabValue] = useState(0)
  const dispatch = useDispatch()
  const authorizedUser = useAuthUser()
  const service_provider = useSelector(({serviceCompany}) => serviceCompany)
  const auth = useSelector(({auth}) => auth)
  const {user} = auth
  const userDefaultOrg = getUserOrg(authorizedUser)
  const chekhubChecker = userDefaultOrg?.chekhub_member_id
  const default_source = useSelector(({wallet}) => wallet.default_source)
  const {orgId} = useParams()
  const chekhub_org_id = userDefaultOrg?.organization_id


  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue)
  }

  return <Box style={{
    width: '100%'
  }}>
    <AppBar position={'sticky'}
    >
      <Tabs {...{
        value: tabValue,
        onChange: handleTabChange,
      }}>
        {
          labels.map((label, index) => {
            return <Tab label={label} {...a11yProps(index)} />
          })
        }
      </Tabs>
    </AppBar>
    <TabPanel value={tabValue} index={0}>
      <CurrentTeamOrProviderListIndy {...{
        editStyles,
        teamOrProviderList: teamOrProviderList.reverse(),
        chekhubChecker
      }}/>
    </TabPanel>
    {ticketsList && <TabPanel value={tabValue} index={1}>
      <TicketListView {... {
        chekhubChecker,
        ticketsList,
        tabValue,
        default_source
      }}/>
    </TabPanel>}
    {ticketsList && <TabPanel value={tabValue} index={2}>
      <TicketListView {... {
        chekhubChecker,
        ticketsList,
        tabValue,
        default_source
      }}/>
    </TabPanel>}
    {ticketsList && <TabPanel value={tabValue} index={3}>
      <TicketListView {... {
        chekhubChecker,
        ticketsList,
        tabValue,
        default_source
      }}/>
    </TabPanel>}
    <TicketPaymentDrawerUtil {...{
      open,
      chekhubChecker,
      ticketsList,
      setOpen: setDrawerOpen,
      backRoutePath: match.url,
    }} />
    {teamOrProviderList && <TeamProviderDrawerUtil {... {
      open,
      setOpen: setDrawerOpen,
      backRoutePath: match.url,
      teamOrProviderList
    }} />}

  </Box>
})
