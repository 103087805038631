import axios from 'axios'

import appConfig from '../../config'
import useAccessToken from './useAccessToken'
import {setBackendWorking, setBackendWorkingDone} from './backend-working.actions'

const { chekhubConnect: { baseURI, defaultHeaders, operations } } = appConfig

const AddRemoveFromTeam = (
  profileId,
  ownerId,
  providerType,
  remove,
) => async (dispatch, getState, getFirebase) => {
  dispatch(setBackendWorking())

  const { addTeam, removeTeam } = operations
  const { path, method } = remove ? removeTeam : addTeam
  const accessToken = await useAccessToken()

  try {
    const { status, data: body } = await axios({
      baseURL: baseURI,
      url: path,
      method,
      headers: {
        ...defaultHeaders,
        authorization: `Bearer ${accessToken}`,
      },
      data: {
        owner_id: ownerId,
        provider_type: providerType,
        profile_id: profileId,
      },
    })

    if (status !== 200) throw new Error('unknown profileUpsertError')
    if (remove) {
      dispatch({ type: 'PROFILE-LIST/TEAM-REMOVE-OK', payload: body.data })
    } else {
      dispatch({ type: 'PROFILE-LIST/TEAM-ADD-OK', payload: body.data })
    }
  } catch (err) {
    dispatch({ type: 'PROFILE-LIST/FETCH-ERROR', payload: err })
    dispatch({
      type: 'TOAST/TRIGGER-NOTIFICATION',
      payload: {
        type: 'error',
        message: `There was an error ${remove ? 'removing from' : 'adding to'} your team`,
      },
    })
    dispatch(setBackendWorkingDone())
    return undefined
  }
  dispatch(setBackendWorkingDone())
  dispatch({
    type: 'TOAST/TRIGGER-NOTIFICATION',
    payload: {
      type: 'success',
      message: `Successfully ${remove ? 'removed from' : 'added to'} your team`,
    },
  })
}

export default AddRemoveFromTeam
