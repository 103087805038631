import {FormControl, InputLabel, MenuItem, Select, Typography} from '@material-ui/core'
import PhoneNumbersEdit from './PhoneNumbersEdit'
import noop from '../util/noop'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { replaceItemInList } from '../util/lists.util'
import { useDispatch } from 'react-redux'

const usePrimaryContactEditStyles = makeStyles(theme => ({
  noPhonesMessage: {
    margin: theme.spacing(1)
  }
}))
export const PrimaryContactEdit = ({
  serviceCompanyManagementList = [],
  selectedUser = {},
  onChange = noop,
  setValid = noop,
  onUserUpdate = noop
}) => {
  const styles = usePrimaryContactEditStyles()
  const dispatch = useDispatch()
  const phoneNumbers = selectedUser.phones

  const onPhoneNumberAdd = (info) => {
    const newPhones = [...phoneNumbers, {
      label: info.label,
      raw_phone: "+" + info.raw_phone,
      country: 'us'
    }]
    updatePhoneNumbers(newPhones)
  }

  const onPhoneNumberRemove = (index) => {
    const newPhones = [...phoneNumbers].filter((p, i) => i !== index)
    updatePhoneNumbers(newPhones)
  }

  const onPhoneNumberUpdate = (index, info) => {
    const newPhones = replaceItemInList(info, phoneNumbers, index)
    updatePhoneNumbers(newPhones)
  }

  const updatePhoneNumbers = (phones) => {
    onUserUpdate({
      ...selectedUser,
      phones
    })
    setValid(true)
  }

  return <>
    <FormControl {...{
      variant: 'outlined',
      fullWidth: true,
      size: 'small'
    }}>
      <InputLabel>Manager</InputLabel>
      <Select {...{
        onChange: onChange,
        value: selectedUser._id,
        label: 'Manager',
        id: 'primary_contact_id'
      }}>
        {
          serviceCompanyManagementList.map((user, index) =>
            <MenuItem key={index} value={user._id}>
              {user.full_name}
            </MenuItem>
          )
        }
      </Select>
    </FormControl>
    {
      !selectedUser?.phones?.length &&
      <Typography className={styles.noPhonesMessage} variant="body2">
        No phone numbers found
      </Typography>
    }
    <PhoneNumbersEdit {...{
      onPhoneNumberUpdate,
      onPhoneNumberRemove,
      onPhoneNumberAdd,
      phoneNumbers,
      showAllowButtonOrNot: setValid
    }}/>
  </>
}
