import Grid from "@material-ui/core/Grid"
import {BackgroundCheckSelector} from "./BackgroundCheckSelector"
import {useEffect, useState} from 'react'
import {BackgroundCheckDisplay} from './BackgroundCheckDisplay'
import {BackGroundCheckPackageCard} from './BackGroundCheckPackageCard'
import {useDispatch, useSelector} from 'react-redux'
import {getUserOrg} from '../util/users.util'
import {getPaymentSources, resetPaymentSources} from '../store/actions/wallet.actions'
import {PaymentChooser} from './PaymentChooser'
import {useAuthUser} from '../hooks/useAuthUser'

export const BackgroundChecker = ({
  backgroundCheckPackages,
  backgroundCheck,
  handleBgPurchase
}) => {
  const {
    sources,
  } = useSelector(
    ({
      wallet: {
        sources
      }
    }) => ({
      sources
    }) )
  const authorizedUser = useAuthUser()
  const dispatch = useDispatch()
  const userOrg = getUserOrg(authorizedUser)
  const [bgPackagesSelected, setBGPackageSelected] = useState()
  const packages = useSelector(({backgroundCheck}) => backgroundCheck.packages)

  useEffect(async () => {
    dispatch(getPaymentSources(userOrg.organization_id))
  }, [])
  useEffect(() => () => dispatch(resetPaymentSources()), [])

  const handlePackageSelection = (e) => {
    const newSelected = packages.find(l => e.target.value === l.name)
    setBGPackageSelected(newSelected)
  }
  const handleReset = () => {
    setBGPackageSelected()
  }

  const handleSubmit = (token) => {
    handleBgPurchase(bgPackagesSelected, token)
  }

  const showBgcDisplay = backgroundCheck.independent_verification
  const showBgcSelector = backgroundCheckPackages &&
    !backgroundCheck.independent_verification
  const showBcgPurchase = bgPackagesSelected && !backgroundCheck.independent_verification

  return <>
    {
      showBgcDisplay &&
      <Grid item xs={12}>
        <BackgroundCheckDisplay {...{
          backgroundCheck,
          handleReset
        }} />
      </Grid>
    }
    {
      showBgcSelector &&
      <BackgroundCheckSelector {...{
        handlePackageSelection,
        bgPackagesSelected,
        backgroundCheckPackages,
      }}/>
    }
    {
      showBcgPurchase &&
      <>
        <Grid item sm={4}>
          <BackGroundCheckPackageCard {...{
            bgPackagesSelected
          }}/>
        </Grid>
        <Grid item sm={8}>
          <PaymentChooser {...{
            paymentSources: sources,
            onSubmit: handleSubmit
          }} />
        </Grid>
      </>
    }
  </>
}
