import {useState} from 'react'
import {CertificationsDisplay} from '../../../CertificationsDisplay'
import {CertificationEdit} from '../../../CertificationEdit'
import {certStateUtils} from '../../../Certifications.util'

export const MOCK_CERTS = [
  {
    "_id": "3984hf90384jhf90834jf",
    "certification_title": "AWS Certified Cloud Architect",
    "issued_by": "AWS",
    "issued_on": "2020-10-12T00:00:00",
    "start_date": "2020-10-10T00:00:00",
    "end_date": "2020-10-12T00:00:00",
    "filename": "certificates/aws-certificate.pdf",
    "expires_on": "2030-12-31T00:00:00",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus feugiat, est vel auctor mollis, massa nibh eleifend eros, non tristique mauris arcu sit amet ex. Morbi viverra nunc at est aliquet porttitor eget sed tortor."
  },
  {
    "_id": "0293jr928hf023ht082ht0h23",
    "certification_title": "AES Mechanic",
    "issued_by": "AES",
    "issued_on": "2020-10-12T00:00:00",
    "start_date": "2020-10-10T00:00:00",
    "end_date": "2020-10-12T00:00:00",
    "filename": "certificates/aes-certificate.pdf",
    "expires_on": "2030-12-31T00:00:00",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus feugiat, est vel auctor mollis, massa nibh eleifend eros, non tristique mauris arcu sit amet ex. Morbi viverra nunc at est aliquet porttitor eget sed tortor."
  }
]

export const CertificationsExample = () => {
  const {
    certs,
    editCert,
    addCert,
    removeCert,
    handleEdit,
    handleEditSubmit,
    onEditChange,
    clearEdit,
    handleAdd,
    clearAdd,
    handleAddSubmit,
    onAddChange,
    handleRemove,
    clearRemove,
    handleRemoveConfirm,
    onUploadSave,
    onRemoveUpload
  } = certStateUtils(
    useState([...MOCK_CERTS]),
    useState(),
    useState(),
    useState()
  )

  return <>
    {
      !editCert && !addCert && <CertificationsDisplay {...{
        certifications: certs,
        onEdit: handleEdit,
        onAdd: handleAdd,
        onRemove: handleRemove,
        removeCert,
        onRemoveConfirm: handleRemoveConfirm,
        onRemoveCancel: clearRemove
      }} />
    }
    {
      editCert && <CertificationEdit {...{
        cert: editCert,
        onSubmit: handleEditSubmit,
        onCancel: clearEdit,
        onChange: onEditChange,
        onUploadSave: onUploadSave,
        onRemoveUpload
      }} />
    }
    {
      addCert && <CertificationEdit {...{
        title: 'Add Certification',
        cert: addCert,
        onSubmit: handleAddSubmit,
        onCancel: clearAdd,
        onChange: onAddChange,
        onUploadSave,
        onRemoveUpload
      }} />
    }
  </>
}

