import thunk from 'redux-thunk'
import { applyMiddleware, compose, createStore } from 'redux'
import { getFirebase } from 'react-redux-firebase'
import rootReducer from './reducers/root'
import logger from 'redux-logger'

const middlewares = [logger, thunk.withExtraArgument(getFirebase)]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
)

export { store }
