import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'

import appConfig from '../config'
import useStyles from './AddressAutocompleteStyles'

function loadScript(src, position, id) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }
const placesService = { current: null }

// TODO: Either find something OSS that works so we're not reinventing the wheel here.
//  Or else, at least make this thing pass a standardized SyntheticEvent to the `onChange` prop
//  so it won't need custom handlers all over the app.
export default function AddressAutocomplete(props) {
  const styles = useStyles()
  const [value, setValue] = React.useState(props.value || null)
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState([])
  const loaded = React.useRef(false)
  const {disabled} = props

  const { labelText = 'Address' } = props

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      const { googlePlaces: { key } } = appConfig
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&language=en&region=US`,
        document.querySelector('head'),
        'google-maps',
      )
    }
    loaded.current = true
  }

  const fetch = React.useMemo(() => throttle((request, callback) => autocompleteService
    .current.getPlacePredictions(request, callback), 200), [])

  const fetchDetails = React.useMemo(() => throttle((request, callback) => placesService
    .current.getDetails(request, callback), 200), [])

  useEffect(() => {
    let active = true
    setValue(props.value)
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
      placesService.current = new window.google.maps.places.PlacesService(document.querySelector('#google-maps'))
    }
    if (!autocompleteService.current || !placesService.current) {
      return undefined
    }
    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = []
        if (value) newOptions = [value]
        if (results) newOptions = [...newOptions, ...results]
        setOptions(newOptions)
      }
    })

    if (!value || !value.place_id) {
      return undefined
    }
    fetchDetails({ placeId: value.place_id }, (result) => {
      if (!result) return undefined
      props.onChange({
        addressComponents: result.address_components,
        lat: result.geometry.location.lat(),
        long: result.geometry.location.lng(),
        formatted_address: result.formatted_address
      })
    })

    return () => {
      active = false
    }
  }, [props.value, inputValue, fetch, fetchDetails])

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      disabled={disabled}
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField {...params} required={props.required}
          label={ labelText }
          variant="outlined"
          fullWidth
          size="small" />
      )}
      renderOption={(option) => {
        if (!option.structured_formatting) return ''
        const matches = option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        )
        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={styles.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}
