import React, { useState, useEffect } from 'react'

import {
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core'

import AddressAutocomplete from './AddressAutocomplete'

import useFormStyles from '../styles/formStyles'

import formatAddress from './shared-functions/format-address'
import addressToLocation from './shared-functions/address-to-location'

const ServiceCompanyProfileBasicEdit = (props) => {
  const { profile, user } = props
  const formStyles = useFormStyles()

  const [currentProfile, setProfile] = useState(profile)

  const { phones, primary_contact_id: primaryContactId } = currentProfile
  const { _id: userId } = user
  if (!primaryContactId) setProfile({ ...profile, primary_contact_id: userId })

  useEffect(() => {
    if (currentProfile !== profile) {
      setProfile(profile)
    }
  })

  const handleProfileChange = (e) => {
    const numberParse = ['company_formation_year']
    const { id: key, value } = e.target
    if (key === 'location.label') {
      const newProfile = {
        ...currentProfile,
        location: {
          ...currentProfile.location,
          label: value,
        },
      }
      props.onChange(newProfile)
      return setProfile(newProfile)
    }
    const newProfile = {
      ...currentProfile,
      [key]: numberParse.includes(key) ? Number(value) : value,
    }
    setProfile(newProfile)
    props.onChange(newProfile)
  }

  const handlePhoneChange = (e) => {
    const [, idx, prop] = e.target.id.split('.')
    phones[idx][prop] = e.target.value
    const newProfile = { ...currentProfile, phones }
    setProfile(newProfile)
    props.onChange(newProfile)
  }

  const handleAddressChange = (addressDetails) => {
    const newProfile = {
      ...currentProfile,
      location: addressToLocation(addressDetails, currentProfile.location),
    }
    setProfile(newProfile)
    props.onChange(newProfile)
  }

  return (
    <div>
      <h2>Service Company profile update</h2>
      <div className={formStyles.row}>
        <TextField id="company_name"
          classeName={formStyles.largeTextField}
          size="small"
          required
          label='Company name'
          defaultValue={currentProfile.company_name || '' }
          onChange={handleProfileChange}
          variant="outlined" />
        <TextField id="ein"
          className={formStyles.smallTextField}
          size='small'
          label='EIN'
          defaultValue={currentProfile.ein || ''}
          onChange={handleProfileChange}
          variant="outlined" />
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor={'company_formation_year'}>Formation Year</InputLabel>
          <Select native
            id="company_formation_year"
            label="Formation Year"
            className={formStyles.select}
            InputLabelProps={{ shrink: true }}
            value={currentProfile.company_formation_year || ''}
            onChange={handleProfileChange} >
              <option value=''></option>
              {([...Array(150)]).map((item, key) => <option key={`yearList${key}`} value={`${key + 1900}`}>{`${key + 1900}`}</option>)}
          </Select>
        </FormControl>
      </div>
      <div className={formStyles.row}>
        <TextField id="website"
          className={formStyles.largeTextField}
          size='small'
          label='Company website'
          defaultValue={currentProfile.website || ''}
          onChange={handleProfileChange}
          variant='outlined' />
      </div>
      {phones.map((item, idx) => (<div key={idx} className={formStyles.row}>
        <TextField id={`phones.${idx}.raw_phone`}
          className={formStyles.smallTextField}
          size="small"
          required
          label="Phone number"
          defaultValue={item.raw_phone || '' }
          onChange={handlePhoneChange}
          variant="outlined" />
      </div>))}
      <div className={formStyles.row}>
        <strong>Address</strong>
      </div>
      <div className={formStyles.row}>
        <AddressAutocomplete id="address-autocomplete"
          required
          defaultValue={formatAddress(currentProfile.location.address)}
          onChange={handleAddressChange} />
      </div>
    </div>
  )
}

export default ServiceCompanyProfileBasicEdit
