import {Breadcrumbs, List, ListItem} from '@material-ui/core'
import {Link, NavLink, Route, useLocation, withRouter} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'

const useExampleLinksStyles = makeStyles(theme => ({
  navLink: {
    '&.active': {
      color: theme.palette.primary.dark
    }
  },
  breadcrumbs: {
    marginBottom: theme.spacing(2)
  }
}))

export const ExampleLinks = withRouter(({
  examples,
  match
}) => {
  const location = useLocation()
  const styles = useExampleLinksStyles()
  return <>
    {
      !match.isExact && <Breadcrumbs className={styles.breadcrumbs}>
        <Link to={match.url}>Sandbox Examples</Link>
        <Link to={location.pathname}>{location.pathname.split('/').pop()}</Link>
      </Breadcrumbs>
    }
    {
      match.isExact && <List dense>
        {
          examples.map(component =>
            <ListItem>
              <NavLink
                className={styles.navLink}
                to={`${match.url}/${component.name}`}>{component.name}</NavLink>
            </ListItem>
          )
        }
      </List>
    }
  </>
})

export const ExampleRoutes = withRouter( ({
  examples,
  match
}) => examples.map(
  component => <Route {...{
    path: `${match.url}/${component.name}`,
    component
  }}/>
))
