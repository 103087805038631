import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  InputBase,
  IconButton,
  CircularProgress,
} from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search'
import NavigateBefore from '@material-ui/icons/NavigateBefore'
import NavigateNext from '@material-ui/icons/NavigateNext'

import useRootStyles from '../styles/rootStyles'

import TechListRowComponent from './dashboard-components/tech-list-row'
import ServiceCompanyRowComponent from './dashboard-components/service-company-row'

// eslint-disable-next-line
import actions from '../store/actions'

const techHeaders = [
  {
    display: 'Name',
    key: 'full_name',
    header: true,
    align: 'left',
  },
  {
    display: 'Phone',
    key: 'phones',
    header: false,
    align: 'center',
  },
  {
    display: 'Email',
    key: 'email',
    header: false,
    align: 'center',
  },
  {
    display: 'Hourly Rate',
    key: 'hour_rate',
    header: false,
    align: 'center',
  },
  {
    display: 'Address',
    key: 'location',
    header: false,
    align: 'center',
  },
  {
    display: 'Certs',
    key: 'certifications',
    header: false,
    align: 'center',
  },
  {
    display: 'Background',
    key: 'background_check',
    header: false,
    align: 'center',
  },
  {
    display: '',
    key: 'edit_button',
    header: false,
    align: 'center',
  },
]

const companyHeaders = [
  {
    display: 'Name',
    key: 'company_name',
    header: true,
    align: 'left',
  },
  {
    display: 'Phone',
    key: 'phones',
    header: false,
    align: 'left',
  },
  {
    display: 'Address',
    key: 'location',
    header: false,
    align: 'left',
  },
  {
    display: 'Insurance',
    key: 'insurance_policies',
    header: false,
    align: 'center',
  },
  {
    display: 'Team Member',
    key: 'is_connect_team_member',
    header: false,
    align: 'center',
  },
]

const ProfileListComponent = ({ userType, orgId, backgroundRunning }) => {
  const profileList = useSelector((state) => state.profileList)
  const dispatch = useDispatch()
  const isTech = userType === 'tech'

  const data = isTech
    ? profileList.tech.data
    : profileList.serviceProvider.data
  const pageNumber = isTech
    ? profileList.tech.pageNumber
    : profileList.serviceProvider.pageNumber
  const pageCount = isTech
    ? profileList.tech.pageCount
    : profileList.serviceProvider.pageCount
  const previousSearchQuery = isTech
    ? profileList.tech.searchQuery
    : profileList.serviceProvider.searchQuery

  const [searchQuery, setSearchQuery] = useState(previousSearchQuery)

  useEffect(() => {
    dispatch(actions['PROFILE/FETCH-LIST']({ orgId, isTechList: isTech, pageNumber }))
  }, [])

  const styles = useRootStyles()

  const displayLoading = () => {
    return <Paper className={styles.loadingPaper}>
      <CircularProgress size={60} />
    </Paper>
  }

  const getEmptyMessage = () => {
    if (!profileList.fetched) {
      return displayLoading()
    }
    if (searchQuery || previousSearchQuery) return 'No Results Found'
    return isTech
      ? 'No technicians have been added to your company yet.'
      : 'No service companies are available.'
  }
  const handleSearch = (e) => {
    e.preventDefault()
    dispatch(actions['PROFILE/FETCH-LIST']({
      orgId,
      isTechList: isTech,
      pageNumber: 0,
      searchQuery,
    }))
  }

  const handleSearchInput = (e) => {
    setSearchQuery(e.target.value)
  }

  const turnPage = (pageNum) => {
    if (pageNum >= 0 && pageNum < pageCount) {
      dispatch(actions['PROFILE/FETCH-LIST']({
        orgId,
        isTechList: isTech,
        pageNumber: pageNum,
        searchQuery,
      }))
    }
  }

  const handlePreviousPage = () => turnPage(pageNumber - 1)
  const handleNextPage = () => turnPage(pageNumber + 1)

  const pageControls = () => {
    const displayCount = pageCount ? (pageNumber + 1) : 0
    return <Paper
          className={styles.pageControls}
          >
          <IconButton
            type="submit"
            className={styles.iconButton}
            aria-label="Previous"
            disabled={pageNumber <= 0}
            onClick={handlePreviousPage}>
            <NavigateBefore />
          </IconButton>
          <span
            style={{ fontSize: '.75rem' }}>
            { displayCount } / { pageCount }
          </span>
          <IconButton
            type="submit"
            className={styles.iconButton}
            aria-label="Next"
            disabled={pageNumber >= (pageCount - 1)}
            onClick={handleNextPage}>
            <NavigateNext />
          </IconButton>
        </Paper>
  }

  return (
    <Fragment>
      <div className={styles.listControls}>
        <Paper
          component="form"
          onSubmit={handleSearch}
          className={styles.listSearchControls}
          >
          <InputBase
            className={styles.input}
            placeholder="Search"
            defaultValue={searchQuery}
            onChange={handleSearchInput}
          />
          <IconButton
            type="submit"
            className={styles.iconButton}
            aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        {pageControls()}
      </div>

      {data && data.length > 0 ? (
        <TableContainer component={Paper}>
          <Table className={styles.table} aria-label="List">
            <TableHead>
              {isTech
                ? techHeaders.map((item) => (
                  <TableCell align={item.align}>{item.display}</TableCell>
                ))
                : companyHeaders.map((item) => (
                  <TableCell align={item.align}>{item.display}</TableCell>
                ))}
            </TableHead>
            <TableBody>
              {isTech
                ? data.map((userProfile) => (
                    <TechListRowComponent profile={userProfile} headers={techHeaders} />
                ))
                : data.map((companyProfile) => (
                    <ServiceCompanyRowComponent
                      profile={companyProfile}
                      headers={companyHeaders}
                      orgId={orgId} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
        style={{ textAlign: 'center', margin: '1em' }}>
        {getEmptyMessage()}</div>
      )}
      <div style={{ display: 'flex', margin: '1ex 0' }}>
        {pageControls()}
      </div>
      {backgroundRunning && displayLoading()}
    </Fragment>
  )
}

export default ProfileListComponent
