import { useState } from 'react'
import { Button, Grid, ListItem } from '@material-ui/core'
import PhoneNumberEdit from './PhoneNumberEdit'
import PhoneNumberDisplay from './PhoneNumberDisplay'
import { AddCircleOutlineSharp } from '@material-ui/icons'
import noop from '../util/noop'

const PhoneNumbersEdit = ({
  onPhoneNumberUpdate,
  onPhoneNumberRemove,
  onPhoneNumberAdd,
  phoneNumbers,
  showAllowButtonOrNot = noop,
  setValid = noop,
}) => {
  const [isEdit, setIsEdit] = useState({})
  const INIT_LABEL = 'home'
  const PHONE_LABELS = [INIT_LABEL, 'mobile', 'work']
  const [phoneNumber, setPhoneNumber] = useState('')
  const [show, setShow] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const [label, setLabel] = useState(INIT_LABEL)

  const labelSelect = (event) => {
    const index = Object.keys(isEdit)[0]
    phoneNumbers[index].label = event?.target?.value
    onPhoneNumberUpdate(index, {raw_phone: phoneNumber, label: event?.target?.value, country: " "})
  }

  const addLabelSelect = (event) => {
    setLabel(event.target.value)
  }

  const onEditNumber = (index, value) => {
    setIsEdit({ ...isEdit, [index]: value })
    value ? setPhoneNumber(phoneNumbers[index].raw_phone) : setPhoneNumber('')
    value && showAllowButtonOrNot(false)
    setShowButton(!showButton)
  }

  const changeNumber =  (event) => {
    setPhoneNumber(event)
  }

  const handleonPhoneNumberUpdate  = (index, newLabel) => {
    onPhoneNumberUpdate(index, {raw_phone: phoneNumber, label: newLabel, country: " "})
    onEditNumber(index, false)
    setIsEdit({})
    setPhoneNumber("")
    showAllowButtonOrNot(true)
  }

  const handleonPhoneNumberAdd = () => {
    onPhoneNumberAdd({raw_phone: phoneNumber, label})
    setLabel(INIT_LABEL)
    setPhoneNumber("")
    setShow(!show)
    setShowButton(true)
    setIsEdit({})
    showAllowButtonOrNot(true)
    setValid(true)
  }


  const setButtonToShowAfterCancelEdit = (index, value) => {
    onEditNumber(index, value)
    showAllowButtonOrNot(true)
    setValid(true)
  }

  return <>
    <Grid item>
        {
          phoneNumbers?.map((phone, index) => (
            <ListItem {...{
              key: index,
              disableGutters: true
            }}>
              {
                isEdit[index] && <PhoneNumberEdit {...{
                  phoneNumber: phone.raw_phone,
                  options: PHONE_LABELS,
                  type: phone.label,
                  country: phone.country,
                  labelSelect,
                  showAllowButtonOrNot,
                  onChange: changeNumber,
                  onRemoveNumber: (() => {
                    setButtonToShowAfterCancelEdit(index, false)
                  }
                  ),
                  show: true,
                  onPhoneNumberUpdate: ((newLabel) => handleonPhoneNumberUpdate(index, newLabel))
                }}
                 />
              }
              {
                !isEdit[index] && <PhoneNumberDisplay
                  onEditNumber={() => onEditNumber(index, true)}
                  {...{
                    phone,
                    index,
                    onPhoneNumberRemove,
                    setValid,
                    showAllowButtonOrNot
                  }}
                />
              }
            </ListItem>
          ))
        }
        <ListItem {...{
          key: 'add-phone-item',
          disableGutters: true
        }}>
          {/*TODO: From Kevin : Merge this Phone Number Edit and the top one together.. this should be possible by
           conditionally rendering which function to use depending on your local state values*/}
          {
            show && <PhoneNumberEdit
              phoneNumber={phoneNumber}
              options={PHONE_LABELS}
              type={label}
              labelSelect={addLabelSelect}
              onChange={changeNumber}
              onRemoveNumber={
                () => {
                  setShow(!show)
                  setShowButton(!showButton)
                  showAllowButtonOrNot(true)
                  setValid(true)
                  setPhoneNumber()
                }
              }
              onPhoneNumberAdd={() => handleonPhoneNumberAdd()} />
          }
          {showButton && <Button {...{
              color: 'primary',
              size: 'small',
              variant: 'outlined',
              startIcon: <AddCircleOutlineSharp fontSize={'small'}/>,
              onClick: () => {
                setShow(!show)
                setShowButton(!showButton)
                showAllowButtonOrNot(false)
                setValid(false)
              }
            }} >
              Add Number
            </Button>
          }
        </ListItem>
    </Grid>
  </>
}

export default PhoneNumbersEdit
