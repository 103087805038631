import {makeStyles} from '@material-ui/core/styles'
import {useDispatch, useSelector} from 'react-redux'
import {downloadFile} from '../store/actions/storage.actions'
import {getStorageDownloadUrl} from '../services/storage.service'
import {CircularProgress} from '@material-ui/core'
import {CloudDownloadSharp} from '@material-ui/icons'

const useCloudDownloadLinkStyles = makeStyles(theme => ({
  downloadLink: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    '& > svg': {
      fontSize: '1.8rem'
    },
    '& > span': {
      marginRight: theme.spacing(.5)
    }
  }
}))

export const CloudDownloadLink = ({
  cloudFilename,
  children,
                                    isBusy,
  ...restProps
}) => {
  const dispatch = useDispatch()
  const styles = useCloudDownloadLinkStyles()
  const {
    backgroundRunning
  } = useSelector(({auth: {backgroundRunning}}) => ({backgroundRunning}))



  const handleDownloadLinkClick = (filename) => async ev => {
    ev.preventDefault()
    await dispatch(downloadFile(filename, restProps.docName))
  }

  return <a {...{
    href: getStorageDownloadUrl(cloudFilename),
    onClick: handleDownloadLinkClick(cloudFilename),
    ...restProps,
    className: `${styles.downloadLink} ${restProps.className || ''}`
  }} >
    {children}{
      isBusy || backgroundRunning ? <CircularProgress {...{
        size: '1em',
        color: 'primary'
      }} /> : <CloudDownloadSharp fontSize="small"/>
    }
  </a>
}
