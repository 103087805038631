import {
  ADMIN_USER_ROLE,
  DISPATCHER_USER_ROLE,
  INDEPENDENT_TECH_USER_TYPE,
  ROLE_BASED_SIGN_IN_ROUTES,
  TECH_USER_ROLE,
  TECH_USER_TYPE
} from '../constants/user.constants'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import {
  CATCH_ALL_PATH,
  CHEKHUB_END_USER_LANDING, SELECT_ORG,
  SIGN_IN_PATH, SIGN_UP_PATH,
  SIGN_UP_SC_CREATE_PATH, SIGN_UP_SELECT_USER_TYPE
} from '../routing/route.constants'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../store/actions'
import { setActiveStep, setCompletedStep } from '../store/actions/stepper.actions'

export const findAdminUsers = (users, orgId) =>
  users.filter(
    m => hasAdminRole(m, orgId)
  )

export const getUserOrg = (user, orgId, path) => {
  const {organizations = []} = user || {}
   return !path ? organizations.find(
     org => orgId ?
       (org.organization_id === orgId) :
       org.organization_id
   ) : organizations.length <= 1 ? organizations[0] : organizations.find(org => org.organization_type === 'service_company')
}

export const getTechProfileIdForOrgId = (user, orgId) => {
  const org = getUserOrg(user, orgId)
  const {profiles = []} = org || {}
  return profiles
    .find(
      p => p.profile_type === TECH_USER_TYPE
    )?.profile_id
}

export const getUserOrgForTechProfileId = (
  {
    organizations = []
  } = {},
  techProfileId
) => {
  return organizations.find(
    (
      {
        profiles = []
      } = {}
    ) => profiles.find(p => p.profile_id === techProfileId)
  )
}

// TODO: use these methods to determine roles throughout the app
export const hasAdminRole = (user, orgId) =>
  getUserOrg(user, orgId)?.roles.includes(ADMIN_USER_ROLE)
export const hasTechRole = (user, orgId) =>
  getUserOrg(user, orgId)?.roles.includes(TECH_USER_ROLE)
export const hasDispatcherRole = (user, orgId) =>
  getUserOrg(user, orgId)?.roles.includes(DISPATCHER_USER_ROLE)

export const hasSomeRoles = (
  user,
  roles = [],
  orgId
) => {
  const orgRoles = (getUserOrg(user, orgId) || {})?.roles || []
  const intersection = orgRoles.filter(r => roles.includes(r))
  return intersection.length > 0
}

export const isServiceCompanyManager = (
  user = {},
  orgId
) => hasSomeRoles(
  user,
  [ADMIN_USER_ROLE, DISPATCHER_USER_ROLE],
  orgId
)

export const shouldDisableMemberRemoval = (
  member,
  serviceCompany,
  authenticatedUser,
  disableAdminRemoval
) => (
  hasAdminRole(member, serviceCompany._id) &&
  disableAdminRemoval
) || authenticatedUser._id === member._id

export const updateMemberOrgRoles = (member, orgId, roles) => {
  const memberOrgs = member.organizations
  const org = getUserOrg(member, orgId)
  const orgIndex = memberOrgs.findIndex(o => o.organization_id === org.organization_id)
  return {
    ...member,
    organizations: [
      ...memberOrgs.slice(0, orgIndex),
      {
        ...memberOrgs[orgIndex],
        roles
      },
      ...memberOrgs.slice(orgIndex + 1)
    ]
  }
}

export const getDefaultRedirectPath = (user = {},  path) => {
  const userDefaultOrg = getUserOrg(user, path) || {}
  const dispatch = useDispatch()
  const isAdmin = hasAdminRole(user, userDefaultOrg?.organization_id)
  const isDispatcher = hasDispatcherRole(user, userDefaultOrg.organization_id)
  const isIndependent = userDefaultOrg?.organization_type === INDEPENDENT_TECH_USER_TYPE
  console.log(userDefaultOrg)
  const chekhubChecker = userDefaultOrg?.organization_type === 'customer_company'
  const orgId = localStorage.getItem("orgId")?.replace(/['"]+/g, '') || null
  const history = useHistory()
  const redirectRoute = ROLE_BASED_SIGN_IN_ROUTES[
    isIndependent ? TECH_USER_ROLE :
      isAdmin ? ADMIN_USER_ROLE :
        isDispatcher ? DISPATCHER_USER_ROLE : !chekhubChecker ? TECH_USER_ROLE : CHEKHUB_END_USER_LANDING
    ]

  const serviceCompanyObject = user.organizations.find(org => org.organization_type === 'service_company')
  const pathParams = isIndependent ?
    {userId: getTechProfileIdForOrgId(user) || 1} : {orgId: orgId ? userDefaultOrg?.organization_id : serviceCompanyObject?.organization_id }
  return Object.keys(userDefaultOrg).length === 0 ? (history.push(SIGN_UP_SELECT_USER_TYPE)) : !orgId && !chekhubChecker ? generatePath(redirectRoute, pathParams) : orgId ?  generatePath(CHEKHUB_END_USER_LANDING, {
    orgId: orgId
  }) : !path ? (history.push(SELECT_ORG)) : (history.push(SIGN_UP_SELECT_USER_TYPE))
}
