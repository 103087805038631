import {createReducer} from 'redux-create-reducer'
import {
  CHEKHUB_LIST_RESET_STATE,
  CHEKHUB_LIST_SET_STATE,
  PROVIDER_LIST_RESET_STATE,
  PROVIDER_LIST_SET_STATE,
  SERVICE_COMPANY_LIST_SET_STATE,
  SERVICE_COMPANY_RESET_STATE
} from '../actions/service-company.actions'

export const INIT_SERVICE_COMPANY_LIST = []

export const serviceCompanyListReducer = createReducer(
  INIT_SERVICE_COMPANY_LIST,
  {
    [SERVICE_COMPANY_LIST_SET_STATE]: (state, {list}) => [
      ...list
    ],
    [SERVICE_COMPANY_RESET_STATE]: () => INIT_SERVICE_COMPANY_LIST
  }
)


export const INIT_PROVIDER_LIST_STATE = []

export const providerListReducer = createReducer(
  INIT_PROVIDER_LIST_STATE,
  {
    [PROVIDER_LIST_SET_STATE]: (state, {list}) => [
      ...list
    ],
    [PROVIDER_LIST_RESET_STATE]: () => INIT_PROVIDER_LIST_STATE
  }
)

export const INIT_CHEKHUB_LIST_STATE = {}

export const chekhubListReducer = createReducer(
  INIT_CHEKHUB_LIST_STATE,
  {
    [CHEKHUB_LIST_SET_STATE]:(state, {list}) => ({
      ...state,
      ...list
    }),
    [CHEKHUB_LIST_RESET_STATE]: () => INIT_CHEKHUB_LIST_STATE
  }
)
