import { dispatchService } from './dispatch-service.util'
import {
  deleteOneRequiredDoc,
  fetchInvitedTeams, fetchInvitedTeamsRequests,
  fetchInvitedTeamsSc,
  fetchOneRequiredDoc
} from '../../services/addToTeam.service'

export const INVITED_TEAMS_SET_STATE = 'INVITED_TEAMS_SET_STATE'
export const INVITED_TEAMS_RESET_STATE = 'INVITED_TEAMS_RESET_STATE'
export const INVITED_TEAM_SET_STATE = 'INVITED_TEAM_SET_STATE'
export const INVITED_TEAM_RESET_STATE = 'INVITED_TEAM_RESET_STATE'


export const invitedTeamsSetState = list => dispatch =>
  dispatch({
    type: INVITED_TEAMS_SET_STATE,
    list
  })

export const invitedTeamsResetState = () => dispatch =>
  dispatch({
    type: INVITED_TEAMS_RESET_STATE
  })

export const invitedTeamSetState = list => dispatch =>
  dispatch({
    type: INVITED_TEAM_SET_STATE,
    list
  })

export const invitedTeamResetState = () => dispatch =>
  dispatch({
    type: INVITED_TEAM_RESET_STATE
  })



export const getInvitedTeams = ({chekhub_org_id}) => async dispatch =>
  await dispatchService(dispatch)(fetchInvitedTeams, invitedTeamsSetState)(chekhub_org_id)

export const getInvitedTeamsSc = ({service_provider_id}) => async dispatch =>
  await dispatchService(dispatch)(fetchInvitedTeamsSc, invitedTeamsSetState)(service_provider_id)

export const getInvitedTeamsIndy = ({service_provider_id}) => async dispatch =>
  await dispatchService(dispatch)(fetchInvitedTeamsSc, invitedTeamsSetState)(service_provider_id)

export const getInvitedTeam = ({chekhub_org_id}) => async dispatch =>
  await dispatchService(dispatch)(fetchOneRequiredDoc, invitedTeamSetState)(chekhub_org_id)

export const getInvitedTeamRequets = ({_id}) => async dispatch =>
  await dispatchService(dispatch)(fetchInvitedTeamsRequests, invitedTeamSetState)(_id)

export const deleteInvitedTeam = ({docId}) => async dispatch =>
  await dispatchService(dispatch)(deleteOneRequiredDoc)(docId)
