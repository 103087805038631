const initState = {
  downloadUrls: {},
  uploadProgress: 0,
  uploadSize: null,
  uploadOk: {},
}

const storageReducer = (state = initState, action) => {
  if (['STORAGE/RESET'].includes(action.type)) {
    return initState
  }
  if (['STORAGE/FILE-UPLOAD-OK', 'STORAGE/FILE-UPLOAD-ERROR'].includes(action.type)) {
    return {
      ...state,
      uploadOk: {
        ...state.uploadOk,
        [action.payload.key]: {
          status: action.payload.status,
          storageFilename: action.payload.storageFilename,
        },
      },
    }
  }
  if (['STORAGE/SIGNED-DOWNLOAD-URL-OK'].includes(action.type)) {
    return {
      ...state,
      downloadUrls: {
        ...state.downloadUrls,
        [action.payload.key]: action.payload.value,
      },
    }
  }
  return state
}

export default storageReducer
