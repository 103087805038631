import { Headers, Methods } from 'http-headers-js'
import generateAuthHeader from './generate-auth-header.util'
import appConfig from '../config'

const {chekhubConnect: {
  paths: {
    paymentService: path,
    connectTicketPayment: ticketPayPath,
    connectGetPaidTickets: paidTicketsPath,
  },
  baseURI,
  intergrationURI,
  defaultHeaders
}} = appConfig
const serviceBaseUri = `${baseURI}${path}`
const intergrationBaseUri = `${intergrationURI}${ticketPayPath}`
const intergrationBaseUriPaid = `${intergrationURI}${paidTicketsPath}`

export const createTicketPay = async (paymentObj) => await fetch(
  `${serviceBaseUri}`,
  {
    method: Methods.POST,
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    body: JSON.stringify(paymentObj)
  }
)


export const payTicketForChekhubEndUser = async (chekhub_org_id, ticket_id) => await fetch(
  `${intergrationBaseUri}`,
  {
    method: Methods.POST,
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    body: JSON.stringify({chekhub_org_id: chekhub_org_id, ticket_id: ticket_id})
  }
)


export const getAllPaidTickets = async (chekhub_org_id) => await fetch(
  `${intergrationBaseUriPaid}/?paid=true&chekhub_org_id=${chekhub_org_id}`,
  {
    method: Methods.GET,
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
  }
)


