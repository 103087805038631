import {Drawer, duration, IconButton, Typography} from '@material-ui/core'
import {ChevronLeft} from '@material-ui/icons'
import {useContext, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import noop from '../util/noop'
import {makeStyles} from '@material-ui/core/styles'
import {Context as BlurContext} from '../contexts/BlurContext'

export const RouteConnectedDrawer = ({
  children,
  backRoutePath,
  transitionDuration = {
    enter: duration.enteringScreen, exit: duration.leavingScreen
  },
  open,
  setOpen = noop,
  title,
  onClose = noop,
  ...props
}) => {
  const history = useHistory()
  const {setBlurred} = useContext(BlurContext)
  transitionDuration = typeof transitionDuration === 'number' ? {
    enter: transitionDuration,
    exit: transitionDuration
  } : transitionDuration

  useEffect(() => {
    // We need this to insure the open animation fires for the drawer
    setTimeout(() => {
      setOpen(true)
      setBlurred(true)
    }, 0)
    return () => {
      open && onDrawerClose()
    }
  }, [])

  const onDrawerClose = () => {
    setOpen(false)
    setBlurred(false)
    // And this to insure the close animation fires before route transition
    setTimeout(() => {
      onClose()
      history.push(backRoutePath)
    }, transitionDuration.exit)
  }
  const getDrawerPaperStyles = theme => ({
    maxWidth: '45vw',
    minWidth: theme.spacing(70),
    overflow: 'auto'
  })
  const getDrawerBoxShadow = (theme, side) => ({
    boxShadow: `${
      theme.spacing(side === 'RIGHT' ? -1.25 : 1.25)
    }px 0px ${
      theme.spacing(2.5)
    }px ${
      theme.spacing(-1.25)
    }px ${
      theme.palette.common.black
    }`
  })
  const useRouteConnectedDrawerStyles = makeStyles(theme => ({
    drawerRoot: {
      // TODO: Make this responsive w/ @media queries somehow
      '& .MuiDrawer-paper.MuiDrawer-paperAnchorRight, & .MuiDrawer-paper.MuiDrawer-paperAnchorRight': {
        ...getDrawerPaperStyles(theme),
        ...getDrawerBoxShadow(theme, 'RIGHT')
      },
      '& .MuiDrawer-paper.MuiDrawer-paperAnchorLeft, & .MuiDrawer-paper.MuiDrawer-paperAnchorLeft': {
        ...getDrawerPaperStyles(theme),
        ...getDrawerBoxShadow(theme, 'LEFT')
      },
    },
    drawerTitle: {
      verticalAlign: 'middle'
    }
  }))

  const routerConnectedDrawerClasses = useRouteConnectedDrawerStyles()

  return <Drawer
    {...{
    open,
    onClose: onDrawerClose,
    transitionDuration,
    className: routerConnectedDrawerClasses.drawerRoot,
    ...props
  }}>
    <header>
      <IconButton {...{
        onClick: onDrawerClose
      }}>
        <ChevronLeft/>
      </IconButton>
      {
        title && <Typography {...{
          variant: 'h5',
          display: 'inline',
          className: routerConnectedDrawerClasses.drawerTitle
        }}>{title}</Typography>
      }
    </header>
    {children}
  </Drawer>
}
