import {Box, Card, CardContent} from '@material-ui/core'
import Typography from "@material-ui/core/Typography"
import {formatCurrency} from '../util/number-formatting.util'

// TODO: Make this a dictionary lookup from the screening names here: https://docs.checkr.com/#tag/SSN-Trace
export function humanizeScreeningName(str) {
  let i, frags = str.split('_');
  for (i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

export const BackGroundCheckPackageCard= ({
  bgPackagesSelected: bgcPackage,
}) => <Card variant="outlined">
  <CardContent>
    <Typography gutterBottom variant="h5" component="h2">
      {bgcPackage.name}
    </Typography>
    <Typography gutterBottom variant="h5" component="h2">
      {formatCurrency(
        bgcPackage.price,
        bgcPackage.currency
      )}
    </Typography>
    {bgcPackage.screenings.map((screen, index) =>
      <Box key={index}>
        <Typography variant="body1" component="p" gutterBottom>
          {screen?.type && humanizeScreeningName(screen?.type)}
        </Typography>
        {
          screen.subtype && <Typography variant="subtitle2" component="p" gutterBottom style={{
            fontWeight: 'bold'
          }}>
            {screen.subtype}
          </Typography>
        }
      </Box>
    )}
  </CardContent>
</Card>
