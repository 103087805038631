const initState = {
  fetched: false,
  addedTeamMembers: null,
  tech: {
    Data: [],
    pageCount: 0,
    PageNumber: 0,
    searchQuery: '',
  },
  serviceProvider: {
    Data: [],
    pageCount: 0,
    PageNumber: 0,
    searchQuery: '',
  },
}

const profileListReducer = (state = initState, action) => {
  if (['PROFILE-LIST/RESET'].includes(action.type)) {
    return initState
  }
  if (['PROFILE-LIST/FETCH-TECH-OK'].includes(action.type)) {
    const {
      payload: {
        data,
        pageNumber,
        pageCount,
        searchQuery,
      },
    } = action
    return {
      ...state,
      fetched: true,
      tech: {
        ...state.tech,
        data,
        pageNumber,
        pageCount,
        searchQuery,
      },
    }
  }
  if (['PROFILE-LIST/FETCH-SERVICE-PROVIDER-OK'].includes(action.type)) {
    const {
      payload: {
        data,
        pageNumber,
        pageCount,
        searchQuery,
      },
    } = action
    const teamMembers = data.reduce((acc, curVal) => {
      return curVal.is_connect_team_member ? [...acc, curVal.profile_data._id] : acc
    }, [])
    return {
      ...state,
      fetched: true,
      addedTeamMembers: teamMembers,
      serviceProvider: {
        ...state.serviceProvider,
        data,
        pageNumber,
        pageCount,
        searchQuery,
      },
    }
  }
  if (['PROFILE-LIST/TEAM-ADD-OK'].includes(action.type)) {
    const { payload } = action
    const changedIds = payload.map((item) => item.profile_id)
    const newList = state.serviceProvider.data.map((item) => {
      if (changedIds.includes(item.profile_data._id)) {
        return { ...item, is_connect_team_member: true }
      }
      return item
    })
    return {
      ...state,
      serviceProvider: {
        ...state.serviceProvider,
        data: newList,
      },
      addedTeamMembers: changedIds,
    }
  }
  if (['PROFILE-LIST/TEAM-REMOVE-OK'].includes(action.type)) {
    const { payload } = action
    const payloadIds = payload.map((item) => item.profile_id)
    const filteredIds = state.addedTeamMembers.filter((index) => {
      return !payloadIds.includes(index)
    })
    const newList = state.serviceProvider.data.map((item) => {
      if (filteredIds.includes(item.profile_data._id)) {
        return { ...item, is_connect_team_member: false }
      }
      return item
    })
    return {
      ...state,
      serviceProvider: {
        ...state.serviceProvider,
        data: newList,
      },
      addedTeamMembers: payloadIds,
    }
  }

  if (['PROFILE-LIST/FETCH-ERROR'].includes(action.type)) {
    const { payload: { err } } = action
    const errorMessage = err ? err.message : 'Unknown Error'
    console.info('DEBUG', errorMessage)
    return state
  }

  return state
}

export default profileListReducer
