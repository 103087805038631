import { Redirect, Route, Switch } from 'react-router-dom'
import SelectOrg from './SelectOrg'
import NotFound from './RouterNotFound'
import ProfileEdit from './ProfileEdit'
import TechsList from './TechsList'
import Ticket from './Ticket'
import ServiceCompanyView from './ServiceCompany.view'
import CreateCompany from './CreateCompany'
import UserProfileView from './UserProfile.view'
import ListServiceCompanyView from "./ListServiceCompany.view"
import TechProfileView from './TechProfile.view'
import ServiceCompanyTechView from "./ServiceCompanyTech.view"
import ServiceCompanyLandingView from "./ServiceCompanyLanding.view"
import {
  CATCH_ALL_PATH,
  CHEKHUB_END_USER_ENGAGEMENTS,
  CHEKHUB_END_USER_ENGAGEMENTS_CURRENT_TEAMS, CHEKHUB_END_USER_ENGAGEMENTS_INVITED_TEAMS,
  CHEKHUB_END_USER_ENGAGEMENTS_TICKETS,
  CHEKHUB_END_USER_LANDING, CHEKHUB_END_USER_REQUIRED_DOCS,
  CHEKHUB_END_USER_WALLET,
  DASHBOARD_PATH, INDY_TECH_CURRENT_TEAMS,
  INDY_TECH_ENGAGEMENTS, INDY_TECH_INVITED_TEAMS, INDY_TECH_TICKETS, INDY_TECH_WALLET,
  INVITE_CREATE_PATH,
  INVITES_LIST_PATH,
  KEVIN_SANDBOX,
  MATT_SANDBOX,
  PRAHLAD_SANDBOX,
  PROFILES_LIST_PATH,
  PROFILES_SC_CREATE_PATH,
  ROOT_PATH,
  SC_LIST_PATH, SELECT_ORG,
  SELECT_ORG_PATH,
  SERVICE_COMPANY_ENGAGEMENTS_CURRENT_TEAMS,
  SERVICE_COMPANY_ENGAGEMENTS_TICKETS, SERVICE_COMPANY_INVITED_TEAMS,
  SERVICE_COMPANY_MANAGE_MEMBERS,
  SERVICE_COMPANY_SUMMARY,
  SERVICE_COMPANY_VIEW_PATH,
  SERVICE_COMPANY_WALLET,
  SIGN_UP_COMPLETE_PROFILE_PATH,
  SIGN_UP_COMPLETE_TECH_PROFILE_PATH,
  SIGN_UP_SC_CREATE_PATH, SIGN_UP_SELECT_USER_TYPE,
  TECH_PROFILE_VIEW_PATH,
  TECH_VIEW_PATH,
  TECHS_LIST_PATH,
  TICKET_VIEW_PATH,
  USER_PROFILE_VIEW_PATH
} from '../routing/route.constants'
import ServiceCompanyManageMembersView from "./ServiceCompanyManageMembersView"
import { SandboxMattView } from '../components/sandboxes/matt/SandboxMatt.view'
import { SandboxKevinView } from '../components/sandboxes/kevin/SandboxKevin.view'
import { SandboxPrahladView } from '../components/sandboxes/prahlad/SandboxPrahlad.view'
import { RevenueBillingView } from './RevenueBilling.view'
import { getDefaultRedirectPath } from '../util/users.util'
import DashboardView from './Dashboard.view'
import { useAuthUser } from '../hooks/useAuthUser'
import { ChekhubEndUserLanding } from './ChekhubEndUserLanding'
import { ViewEngagementsChekhubEndUser } from '../components/ViewEngagementsChekhubEndUser'
import { RevenueBillingChekhubView } from './RevenueBillingChekhub.view'
import { ViewEngagementsIndyTech } from './ViewEngagementsIndyTech'
import { CurrentTeamOrProviderListEndUser } from '../components/CurrentTeamOrProviderListEndUser'
import { TicketListView } from './TicketList.view'
import { CurrentTeamOrProviderListSc } from '../components/CurrentTeamOrProviderListSc'
import { CurrentTeamOrProviderListIndy } from '../components/CurrentTeamOrProviderListIndy'
import { SelectOrgView } from '../components/SelectOrgView'
import { CurrentInvitedTeamsEndUser } from '../components/CurrentInvitedTeamsEndUser'
import { RequiredDocumentsChekhubEndUser } from '../components/RequiredDocumentsChekhubEndUser'
import { SelectUserTypeEndUser } from '../components/SelectUserTypeEndUser'
import { MiddleManSignUpPage } from './MiddleManSignUpPage'

const RerouteRoot = () => <Redirect to={getDefaultRedirectPath(useAuthUser())} />

// TODO: Move this out to `/src/routing` and remove anything not routing related (i.e. Grid)
const Private = () =>
  <Switch>
    <Route exact path={ROOT_PATH}>
      <RerouteRoot />
    </Route>
    <Route exact path={DASHBOARD_PATH} component={DashboardView} />
    <Route exact path={SELECT_ORG} component={SelectOrgView} />
    <Route exact path={SELECT_ORG_PATH} component={SelectOrg} />
    <Route exact path={SIGN_UP_SC_CREATE_PATH} component={ServiceCompanyView}/>
    <Route exact path={SIGN_UP_COMPLETE_PROFILE_PATH} component={MiddleManSignUpPage}/>
    <Route exact path={SIGN_UP_COMPLETE_TECH_PROFILE_PATH} component={TechProfileView}/>
    <Route exact path={PROFILES_SC_CREATE_PATH} component={CreateCompany} />
    <Route exact path={SIGN_UP_SELECT_USER_TYPE} component={SelectUserTypeEndUser} />
    <Route exact path={TECH_PROFILE_VIEW_PATH} component={TechProfileView} />
    <Route exact path={SC_LIST_PATH} component={ListServiceCompanyView} />
    <Route exact path={TECHS_LIST_PATH} component={TechsList} />
    <Route path={INDY_TECH_CURRENT_TEAMS} component={CurrentTeamOrProviderListIndy} />
    <Route path={INDY_TECH_INVITED_TEAMS} component={CurrentInvitedTeamsEndUser} />
    <Route path={INDY_TECH_TICKETS} component={TicketListView} />
    <Route path={INDY_TECH_WALLET} component={RevenueBillingView} />
    <Route path={TECH_VIEW_PATH} component={ServiceCompanyTechView} />
    <Route exact path={USER_PROFILE_VIEW_PATH} component={UserProfileView} />
    <Route exact path={PROFILES_LIST_PATH} component={ProfileEdit} />
    <Route exact path={TICKET_VIEW_PATH} component={Ticket} />
    <Route exact path={INVITE_CREATE_PATH} component={ProfileEdit} />
    <Route exact path={INVITES_LIST_PATH} component={ProfileEdit} />
    <Route exact path={SERVICE_COMPANY_SUMMARY} component={ServiceCompanyLandingView} />
    <Route path={SERVICE_COMPANY_WALLET} component={RevenueBillingView} />
    <Route path={SERVICE_COMPANY_MANAGE_MEMBERS} component={ServiceCompanyManageMembersView} />
    <Route path={SERVICE_COMPANY_VIEW_PATH} component={ServiceCompanyView} />
    <Route path={SERVICE_COMPANY_ENGAGEMENTS_CURRENT_TEAMS} component={CurrentTeamOrProviderListSc} />
    <Route path={SERVICE_COMPANY_INVITED_TEAMS} component={CurrentInvitedTeamsEndUser} />
    <Route path={SERVICE_COMPANY_ENGAGEMENTS_TICKETS} component={TicketListView} />
    <Route path={CHEKHUB_END_USER_ENGAGEMENTS_CURRENT_TEAMS} component={CurrentTeamOrProviderListEndUser} />
    <Route path={CHEKHUB_END_USER_ENGAGEMENTS_INVITED_TEAMS} component={CurrentInvitedTeamsEndUser} />
    <Route path={CHEKHUB_END_USER_REQUIRED_DOCS} component={RequiredDocumentsChekhubEndUser} />
    <Route path={CHEKHUB_END_USER_ENGAGEMENTS_TICKETS} component={TicketListView} />
    <Route path={CHEKHUB_END_USER_LANDING} component={ChekhubEndUserLanding} />
    <Route path={CHEKHUB_END_USER_WALLET} component={RevenueBillingChekhubView} />

    <Route path={MATT_SANDBOX} component={SandboxMattView} />
    <Route path={KEVIN_SANDBOX} component={SandboxKevinView} />
    <Route path={PRAHLAD_SANDBOX} component={SandboxPrahladView} />

    <Route component={NotFound} />
    <Route path={CATCH_ALL_PATH} component={NotFound} />
  </Switch>

export default Private
