import {useState} from 'react'
import {PlacesAutoComplete} from '../../../PlacesAutoComplete'
import {useValidations} from '../../../../hooks/useValidations'
import noop from '../../../../util/noop'
import {FormSubmitButton} from '../../../FormSubmitButton'
import DEFAULT_ADDRESS_DETAILS from './mock-data/address-details.json'

export const PlacesAutoCompleteExample = () => {
  const [entity, setEntity] = useState({
    addressDetails: DEFAULT_ADDRESS_DETAILS
  })
  console.log(`entity.addressDetails => `, JSON.stringify(entity.addressDetails))
  return <PlacesAutoCompleteExampleForm {...{
    entity,
    onAddressChange: (ev, addressDetails) => {
      setEntity({
        ...entity,
        addressDetails
      })
    },
    onSubmit: ev => {
      ev.preventDefault()
      console.log(`ev => `, ev)
    }
  }} />
}

const ADDRESS_FIELDNAME = 'address'
const placesAutoCompleteValidations = {
  [ADDRESS_FIELDNAME]: {
    required: 'Company address is required'
  }
}
const PlacesAutoCompleteExampleForm = ({
  entity = {},
  onAddressChange = noop,
  onSubmit = noop
}) => {
  const {
    addressDetails = {}
  } = entity
  const {
    onBlurValidation,
    onChangeValidation,
    onSubmitValidation,
    fieldErrors,
    hasFieldErrors
  } = useValidations(
    placesAutoCompleteValidations
  )

  const handleOnAddressChange = (ev, addressDetails) => {
    onChangeValidation(ev, addressDetails)
    onAddressChange(ev, addressDetails)
  }

  const handleOnSubmit = ev => {
    ev.preventDefault()
    const formIsValid = onSubmitValidation(ev)
    !hasFieldErrors() && formIsValid && onSubmit(ev)
  }

  return <form {...{
    onSubmit: handleOnSubmit
  }}>
    <PlacesAutoComplete {...{
      id: 'address',
      name: 'address',
      size: 'small',
      addressDetails,
      onChange: handleOnAddressChange,
      required: true,
      onBlur: onBlurValidation,
      error: Boolean(fieldErrors[ADDRESS_FIELDNAME]),
      helperText: fieldErrors[ADDRESS_FIELDNAME] || null
    }} />
    <FormSubmitButton {...{
      disabled: hasFieldErrors()
    }}>
      <span>Submit</span>
    </FormSubmitButton>
  </form>
}
