import { Box, Button, Checkbox, Tooltip, Typography } from '@material-ui/core'
import { EditRemoveButtons } from './EditRemoveButtons'
import makeStyles from '@material-ui/core/styles/makeStyles'
import noop from '../util/noop'
import { CheckSharp } from '@material-ui/icons'
import { SOURCE_TYPE_BANK_ACCOUNT } from './PaymentSources'

const usePaymentSourceStyles = makeStyles(theme => ({
  iconTypography: {
    marginRight: theme.spacing(1),
    lineHeight: 1
  },
  sourceName: {
    lineHeight: '1.2rem'
  },
  typeDisplay: {
    marginRight: theme.spacing(.5)
  },
  verifyButton: {
    marginLeft: theme.spacing(1)
  },
  verifiedIcon: {
    color: theme.palette.success.main,
    marginLeft: theme.spacing(1)
  }
}))

// TODO: Just pass in the source as an object here instead of all these different properties
export const PaymentSource = ({
  sourceId,
  sourceName,
  last4,
  icon,
  typeDisplay = '',
  onRemove = noop,
  disableRemove = false,
  sourceType,
  sourceStatus,
  onVerify = noop,
  enableSelect = false,
  selected = false,
  handleOnSelect = noop
}) => {
  const styles = usePaymentSourceStyles()
  const showVerifyButton = sourceType === SOURCE_TYPE_BANK_ACCOUNT && sourceStatus === 'new'
  const showVerifiedCheck = sourceType === SOURCE_TYPE_BANK_ACCOUNT && sourceStatus !== 'new'
  return <Box {...{
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  }}>
    {
      enableSelect &&
        <Checkbox
          checked={selected?.source_id === sourceId}
          onChange={handleOnSelect}
          name="selected"
          color="primary"
        />
    }
    <Typography {...{
      className: styles.iconTypography,
      variant: 'h4',
      color: 'textSecondary'
    }} >
      {icon}
    </Typography>
    <Box {...{
      display: 'flex-inline',
      direction: 'column'
    }}>
      <Typography {...{
        variant: 'body1',
        className: styles.sourceName
      }} >
        {sourceName}
      </Typography>
      <Typography {...{
        variant: 'caption'
      }} >
        <span className={styles.typeDisplay}>{typeDisplay}</span>
        <span>••••</span>
        <span>{last4}</span>
      </Typography>
    </Box>
    {
      showVerifyButton &&
        <Button {...{
          variant: 'outlined',
          size: 'small',
          className: styles.verifyButton,
          onClick: onVerify
        }}>Verify</Button>
    }
    {
      showVerifiedCheck &&
        <Tooltip title="Account verified and ready for use" placement="right">
          <CheckSharp {...{
            className: styles.verifiedIcon,
            fontSize: 'small',
          }} />
        </Tooltip>
    }
    {
      !disableRemove && <Box marginLeft="auto">
        <EditRemoveButtons {...{
          disableEdit: true,
          onRemove
        }} />
      </Box>
    }
  </Box>
}
