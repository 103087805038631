import React from 'react'
import { useDispatch } from 'react-redux'

import {
  Button,
  TableCell,
  TableRow,
} from '@material-ui/core'

import formatAddress from '../shared-functions/format-address'
import { insuranceIcon } from '../shared-functions/compact-display-lists'
import actions from '../../store/actions'

function ServiceCompanyRowComponent({ profile, headers, orgId }) {
  const {
    profile_type: profileType,
    is_connect_team_member: isConnectTeamMember,
    profile_data: profileData,
  } = profile
  const dispatch = useDispatch()

  const teamButton = () => {
    return (
      <Button
        size="small"
        style={{ marginLeft: 'auto' }}
        variant="outlined"
        onClick={() => {
          dispatch(actions['PROFILE/ADD-TO-TEAM'](profileData._id, orgId, profileType, isConnectTeamMember))
        }}
      >
        { isConnectTeamMember ? 'Remove' : 'Add'}
      </Button>
    )
  }

  const getDisplayValue = (key) => {
    switch (key) {
      case 'profile_type':
        return profileType
      case 'is_connect_team_member':
        return teamButton()
      case 'phones':
        return profileData[key]?.[0].raw_phone
      case 'location':
        return formatAddress(profileData[key]?.address)
      case 'insurance_policies':
        return insuranceIcon(profileData[key])
      default:
        return profileData[key]
    }
  }

  return (
    <TableRow key={profileData._id}>
      {headers.map((hdr) => (
        <TableCell
          component={hdr.header ? 'th' : 'td'}
          align={hdr.align}>
          {getDisplayValue(hdr.key)}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default ServiceCompanyRowComponent
