// TODO: Rename this to SignUp.js
import { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import SignUpForm from './SignUpForm'
import {setStepperSCFlow, setStepperTechsFlow} from "../store/actions/stepper.actions"
import {signUpUser} from '../store/actions/users.actions'
import {SIGN_UP_COMPLETE_TECH_PROFILE_PATH, SIGN_UP_SC_CREATE_PATH} from '../routing/route.constants'
import {INDEPENDENT_TECH_USER_TYPE, SC_USER_TYPE} from '../constants/user.constants'
import {isServiceErrorResult} from '../services/is-service-error-result.util'
import { resetServiceCompanyState } from '../store/actions/service-company.actions'
import { Card, Divider, Typography } from '@material-ui/core'
import { FormSubmitButton } from './FormSubmitButton'

// TODO: Move all this business logic up into /pages/SignUp[.view].js
const SignUpComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(resetServiceCompanyState())
    dispatch({type: 'AUTH/CLEAR-ERROR'})
  }, [])

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    userType: SC_USER_TYPE,
    first_name: '',
    last_name: '',
    company_name: '',
  })

  const handleChange = e => setCredentials({
    ...credentials,
    [e.target.id]: e.target.value,
  })

  const handleSubmit = async e => {
    e.preventDefault()
    const signUpError = await dispatch(signUpUser(credentials))

    !isServiceErrorResult(signUpError) && history.push(
      credentials.userType === SC_USER_TYPE ?
      SIGN_UP_SC_CREATE_PATH : SIGN_UP_COMPLETE_TECH_PROFILE_PATH
    )
  }

  useEffect(async () => {
    await dispatch(setStepperSCFlow())
  }, [])


  return  <SignUpForm
    handleChange={handleChange}
    handleSubmit={handleSubmit}
    userType={credentials.userType}
    auth={auth}
  />
}

export default SignUpComponent
