import makeStyles from "@material-ui/core/styles/makeStyles"
import {MAX_PAGE_WIDTH} from '../theme'

export const useMainNavBarStyles = makeStyles((theme) => ({
  links: {
    paddingRight: theme.spacing(3),
    '& a': {
      color: theme.palette.secondary.main,
      '&:active, &.active': {
        color: theme.palette.primary.main
      },
      '&:hover': {
        color: theme.palette.primary.light
      }
    },
  },
  paper: {
    width: '100%',
    zIndex: 1
  },
  navWrapper: {
    backgroundColor: theme.palette.surfaces.paper.background,
  } ,
  nav: {
    width: '100%',
    maxWidth: MAX_PAGE_WIDTH,
    '& h3': {
      fontWeight: 'normal'
    },
    margin: `0 ${theme.spacing(0)}px 0 ${theme.spacing(2)}px`,
    padding: `${theme.spacing(1)}px 0`,
    textTransform: 'uppercase'
  }
}))
