import {
  List,
} from '@material-ui/core'
import PhoneNumberDisplay from './PhoneNumberDisplay'
import Typography from '@material-ui/core/Typography'

const PhoneNumbersDisplay = ({ phones= []}) => {
  return (
    <>
      <List dense disablePadding key={phones}>
        {
          !phones?.length &&
          <Typography {...{
            variant: 'subtitle'
          }}>No Phone Numbers Available</Typography>
        }
        {phones.map((phone, index) =>
          <PhoneNumberDisplay
            {...{
              phone,
              index,
              style: {
                alignItems: 'center'
              }
            }}
            disableActions={true}
          />
        )}
      </List>
    </>
  )
}

export default PhoneNumbersDisplay
