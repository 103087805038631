import useLandingViewStyles from '../components/LandingViewStyles'
import {useDispatch, useSelector} from 'react-redux'
import {fetchServiceCompany, resetServiceCompanyState} from '../store/actions/service-company.actions'
import Grid from '@material-ui/core/Grid'
import {Hidden} from '@material-ui/core'
import ServiceCompanySummary from '../components/ServiceCompanySummary'
import {Redirect, useParams} from 'react-router-dom'
import {fetchTechListForOrg, userListResetState} from '../store/actions/users.actions'
import {ServiceCompanyMembers} from '../components/ServiceCompanyMembers'
import {useEffect, useRef} from 'react'
import {useAuthUser} from '../hooks/useAuthUser'
import {getDefaultRedirectPath, isServiceCompanyManager} from '../util/users.util'
import {useDimensionsContextStyles} from '../components/DimensionsContext'
import useAccessToken from '../store/actions/useAccessToken'

const ServiceCompanyManageMembersView = () => {
  const styles = useLandingViewStyles()
  const dimensionsContextStyles = useDimensionsContextStyles()
  const {
    serviceCompany,
    userList: members,
  } = useSelector(
    ({
      serviceCompany,
      userList,
    }) => ({
      serviceCompany,
      userList,
    })
  )
  const authorizedUser = useAuthUser()
  const {orgId} = useParams()
  const isManager = isServiceCompanyManager(authorizedUser, orgId)
  const dispatch = useDispatch()

  function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const previousTechList = usePrevious(members) || []

  useEffect(
    async () =>
      orgId && !serviceCompany._id && dispatch(fetchServiceCompany(orgId)),
    [serviceCompany]
  )

  useEffect(
    () => orgId && dispatch(fetchTechListForOrg(orgId)),
    []
  )

  // TODO: figure out how the heck to insure this only fires ONCE on mount.
  useEffect(async () => {
      return orgId && (!members ||
        members.length !== previousTechList?.length) &&
        await dispatch(fetchTechListForOrg(orgId))
    },
    [members]
  )

  useEffect(() => () => {
    dispatch(userListResetState())
  }, [])

  return !isManager && orgId ?
    <Redirect to={getDefaultRedirectPath(authorizedUser)}/> :
    ((orgId && serviceCompany._id) || (!orgId && serviceCompany)) &&
      <Grid container className={dimensionsContextStyles.minHeight}>
        <Hidden smDown>
          <Grid item xs={3}>
            <ServiceCompanySummary {...{
              profile: serviceCompany
            }} />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          xl={9}
          className={styles.skeleton} >
          <ServiceCompanyMembers {...{
            members,
            serviceCompany
          }}/>
        </Grid>
    </Grid>
}

export default ServiceCompanyManageMembersView
