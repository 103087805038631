import {createReducer} from 'redux-create-reducer'
import { INVITED_TEAMS_RESET_STATE, INVITED_TEAMS_SET_STATE } from '../actions/invited-teams-list.actions'
import {
  REQUIRED_DOC_RESET_STATE, REQUIRED_DOC_SET_STATE,
  REQUIRED_DOCS_RESET_STATE,
  REQUIRED_DOCS_SET_STATE
} from '../actions/required-docs.actions'


export const INIT_REQUIRED_DOCS_STATE  = []

export const requiredDocsReducer = createReducer(
  INIT_REQUIRED_DOCS_STATE,
  {
    [REQUIRED_DOCS_SET_STATE]: (state, {list}) => [
      ...list
    ],
    [REQUIRED_DOCS_RESET_STATE]: () => INIT_REQUIRED_DOCS_STATE
  }
)


export const INIT_REQUIRED_DOC_STATE  = {}

export const requiredDocReducer = createReducer(
  INIT_REQUIRED_DOC_STATE,
  {
    [REQUIRED_DOC_SET_STATE]: (state, {doc}) => {
      doc
    },
    [REQUIRED_DOC_RESET_STATE]: () => INIT_REQUIRED_DOCS_STATE
  }
)
