import axios from 'axios'

import appConfig from '../../../config'

const updateUser = async (credentials, userType, accessToken) => {
  return [null, null] // the api endpoint is not ready
  // eslint-disable-next-line no-unreachable
  const { chekhubConnect: { baseURI, operations, defaultHeaders } } = appConfig
  const { usersUpdate: { path, method } } = operations

  try {
    const { status, data: body } = await axios({
      baseURL: baseURI,
      url: `${path}/${credentials._id}`,
      method,
      headers: {
        ...defaultHeaders,
        authorization: `Bearer ${accessToken}`,
      },
      params: {
        user_type: userType,
        agent: 'react',
      },
      data: credentials,
    })

    if (status !== 200) throw new Error('unknown chekhubAuthError')
    return [body.data, null]
  } catch (err) {
    return [null, err]
  }
}

export default updateUser
