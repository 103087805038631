import {setBackendWorking, setBackendWorkingDone} from './backend-working.actions'
import {
  getAuthCustomToken,
  identityProviderAuth,
  identityProviderSignInWithCustomToken
} from '../../services/identity-provider.service'
import { chekhubCustomerSignInParams } from './sign-in.actions'
import { CHEKHUB_END_USER_LANDING } from '../../routing/route.constants'
import { useHistory } from 'react-router-dom'

export const IDENTITY_PROVIDER_SIGN_IN_OK = 'IDENTITY_PROVIDER_SIGN_IN_OK'

export const identityProviderSignIn = (credentials) => async dispatch => {
  let err = undefined
  console.log('reg login')
  dispatch(setBackendWorking())
    try {
      await identityProviderAuth(credentials)
      dispatch(identityProviderSignInOk(credentials.userType))
    } catch (error) {
      err = error
      dispatch({type: 'AUTH/SIGN-IN-ERROR', error})
    } finally {
      dispatch(setBackendWorkingDone())
    }
    return err
}

export const identityProviderCustomTokenSignIn = (credentials) => async dispatch => {
  let err = undefined
  console.log('special login')
  console.log(credentials)
  dispatch(setBackendWorking())
    try {
      let resp = await getAuthCustomToken(credentials)
      if (!resp.ok) {
        throw new Error('Sign in failed')
      }
      const {data: {access_token: token}} = await resp.json()
      await identityProviderSignInWithCustomToken(token)
      dispatch(identityProviderSignInOk(credentials.userType))
    } catch (error) {
      err = error
      dispatch({type: 'AUTH/SIGN-IN-ERROR', error})
    } finally {
      dispatch(setBackendWorkingDone())
    }
    return err
}

export const identityProviderCustomTokenSignInWithParams = (quickFix, history) => async dispatch => {
  let err = undefined
  console.log('special login')
  console.log(quickFix)
  dispatch(setBackendWorking())
  await identityProviderSignInWithCustomToken(quickFix)
  dispatch(setBackendWorkingDone())
}


export const identityProviderSignInOk = userType => ({
  type: IDENTITY_PROVIDER_SIGN_IN_OK,
  userType
})
