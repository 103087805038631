import { Route, Switch, withRouter } from 'react-router-dom'
import { RouteConnectedDrawer } from '../components/RouteConnectedDrawer'
import { duration } from '@material-ui/core'
import { useContext } from 'react'
import { Context as BlurContext } from '../contexts/BlurContext'
import { RequiredDocsSummary } from './RequiredDocsSummary'


export const RequiredDocsDrawerUtil = withRouter(
  ({
     open,
     setOpen,
     backRoutePath,
     match,
     routeTransitionDelay = duration.leavingScreen,
     requiredDocsList,
     onDialogOpen,
     setOpenDialog
   }) => {
    const {setBlurred} = useContext(BlurContext)
    return <Switch>
      <Route path={`${match.path}/:docId/summary`}>
        <RouteConnectedDrawer {...{
          open,
          setOpen,
          anchor: 'right',
          title: 'View Service Provider',
          backRoutePath
        }}>
          <RequiredDocsSummary {...{
            requiredDocsList,
            setOpen,
            backRoutePath,
            routeTransitionDelay,
            setBlurred,

          }}/>
        </RouteConnectedDrawer>
      </Route>
    </Switch>
  }
)
