import { useSelector } from 'react-redux'
import { Button, Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import noop from '../util/noop'
import { BusyIndicator } from './BusyIndicator'

export const useFormSubmitButtonStyles = makeStyles(theme => ({
  button: {
    '&:disabled': {
      paddingLeft: theme.spacing(.5),
      paddingRight: theme.spacing(.5)
    }
  },
  fab: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    '& .MuiButton-textPrimary': {
      color: theme.palette.common.white
    }
  }
}))

// TODO: use this for form submit buttons across the codebase
export const FormSubmitButton = ({
  onClick = noop,
  children,
  useFab = false,
  className = '',
  disabled,
  stillBusy,
                                   isBusy,
                                   startBusy,
                                   endBusy,
  color,
  ...restProps
}) => {
  const {backgroundRunning} = useSelector(
    ({auth: {backgroundRunning}}) => ({
      backgroundRunning
    })
  )

  const styles = useFormSubmitButtonStyles()
  const button = <Button {...{
    type: onClick === noop ? 'submit' : 'button',
    variant: !useFab ? 'contained' : undefined,
    color: !color ? 'primary' : color,
    onClick,
    disabled: backgroundRunning || disabled,
    ...restProps,
    className: `${className || ''} ${styles.button}`,
  }} >
    {
      children
    }<BusyIndicator {...{
      busy: isBusy,
      inverse: restProps.variant === 'contained'
    }} />
  </Button>

  return !useFab ? button :
    <Fab {...{
      className: `${className || ''} ${styles.fab}`,
      variant: 'extended',
      color: 'primary',
      size: 'medium',
      disabled: backgroundRunning || disabled,
      ...restProps
    }} >
      {button}
    </Fab>
}
