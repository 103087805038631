import {useValidations} from '../../../../hooks/useValidations'
import {useState} from 'react'
import Typography from '@material-ui/core/Typography'
import {SSN_FIELDNAME, SsnInput, SsnInputValidations} from '../../../SsnInput'

export const SsnInputExample = () => {
  const [ssn, setSsn] = useState()
  const {
    onChangeValidation,
    onBlurValidation,
    fieldErrors,
  } = useValidations({
    [SSN_FIELDNAME]: {
      ...SsnInputValidations,
      required: 'Enter your SSN foo!'
    }
  })

  const handleOnChange = ev => {
    const {detail} = ev
    onChangeValidation(ev, detail.value, detail)
    setSsn(ev.target.value)
  }

  const handleOnBlur = ev => {
    const {detail} = ev
    onBlurValidation(ev, detail.value, detail)
  }
  return <>
    <SsnInput {...{
      fullWidth: true,
      required: true,
      ssn,
      onChange: handleOnChange,
      onBlur: handleOnBlur,
      error: Boolean(fieldErrors[SSN_FIELDNAME]),
      helperText: fieldErrors[SSN_FIELDNAME] &&
        <Typography component={'span'}>{fieldErrors[SSN_FIELDNAME]}</Typography>
    }} />
    <h4>Unmasked value: {ssn}</h4>
  </>
}
