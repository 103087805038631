import {createReducer} from "redux-create-reducer"
import {
  RESET_BACKGROUND_CHECK_PACKAGES_STATE,
  SET_BACKGROUND_CHECK_PACKAGES_STATE
} from "../actions/background-check.actions"

export const BACKGROUND_CHECK_INIT_STATE = {
  packages: []
}

export const backgroundCheckReducer = createReducer(
  BACKGROUND_CHECK_INIT_STATE,
  {
    [SET_BACKGROUND_CHECK_PACKAGES_STATE]: (state, {packages}) => ({
      ...state,
      packages
    }),
    [RESET_BACKGROUND_CHECK_PACKAGES_STATE]: () => ({...BACKGROUND_CHECK_INIT_STATE})
  }
)
