export const teamOrProvider = [
  {
  "status": "active",
  "working_hours": [],
  "company_logo": null,
  'is_connect_member': true,
  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
    'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut' +
    ' enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ' +
    'ut aliquip ex ea commodo consequat',
  'industries': [
    'ATM Machines',
    'Car Electronics'
  ],
  "company_formation_year": 2010,
  "website": "https://www.defense.gov",
  "linkedin_page": "https://www.linkedin.com/company/deptofdefense/",
  "primary_contact_id": "f1CmA1yLQRhMeULBqr79n8pqpz52",
  "accounting_contact_id": null,
  "ein": "87-2342343",
  "revenue": {
    "currency": "usd",
    "ytd": 0,
    "lifetime": 0
  },
  "_id": "60766713cb0bd2d48f01c44c",
  "company_name": "ACME Firearms Inc.",
  "phones": [
    {
      "label": "home",
      "country": " ",
      "_id": "60b030a00e112903ae62e5b4",
      "raw_phone": "15555555554",
      "formatted_phone_local": null,
      "formatted_phone_intl": null
    },
    {
      "label": "mobile",
      "country": " ",
      "_id": "60bfb879935bb028f641f512",
      "raw_phone": "13333333332",
      "formatted_phone_local": null,
      "formatted_phone_intl": null
    }
  ],
  "location": {
    "label": "work",
    "alt_label": null,
    "lat": 38.8976634,
    "long": -77.03657419999999,
    "formatted_address": "1600 Pennsylvania Avenue NW, Washington, DC 20500, USA",
    "address": {
      "street_number": "1600",
      "route": "Pennsylvania Avenue NW",
      "locality": "Washington",
      "administrative_area_level_3": null,
      "administrative_area_level_2": null,
      "administrative_area_level_1": "District of Columbia",
      "administrative_area_level_1_abbr": "DC",
      "country": "United States",
      "country_abbr": "US",
      "postal_code": "20500",
      "address_detail": null
    }
  },
  "served_metros": [],
  "insurance_policies": [
    {
      "_id": "60a42cb07f9add82c79b5240",
      "issued_by": "Alliance Insurance Inc.",
      "insurance_type": "workers_comp",
      "issued_on": "2021-05-21T00:00:00",
      "filename": "certificates/60a42caae810c697169cba7d.pdf",
      "updated_at": "2021-06-08T18:35:37.423Z",
      "created_at": "2021-06-08T18:35:37.423Z",
      "expires_on": "2021-05-31T00:00:00",
      "policy_number": "234r234f"
    },
    {
      "_id": "60a44bd59f4b02e50d16132b",
      "insurance_type": "liability",
      "issued_by": "Progressive",
      "policy_number": "3094rt0394jf",
      "issued_on": "2021-05-07T00:00:00",
      "expires_on": "2021-05-22T00:00:00",
      "filename": "certificates/60a44b84b3591c9d0281c3c2.pdf",
      "updated_at": "2021-06-08T18:35:37.423Z",
      "created_at": "2021-06-08T18:35:37.423Z"
    }
  ],
  "created_at": "2021-04-14T03:52:51.531Z",
  "updated_at": "2021-06-08T18:35:37.423Z"
},
  {
    "status": "active",
    "working_hours": [],
    "company_logo": null,
    'is_connect_member': true,
    'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
      'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut' +
      ' enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ' +
      'ut aliquip ex ea commodo consequat',
    'industries': [
      'ATM Machines',
    ],
    "company_formation_year": 2010,
    "website": "https://www.defense.gov",
    "linkedin_page": "https://www.linkedin.com/company/deptofdefense/",
    "primary_contact_id": "f1CmA1yLQRhMeULBqr79n8pqpz52",
    "accounting_contact_id": null,
    "ein": "87-2342343",
    "revenue": {
      "currency": "usd",
      "ytd": 0,
      "lifetime": 0
    },
    "_id": "60766713cb0bd2d48f01c46c",
    "company_name": "Cracker Barrel",
    "phones": [
      {
        "label": "home",
        "country": " ",
        "_id": "60b030a00e112903ae62e5b4",
        "raw_phone": "15555555554",
        "formatted_phone_local": null,
        "formatted_phone_intl": null
      },
      {
        "label": "mobile",
        "country": " ",
        "_id": "60bfb879935bb028f641f512",
        "raw_phone": "13333333332",
        "formatted_phone_local": null,
        "formatted_phone_intl": null
      }
    ],
    "location": {
      "label": "work",
      "alt_label": null,
      "lat": 38.8976634,
      "long": -77.03657419999999,
      "formatted_address": "1600 Pennsylvania Avenue NW, Washington, DC 20500, USA",
      "address": {
        "street_number": "1600",
        "route": "Pennsylvania Avenue NW",
        "locality": "Washington",
        "administrative_area_level_3": null,
        "administrative_area_level_2": null,
        "administrative_area_level_1": "District of Columbia",
        "administrative_area_level_1_abbr": "DC",
        "country": "United States",
        "country_abbr": "US",
        "postal_code": "20500",
        "address_detail": null
      }
    },
    "served_metros": [],
    "insurance_policies": [
      {
        "_id": "60a42cb07f9add82c79b5240",
        "issued_by": "Alliance Insurance Inc.",
        "insurance_type": "workers_comp",
        "issued_on": "2021-05-21T00:00:00",
        "filename": "certificates/60a42caae810c697169cba7d.pdf",
        "updated_at": "2021-06-08T18:35:37.423Z",
        "created_at": "2021-06-08T18:35:37.423Z",
        "expires_on": "2021-05-31T00:00:00",
        "policy_number": "234r234f"
      },
      {
        "_id": "60a44bd59f4b02e50d16132b",
        "insurance_type": "liability",
        "issued_by": "Progressive",
        "policy_number": "3094rt0394jf",
        "issued_on": "2021-05-07T00:00:00",
        "expires_on": "2021-05-22T00:00:00",
        "filename": "certificates/60a44b84b3591c9d0281c3c2.pdf",
        "updated_at": "2021-06-08T18:35:37.423Z",
        "created_at": "2021-06-08T18:35:37.423Z"
      }
    ],
    "created_at": "2021-04-14T03:52:51.531Z",
    "updated_at": "2021-06-08T18:35:37.423Z"
  },
  {
    "status": "active",
    "working_hours": [],
    "company_logo": null,
    'is_connect_member': true,
    'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
      'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut' +
      ' enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ' +
      'ut aliquip ex ea commodo consequat',
    'industries': [
      'ATM Machines',
      'Car Electronics'
    ],
    "company_formation_year": 2010,
    "website": "https://www.defense.gov",
    "linkedin_page": "https://www.linkedin.com/company/deptofdefense/",
    "primary_contact_id": "f1CmA1yLQRhMeULBqr79n8pqpz52",
    "accounting_contact_id": null,
    "ein": "87-2342343",
    "revenue": {
      "currency": "usd",
      "ytd": 0,
      "lifetime": 0
    },
    "_id": "60766713cb0bd2d48f01c45c",
    "company_name": "Woody's Gamertag",
    "phones": [
      {
        "label": "home",
        "country": " ",
        "_id": "60b030a00e112903ae62e5b4",
        "raw_phone": "15555555554",
        "formatted_phone_local": null,
        "formatted_phone_intl": null
      },
      {
        "label": "mobile",
        "country": " ",
        "_id": "60bfb879935bb028f641f512",
        "raw_phone": "13333333332",
        "formatted_phone_local": null,
        "formatted_phone_intl": null
      }
    ],
    "location": {
      "label": "work",
      "alt_label": null,
      "lat": 38.8976634,
      "long": -77.03657419999999,
      "formatted_address": "1600 Pennsylvania Avenue NW, Washington, DC 20500, USA",
      "address": {
        "street_number": "1600",
        "route": "Pennsylvania Avenue NW",
        "locality": "Washington",
        "administrative_area_level_3": null,
        "administrative_area_level_2": null,
        "administrative_area_level_1": "District of Columbia",
        "administrative_area_level_1_abbr": "DC",
        "country": "United States",
        "country_abbr": "US",
        "postal_code": "20500",
        "address_detail": null
      }
    },
    "served_metros": [],
    "insurance_policies": [
      {
        "_id": "60a42cb07f9add82c79b5240",
        "issued_by": "Alliance Insurance Inc.",
        "insurance_type": "workers_comp",
        "issued_on": "2021-05-21T00:00:00",
        "filename": "certificates/60a42caae810c697169cba7d.pdf",
        "updated_at": "2021-06-08T18:35:37.423Z",
        "created_at": "2021-06-08T18:35:37.423Z",
        "expires_on": "2021-05-31T00:00:00",
        "policy_number": "234r234f"
      },
      {
        "_id": "60a44bd59f4b02e50d16132b",
        "insurance_type": "liability",
        "issued_by": "Progressive",
        "policy_number": "3094rt0394jf",
        "issued_on": "2021-05-07T00:00:00",
        "expires_on": "2021-05-22T00:00:00",
        "filename": "certificates/60a44b84b3591c9d0281c3c2.pdf",
        "updated_at": "2021-06-08T18:35:37.423Z",
        "created_at": "2021-06-08T18:35:37.423Z"
      }
    ],
    "created_at": "2021-04-14T03:52:51.531Z",
    "updated_at": "2021-06-08T18:35:37.423Z"
  },
]
