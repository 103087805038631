import {dispatchService} from './dispatch-service.util'
import {getUser, postUser, putUser, putUserPasswordReset, removeUserFromOrg} from '../../services/users.service'
import toastShow, {TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS} from './toast-show'
import {signInAction} from './sign-in.actions'
import {serviceCompanySetState} from './service-company.actions'
import {noopAction} from '../../util/noop'
import {setBackendWorking, setBackendWorkingDone} from './backend-working.actions'
import {getRespError} from '../../services/get-resp-error.util'
import {isServiceErrorResult} from '../../services/is-service-error-result.util'
import {getMembersList} from '../../services/service-company.service'
import {CURRENT_USER_ID} from '../../constants/user.constants'
import {getTechProfileListForOrg} from '../../services/tech-profiles.service'

export const USER_SET_STATE = 'AUTH/FETCH-USER-OK'
export const USER_LIST_SET_STATE = 'USER_LIST_SET_STATE'
export const USER_LIST_RESET_STATE = 'USER_LIST_RESET_STATE'
export const USER_LIST_APPEND = 'USER_LIST_APPEND'
export const USER_LIST_UPDATE_USER = 'USER_LIST_UPDATE_USER'
export const USER_LIST_REMOVE_USER = 'USER_LIST_REMOVE_USER'

export const userSetState = user => ({
  type: USER_SET_STATE,
  payload: user
})

export const userListSetState = list => ({
  type: USER_LIST_SET_STATE,
  list
})

export const userListResetState = () => ({
  type: USER_LIST_RESET_STATE
})

export const userListAppend = user => ({
  type: USER_LIST_APPEND,
  user
})

export const userListUpdateUser = user => ({
  type: USER_LIST_UPDATE_USER,
  user
})

export const userListRemoveUser = userId => ({
  type: USER_LIST_REMOVE_USER,
  userId
})

export const createUser = (user, setUserStateMethod) => async dispatch =>
  await dispatchService(dispatch)(postUser, setUserStateMethod || userSetState)(user)

export const fetchSignedInUser = () => async dispatch =>
  await dispatchService(dispatch)(getUser, userSetState)(CURRENT_USER_ID)

export const fetchUserListForOrg = orgId => async dispatch =>
  await dispatchService(dispatch)(getMembersList, userListSetState)(orgId)

export const fetchTechListForOrg = orgId => async dispatch =>
  // TODO: Make this start using techProfileList redux state instead of userList for clarity
  await dispatchService(dispatch)(getTechProfileListForOrg, userListSetState)(orgId)

export const updateUser = (user, setUserStateMethod) => async dispatch => {
  const result = await dispatchService(dispatch)(putUser, setUserStateMethod || userSetState)(user)
  if (!isServiceErrorResult(result)) {
    dispatch(toastShow(TOAST_TYPE_SUCCESS, "Successfully updated User"))
  }
  return result
}

export const removeUserFromTeam = (user, orgId) => async dispatch => {
  let error
  dispatch(setBackendWorking())
  try {
    const resp = await removeUserFromOrg(user._id, orgId)
    if (!resp.ok) {
      throw await getRespError(resp)
    }
    dispatch(userListRemoveUser(user.id))
  } catch (e) {
    error = e
    console.error(e)
    dispatch(toastShow(TOAST_TYPE_ERROR, e.message))
  } finally {
    dispatch(setBackendWorkingDone())
  }
  return error
}

export const signUpUser = userCredentials => async (dispatch) => {
  const {password, userType: user_type, ...user} = userCredentials
  let error = await dispatch(createUser({
    ...user,
    password,
    searchParams: {
      user_type
    }
  }, noopAction)) // Don't persist user in redux. We'll do that in signInAction, below.
  if (isServiceErrorResult(error)) {
    dispatch(toastShow(
      TOAST_TYPE_ERROR,
      `Could not create user: ${error.message}`
    ))
    return error
  }

  error = await dispatch(signInAction(userCredentials))
  if (isServiceErrorResult(error)) {
    dispatch(toastShow(TOAST_TYPE_ERROR, "Could not create session"))
    return error
  }

  dispatch(serviceCompanySetState({
    company_name: userCredentials.company_name,
  }))
}

export const requestPasswordResetEmail = email => async dispatch => {
  const result = await dispatchService(dispatch)(putUserPasswordReset)(email)
  if (!isServiceErrorResult(result)) {
    dispatch(toastShow(TOAST_TYPE_SUCCESS, `Email sent to ${email}`))
  }
  return result
}
