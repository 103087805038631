import {useState} from "react"
import {useDispatch} from "react-redux"
import {replaceItemInList} from "../util/lists.util"
import TechWorkExpDisplay from "./TechWorkExpDisplay"
import TechWorkExpEdit from "./TechWorkExpEdit"
import {ConfirmationModal} from "./ConfirmationModal"
import {useParams} from "react-router-dom"
import {techProfileSetState} from "../store/actions/tech-profile.actions"
import Typography from '@material-ui/core/Typography'

export function isEmpty(obj) {
  for(let key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;
}


export const WorkExperienceView = ({
  techProfile,
  showAllowButtonOrNot,
  title = <Typography variant={'h6'} display={'inline'}>
    Work Experience
  </Typography>
}) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [workIndex, setWorkIndex] = useState(0)
  const [refIndex, setRefIndex] = useState(0)
  const [selectedWorkExp, setSelectedWorkExp] = useState()
  const [addMode, setAddMode] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [removeItem, setRemoveItem] = useState(false)
  const {orgId} = useParams()
  const {work_experience} = techProfile
  let newWorkExpArr = [...work_experience]

  const techProfileEditPath = `/techs/${techProfile._id}/edit`
  const serviceCompanyEditPath = `/service-company/${orgId}/members/${techProfile._id}/edit`
  const serviceCompanyAddPath = `/service-company/${orgId}/members/member/create`

  const onAddSave = (experience) => () => {
    if(isEmpty(experience)) {
      setWorkIndex()
      setSelectedWorkExp()
      setOpen(false)
      setAddMode(false)
      setRefIndex()
    }else {
      setState([
        ...newWorkExpArr,
        {
          ...experience,
        }
      ])
    }
  }

  const onEditSave = experience => () => {
    setState(replaceItemInList(
      experience,
      newWorkExpArr,
      workIndex
    ))
  }
  const handleWorkExpDeleteClick = (index) => {
    setState([
      ...newWorkExpArr.slice(0, index),
      ...newWorkExpArr.slice(index + 1)
    ])
  }

  const setState = (settingStateForNewArrayForWorkExp) => {
    newWorkExpArr = [...settingStateForNewArrayForWorkExp]
     dispatch(techProfileSetState({
      ...techProfile,
       work_experience: newWorkExpArr
    }))


    setSelectedWorkExp()
    setRemoveItem()
    setAddMode(false)
    setEditMode(false)
    showAllowButtonOrNot(true)
  }

  const onOkFunc = () => {
    setOpen(false)
  }

  const removeObjectFromWorkExpRef = () => {
    let removingWorkExpReferenceAfterYouCloseDrawer = removeItem?.references?.splice(refIndex, 1)
    setState(removingWorkExpReferenceAfterYouCloseDrawer)

    setSelectedWorkExp(removeItem)
    setOpen(false)
  }

  const onCancelRemove = () => {
    setRemoveItem()
    setOpen(false)
    setRefIndex()
  }

  const onAddCancelRemove = () => {
    setWorkIndex()
    setSelectedWorkExp()
    setOpen(false)
    setAddMode(false)
    setRefIndex()
    showAllowButtonOrNot(true)
  }

  return <>
    {!selectedWorkExp && <TechWorkExpDisplay {...{
      work_experience,
      workIndex,
      setWorkIndex,
      setSelectedWorkExp,
      setAddMode,
      setOpen,
      setEditMode,
      addMode,
      editMode,
      handleWorkExpDeleteClick,
      techProfileEditPath,
      serviceCompanyEditPath,
      serviceCompanyAddPath,
      showAllowButtonOrNot,
      title
    }}/>}
    {selectedWorkExp && <TechWorkExpEdit {...{
      selectedWorkExp,
      onAddSave,
      setSelectedWorkExp,
      setEditMode,
      addMode,
      onEditSave,
      setRefIndex,
      setOpen,
      setRemoveItem,
      workIndex,
      setAddMode,
      showAllowButtonOrNot
    }}/>}
    <ConfirmationModal {...{
      open: open,
      onOk: addMode ? onOkFunc : removeObjectFromWorkExpRef,
      onClose: addMode? onAddCancelRemove :  onCancelRemove,
    }}>{addMode ? 'Add Another Work Experience?' : 'Delete This?'}</ConfirmationModal>
  </>
}

