import axios from 'axios'

import appConfig from '../../config'
import useAccessToken from './useAccessToken'

const { chekhubConnect: { baseURI, defaultHeaders, paths } } = appConfig

const downloadFileUrl = (filename) => async (dispatch, getState, getFirebase) => {
  const { storageService } = paths
  const accessToken = await useAccessToken()

  try {
    const { status: signedUrlStatus, data: body } = await axios({
      baseURL: baseURI,
      url: `${storageService}/${encodeURIComponent(filename)}`,
      method: 'GET',
      headers: {
        ...defaultHeaders,
        authorization: `Bearer ${accessToken}`,
      },
    })
    if (signedUrlStatus !== 200) throw new Error('unknown signedDownloadUrlError')
    const { data: { download_url: downloadUrl } } = body
    dispatch({
      type: 'STORAGE/SIGNED-DOWNLOAD-URL-OK',
      payload: {
        key: filename,
        value: downloadUrl,
      },
    })
  } catch (err) {
    dispatch({
      type: 'TOAST/TRIGGER-NOTIFICATION',
      payload: {
        type: 'error',
        message: 'There was an error accessing the storage service',
      },
    })
    dispatch({ type: 'STORAGE/SIGNED-DOWNLOAD-URL-ERROR', payload: err })
  }
}

export default downloadFileUrl
