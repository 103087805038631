import {useState} from 'react'
import {Grid, TextField} from '@material-ui/core'
import {SkillsEdit} from '../../../SkillsEdit'
import {SkillsDisplay} from '../../../SkillsDisplay'

export const MOCK_SKILLS = ['plumbing', 'diesel']
export const MOCK_SKILLS2 = ['plumbing', 'diesel', 'routers', 'lineman']


export const SkillsEditExample = () => {
  const [skills, setSkills] = useState(MOCK_SKILLS)
  const [skills2, setSkills2] = useState(MOCK_SKILLS2)
  const [skills3, setSkills3] = useState([])

  return <>
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <h5>Example small TextField for sizing comparison</h5>
        <TextField {...{
          fullWidth: true,
          size: 'small',
          variant: 'outlined'
        }} />
      </Grid>
      <Grid item xs={3}>
        <h5>Skills</h5>
        <SkillsEdit {...{
          skills,
          size: 'small',
          setSkills
        }} />
      </Grid>
      <Grid item xs={3}>
        <h5>Skills</h5>
        <SkillsEdit {...{
          skills: skills2,
          size: 'small',
          setSkills: setSkills2
        }} />
      </Grid>
      <Grid item xs={3}>
        <h5>Skills</h5>
        <SkillsEdit {...{
          skills: skills3,
          size: 'small',
          setSkills: setSkills3
        }} />
      </Grid>
      <Grid item xs={3}>
        <h5>Example large TextField for sizing comparison</h5>
        <TextField {...{
          fullWidth: true,
          variant: 'outlined'
        }} />
      </Grid>
      <Grid item xs={3}>
        <h5>Skills</h5>
        <SkillsEdit {...{
          skills,
          setSkills
        }} />
      </Grid>
      <Grid item xs={3}>
        <h5>Skills</h5>
        <SkillsEdit {...{
          skills: skills2,
          setSkills: setSkills2
        }} />
      </Grid>
      <Grid item xs={3}>
        <h5>Skills</h5>
        <SkillsEdit {...{
          skills: skills3,
          setSkills: setSkills3
        }} />
      </Grid>
    </Grid>
    <Grid container spacing={1}>
      <Grid item xs={3}><></>
      </Grid>
      <Grid item xs={3}>
        <h5>Skills Display</h5>
        <SkillsDisplay {...{skills}} />
      </Grid>
      <Grid item xs={3}>
        <h5>Skills Display</h5>
        <SkillsDisplay {...{
          skills: skills2,
          size: 'small',
          variant: 'outlined'
        }} />
      </Grid>
      <Grid item xs={3}>
        <h5>Skills Display</h5>
        <SkillsDisplay {...{skills: skills3}} />
      </Grid>
    </Grid>
  </>
}
