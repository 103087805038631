import { dispatchService } from "./dispatch-service.util"
import { fetchTicketList } from '../../services/ticket-list.service'
import { getTechProfileListForOrg } from '../../services/tech-profiles.service'
import { serviceCompanySetTechCount } from './service-company.actions'
import { fetchTicket } from '../../services/ticket-one.service'
import { getChekhubCustomToken } from '../../services/identity-provider.service'

export const TICKET_LIST_SET_STATE = 'TICKET_LIST_SET_STATE'
export const TICKET_LIST_RESET_STATE = 'TICKET_LIST_RESET_STATE'
export const TICKET_SET_STATE = 'TICKET_SET_STATE'
export const TICKET_RESET_STATE = 'TICKET_RESET_STATE'
export const PAID_TICKETS_SET_STATE = `PAID_TICKETS_SET_STATE`
export const PAID_TICKETS_RESET_STATE = `PAID_TICKETS_RESET_STATE`



export const setTicketList = tList => ({
  type: TICKET_LIST_SET_STATE,
  tList
})

export const setPaidTicketList = tList => ({
  type: PAID_TICKETS_SET_STATE,
  tList
})
export const resetPaidTicketList = () => ({
  type: PAID_TICKETS_RESET_STATE
})

export const setTicket = ticket => ({
  type: TICKET_SET_STATE,
  ticket
})

export const resetTicket = () => ({
  type: TICKET_RESET_STATE
})

export const resetTicketList = () => ({
  type: TICKET_LIST_RESET_STATE
})

export const setTicketListState = tList =>
  (dispatch) => {
    return dispatch(setTicketList({
      tList
    }))
  }


export const getTicketList = chekhub_org_id => async dispatch =>
  dispatchService(dispatch)(
    fetchTicketList(chekhub_org_id),
    respBody => dispatch => {
      dispatch(setTicketList(respBody))
    },
  )(chekhub_org_id)

export const getTicket = ticketId => async dispatch =>
  dispatchService(dispatch)(
    fetchTicket , setTicket
  )(ticketId)
