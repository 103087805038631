import React, { useEffect, useRef, useState } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'
import { generatePath, NavLink, useHistory, useParams } from 'react-router-dom'
import {
  CHEKHUB_END_USER_ENGAGEMENTS_CURRENT_TEAMS, CHEKHUB_END_USER_ENGAGEMENTS_INVITED_TEAMS,
  CHEKHUB_END_USER_ENGAGEMENTS_TICKETS,
  INDY_TECH_CURRENT_TEAMS, INDY_TECH_INVITED_TEAMS,
  INDY_TECH_TICKETS,
  SERVICE_COMPANY_ENGAGEMENTS_CURRENT_TEAMS,
  SERVICE_COMPANY_ENGAGEMENTS_TICKETS, SERVICE_COMPANY_INVITED_TEAMS
} from '../../../../routing/route.constants'
import { useAuthUser } from '../../../../hooks/useAuthUser'
import { getUserOrg, isServiceCompanyManager } from '../../../../util/users.util'
import { getLandingPath } from '../../../../util/landingPath.util'
import { INDEPENDENT_TECH_ORG_TYPE } from '../../../../constants/user.constants'
import { Link } from '@material-ui/core'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  menuItem: {
    justifyContent: 'center'
  }
}));

export const NavMenuDropdown = () => {
  const classes = useStyles()
  const authorizedUser = useAuthUser()
  const history = useHistory()
  const pathCheck = history.location.pathname
  const path = pathCheck.includes('/service-company')
  const orgId = localStorage.getItem("orgId")?.replace(/['"]+/g, '')
  const user = useSelector(({auth}) => auth.user)
  const chekhubList = useSelector(({chekhubList}) => chekhubList)
  const orgIdFromState = useSelector(({userProfile}) => userProfile.user)
  const userDefaultOrg = getUserOrg(authorizedUser, orgId, path)
  const isManager = isServiceCompanyManager(authorizedUser, userDefaultOrg?.organization_id)

  const chekhubChecker = userDefaultOrg?.organization_type === 'customer_company'
  const isTech = userDefaultOrg?.organization_type === INDEPENDENT_TECH_ORG_TYPE || authorizedUser?.userRole === 'tech'
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const serviceCompanyObject = user?.organizations?.find(org => org.organization_type === 'service_company')
  const activeOrgObject = chekhubList?.memberships?.find(org => org.organization._id === orgId)


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div className={classes.root}>
      <div>
        <Link
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{
            cursor: 'pointer'
          }}
        >
          View Engagements
        </Link>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({TransitionProps, placement}) => (
            <Grow
              {... TransitionProps}
              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={handleClose}>
                      <NavLink to={!orgId ?
                        generatePath(SERVICE_COMPANY_ENGAGEMENTS_CURRENT_TEAMS, {
                          orgId: serviceCompanyObject?.organization_id
                        }) : !isTech ?
                          generatePath(CHEKHUB_END_USER_ENGAGEMENTS_CURRENT_TEAMS, {
                            orgId: orgId || activeOrgObject?.organization?._id
                          }) : generatePath(INDY_TECH_CURRENT_TEAMS, {
                            userId: userDefaultOrg?.profiles[0]?.profile_id
                          })
                      }>
                        View Current Teams
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose} style={{
                      textAlign: 'center'
                    }}>
                      <NavLink to={ !isTech && serviceCompanyObject ?
                          generatePath(SERVICE_COMPANY_INVITED_TEAMS, {
                          orgId: serviceCompanyObject?.organization_id
                        }) : isTech ? generatePath(INDY_TECH_INVITED_TEAMS, {
                            userId: userDefaultOrg?.profiles[0]?.profile_id
                          }) :
                          generatePath(CHEKHUB_END_USER_ENGAGEMENTS_INVITED_TEAMS, {
                            orgId: activeOrgObject?.organization?._id
                          })
                      } style={{
                        paddingLeft: '15px'
                      }}>
                        {!chekhubChecker ? 'View Invitations' : 'View Invited Teams'}
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose} className={classes.menuItem}>
                      <NavLink to={ !isTech && serviceCompanyObject ?
                        generatePath(SERVICE_COMPANY_ENGAGEMENTS_TICKETS, {
                          orgId: serviceCompanyObject?.organization_id
                        }) : !isTech ?
                          generatePath(CHEKHUB_END_USER_ENGAGEMENTS_TICKETS, {
                            orgId: orgId || activeOrgObject?.organization?._id
                          }) : generatePath(INDY_TECH_TICKETS, {
                            userId: userDefaultOrg?.profiles[0]?.profile_id
                          })
                      }>
                        View Tickets
                      </NavLink>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  )
}
