import { PaymentBankAccount } from '../components/PaymentBankAccount'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { payStripe, setSourceId } from '../store/actions/wallet.actions'
import { isServiceErrorResult } from '../services/is-service-error-result.util'
import toastShow, { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '../store/actions/toast-show'
import { CircularProgress, TextField, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { FieldDisplay } from '../components/FieldDisplay'
import { FormSubmitButton } from '../components/FormSubmitButton'
import React, { useEffect, useState } from 'react'
import { paymentCallToStripe } from '../services/wallet.service'
import { useBusyIndicatorStyles } from '../components/BusyIndicator'



export const AddBankAccountChekhubEndUser = ({
  default_source = {},
                                               useForceUpdate
                                             }) => {

  const {orgId} = useParams()
  const dispatch = useDispatch()
  const styles = useBusyIndicatorStyles()
  const [newAccount, setNewAccount] = useState(false)
  const [value1, setValue1] = useState('')
  const [value2, setValue2] = useState('')
  const verified = default_source?.default_source?.sources?.data[0]?.status !== 'new'


  const onSubmit = async (token) => {
    const result = await dispatch(setSourceId(
      orgId,
      token
    ))
    const isError = isServiceErrorResult(result)
    useForceUpdate()
    dispatch(toastShow(
      isError ? TOAST_TYPE_ERROR : TOAST_TYPE_SUCCESS,
      isError ?
        'Add Bank Account Failed' :
        'Add Bank Account Success'
    ))
    setNewAccount(false)
  }


  const onVerify1Change = (e) => {
    setValue1(e.target.value)
  }
  const onVerify2Change = (e) => {
    setValue2(e.target.value)
  }

  const onVerify = async () => {
    await dispatch(payStripe({
      customer_id: orgId,
      source_id: default_source?.default_source?.default_source,
      amount_1: value1*100,
      amount_2: value2*100,
    }))
  }

  const replaceCurrentAccount = () => {
    setNewAccount(true)
  }

  const onCancel = () => {
    setNewAccount(false)
  }

  return <> { default_source.default_source ? newAccount || default_source?.default_source?.sources?.data?.length === 0 ? <PaymentBankAccount {...{
    onSubmit,
    onCancel,
  }}/> : default_source.default_source && <Grid container style={{
    paddingTop: '10px'
  }}>
    <Grid item xs={12}>
    <Typography variant={'h6'}>
    Current Bank Account
    </Typography>
    </Grid>
    <Grid item xs={6}>
      <FieldDisplay {...{
        label: 'Bank Name'
      }}/>
      <Typography variant={'h6'}>
        {default_source?.default_source?.sources?.data[0]?.bank_name}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <FieldDisplay {...{
        label: 'Last 4 Digits'
      }}/>
      <Typography variant={'h6'}>
        {default_source?.default_source?.sources?.data[0]?.last4}
      </Typography>
    </Grid>
    {
      default_source?.default_source?.sources?.data[0]?.status === 'new' || !verified ?
        <Grid container style={{
          paddingTop: '30px',
          paddingBottom: '30px'
        }}>
          <Grid item xs={12}>
          <FieldDisplay {...{
            label: 'Enter in two small deposits to verify your bank account'
          }}/>
          </Grid>
          <Grid item xs={6}>
          <TextField variant={'outlined'}
                     placeholder={'$0.52'}
                     size={'small'}
                     value={value1}
                     onChange={onVerify1Change}
          />
          </Grid>
          <Grid item xs={6}>
          <TextField variant={'outlined'}
                     placeholder={'$0.48'}
                     size={'small'}
                     value={value2}
                     onChange={onVerify2Change}
          />

          </Grid>
        </Grid> : <Grid item xs={12} style={{
          paddingTop: '20px'
        }}>
          <FieldDisplay {...{
            label: 'Account Verified'
          }}/>
          <Typography variant={'h6'} style={{
            textTransform: 'uppercase'
          }}>
            {default_source?.default_source?.sources?.data[0]?.status}
          </Typography>
        </Grid>
    }
    <Grid item xs={6} style={{
      paddingTop: '20px'
    }}>
      <FormSubmitButton {...{
        onClick: replaceCurrentAccount,
        useFab: false,
      }}>
        Change Bank Account
      </FormSubmitButton>
    </Grid>
    {!verified && <Grid item xs={6} style={{
      paddingTop: '20px'
    }}>
      <FormSubmitButton {... {
        onClick: onVerify,
        useFab: false,
      }}>
        Verify Bank Account
      </FormSubmitButton>
    </Grid>}
  </Grid>:  <Grid
    container
    justify={'center'}
  >
    <CircularProgress {...{
      size: '2em',
      className: styles.spinner,
      color: 'primary'
    }} />
  </Grid>
  }
    </>
}
