import mockConnectedAccountData from './mock-data/connected-account.json'
import {ReceivingAccount} from '../../../ReceivingAccount'
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@material-ui/core'
import {useState} from 'react'

export const ReceivingAccountExample = () => {
  const [selectedAccountState, setSelectedAccountState] = useState('active')
  const handleAccountStateChange = ev => setSelectedAccountState(ev.target.value)

  return <>
    <FormControl component="fieldset">
      <FormLabel component="legend">Account State</FormLabel>
      <RadioGroup aria-label="gender" name="accountState1" value={selectedAccountState} onChange={handleAccountStateChange}>
        <FormControlLabel value="pending" control={<Radio />} label="Pending" />
        <FormControlLabel value="active" control={<Radio />} label="Active" />
      </RadioGroup>
    </FormControl>
    <ReceivingAccount {...{
      connectedAccounts: mockConnectedAccountData[selectedAccountState]
    }} />
  </>
}
