import axios from 'axios'

import appConfig from '../../config'
import useAccessToken from './useAccessToken'
import {setBackendWorking, setBackendWorkingDone} from './backend-working.actions'

const profileFetchList = ({
  orgId,
  isTechList,
  pageNumber = 0,
  pageSize = 40,
  searchQuery = '',
}) => async (dispatch, getState, getFirebase) => {
  const { chekhubConnect: { baseURI, operations, defaultHeaders } } = appConfig
  const { techProfileFetchMany, unifiedProfileFetchMany } = operations
  const fetchOperation = isTechList ? techProfileFetchMany : unifiedProfileFetchMany
  const { path, method, authRequired } = fetchOperation

  const token = authRequired ? await useAccessToken() : null

  dispatch(setBackendWorking())

  try {
    const { status, data: body } = await axios({
      baseURL: baseURI,
      url: `${path}`,
      method,
      headers: {
        ...defaultHeaders,
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
      params: {
        ...(pageNumber ? { skip: (pageNumber) } : {}),
        ...(isTechList ? { organization_id: orgId } : { owner_id: orgId }),
        ...(searchQuery ? { search: searchQuery } : {}),
        ...(isTechList ? {} : { profile_types: 'service_company,independent_tech' }),
        limit: pageSize,
      },
    })
    if (status !== 200) throw new Error('unknown profileFetchError')
    const numDocs = body.matching_docs || body.macthing_docs
    const pgSize = isTechList ? pageSize : pageSize * 2
    const pageCount = numDocs !== undefined ? Math.ceil(numDocs / pgSize) : 0
    if (isTechList) {
      dispatch({
        type: 'PROFILE-LIST/FETCH-TECH-OK',
        payload: {
          data: body.data,
          pageCount,
          pageNumber,
          searchQuery,
        },
      })
    } else {
      dispatch({
        type: 'PROFILE-LIST/FETCH-SERVICE-PROVIDER-OK',
        payload: {
          data: body.data,
          pageCount,
          pageNumber,
          searchQuery,
        },
      })
    }
  } catch (err) {
    dispatch({
      type: 'TOAST/TRIGGER-NOTIFICATION',
      payload: {
        type: 'error',
        message: `There was an error loading the list of ${isTechList ? 'techs' : 'service companies'}`,
      },
    })
    dispatch({ type: 'PROFILE-LIST/FETCH-ERROR', payload: err })
  }
  dispatch(setBackendWorkingDone())
}

export default profileFetchList
