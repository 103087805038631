import {dispatchService} from './dispatch-service.util'
import {getUser} from '../../services/users.service'
import {createUser, updateUser} from './users.actions'

export const USER_PROFILE_SET_STATE = 'USER_PROFILE_SET_STATE'
export const USER_PROFILE_RESET_STATE = 'USER_PROFILE_RESET_STATE'

export const userProfileSetState = user => ({
  type: USER_PROFILE_SET_STATE,
  user
})

export const userProfileResetState = () => ({
  type: USER_PROFILE_RESET_STATE
})

export const fetchUserProfile = userId => async dispatch =>
  await dispatchService(dispatch)(getUser, userProfileSetState)(userId)

export const updateUserProfile = user => async (dispatch) =>
  await dispatch(updateUser(user, userProfileSetState))

export const createUserProfile = user => async (dispatch, getState) => {
  const error = await dispatch(createUser(user, userProfileSetState))
  // TODO: figure out a better way to return entities from services, while throwing errors
  const {userProfile} = getState()
  return error || userProfile
}
