import {createReducer} from 'redux-create-reducer'
import {
  RESET_SERVICE_COMPANY_MANAGEMENT_LIST_STATE,
  SET_SERVICE_COMPANY_MANAGEMENT_LIST_STATE
} from '../actions/service-company-management-list.actions'

export const INIT_SERVICE_COMPANY_MANAGEMENT_LIST = []

export const serviceCompanyManagementListReducer = createReducer(INIT_SERVICE_COMPANY_MANAGEMENT_LIST,
  {
  [SET_SERVICE_COMPANY_MANAGEMENT_LIST_STATE]: (state, {list}) => [...list],
  [RESET_SERVICE_COMPANY_MANAGEMENT_LIST_STATE]: () =>
    [...INIT_SERVICE_COMPANY_MANAGEMENT_LIST]
})
