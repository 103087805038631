import axios from 'axios'

import appConfig from '../../config'
import useAccessToken from './useAccessToken'
import {setBackendWorking, setBackendWorkingDone} from './backend-working.actions'

const { chekhubConnect: { baseURI, defaultHeaders, paths } } = appConfig

const upsertProfileSubcollectionFunction = async ({
  documentType,
  documentId = null,
  isTechProfile,
  profile,
  userImpersonated,
  data,
}, dispatch, getState, getFirebase) => {
  dispatch(setBackendWorking())
  const eventPrefix = userImpersonated ? 'IMPERSONATED-' : ''
  const accessToken = await useAccessToken()

  const { serviceCompanyProfileService, techProfileService } = paths
  const { _id: profileId } = profile

  const upsertPath = isTechProfile ? techProfileService : serviceCompanyProfileService
  const method = documentType === 'background-check' || !documentId ? 'POST' : 'PUT'
  const url = `${upsertPath}/${profileId}/${documentType}/${method === 'POST' ? '' : documentId}`
  try {
    const { status, data: body } = await axios({
      baseURL: baseURI,
      url,
      method,
      headers: {
        ...defaultHeaders,
        authorization: `Bearer ${accessToken}`,
      },
      data,
    })
    if (status !== 200) throw new Error('unknown backgroundCheckUpsertError')
    dispatch({ type: `${eventPrefix}PROFILE/SUBCOLLECTION-UPSERT-OK`, payload: { documentType, profile: body.data } })
  } catch (err) {
    dispatch({
      type: 'TOAST/TRIGGER-NOTIFICATION',
      payload: {
        type: 'error',
        message: 'There was an error saving the profile',
      },
    })
    dispatch({ type: `${eventPrefix}PROFILE/SUBCOLLECTION-UPSERT-ERROR`, payload: err.response })
  }
  dispatch(setBackendWorkingDone())
}

const upsertProfileSubcollection = (args) => async (dispatch, getState, getFirebase) => {
  await upsertProfileSubcollectionFunction(args, dispatch, getState, getFirebase)
}

export { upsertProfileSubcollection, upsertProfileSubcollectionFunction }
