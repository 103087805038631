const techInitState = {
  first_name: null,
  last_name: null,
  email: null,
  birth_date: null,
  userType: 'tech',
  phones: [{
    label: 'mobile',
    country: 'us',
    raw_phone: null,
  }],
  service_radius: null,
  hour_rate: null,
  location: {
    label: 'work',
    lat: null,
    long: null,
    address: {},
  },
  insurance_policies: [],
  certificates: [],
  work_experience: [],
}

const initState = {
  isNewProfile: true,
  data: techInitState,
}

const impersonatedProfileReducer = (state = initState, action) => {
  if (['IMPERSONATED-PROFILE/RESET'].includes(action.type)) {
    return initState
  }
  if (['IMPERSONATED-PROFILE/SET'].includes(action.type)) {
    return {
      ...state,
      isNewProfile: false,
      data: {
        ...state.data,
        ...action.payload.data,
      },
    }
  }
  if (['IMPERSONATED-PROFILE/FETCH-OK'].includes(action.type)) {
    return {
      ...state,
      fetched: true,
      isNewProfile: false,
      data: {
        ...state.data,
        ...action.payload.data,
      },
    }
  }
  if (['IMPERSONATED-PROFILE/UPSERT-OK'].includes(action.type)) {
    return {
      ...state,
      isNewProfile: false,
      data: {
        ...state.data,
        ...action.payload,
      },
    }
  }
  if (['IMPERSONATED-PROFILE/SUBCOLLECTION-UPSERT-OK', 'IMPERSONATED-PROFILE/SUBCOLLECTION-DELETE-ITEM-OK'].includes(action.type)) {
    return {
      ...state,
      data: {
        ...state.data,
        ...action.payload.profile,
      },
    }
  }
  return state
}

export default impersonatedProfileReducer
