import { Card, CircularProgress, Hidden, Typography, } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useParams, withRouter } from 'react-router-dom'
import { getUserOrg } from '../util/users.util'
import { useAuthUser } from '../hooks/useAuthUser'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchServiceCompany,
  fetchServiceCompanyList,
  resetServiceCompanyListState, resetServiceCompanyState
} from '../store/actions/service-company.actions'
import { useBusyIndicatorStyles } from './BusyIndicator'
import Grid from '@material-ui/core/Grid'
import { CurrentTeamOrProviderTemplate } from './CurrentTeamOrProviderTemplate'
import { ChekhubEndUserSummary } from './ChekhubEndUserSummary'
import ServiceCompanySummary from './ServiceCompanySummary'
import useLandingViewStyles from './LandingViewStyles'
import { useDimensionsContextStyles } from './DimensionsContext'
import useEditStyles from './ServiceCompanyViewStyles'
import useListStyles from './ListServiceCompanyStyles'
import { invitedTeamResetState } from '../store/actions/invited-teams-list.actions'

export const CurrentTeamOrProviderListSc = withRouter(({
                                                              match,
                                                            }) => {

  const {orgId} = useParams()
  const [open, setDrawerOpen] = useState(false)
  const user = useSelector(({auth}) => auth.user)
  const dispatch = useDispatch()
  const dimensionsContextStyles = useDimensionsContextStyles()
  const styles = useLandingViewStyles()
  const editStyles = useEditStyles()
  const listStyles = useListStyles()
  const busyStyles = useBusyIndicatorStyles()
  const landingStyles = useLandingViewStyles()
  const serviceCompanyObject = user.organizations.find(org => org.organization_type === 'service_company')
  const teamOrProviderList = useSelector(({serviceCompanyList}) => serviceCompanyList)
  const authorizedUser = useAuthUser()
  const userDefaultOrg = getUserOrg(authorizedUser)
  const chekhubChecker = userDefaultOrg?.chekhub_member_id
  const profile = useSelector(({serviceCompany}) => serviceCompany)
  const chekhubOrgId = localStorage.getItem("orgId")?.replace(/['"]+/g, '')

  useEffect(async () => {
    orgId && await dispatch(fetchServiceCompany(orgId))
  }, [])

  useEffect(async () => {
   orgId && await dispatch(fetchServiceCompanyList({
      orgId
    }))
  }, [, open])


  useEffect(() => {
    dispatch(resetServiceCompanyListState())
    dispatch(resetServiceCompanyState())
    dispatch(invitedTeamResetState())
  },[])

  return <Grid container>
    <Hidden smDown>
      <Grid item xs={3}>
        {!chekhubOrgId ? <ServiceCompanySummary {...{
          profile
        }}/> : <ChekhubEndUserSummary />}
      </Grid>
    </Hidden>
    <Grid
      item
      xs={12}
      sm={12}
      md={9}
      lg={9}
      xl={9}
      className={landingStyles.skeleton}
    >
    {
      teamOrProviderList && teamOrProviderList.length <= 0  ? <Card className={
      `${styles.membersListCard} ${dimensionsContextStyles.minHeight} ${dimensionsContextStyles.card}`
    } ><Typography variant={'h6'}>No teams available</Typography></Card> : teamOrProviderList.length > 0 ?
      <CurrentTeamOrProviderTemplate {... {
        teamOrProviderList,
        setDrawerOpen,
        open,
        user,
        orgId
      }}/> : <Grid
        container
        justify={'center'}
      >
        <CircularProgress {... {
          size: '2em',
          className: busyStyles.spinner,
          color: 'primary'
        }} />
      </Grid>
  }
    </Grid>
  </Grid>

})

