import {makeStyles} from '@material-ui/core/styles'
import noop from '../util/noop'
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core'
import {UserAvatar} from './UserAvatar'
import {findAdminUsers, getUserOrg, hasAdminRole, updateMemberOrgRoles} from '../util/users.util'
import {ServiceCompanyMemberActions} from './ServiceCompanyMemberActions'
import CreateIcon from '@material-ui/icons/Create'
import {useState} from 'react'
import {ConfirmationModal} from './ConfirmationModal'
import {ServiceCompanyEditMemberRoles} from './ServiceCompanyEditMemberRoles'
import {AddCircleOutlineSharp} from '@material-ui/icons'
import {AddServiceCompanyManagerForm} from './AddServiceCompanyManagerForm'
import {ServiceCompanyManagerSecondaryText} from './ServiceCompanyManagerSecondaryText'
import {ADMIN_USER_ROLE, TECH_USER_ROLE} from '../constants/user.constants'
import multisort from 'multisort'
import {useAuthUser} from '../hooks/useAuthUser'

export const useServiceCompanyManagementListStyles = makeStyles(theme => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0
  },
  actions: {
    right: 0
  },
  avatar: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    fontSize: '1em'
  },
  listItemAvatar: {
    minWidth: theme.spacing(4)
  }
}))

const sortByAdminRole = orgId => m => hasAdminRole(m, orgId) ? -1 : 1
const defaultSort = (members, orgId) => multisort(
  [...members],
  [
    sortByAdminRole(orgId),
    m => m.created_at
  ]
)

export const ServiceCompanyManagementList = ({
  serviceCompanyManagementList = [],
  orgId,
  onRemove = noop,
  onEdit = noop,
  onAdd = noop,
  disableRemove = false,
  disableEdit = false,
  disableAdd  = false
}) => {
  const sortedManagementList = defaultSort(serviceCompanyManagementList)
  const styles = useServiceCompanyManagementListStyles()
  const [removeMember, setRemoveMember] = useState()
  const [editMember, setEditMember] = useState()
  const [addMember, setAddMember] = useState()
  const handleRemove = member => () => setRemoveMember(member)
  const onCancelRemove = () => setRemoveMember()
  const authorizedUser = useAuthUser()
  const admins = findAdminUsers(sortedManagementList, orgId)

  const onRemoveConfirm = member => () => {
    onRemove(member)
    onCancelRemove()
  }

  const handleAddMember = () => setAddMember({})

  const handleAddConfirm = member => () => {
    onAdd(member)
    setAddMember()
  }

  const handleAddCancel = () => setAddMember()

  const handleEdit = member => () => setEditMember({...member})

  const handleSetEditMemberRoles = member => roles =>
    setEditMember(updateMemberOrgRoles(member, orgId, roles))

  const handleEditMemberRolesCancel = () => setEditMember()

  const handleEditMemberRolesConfirm = member => () => {
    onEdit(member)
    setEditMember()
  }

  const handleOnAddMemberChange = member => ({target}) => setAddMember({
    ...member,
    [target.id]: target.value
  })

  return <>
    <List className={styles.list} dense={true}>
      {
        sortedManagementList.map((member, key) => {
          const showRolesToggleForMember = member._id && editMember?._id === member._id
          const isLastAdmin = (admins.length <= 1 && admins.find(a => a._id === member._id))
          const disableRemoval = disableRemove || (authorizedUser._id === member._id) || isLastAdmin

          return <ListItem {...{
            key,
            className: styles.listItem,
            alignItems: 'flex-start'
          }} >
            <ListItemAvatar {...{
              className: styles.listItemAvatar
            }}>
              <UserAvatar {...{
                user: member,
                className: styles.avatar
              }} />
            </ListItemAvatar>
            <ListItemText{...{
              primary: member.full_name,
              secondary: <>
                <ServiceCompanyManagerSecondaryText {...{
                  member,
                  orgId
                }} />
                {
                  showRolesToggleForMember && <ServiceCompanyEditMemberRoles {...{
                    roles: getUserOrg(editMember, orgId).roles || [],
                    setRoles: handleSetEditMemberRoles(editMember),
                    onCancel: handleEditMemberRolesCancel,
                    onConfirm: handleEditMemberRolesConfirm(editMember),
                    excludeRoles: [
                      TECH_USER_ROLE,
                      ...(
                        editMember._id === authorizedUser._id ?
                         [ADMIN_USER_ROLE] : []
                      )
                    ],
                    size: 'small'
                  }} />
                }
              </>
            }} />
            <ListItemSecondaryAction className={styles.actions}>
              {
                !showRolesToggleForMember && <ServiceCompanyMemberActions {...{
                  member,
                  disableRemoval,
                  disableEdit,
                  removeTitle: 'Remove manager',
                  editComponent:
                    <IconButton {...{
                      edge: 'end',
                      title: 'Edit manager roles',
                      size: 'small',
                      onClick: handleEdit(member)
                    }} >
                      <CreateIcon fontSize={'small'}/>
                    </IconButton>,
                  onRemove: handleRemove(member),
                  size: 'small'
                }} />
              }
            </ListItemSecondaryAction>
          </ListItem>
        })
      }
      {
        !disableAdd && <ListItem {...{
          className: styles.listItem,
          alignItems: 'flex-start'
        }}>
          {
            addMember ?
              <AddServiceCompanyManagerForm {...{
                member: addMember,
                onSubmit: handleAddConfirm(addMember),
                onCancel: handleAddCancel,
                onChange: handleOnAddMemberChange(addMember)
              }} /> :
              <Button {...{
                size: 'small',
                variant: 'outlined',
                color: 'primary',
                onClick: handleAddMember,
                startIcon: <AddCircleOutlineSharp fontSize={'small'}/>
              }}>
                Add Manager
              </Button>
          }
        </ListItem>
      }
    </List>
    <ConfirmationModal {...{
      open: removeMember,
      user: removeMember,
      onOk: onRemoveConfirm(removeMember),
      onClose: onCancelRemove,
    }}>Remove manager, {removeMember?.full_name}?</ConfirmationModal>
  </>
}

