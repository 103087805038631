import noop from '../util/noop'
import { useState } from 'react'
import { PaymentSourceAddButtons } from './PaymentSourceAddButtons'
import { PaymentSourcesList } from './PaymentSourcesList'
import { PaymentCard } from './PaymentCard'
import { PaymentBankAccount } from './PaymentBankAccount'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

const PAYMENT_SOURCE_INIT_STATE = {}
export const SOURCE_TYPE_BANK_ACCOUNT = 'bank_account'
export const SOURCE_TYPE_CARD = 'card'

export const PaymentSources = ({
  sources = [],
  onAddCardSubmit = noop,
  onAddBankAccountSubmit = noop,
  onRemove = noop
}) => {
  const [addCardSource, setAddCardSource] = useState()
  const [addBankAccountSource, setAddBankAccountSource] = useState()

  const onAddBankAccountClick = () => setAddBankAccountSource({...PAYMENT_SOURCE_INIT_STATE})
  const onAddCardAccountClick = () => setAddCardSource({...PAYMENT_SOURCE_INIT_STATE})

  const clearAddCardSource = () => setAddCardSource()
  const clearAddBankAccountSource = () => setAddBankAccountSource()

  const handleAddCardSubmit = token => {
    onAddCardSubmit(token)
    clearAddCardSource()
  }
  const handleAddBankAccountSubmit = token => {
    onAddBankAccountSubmit(token)
    clearAddBankAccountSource()
  }

  return <>
    {
      !addBankAccountSource && !addCardSource && <>
        <PaymentSourceAddButtons {...{
          onAddBankAccountClick,
          onAddCardAccountClick
        }} />
        <PaymentSourcesList {...{
          sources,
          onRemove,
        }} />
      </>
    }
    {
      addCardSource && <>
        <Grid item xs={12}>
          <Typography {...{
            variant: 'h6'
          }}>Add A Credit Card</Typography>
        </Grid>
        <PaymentCard {...{
          onSubmit: handleAddCardSubmit,
          onCancel: clearAddCardSource
        }} />
      </>
    }
    {
      addBankAccountSource && <PaymentBankAccount {...{
        onCancel: clearAddBankAccountSource,
        onSubmit: handleAddBankAccountSubmit
      }} />
    }
  </>
}
