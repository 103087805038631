import {makeStyles} from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  h1: {
    marginBottom: '1em',
  },
  paper: {
    background: '#fff',
  },
  textField: {
    // !important? But why?! Because "CSS-in-JS is flippin' idiotic, that's why 😡
    marginBottom: `${theme.spacing(2)}px !important`,
  },
  signInGrid: {
    display: 'flex',
  },
  signInButton: {
    // Have to apply an #id here because JSS production build classes break CSS
    //  specificity 🤦‍
    '&#sign-in-button': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light
      },
      '&.MuiButton-disableElevation.Mui-disabled': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.dark
      }
    }
  },
  signUpButton: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
    color: '#fff !important'
  },
  checkboxLabelLogo: {
    height: '1em',
    marginLeft: theme.spacing(.5),
    marginRight: theme.spacing(.5)
  },
  flippyFront: {
    height: 'auto',
    width: 'auto',
    padding: 0,
    boxShadow: 'none',
    background: theme.palette.grey[50],
    '&.hideContent': {
      visibility: 'hidden'
    }
  },
  flippyBack: {
    height: '100%',
    padding: 0,
    boxShadow: 'none !important',
    background: theme.palette.grey[50],
    '&.hideContent': {
      visibility: 'hidden'
    }
  },
  backCardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    flexDirection: 'column',
  },
  activeOrgSelector: {
    width: '80%'
  },
  span: {
    fontSize: theme.spacing(2),
  },
  jumboHolder: {
    backgroundColor: theme.palette.secondary.dark,
    width: 'auto',
    color: theme.palette.primary.contrastText
  },
  signInHolder: {
    paddingLeft: theme.spacing(2),
    borderRadius: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  spanHolder: {
    marginTop: 'auto',
    paddingBottom: theme.spacing(2)
  },
  signInHeader: {
    fontSize: theme.spacing(5),
    paddingTop: theme.spacing(4),
    color: theme.palette.primary.main
  },
  img: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(4)
  },
  signInSpan: {
    textAlign: 'center',
    paddingBottom: theme.spacing(3),
    color: theme.palette.grey[600]
  },
  forgotPassword: {
    paddingLeft: theme.spacing(2.6)
  },
  rememberMeCheck: {
    height: theme.spacing(1)
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gridTemplateRows: '20px 0',
    gridGap: '20px',
    '&::before': {
      content: "''",
      display: 'block',
      borderBottom: `1px solid ${theme.palette.secondary.dark}`
    },
    '&::after':  {
      content: '""',
      display: 'block',
      borderBottom: `1px solid ${theme.palette.secondary.dark}`
    },
  }
}))
