import {Button, CircularProgress, Grid} from '@material-ui/core'
import {
  EMAIL_FIELDNAME,
  FIRST_NAME_FIELDNAME,
  LAST_NAME_FIELDNAME,
  UserProfileFormFields
} from './UserProfileFormFields'
import noop from '../util/noop'
import {useValidations} from '../hooks/useValidations'
import {validate as emailValidate} from 'email-validator'

const UserProfileForm = ({
  editStyles,
  formStyles,
  user,
  backgroundRunning,
  onSubmit,
  onChange,
  editable,
}) => {
  const {
    onChangeValidation,
    onBlurValidation,
    fieldErrors,
    hasFieldErrors
  } = useValidations({
    [FIRST_NAME_FIELDNAME]: {
      required: 'First name is required'
    },
    [LAST_NAME_FIELDNAME]: {
      required: 'Last name is required'
    },
    [EMAIL_FIELDNAME]: {
      required: 'Email is required',
      validator: (val) => !emailValidate(val) ? 'Invalid email format' : null
    }
  })

  const handleOnChange = ev => {
    onChangeValidation(ev)
    onChange(ev)
  }
  return <form onSubmit={onSubmit || noop}>
    <Grid container spacing={1}>
      <UserProfileFormFields {...{
        user,
        styles: editStyles,
        editable,
        onChange: handleOnChange,
        onBlur: onBlurValidation,
        fieldErrors
      }} />
      {!onSubmit ? null : <Grid container justify='center'>
        <Grid item>
          <Button variant='contained' color='primary'
                  type='submit'
                  className={editStyles.button}
                  disabled={hasFieldErrors() || backgroundRunning}
          >
            {backgroundRunning
              ? <CircularProgress size={'1.7em'}
                                  className={formStyles.buttonProgress}
              />
              : <span>{user._id ? 'Save' : 'Create'} User Profile</span>
            }
          </Button>
        </Grid>
      </Grid>}
    </Grid>
  </form>
}

export default UserProfileForm
