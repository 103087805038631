import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  footer: {
    backgroundColor: '#333',
    textAlign: 'center',
    position: 'sticky',
    bottom: '0',
    top: 'auto',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  copyright: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto"
  },
  toolbar: {
    minHeight: "auto"
  },
  icon: {
    paddingLeft: theme.spacing(.5),
    fontSize: '.9rem',
  },
  span: {
    fontSize: theme.spacing(2),
  },
  landingSpan: {
    fontSize: theme.spacing(2),
    color: 'black'
  },
  regCaption: {
    color: 'inherit'
  },
  caption: {
    color: 'black'
  },
  landingFooter: {
    backgroundColor: '#E0E0E0',
    textAlign: 'center',
    position: 'sticky',
    bottom: '0',
    top: 'auto',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}))
