import {useState} from 'react'
import {InsurancePoliciesDisplay} from '../../InsurancePoliciesDisplay'
import {replaceItemInList} from '../../..//util/lists.util'
import {InsuranceEdit} from '../../InsuranceEdit'

export const MOCK_CERTS = [
  {
  "_id": "3984hf90384jhf90834jf",
  "type": "AWS Certified Cloud Architect",
  "issued_by": "AWS",
  "issued_on": "2020-10-12T00:00:00Z",
  "filename": " ",
  "expires_on": "2030-12-31T00:00:00Z",
  "policy_number": "123456"
  },
  {
    "_id": "0293jr928hf023ht082ht0h23",
    "type": "AES Mechanic",
    "issued_by": "AES",
    "issued_on": "2020-10-12T00:00:00Z",
    "filename": " ",
    "expires_on": "2030-12-31T00:00:00Z",
    "policy_number": "ggdgdf"}
]

export const InsurancePolicy = () => {
  const [insurance, setInsurance] = useState(MOCK_CERTS)
  const [editInsurance, setEditInsurance] = useState()
  const [addInsurance, setAddInsurance] = useState()
  const [removeInsurance, setRemoveInsurance] = useState()

  const handleEdit = (ins) => () => {
    setEditInsurance(ins)
  }
  const handleEditSubmit = (index, cert) => {
    setInsurance(
      replaceItemInList(cert, insurance, index)
    )
    clearEdit()
  }
  const clearEdit = () => setEditInsurance()

  const handleAdd = () =>
    setAddInsurance({
      "type": "",
      "issued_by": "",
      "issued_on": "",
      "filename": "",
      "expires_on": "",
      "policy_number": ""})

  const clearAdd = () =>
    setAddInsurance()
  const handleAddSubmit = insPolicy => {
    setInsurance([
      ...insurance,
      {
        ...insPolicy,
        _id: `new-id-${insPolicy.length + 1}`
      }
    ])
    clearAdd()
  }

  const handleRemove = ins => () => {
    setRemoveInsurance({...ins})
  }
  const clearRemove = () => setRemoveInsurance()
  const handleRemoveConfirm = remIns => () => {
    setInsurance(insurance.filter(c => c._id !== remIns._id))
    clearRemove()
  }
  return <>
    {
      !editInsurance && !addInsurance && <InsurancePoliciesDisplay {...{
        insurance,
        onEdit: handleEdit,
        onAdd: handleAdd,
        onRemove: handleRemove,
        removeInsurance,
        onRemoveConfirm: handleRemoveConfirm,
        onRemoveCancel: clearRemove
      }} />
    }
    {
      editInsurance && !addInsurance && <InsuranceEdit {...{
        insur: editInsurance,
        onSubmit: handleEditSubmit,
        onCancel: clearEdit,
        onRemoveUpload: handleRemove,
        label: "Edit insurance policy"
      }} />
    }
    {
      addInsurance && <InsuranceEdit {...{
        insur: addInsurance,
        onSubmit: handleAddSubmit,
        onCancel: clearAdd,
        onRemoveUpload: handleRemove,
        label: "Add insurance policy"
      }} />
    }
  </>
}

