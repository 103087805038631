import { Card, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import { TicketList } from './TicketList'
import { TicketPaymentDrawerUtil } from '../pages/TicketPaymentDrawerUtil'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'


export const TicketListViewTemplate = withRouter(({
  handleChange,
  selection,
  open,
  setDrawerOpen,
  match,
  ticketsList,
  chekhubChecker,
  source,


                                       }) => {

  return <Card variant={'outlined'} style={{
    height: '100%'
  }}>
    <Grid container spacing={1}>
      <Grid item xs={4}>
    <Typography variant={'h5'}
                align={'center'}
                style={{
                  paddingLeft: '10px',
                  paddingTop: '20px'
                }}>
      My Tickets
    </Typography>
      </Grid>
      <Grid item xs={8}>
    <FormControl style={{
      width: '100%',
      marginTop: '10px',
    }}>
      <InputLabel id="demo-simple-select-helper-label">Select Ticket Type</InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={selection}
        onChange={handleChange}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value={0}>Active Tickets</MenuItem>
        <MenuItem value={1}>Completed</MenuItem>
        <MenuItem value={2}>Paid</MenuItem>
      </Select>
    </FormControl>
      </Grid>
    </Grid>
    <TicketList {...{
      ticketsList,
      chekhubChecker,
      selection,

    }}/>
    <TicketPaymentDrawerUtil {...{
      open,
      chekhubChecker,
      ticketsList,
      setOpen: setDrawerOpen,
      backRoutePath: match.url,
      source
    }} />
  </Card>
})
