import { makeStyles } from '@material-ui/core/styles'
import { SectionHeader } from './SectionHeader'
import { Box, CircularProgress } from '@material-ui/core'
import { IconWithAdornment } from './IconWithAdornment'
import { AccountBalanceSharp, ArrowBackSharp, LinkSharp } from '@material-ui/icons'
import { ConnectedAccountPendingCta } from './ConnectedAccountPendingCta'
import { ConnectedAccountActive } from './ConnectedAccountActive'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import useLandingViewStyles from './LandingViewStyles'
import { useBusyIndicatorStyles } from './BusyIndicator'

const useReceivingAccountStyles = makeStyles(theme => ({
  headIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  linkedAccountIcon: {
    color: theme.palette.primary.dark
  },
  linkedAccountBox: {
    padding: theme.spacing(1),
    paddingTop: 0
  }
}))
const CONNECTED_ACCOUNT_STATUS_PENDING = 'pending'
const CONNECTED_ACCOUNT_STATUS_ACTIVE = 'active'

export const ReceivingAccount = ({connectedAccounts} = {}) => {
  const styles = useReceivingAccountStyles()
  const busyStyles = useBusyIndicatorStyles()
  const {
    status
  } = connectedAccounts || {}


  return status ? <>
    <SectionHeader>
      <Box {...{
        display: 'flex',
        alignItems: 'center'
      }}>
        <IconWithAdornment {...{
          fontSize: 'medium',
          className: styles.headIcon,
          adornment: <ArrowBackSharp/>
        }} >
          <AccountBalanceSharp/>
        </IconWithAdornment>
        <span>Receiving Account</span>
      </Box>
    </SectionHeader>
    <Box {...{
      className: styles.linkedAccountBox,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
      <IconWithAdornment {...{
        fontSize: 'x-large',
        className: styles.linkedAccountIcon,
        adornment: <LinkSharp/>
      }} >
        <AccountBalanceSharp/>
      </IconWithAdornment>
      {
        (status === CONNECTED_ACCOUNT_STATUS_PENDING) &&
          <ConnectedAccountPendingCta {...{
            connectedAccounts
          }} />
      }
      {
        status === CONNECTED_ACCOUNT_STATUS_ACTIVE &&
          <ConnectedAccountActive {...{
            connectedAccounts
          }} />
      }
    </Box>
  </> :  <Grid
    container
    justify={'center'}
  >
    <CircularProgress {... {
      size: '2em',
      className: busyStyles.spinner,
      color: 'primary'
    }} />
  </Grid>
}

