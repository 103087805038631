import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  root: {

  },
  list: {
    margin: '0.5em 0',
    fontFamily: 'monospace',
  },
  button: {
    marginLeft: '1em',
  },
  deleteButton: {
    fontSize: 'medium',
    cursor: 'pointer',
  },
}))
