import makeStyles from "@material-ui/core/styles/makeStyles";


const SignUpStepperStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.primary.main,
    fontSize: 25,
    marginTop: '-.3em'
  },
  notCompleted: {
    color: theme.palette.secondary.main,
  },
  completedLabel: {
    '& .MuiStepLabel-label': {
      marginTop: '.25em'
    }
  }
}));

export default SignUpStepperStyles
