import {Avatar, CircularProgress, FormControl, FormHelperText, MenuItem, Select} from '@material-ui/core'
import {CUSTOMER_COMPANY_USER_TYPE} from '../services/identity-provider.service'
import {makeStyles} from '@material-ui/core/styles'
import noop from '../util/noop'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchChekhubOrgList } from '../store/actions/service-company.actions'
import { generatePath, useHistory } from 'react-router-dom'
import { CHEKHUB_END_USER_LANDING } from '../routing/route.constants'

export const useActiveOrgSelectorStyles = makeStyles(theme => ({
  formControl: {
    display: 'flex'
  },
  spinner: {
    color: '#606060',
    float: 'right'
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(2),
    background: theme.palette.primary.main
  },
  avatarLocationCityIcon: {
    fontSize: 'medium',
  }
}))

const ActiveOrgSelector = ({
  className,
  value = '',
  onChange = noop,
  backgroundRunning,
                             hasPermissions
}) => {

  const dispatch = useDispatch()
  const user = useSelector(({auth}) => auth.user)
  const classNames = useActiveOrgSelectorStyles()
  const history = useHistory()
  const chekhubList = useSelector(({chekhubList}) => chekhubList)
  // useEffect( async () => {
  //   user && await history.push(generatePath(CHEKHUB_END_USER_LANDING, {
  //     orgId: user.organizations[0].organization_id
  //   }), [])
  // })
  return chekhubList && <form {...{className}}>
    <FormControl {...{
      className: classNames.formControl
    }}>
      <Select
        {...{
          onChange,
          value,
          className: classNames.select,
          disabled: backgroundRunning
        }}
        displayEmpty
      >
        <MenuItem value="" disabled>
          <span>Choose...</span>
        </MenuItem>
        {
          chekhubList?.memberships?.filter(item => item.role_object &&
            item?.role_object?.privileges.find(priv =>
              priv === 'connect/x')).map(
            (org, key) => <MenuItem {...{
              value: org?.organization._id,
              orgRole: org?.role_object?.name?.toLowerCase(),
              key
            }} >
              {/*TODO: Figure out how the heck to put MenuItem into separate file and propagate onChange for Select*/}
              {
                org.image ?
                  <Avatar {...{
                    src: org.image.url
                  }} /> :
                  <Avatar className={classNames.avatar}>
                    <LocationCityIcon className={classNames.avatarLocationCityIcon}/>
                  </Avatar>
              }
              <span>{org.organization.name}</span>
              {
                backgroundRunning && <CircularProgress {...{
                  size: 20,
                  className: classNames.spinner
                }} />
              }
            </MenuItem>
          )
        }
      </Select>
      <FormHelperText>Chekhub Organization</FormHelperText>
    </FormControl>
  </form>
}

export default ActiveOrgSelector
