export const replaceItemInList = (item, list, index) => [
  ...list.slice(0, index),
  item,
  ...list.slice(index + 1)
]

export const removeItemByIndex = (list, index) => [
  ...list.slice(0, index),
  ...list.slice(index + 1)
]
