import createUser from './auth-sign-up-functions/create-user'
import mapToHuman from './shared-functions/map-error-to-readable'
import {setBackendWorking, setBackendWorkingDone} from './backend-working.actions'
import {serviceCompanySetState} from "./service-company.actions";
import {signInAction} from './sign-in.actions'
import {
  SIGN_UP_COMPLETE_PROFILE_PATH,
  SIGN_UP_COMPLETE_TECH_PROFILE_PATH,
  SIGN_UP_SC_CREATE_PATH
} from '../../routing/route.constants'

const authSignUp = (credentials, history) => async (dispatch) => {
  dispatch(setBackendWorking())

  const { userType } = credentials

  const [, signUpError] = await createUser(credentials, userType)
  if (signUpError) {
    const humanMessage = mapToHuman(signUpError.response ? signUpError.response.data?.errors : '')
    dispatch({
      type: 'TOAST/TRIGGER-NOTIFICATION',
      payload: {
        type: 'error',
        message: `There was an error creating this user: ${humanMessage}`,
      },
    })
    dispatch({ type: 'AUTH/SIGN-UP-ERROR', payload: signUpError })
    dispatch(setBackendWorkingDone())
    return undefined
  }
  dispatch({ type: 'AUTH/SIGN-UP-OK' })
  await dispatch(signInAction(credentials))

  dispatch(serviceCompanySetState({
    company_name: credentials.company_name,
  }))
  const path = credentials.userType === 'service_company' ?
    SIGN_UP_SC_CREATE_PATH : SIGN_UP_COMPLETE_TECH_PROFILE_PATH
  history.push(path)
}

export default authSignUp
