export const ROOT_PATH = '/'
export const CATCH_ALL_PATH = '*'
export const SELECT_ORG = '/select-org'
export const LANDING_PATH = '/landing'
export const SIGN_IN_PATH = '/sign-in'
export const CHEKHUB_END_USER_SIGN_IN = '/sign-in-chekhub-end-user'
export const CHEKHUB_END_USER_SIGN_IN_WITH_AUTH = '/sign-in-chekhub-end-user/:auth'
export const CHEKHUB_END_USER_SIGN_IN_WITH_AUTH_AND_ORG = '/sign-in-chekhub-end-user/:auth/:organizationId'
export const DASHBOARD_PATH = '/dashboard'
export const SELECT_ORG_PATH = '/select-org'
export const SIGN_UP_PATH = '/service-company/sc/sign-up'
export const SIGN_UP_PATH_INDY = '/indy-tech/indy/sign-up-tech'
export const SIGN_UP_SC_CREATE_PATH = '/sign-up/service-company/create'
export const SIGN_UP_SELECT_USER_TYPE = '/sign-up/chekhub-user/select/user-type'
export const SIGN_UP_COMPLETE_PROFILE_PATH = '/sign-up/profile/complete'
export const SIGN_UP_COMPLETE_TECH_PROFILE_PATH = '/sign-up/tech/profile/complete'
export const PROFILES_SC_CREATE_PATH = '/profiles/service-company/create'
export const TECH_PROFILE_VIEW_PATH = '/profiles/techs/:editId'
export const TECH_VIEW_PATH = '/techs/:userId'
export const TECHS_LIST_PATH = '/service-company/:orgId/techs'
export const SC_LIST_PATH = '/find/service-providers'
export const USER_PROFILE_VIEW_PATH = '/profiles/:userId'
export const PROFILES_LIST_PATH = '/profiles'
export const TICKET_VIEW_PATH = '/ticket/:ticketId'
export const INVITE_CREATE_PATH = '/invites/create'
export const INVITES_LIST_PATH = '/invites'
export const SERVICE_COMPANY_VIEW_PATH = '/service-company-view/:orgId'
export const SERVICE_COMPANY_MANAGE_MEMBERS = '/service-company/:orgId/members'
export const SERVICE_COMPANY_EDIT = '/service-company/:orgId/members/edit'
export const SERVICE_COMPANY_EDIT_DRAWER = '/service-company/:orgId/members/service-company-edit'
export const SERVICE_COMPANY_SUMMARY = '/service-company/:orgId/summary'
export const SERVICE_COMPANY_WALLET = '/service-company/:orgId/wallet'
export const CHEKHUB_END_USER_WALLET = '/chekhub-user/:orgId/wallet'
export const SERVICE_COMPANY_ENGAGEMENTS = '/service-company/:orgId/engagements'
export const SERVICE_COMPANY_ENGAGEMENTS_CURRENT_TEAMS = '/service-company/:orgId/engagements/current-teams'
export const SERVICE_COMPANY_INVITED_TEAMS = '/service-company/:orgId/engagements/invited-teams'
export const SERVICE_COMPANY_ENGAGEMENTS_TICKETS = '/service-company/:orgId/engagements/tickets'
export const CHEKHUB_END_USER_ENGAGEMENTS_CURRENT_TEAMS = '/chekhub-user/:orgId/engagements/current-teams'
export const CHEKHUB_END_USER_ENGAGEMENTS_INVITED_TEAMS = '/chekhub-user/:orgId/engagements/invited-teams'
export const CHEKHUB_END_USER_REQUIRED_DOCS = '/chekhub-user/:orgId/engagements/required-docs'
export const CHEKHUB_END_USER_ENGAGEMENTS_TICKETS = '/chekhub-user/:orgId/engagements/tickets'
export const CHEKHUB_END_USER_LANDING = '/chekhub-user/:orgId/landing'
export const CHEKHUB_END_USER_ENGAGEMENTS = '/chekhub-user/:orgId/engagements'
export const INDY_TECH_CURRENT_TEAMS = '/techs/:userId/engagements/current-teams'
export const INDY_TECH_INVITED_TEAMS = '/techs/:userId/engagements/invited-teams'
export const INDY_TECH_TICKETS = '/techs/:userId/engagements/tickets'
export const INDY_TECH_WALLET = '/techs/:userId/wallet'

export const MATT_SANDBOX = '/sandboxes/matt'
export const KEVIN_SANDBOX = '/sandboxes/kevin'
export const PRAHLAD_SANDBOX = '/sandboxes/prahlad'
