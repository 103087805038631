import { Redirect, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServiceCompany, resetServiceCompanyState } from '../store/actions/service-company.actions'
import {Box, Card, CardContent, Divider, Hidden} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import ServiceCompanySummary from '../components/ServiceCompanySummary'
import ManagePaymentSources from '../components/ManagePaymentSources'
import useLandingViewStyles from '../components/LandingViewStyles'
import { ReceivingAccount } from '../components/ReceivingAccount'
import {
  fetchConnectedAccounts,
  fetchConnectedAccountsIndy,
  resetConnectedAccounts
} from '../store/actions/wallet.actions'
import { useAuthUser } from '../hooks/useAuthUser'
import { getDefaultRedirectPath, getUserOrg, isServiceCompanyManager } from '../util/users.util'
import { INDEPENDENT_TECH_ORG_TYPE } from '../constants/user.constants'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { fetchTechProfile } from '../store/actions/tech-profile.actions'

const userRevenueBillingViewStyles = makeStyles(theme => ({
  verticalDividerBox:{
    height: 'auto',
    alignSelf: 'stretch',
    marginLeft: '-1px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }
}))
export const RevenueBillingView = () => {
  const {orgId} = useParams()
  const styles = {
    ...useLandingViewStyles(),
    ...userRevenueBillingViewStyles()
  }
  const dispatch = useDispatch()
  const {
    serviceCompany: serviceCompanyProfile,
    connectedAccounts,
    techProfile
  } = useSelector(
    ({
      serviceCompany,
      wallet,
      techProfile
    }) => ({
      serviceCompany,
      techProfile,
      connectedAccounts: wallet.connectedAccounts
    })
  )
  const authorizedUser = useAuthUser()
  const org = getUserOrg(authorizedUser, orgId)
  const isIndependentTechOrg = org?.organization_type === INDEPENDENT_TECH_ORG_TYPE
  const isManager = isServiceCompanyManager(authorizedUser, orgId)
  const {userId} = useParams()


  useEffect(async () => {
    !isIndependentTechOrg ? await dispatch(fetchServiceCompany(orgId)) : await dispatch(fetchTechProfile(userId))
    !isIndependentTechOrg ? await dispatch(fetchConnectedAccounts(orgId))
      : techProfile.user_id && await dispatch(fetchConnectedAccountsIndy(techProfile.user_id))
  }, [, ])



  useEffect(() => () => {
    dispatch(resetServiceCompanyState())
    dispatch(resetConnectedAccounts())
  }, [])

  return isManager ?
    <Grid container>
      {
        serviceCompanyProfile._id && !isIndependentTechOrg && <Hidden smDown>
          <Grid item xs={3}>
            <ServiceCompanySummary {...{
              profile: serviceCompanyProfile,
              styles
            }} />
          </Grid>
        </Hidden>
      }
      <Grid
        item
        sm={12}
        md={isIndependentTechOrg ? 12 : 9}
        className={styles.skeleton} >
        <Card
          variant="outlined"
          className={styles.card}>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <ManagePaymentSources />
              </Grid>
              <Hidden xsDown>
                <Box className={styles.verticalDividerBox}>
                  <Divider {...{
                    orientation: 'vertical',
                  }}/>
                </Box>
              </Hidden>
              <Grid item xs={12} sm={6}>
                <ReceivingAccount {...{
                  connectedAccounts
                }} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid> :
    <Redirect to={getDefaultRedirectPath(authorizedUser)}/>
}

