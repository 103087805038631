import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab'
import TableChartIcon from '@material-ui/icons/TableChartSharp'
import ListIcon from '@material-ui/icons/ViewListSharp'
import {makeStyles} from '@material-ui/core/styles'

const useDataDisplayToggleStyles = makeStyles(theme => ({
  group: {
    '& button.MuiToggleButton-root': {
      padding: theme.spacing(0.625)
    },
    '& button.MuiToggleButton-root, & svg.MuiSvgIcon-root': {
      fontSize: '1.2rem',
    }
  }
}))

export const LIST_DISPLAY = 'LIST_DISPLAY'
export const TABLE_DISPLAY = 'TABLE_DISPLAY'
export const DataDisplayToggle = ({
  selected,
  onChange
}) => {
  const styles = useDataDisplayToggleStyles()
  return <ToggleButtonGroup {...{
    size: 'small',
    value: selected,
    exclusive: true,
    onChange,
    className: styles.group
  }}>
    <ToggleButton
      {...{
        value: TABLE_DISPLAY,
        selected: selected === TABLE_DISPLAY
      }} >
      <TableChartIcon/>
    </ToggleButton>
    <ToggleButton
      {...{
        value: LIST_DISPLAY,
        selected: selected === LIST_DISPLAY
      }} >
      <ListIcon/>
    </ToggleButton>
  </ToggleButtonGroup>
}
