import {Headers, Methods, MimeTypes} from 'http-headers-js'
import appConfig from '../config'
import generateAuthHeader from './generate-auth-header.util'
import useAccessToken from '../store/actions/useAccessToken'

const {chekhubConnect: {
  paths: {
    storageService: path,
  },
  baseURI,
  defaultHeaders
}} = appConfig

export const STORAGE_SERVICE_BASE_URI = `${baseURI}${path}`

export const getStorageDownloadUrl = filename =>
  `${STORAGE_SERVICE_BASE_URI}/${encodeURIComponent(filename)}`

export const getNewUploadUrl = async file => await fetch(
  STORAGE_SERVICE_BASE_URI, {
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    method: Methods.POST,
    body: JSON.stringify({
      filename: file.name,
      // TODO: make these dynamic, if we really need them,
      //  or else rip them out once the API allows it.
      document_type: 'certification',
      owner: {
        type: 'service_company',
      },
    })
  }
)

export const uploadFileToUrl = async (file, url) =>
  await fetch(
    url, {
      headers: {
        ...defaultHeaders,
        [Headers.CONTENT_TYPE]: MimeTypes.X.WWW_FORM_URLENCODED,
      },
      method: Methods.PUT,
      body: file
    }
  )

export const downloadStorageContent = async filename =>
  await fetch(
    getStorageDownloadUrl(filename),
    {
      headers: {
        ...defaultHeaders,
        [Headers.AUTHORIZATION]: await generateAuthHeader()
      }
    }
  )


