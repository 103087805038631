import {useDispatch, useSelector} from 'react-redux'
import useAsyncEffect from 'use-async-effect'
import {generatePath, useHistory, useParams} from 'react-router-dom'
import useFormStyles from '../styles/formStyles'
import useEditStyles from '../components/UserProfileStyles'
import TechProfile from '../components/TechProfile'
import {
  TECH_VIEW_PATH
} from '../routing/route.constants'
import {
  fetchTechProfile,
  techProfileResetState, techProfileSetState,
  updateTechProfile
} from '../store/actions/tech-profile.actions'
import {
  createUserProfile,
  fetchUserProfile,
  updateUserProfile,
  userProfileResetState
} from '../store/actions/user-profile.actions'
import {TECH_USER_TYPE} from '../constants/user.constants'
import {Grid} from '@material-ui/core'
import {
  onCertUploadChange,
  onChangeDD214Url,
  onChangeVetStatus, onInsurUploadChange,
  onRemoveDD214Url, onUpdateCertifications,
  onUpdateInsurancePolicy, onUpdateSkills,
  techProfileOnAddressChange,
  techProfileOnChange
} from './TechProfile.util'
import {getTechProfileIdForOrgId} from '../util/users.util'
import {isServiceErrorResult} from '../services/is-service-error-result.util'
import {replaceItemInList} from '../util/lists.util'
import {useState} from "react"
import makeStyles from '@material-ui/core/styles/makeStyles'

const TECH_PROFILE_OPERATIONS = {
  ADD: 'ADD',  // TODO: Rip this out, it's handled by ServiceCompanyMemberAdd.view.js
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  SIGN_UP_TECH: "SIGN_UP_TECH"
}

const useTechProfileViewStyles = makeStyles(theme => ({
  root: {
    '& .MuiFab-root' : {
      bottom: theme.spacing(6.75)
    }
  },
}))

// TODO: Figure out how to separate this out into 4 different view components:
//  AddTechProfileView, EditTechProfileView, CreateTechProfileView and SignUpTechProfileView
const TechProfileView = () => {
  const formStyles = useFormStyles()
  const editStyles = useEditStyles()
  const styles = useTechProfileViewStyles()
  const {
    userProfile,
    techProfile,
    user
  } = useSelector(({
    auth,
    userProfile,
    techProfile,
  }) => ({
    userProfile,
    techProfile,
    user: auth.user
  }))
  const dispatch = useDispatch()
  const history = useHistory()
  const {editId, orgId} = useParams()
  const operation = orgId ?
    TECH_PROFILE_OPERATIONS.ADD :
    editId ? TECH_PROFILE_OPERATIONS.EDIT :
      user._id && !techProfile._id ? TECH_PROFILE_OPERATIONS.SIGN_UP_TECH :
        TECH_PROFILE_OPERATIONS.CREATE

  const phoneNumbers = techProfile.phones || []
  const [editHoursFlag, setEditHoursFlag] = useState(false)
  const [allowSubmit, setAllowSubmitState] = useState(true)
  const [allowPhones, setAllowPhones] = useState(true)
  const [allowCerts, setAllowCerts] = useState(true)
  const [allowInsurance, setAllowInsurance] = useState(true)
  const [allowWorkExperience, setAllowWorkExperience] = useState(true)
  const [allowWorkingHours, setAllowWorkingHours] = useState(true)
  const [allowPrimaryContactPhones, setAllowPrimaryContactPhones] = useState(true)
  const showAllowButtonOrNot = (bool) => {
    setAllowSubmitState(bool)
  }

  useAsyncEffect(async () => {
    const userProfileId = editId ?
      editId :
      operation === TECH_PROFILE_OPERATIONS.SIGN_UP_TECH ?
        user._id : null

    userProfileId && dispatch(fetchUserProfile(editId))
    editId && dispatch(fetchTechProfile(editId))
  }, async () => {
    dispatch(userProfileResetState())
    dispatch(techProfileResetState())
  }, [])

  const onSubmit = async e => {
    e.preventDefault()
    const persistTechProfile = updateTechProfile
    const persistUserProfile = userProfile._id ?
      updateUserProfile : createUserProfile

    const userSearchParams = operation === TECH_PROFILE_OPERATIONS.ADD ?
      {user_type: TECH_USER_TYPE} : {}
    const userResult = await dispatch(persistUserProfile({
      ...userProfile,
      organization_id: orgId || userProfile.organization_id,
      searchParams: userSearchParams
    }))
    if (userResult?.constructor === Error) {
      return userResult
    }

    const techProfileId = getTechProfileIdForOrgId(
      userProfile?.id ?
        userProfile: userResult.user
    )
    const techError = await dispatch(persistTechProfile({
      ...techProfile,
      _id: techProfileId
    }))

    if (!isServiceErrorResult(techError)) {
      const nextRoute = [
        TECH_PROFILE_OPERATIONS.ADD,
        TECH_PROFILE_OPERATIONS.CREATE,
        TECH_PROFILE_OPERATIONS.EDIT
      ].includes(operation) ? null : generatePath(TECH_VIEW_PATH, {
        userId: techProfileId
      })
      if (nextRoute) {
        history.push(nextRoute)
      } else {
        history.goBack()
      }
    }
  }

  const title = (
    operation === TECH_PROFILE_OPERATIONS.CREATE ?
      'Create Technician Profile' :
        operation === TECH_PROFILE_OPERATIONS.ADD ?
      'Add Technician Profile' : ''
  )

  const submitButtonLabel = operation === TECH_PROFILE_OPERATIONS.ADD ?
    'Add Profile' :
    TECH_PROFILE_OPERATIONS.EDIT ?
      'Update Profile' : 'Create Profile'

  const editable = (
    (
      // CREATE || ADD
      (!userProfile._id && !techProfile._id) ||
      // EDIT
      (
        userProfile._id &&
        techProfile._id &&
        [userProfile._id, techProfile._id].every(id => user._id === id)
      ) ||
      // SIGN_UP_TECH
      (
        userProfile._id === user._id && !techProfile._id
      )
    )
  )

  // TODO: @prahlad these need to be abstracted out to a phones.util.js file so we're not copying them all over
  const onPhoneNumberAdd = (info) => {
    const newPhones = [...techProfile?.phones, {
      label: info.label,
      raw_phone: "+" + info.raw_phone,
      country: 'us'
    }]
    updatePhoneNumbers(newPhones)
  }

  const onPhoneNumberRemove = (index) => {
    const newPhones = [...phoneNumbers].filter((p, i) => i !== index)
    updatePhoneNumbers(newPhones)
  }

  const onPhoneNumberUpdate = (index, info) => {
    const currentPhones = techProfile.phones
    const newPhones = replaceItemInList(info, currentPhones, index)
    updatePhoneNumbers(newPhones)
  }

  const updatePhoneNumbers = (newPhones) => {
    techProfile.phones = newPhones
    dispatch(techProfileSetState({
      ...techProfile,
      phones: newPhones
    }))
    showAllowButtonOrNot(true)
  }

  const onSave = (dayHours) =>{
    let newProfile = {
      ...techProfile
    }
    dispatch(techProfileSetState({
      ...newProfile,
      pay_info: {
        ...newProfile.pay_info,
        hour_rate_overtime: 0,
        technician_role: '',
        working_hours: dayHours
      }
    }))
    showAllowButtonOrNot(true)
    setEditHoursFlag(false)
  }
  return <Grid {...{
    container: true,
    justify: 'center',
    className: `${editStyles.editProfileGrid} ${styles.root}`
  }}>
    <TechProfile {...{
      editStyles,
      formStyles,
      onSubmit,
      onAddressChange: techProfileOnAddressChange(techProfile, dispatch),
      onChange: techProfileOnChange(userProfile, techProfile, dispatch),
      techProfile,
      title,
      editable,
      submitButtonLabel,
      onChangeVetStatus: onChangeVetStatus(techProfile, dispatch),
      onChangeDD214Url : onChangeDD214Url(techProfile, dispatch),
      onRemoveDD214Url: onRemoveDD214Url(techProfile, dispatch),
      onPhoneNumberAdd,
      onPhoneNumberRemove,
      onPhoneNumberUpdate,
      updatePhoneNumbers,
      phoneNumbers,
      showAllowButtonOrNot,
      allowSubmit,
      history,
      onSave,
      editHoursFlag,
      setEditHoursFlag,
      onInsurUploadChange: onInsurUploadChange(dispatch),
      onUpdateCertifications: onUpdateCertifications(techProfile, dispatch),
      onUpdateInsurancePolicy: onUpdateInsurancePolicy(techProfile, dispatch),
      onCertUploadChange: onCertUploadChange(dispatch),
      onUpdateSkills: onUpdateSkills(techProfile, dispatch),
      allowPhones,
      setAllowPhones,
      allowCerts,
      setAllowCerts,
      allowInsurance,
      setAllowInsurance,
      allowWorkExperience,
      setAllowWorkExperience,
      allowWorkingHours,
      setAllowWorkingHours,
      allowPrimaryContactPhones,
      setAllowPrimaryContactPhones
    }} />
  </Grid>
}

export default TechProfileView
