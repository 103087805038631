import {format, isDate} from 'date-fns'

export const DEFAULT_HUMAN_READABLE_DATE_FORMAT = 'M/d/yyyy'
export const ISO_DATE_FORMAT = `yyyy-MM-dd'T'HH:mm:ss`
export const getHumanReadableDate = (
  dateString,
  formatStr = DEFAULT_HUMAN_READABLE_DATE_FORMAT
) =>
  format(new Date(dateString), formatStr)

export const getISOFormattedDateString = (birthDate) => format(
  !isDate(birthDate) ?
    new Date(birthDate) :
    birthDate,
  ISO_DATE_FORMAT
)

export const getReadableDateHours = (hourDate) =>
  new Date(hourDate).toLocaleTimeString([],
    { hour: '2-digit', minute: '2-digit' })

export const getDateObjectFromYear = year => !year ? null :
  year.constructor === Date ?
    year.constructor :
    new Date(`${year} 12:00:00 GMT-0500`)

export const isValidDate = d =>
  d instanceof Date && !isNaN(d)

export const isInValidDate = d =>
  d instanceof Date && isNaN(d)
