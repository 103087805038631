import { useState } from 'react'
import {Card, CardContent, Grid, Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PhoneNumbersEdit from '../../PhoneNumbersEdit'
import {ExampleLinks, ExampleRoutes} from '../examples.util'
import {InsurancePolicy} from './Insurance'

const usePrahladsSandboxStyles = makeStyles(theme => ({
  header: {
    textTransform: 'uppercase'
  }
}))

export const SandboxPrahladView = () => {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const styles = usePrahladsSandboxStyles()

  const onPhoneNumberAdd = (info) => {
    setPhoneNumbers([...phoneNumbers, {
      label: info.label,
      raw_phone: "+" + info.raw_phone
    }])
  }

  const onPhoneNumberRemove = (index) => {
    setPhoneNumbers([...phoneNumbers].filter((p, i) => i !== index))
  }

  const onPhoneNumberUpdate = (index, info) => {
    phoneNumbers[index] = {
      label: info.label,
      //phone number input is not returning '+'. Checking to see if plus is there if not adding it.
      raw_phone: info.raw_phone.substring(0,1) == "+" ? info.raw_phone : "+" + info.raw_phone
    }
    setPhoneNumbers(phoneNumbers)
  }

  const EXAMPLES = [
    InsurancePolicy
  ]

  return <Grid container>
    <Grid item lg={12} md={12} xs={12}>
      <Card variant='outlined'>
        <CardContent>
          <Typography
            variant='h4'
            className={styles.header}
          >Prahlad's Sandbox</Typography>
        <PhoneNumbersEdit {...{
          phoneNumbers,
          onPhoneNumberAdd,
          onPhoneNumberUpdate,
          onPhoneNumberRemove
          }}
        />
           <ExampleLinks {...{examples: EXAMPLES}} />
          <ExampleRoutes {...{examples: EXAMPLES}} />
        </CardContent>
      </Card>
    </Grid>
  </Grid>
}
