import { makeStyles } from '@material-ui/core/styles'
import noop from '../util/noop'
import { useStripe } from '@stripe/react-stripe-js'
import { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { FormSubmitButton } from './FormSubmitButton'
import { useDispatch } from 'react-redux'
import { setBackendWorking, setBackendWorkingDone } from '../store/actions/backend-working.actions'
import { SOURCE_TYPE_BANK_ACCOUNT } from './PaymentSources'

const ACCOUNT_HOLDER_TYPE_INDIVIDUAL = 'individual'
const ACCOUNT_HOLDER_TYPE_COMPANY = 'company'
const usePaymentBankAccountFormStyles = makeStyles(theme => ({
  cancelButton: {
    marginRight: theme.spacing(1)
  }
}))

export const PaymentBankAccountForm = ({
  onSubmit = noop,
  onCancel = noop
}) => {
  const stripe = useStripe()
  const styles = usePaymentBankAccountFormStyles()
  const [accountHolderType, setAccountHolderType] = useState(ACCOUNT_HOLDER_TYPE_COMPANY)
  const [tokenError, setTokenError] = useState(false)
  const dispatch = useDispatch()
  const clearTokenError = () => setTokenError(false)

  const handleOnSubmit = async ev => {
    ev.preventDefault()
    clearTokenError()
    const {
      account_holder_name: {value: account_holder_name},
      account_number: {value: account_number},
      routing_number: {value: routing_number}
    } = ev.target
    dispatch(setBackendWorking())
    const {
      token, error
    } = await stripe.createToken(
      SOURCE_TYPE_BANK_ACCOUNT,
      {
        account_holder_name,
        account_holder_type: accountHolderType,
        account_number,
        country: 'US',
        currency: 'usd',
        routing_number
      }
    )
    dispatch(setBackendWorkingDone())
    if (error) {
      return setTokenError(error)
    }
    return onSubmit(token)
  }

  const handleAccountHolderOnChange = ev => {
    setAccountHolderType(ev.target.value)
  }

  return <form {...{
    onSubmit: handleOnSubmit
  }} >
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography {...{
          variant: 'h6'
        }}>Add A Bank Account</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset" required>
          <FormLabel component="legend">Account Type</FormLabel>
          <RadioGroup {...{
            id: 'account_holder_type',
            row: true,
            defaultValue: ACCOUNT_HOLDER_TYPE_COMPANY,
            value: accountHolderType,
            onChange: handleAccountHolderOnChange
          }} >
            <FormControlLabel {...{
              value: ACCOUNT_HOLDER_TYPE_COMPANY,
              control: <Radio/>,
              label: 'Business'
            }} />
            <FormControlLabel {...{
              value: ACCOUNT_HOLDER_TYPE_INDIVIDUAL,
              control: <Radio/>,
              label: 'Individual'
            }} />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField {...{
          fullWidth: true,
          variant: 'outlined',
          required: true,
          size: 'small',
          id: 'account_holder_name',
          label: 'Account Holder'
        }} />
      </Grid>
      <Grid item xs={12}>
        <TextField {...{
          fullWidth: true,
          variant: 'outlined',
          required: true,
          size: 'small',
          id: 'account_number',
          label: 'Account Number',
          inputProps: {pattern: '[0-9]{6,17}'},
          placeholder: '000123456789'
        }} />
      </Grid>
      <Grid item xs={12}>
        <TextField {...{
          fullWidth: true,
          variant: 'outlined',
          required: true,
          size: 'small',
          id: 'routing_number',
          label: 'Routing Number',
          inputProps: {pattern: '[0-9]{9}'},
          placeholder: '110000000'
        }} />
      </Grid>
      <Grid item xs={12}>
        <Button {...{
          className: styles.cancelButton,
          onClick: onCancel,
          variant: 'contained'
        }}>Cancel</Button>
        <FormSubmitButton>Submit</FormSubmitButton>
        {
          tokenError && <FormHelperText error={true}>There was a problem adding your account.</FormHelperText>
        }
      </Grid>
    </Grid>
  </form>
}
