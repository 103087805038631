import noop from '../util/noop'
import {Box, IconButton} from '@material-ui/core'
import {CreateSharp, DeleteSharp} from '@material-ui/icons'
import {makeStyles} from '@material-ui/core/styles'

const useEditRemoveButtonsStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    '& > *': {
      marginRight: theme.spacing(.25)
    },
    '& :last-child': {
      marginRight: 0
    },
    '& button.MuiButtonBase-root:hover': {
      color: theme.palette.primary.dark
    }
  }
}))

export const EditRemoveButtons = ({
  editTitle,
  removeTitle,
  size = 'small',
  onEdit = noop,
  onRemove = noop,
  disableEdit = false,
  disableRemove = false
}) => {
  const styles = useEditRemoveButtonsStyles()
  return <Box className={styles.root}>
    {
      !disableEdit && <IconButton {...{
        edge: 'end',
        title: editTitle,
        size,
        onClick: onEdit
      }} >
        <CreateSharp fontSize={size}/>
      </IconButton>
    }
    {
      !disableRemove && <IconButton {...{
        edge: 'end',
        title: removeTitle,
        size,
        onClick: onRemove
      }} >
        <DeleteSharp fontSize={size}/>
      </IconButton>
    }
  </Box>
}
