import {createReducer} from 'redux-create-reducer'
import {USER_PROFILE_RESET_STATE, USER_PROFILE_SET_STATE} from '../actions/user-profile.actions'

export const USER_PROFILE_INIT_STATE = {
  _id: '',
  email: '',
  first_name: '',
  last_name: '',
  organizations: [],
  phones: [],
  full_name: ''
}

const userProfileReducer = createReducer(
  USER_PROFILE_INIT_STATE,
  {
    [USER_PROFILE_SET_STATE]: (state, {user}) => ({
      ...state,
      ...user
    }),
    [USER_PROFILE_RESET_STATE]: () => USER_PROFILE_INIT_STATE
  }
)

export default userProfileReducer
