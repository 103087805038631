import React  from 'react'
import {
  Button,
  CircularProgress,
} from '@material-ui/core'
import ServiceCompanyProfileBasicEdit from '../components/profile-ServiceCompanyProfileBasicEdit'
import TechProfileBasicEdit from '../components/profile-TechProfileBasicEdit'
import useFormStyles from '../styles/formStyles'

const SaveCreateBtn = ({
  handleSaveChanges,
  auth,
  profile,
  handleChange,
  user,
  profileIsNew
}) => {
    const formStyles = useFormStyles()
    return (
    <form onSubmit={handleSaveChanges}>
      <div className={formStyles.row}>
        {
          auth.userRole === 'tech'
            ? ''
            : <ServiceCompanyProfileBasicEdit
              user={user}
              profile={profile}
              onChange={handleChange}
              />
          }
      </div>
      <div className={formStyles.row}>
        <Button variant='contained' color='primary'
          type='submit'
          className={formStyles.smallTextField}
          disabled={auth.backgroundRunning}
          >
          { auth.backgroundRunning
            ? <CircularProgress size={'1.7em'}
            className={formStyles.buttonProgress} />
            : <span>{profileIsNew ? 'Create ' : 'Save '} Profile</span>
          }
        </Button>
      </div>
    </form>
    )
}

export default SaveCreateBtn