import React from 'react'
import { useParams } from 'react-router-dom'
import TicketViewComponent from '../components/ticket-view-component'

const Ticket = () => {
  const { ticketId } = useParams()
  return <TicketViewComponent ticketId={ticketId}/>
}

export default Ticket
