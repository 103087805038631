import {Divider, Link, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {InlineCopyrightIcon} from './InlineCopyrightIcon'

const useConnectedAccountActiveStyles = makeStyles(theme => ({
  accountType: {
    textTransform: 'uppercase',
    marginRight: theme.spacing(1)
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  status: {
    '& span:last-child': {
      textTransform: 'uppercase'
    },
    '& span:first-child': {
      marginRight: theme.spacing(.5)
    }
  },
}))

export const ConnectedAccountActive = ({connectedAccounts}) => {
  const styles = useConnectedAccountActiveStyles()
  const {
    status,
    account_link,
    stripe_data: {
      external_accounts: {
        data: bankAccountsData = []
      } = {}
    } = {}
  } = connectedAccounts || {}
  const bankAccountData = bankAccountsData[0] || {}
  const {
    bank_name,
    last4
  } = bankAccountData
  return <>
    <Typography {...{
      variant: 'h6'
    }}>
      {bank_name}
    </Typography>
    <Typography {...{
      variant: 'subtitle2'
    }}>
      <span className={styles.accountType}>Checking</span>
      <span>••••</span>
      <span>{last4}</span>
    </Typography>
    <Typography {...{
      variant: 'subtitle2',
      gutterBottom: true
    }}>
      <Link href={account_link} target="_blank">
        Manage account on Stripe<InlineCopyrightIcon/>
      </Link>
    </Typography>
    <Divider className={styles.divider}/>
    <Typography {...{
      className: styles.status,
      variant: 'body1'
    }}>
      <span>Status:</span><span>{status}</span>
    </Typography>
    <Divider className={styles.divider}/>
    <Typography variant="body2">
      Your account is linked and ready to receive payments on Chekhub Connect
    </Typography>
  </>
}
