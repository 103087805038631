import { getUserOrg } from '../../../util/users.util'

const fetchActiveOrg = ({organizations}, user) => {
  const userDefaultOrg = getUserOrg(user) || {}
  const userType = userDefaultOrg.organization_type
  let userOrgs = organizations
    .filter((item) => (item.organization_type === userType && item.organization_id))
  const selectedOrg = userOrgs.length === 0 ? userDefaultOrg : userOrgs[0]
  const { organization_id: orgId, role: userRole } = selectedOrg
  return { orgId, userRole }
}

export default fetchActiveOrg
