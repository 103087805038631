import React, { useState, useEffect, Fragment } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import useFormStyles from '../styles/formStyles'
import ServiceCompanyProfileBasicEdit from '../components/profile-ServiceCompanyProfileBasicEdit'
import ProgressButton from '../components/ProgressButton'
import actions from '../store/actions'

const CreateCompany = () => {
  const dispatch = useDispatch()
  const formStyles = useFormStyles()
  const auth = useSelector((state) => state.auth)
  const user = useSelector((state) => state.auth.user)

  const profileSelector = useSelector((state) => {
    const { profile: { data } } = state

    return {
      ...data,
      primary_contact_id: data.primary_contact_id || user.id,
      primary_contact: data.primary_contact || user.id,
    }
  })

  const [profile, setProfile] = useState(profileSelector)

  const handleChange = (profileValue) => {
    setProfile(profileValue)
  }

  const handleSaveChanges = (e) => {
    e.preventDefault()
    // Remove empty optional values
    // eslint-disable-next-line prefer-const
    let completeProfile = profile
    if (!profile.website) delete completeProfile.website
    if (!profile.ein) delete completeProfile.ein
    if (!profile.company_formation_year) delete completeProfile.company_formation_year

    dispatch(actions['PROFILE/UPSERT'](auth.userRole, completeProfile, user))
  }

  return <Fragment>
    <form onSubmit={handleSaveChanges}>
      <div className={formStyles.row}>
        <ServiceCompanyProfileBasicEdit
          user={user}
          profile={profile}
          onChange={handleChange}
        />
      </div>
      <div className={formStyles.row}>
        <ProgressButton
          text='Continue'
          backgroundRunning={auth.backgroundRunning}
        />
      </div>
    </form>
  </Fragment>

}

export default CreateCompany
