import {useDispatch, useSelector} from "react-redux"
import {fetchServiceCompany, resetServiceCompanyState} from "../store/actions/service-company.actions"
import useServiceCompanyTechStyles from "../components/LandingViewStyles"
import {Hidden} from "@material-ui/core"
import ServiceCompanySummary from "../components/ServiceCompanySummary"
import Grid from "@material-ui/core/Grid"
import {useParams} from 'react-router-dom'
import ScTechProfileView from "../components/ScTechProfileView"
import {fetchTechProfile, techProfileResetState} from "../store/actions/tech-profile.actions"
import {format} from 'date-fns'
import {useEffect, useState} from 'react'
import {getUserOrgForTechProfileId} from '../util/users.util'
import {createBackgroundCheckPackage, getBackgroundCheckPackages,} from "../store/actions/background-check.actions"
import {isServiceErrorResult} from '../services/is-service-error-result.util'
import toastShow, {TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS} from '../store/actions/toast-show'
import {INDEPENDENT_TECH_ORG_TYPE} from '../constants/user.constants'

const ServiceCompanyTechView = () => {
  const styles = useServiceCompanyTechStyles()
  const dispatch = useDispatch()
  const {userId} = useParams()
  const {
    serviceCompany: serviceCompanyProfile,
    tech,
    backgroundCheckPackages
  } = useSelector(
    ({
      serviceCompany,
      techProfile,
      backgroundCheck
    }) => ({
      backgroundCheckPackages: backgroundCheck.packages,
      serviceCompany,
      tech: techProfile
    })
  )
  const techOrg = getUserOrgForTechProfileId(tech, userId)
  const isIndependentTechOrg = techOrg?.organization_type === INDEPENDENT_TECH_ORG_TYPE
  const backgroundCheck = tech.background_check || {}
  const orgId = techOrg?.organization_id
  const [editHoursFlag, setEditHoursFlag] = useState(false)

  useEffect(
    () => {
      !tech._id && userId && dispatch(fetchTechProfile(userId))
    },
    [tech]
  )

  useEffect(
    () =>
      tech._id &&
      !isIndependentTechOrg &&
      !serviceCompanyProfile._id &&
      orgId &&
      dispatch(fetchServiceCompany(orgId)),
      [tech, serviceCompanyProfile]
  )

  useEffect(() => () => {
    dispatch(resetServiceCompanyState())
    dispatch(techProfileResetState())
  }, [])

  useEffect(
    () => {
      (!backgroundCheckPackages || !backgroundCheckPackages.length) && dispatch(getBackgroundCheckPackages())
    },
    [backgroundCheckPackages]
  )

  const activeSince = tech.created_at &&
    format(new Date(tech.created_at), 'M/yyyy')

  const handleBgPurchase = async (backgroundCheckPk, token) => {
    const requestBody = {
      organization_id: orgId,
      tech_profile_id: tech._id,
      package_id: backgroundCheckPk.package_id,
      currency: backgroundCheckPk.currency,
      price100: backgroundCheckPk.price100,
      source_id: token.id,
      is_source_token: token.is_source_token
    }
    const result = await dispatch(createBackgroundCheckPackage(requestBody))
    const isError = isServiceErrorResult(result)
    dispatch(toastShow(
      isError ? TOAST_TYPE_ERROR : TOAST_TYPE_SUCCESS,
      isError ?
        'Purchase failed' :
        'Purchase success'
    ))
  }

  return tech._id &&  <Grid container>
    { !isIndependentTechOrg && <Hidden smDown>
        <Grid item xs={3}>
            <ServiceCompanySummary {...{
              profile: serviceCompanyProfile,
              styles
            }} />
        </Grid>
      </Hidden>
    }
    <Grid
      item
      sm={12}
      md={isIndependentTechOrg ? 12 : 9}
      className={styles.skeleton}
    >
      {backgroundCheckPackages && <ScTechProfileView {...{
        tech,
        activeSince,
        setEditHoursFlag,
        editHoursFlag,
        backgroundCheckPackages,
        backgroundCheck,
        handleBgPurchase,
        orgId: serviceCompanyProfile?._id
      }}/>}
    </Grid>
  </Grid>
}

export default ServiceCompanyTechView
