import axios from 'axios'

import appConfig from '../../config'
import useAccessToken from './useAccessToken'
import { authFetchUserFunction } from './auth-fetch-user'
import {setBackendWorking, setBackendWorkingDone} from './backend-working.actions'

const { chekhubConnect: { baseURI, defaultHeaders, paths } } = appConfig

const updateUser = async (user, accessToken) => {
  const { userService } = paths
  if (user && accessToken) console.info(userService)
  return true
}

const upsertProfile = (
  profileType,
  profile,
  user,
) => async (dispatch, getState, getFirebase) => {
  dispatch(setBackendWorking())
  const { techProfileService, serviceCompanyProfileService } = paths
  const accessToken = await useAccessToken()

  const techProfile = profileType === 'tech'
  if (techProfile) updateUser(user, accessToken)

  const upsertPath = techProfile ? techProfileService : serviceCompanyProfileService
  const { _id: profileId } = profile

  // TODO: figure out how we should be handling "unset" optional params better
  let data = {...profile}
  if (!profile.website) delete data.website
  if (!profile.ein) delete data.ein
  if (!profile.company_formation_year) delete data.company_formation_year

  try {
    const { status, data: body } = await axios({
      baseURL: baseURI,
      url: upsertPath + (profileId ? `/${profileId}` : ''),
      method: profileId ? 'PUT' : 'POST',
      headers: {
        ...defaultHeaders,
        authorization: `Bearer ${accessToken}`,
      },
      data,
    })

    if (status !== 200) throw new Error('unknown profileUpsertError')
    const { organizations } = user
    if ((!organizations || !organizations.find((org) => org.organization_id))) {
      authFetchUserFunction(user._id, dispatch, getState, getFirebase)
    }

    dispatch({ type: 'PROFILE/UPSERT-OK', payload: body.data })
  } catch (err) {
    dispatch({ type: 'PROFILE/UPSERT-ERROR', payload: err })
    dispatch({
      type: 'TOAST/TRIGGER-NOTIFICATION',
      payload: {
        type: 'error',
        message: `There was an error ${!profileId ? 'creating' : 'updating'} the profile`,
      },
    })
    dispatch(setBackendWorkingDone())
    return undefined
  }
  useAccessToken(true)
  dispatch(setBackendWorkingDone())
  dispatch({
    type: 'TOAST/TRIGGER-NOTIFICATION',
    payload: {
      type: 'success',
      message: `Profile successfully ${!profileId ? 'created' : 'updated'}`,
    },
  })
}

export default upsertProfile
