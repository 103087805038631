import {Route, Switch, withRouter} from 'react-router-dom'
import {RouteConnectedDrawer} from './RouteConnectedDrawer'
import {ServiceCompanyMemberAdd} from './ServiceCompanyMemberAdd.view'
import {ServiceCompanyMemberEditView} from './ServiceCompanyMemberEdit.view'
import {useSelector} from 'react-redux'

export const ServiceCompanyMemberDrawerUtil = withRouter(
    ({
    open,
    setOpen,
    backRoutePath,
    match
  }) => {
    const {techProfile} = useSelector(
      ({techProfile}) => ({techProfile})
    )
    return <Switch>
      <Route path={`${match.path}/member/create`}>
        <RouteConnectedDrawer {...{
          open,
          setOpen,
          anchor: 'right',
          title: 'Add Technician',
          backRoutePath
        }}>
          <ServiceCompanyMemberAdd {...{
            setDrawerOpen: setOpen,
            backRoutePath
          }} />
        </RouteConnectedDrawer>
      </Route>
      <Route exact path={`${match.path}/:profileId/edit`}>
        <RouteConnectedDrawer {...{
          open,
          setOpen,
          anchor: 'right',
          title: techProfile.full_name,
          backRoutePath
        }}>
          {
            <ServiceCompanyMemberEditView {...{
              setDrawerOpen: setOpen,
              backRoutePath
            }} />
          }
        </RouteConnectedDrawer>
      </Route>
    </Switch>
  }
)
