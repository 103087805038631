export const closeDrawerAndTransition = (
  setDrawerOpen,
  backRoutePath,
  history,
  routeTransitionDelay,
  setBlurred
) => {
  setDrawerOpen(false)
  setBlurred(false)
  setTimeout(
    () => history.push(backRoutePath),
    routeTransitionDelay
  )
}
