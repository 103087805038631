import {Avatar, Box, Card, CardContent, Grid, Link as LinkNative, List, ListItem} from '@material-ui/core'
import {Rating} from '@material-ui/lab'
import {useEffect, useState} from 'react'
import formatAddress from './shared-functions/format-address'
import CreateIcon from '@material-ui/icons/Create'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import useLandingViewStyles from './LandingViewStyles'
import {Link, withRouter} from 'react-router-dom'
import {format} from 'date-fns'
import {useDispatch, useSelector} from 'react-redux'
import {
  fetchServiceCompanyManagementList,
  resetServiceCompanyManagementListState,
  setServiceCompanyManagementListState
} from '../store/actions/service-company-management-list.actions'
import ServiceCompanyDrawer from './ServiceCompanyDrawer'
import {ServiceCompanyManagementList} from './ServiceCompanyManagementList'
import {
  createServiceCompanyMember,
  removeServiceCompanyMember,
  updateServiceCompanyMember
} from '../store/actions/service-company-members.actions'
import {replaceItemInList} from '../util/lists.util'
import PhoneNumbersDisplay from './PhoneNumbersDisplay'
import Typography from '@material-ui/core/Typography'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import {InsuranceDisplay} from './InsuranceDisplay'
import {useDimensionsContextStyles} from './DimensionsContext'
import {isServiceCompanyManager} from '../util/users.util'
import {useAuthUser} from '../hooks/useAuthUser'
import {formatCurrency} from '../util/number-formatting.util'
import {GOOGLE_MAP_API_HREF} from '../constants/user.constants'
import {fetchServiceCompanyPrimaryContact, fetchServiceCompanyTechCount} from '../store/actions/service-company.actions'
import {FieldDisplay} from './FieldDisplay'
import {SectionHeader} from './SectionHeader'

const ServiceCompanySummary = withRouter(({
  profile: serviceCompanyProfile,
  match,
}) => {
  const styles = useLandingViewStyles()
  const dispatch = useDispatch()
  const dimensionsContextStyles = useDimensionsContextStyles()
  const [rating, setRating] = useState(2)
  const [open, setDrawerOpen] = useState(false)
  const activeSince = serviceCompanyProfile?.created_at &&
    format(new Date(serviceCompanyProfile.created_at), 'M/yyyy')
  const scEditPath = `${match.url}/service-company-edit`
  const {phones} = serviceCompanyProfile || {}
  const insurances = serviceCompanyProfile.insurance_policies || []
  const {
    serviceCompanyManagementList
  } = useSelector(
    ({
      serviceCompanyManagementList,
    }) => ({
      serviceCompanyManagementList
    })
  )
  const {
    revenue = {},
    primaryContact = {}
  } = serviceCompanyProfile || {}
  const authorizedUser = useAuthUser()
  const isManager = isServiceCompanyManager(authorizedUser, serviceCompanyProfile._id)

  useEffect(
    () =>
      serviceCompanyProfile._id &&
        dispatch(fetchServiceCompanyManagementList(serviceCompanyProfile._id)),
    [serviceCompanyProfile._id]
  )

  useEffect(
    () => serviceCompanyProfile.primary_contact_id &&
        dispatch(fetchServiceCompanyPrimaryContact(serviceCompanyProfile.primary_contact_id)),
    [serviceCompanyProfile.primary_contact_id]
  )

  useEffect(() =>
    () => dispatch(resetServiceCompanyManagementListState()),
    []
  )

  useEffect(() => {
    serviceCompanyProfile._id && dispatch(fetchServiceCompanyTechCount(serviceCompanyProfile._id))
  }, [serviceCompanyProfile._id])

  const handleAddManager = member => dispatch(createServiceCompanyMember(
    member,
    serviceCompanyProfile._id,
    newMember =>
        dispatch => dispatch(setServiceCompanyManagementListState([
          ...serviceCompanyManagementList,
          newMember
        ]))
  ))

  const handleRemoveManager = async member =>
    dispatch(
      removeServiceCompanyMember(
        member._id,
        serviceCompanyProfile._id,
        () =>
            dispatch => dispatch(setServiceCompanyManagementListState(
              serviceCompanyManagementList.filter(m => m._id !== member._id)
            ))
      )
    )

  const handleEditManager = member => dispatch(updateServiceCompanyMember(
    member,
    serviceCompanyProfile._id,
    updatedMember => dispatch =>
      dispatch(
        setServiceCompanyManagementListState(
          replaceItemInList(
            updatedMember,
            serviceCompanyManagementList,
            serviceCompanyManagementList.findIndex(m => m._id === updatedMember._id)
          )
        )
      )
  ))

  return serviceCompanyProfile && <Box {...{
    border: 0,
    className: dimensionsContextStyles.scrollContainer
  }}>
        <Card className={styles.summaryHolder} variant={'outlined'}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={10} className={styles.avatarHolder}>
                <Avatar {...{
                  src: serviceCompanyProfile.company_logo ? serviceCompanyProfile.company_logo : null,
                  className: styles.avatar,
                  color: 'primary'
                }}><LocationCityIcon/></Avatar>
              </Grid>
              {
                isManager && <Grid item xs={2} className={styles.editLinkGridItem}>
                  <Link className={styles.editLink}
                        to={scEditPath}>
                    <CreateIcon/>
                  </Link>
                </Grid>
              }
              <Grid className={styles.disableVerticalPadding} item xs={12}>
                <Typography {...{
                  className: styles.companyName,
                  variant: 'h6'
                }}>{serviceCompanyProfile.company_name}
                </Typography>
              </Grid>
              {
                serviceCompanyProfile.website &&
                <Grid className={styles.disableVerticalPadding} item xs={12} >
                  <Typography>
                    <LinkNative
                      href={serviceCompanyProfile.website}
                      className={styles.websiteLink}
                      target="_blank">
                      {serviceCompanyProfile.website}
                    </LinkNative>
                  </Typography>
                </Grid>
              }
              {
                serviceCompanyProfile.linkedin_page &&
                <Grid className={styles.disableVerticalPadding} item xs={12}>
                  <Typography
                    {...{
                      variant: 'body2'
                    }} gutterBottom>
                    <LinkNative
                      href={serviceCompanyProfile.linkedin_page}
                      className={styles.linkedInLink} target={'_blank'}>
                      <LinkedInIcon {...{
                        fontSize: 'small',
                        className: styles.icons
                      }} /> LinkedIn Page
                    </LinkNative>
                  </Typography>
                </Grid>
              }
              <Grid className={styles.disableVerticalPadding} item xs={12}>
                <FieldDisplay {...{
                  label: <span>Active Since:</span>
                }}>{activeSince}</FieldDisplay>
              </Grid>
              <Grid item xs={12}>
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldDisplay {...{
                  label: <span>Connect Platform Revenue</span>
                }}/>
                <Typography {...{variant: 'body2'}}>Year to Date: {
                  formatCurrency(revenue.ytd, revenue.currency)
                }</Typography>
                <Typography {...{variant: 'body2'}}>Total: {
                  formatCurrency(revenue.lifetime, revenue.currency)
                }</Typography>
              </Grid>
              <Grid item xs={12}>
                <SectionHeader {...{
                  dividerProps: {
                    className: styles.sectionHeaderDivider
                  }
                }}>
                  Contact
                </SectionHeader>
              </Grid>
              <Grid item xs={12}>
                <FieldDisplay {...{
                  label: <span>Address</span>
                }}/>
                <LinkNative
                  target={'_blank'}
                  href={`${GOOGLE_MAP_API_HREF}${formatAddress(serviceCompanyProfile.location?.address)}`}>
                  {formatAddress(serviceCompanyProfile.location?.address)}
                </LinkNative>
              </Grid>
              <Grid item xs={12}>
                <FieldDisplay {...{
                  label: <span>Phone Numbers</span>
                }}/>
                <PhoneNumbersDisplay {...{
                  phones
                }}/>
              </Grid>
              <Grid item xs={12}>
                <FieldDisplay {...{
                  label: <span>Primary Contact</span>
                }}/>
                <Typography {...{variant: 'body2'}}>{primaryContact.full_name}</Typography>
                <Typography {...{variant: 'body2'}}><LinkNative {...{
                  href: `mailto:${primaryContact.email}`
                }}>{primaryContact.email}</LinkNative></Typography>
                <PhoneNumbersDisplay {...{
                  phones: primaryContact.phones
                }}/>
              </Grid>
              <Grid item xs={12}>
                <SectionHeader {...{
                  dividerProps: {
                    className: styles.sectionHeaderDivider
                  }
                }}>
                  Operations
                </SectionHeader>
              </Grid>
              <Grid item xs={12}>
                <FieldDisplay {...{
                  label: <span>EIN:</span>
                }}>{serviceCompanyProfile.ein}</FieldDisplay>
              </Grid>
              <Grid item xs={12}>
                <FieldDisplay {...{
                  label: <span>Number of Techs:</span>
                }}>{serviceCompanyProfile.tech_count}</FieldDisplay>
              </Grid>
              <Grid item xs>
                <FieldDisplay {...{
                  label: <span>Zip Codes Serviced:</span>
                }}>
                  {serviceCompanyProfile.location.address.postal_code}
                </FieldDisplay>
              </Grid>
              <Grid item xs={12}>
                <SectionHeader {...{
                  dividerProps: {
                    className: styles.sectionHeaderDivider
                  }
                }}>
                  Insurance Policies
                </SectionHeader>
              </Grid>
              <Grid className={styles.disableVerticalPadding} item xs={12}>
                {
                  insurances?.length ? <List className={styles.disableVerticalPadding} dense={true}>
                    {insurances?.map((insur, index) => (
                      <ListItem
                        {...{
                          key: index,
                          disableGutters: true,
                          className: styles.listItem,
                        }}
                      >
                        <Grid container spacing={1}>
                          <InsuranceDisplay
                            {...{
                              insur,
                            }}
                          />
                        </Grid>
                      </ListItem>
                    ))}
                  </List> :
                  <Typography {...{
                    variant: 'subtitle'
                  }}>No insurance policies available</Typography>
                }
              </Grid>
              <Grid item xs={12}>
                <SectionHeader {...{
                  dividerProps: {
                    className: styles.sectionHeaderDivider
                  }
                }}>
                  Managers
                </SectionHeader>
              </Grid>
              <Grid className={styles.disableVerticalPadding} item xs={12}>
                <ServiceCompanyManagementList {...{
                  serviceCompanyManagementList,
                  orgId: serviceCompanyProfile._id,
                  onEdit: handleEditManager,
                  onRemove: handleRemoveManager,
                  onAdd: handleAddManager,
                  disableRemove: !isManager,
                  disableEdit: !isManager,
                  disableAdd: !isManager
                }} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <ServiceCompanyDrawer {...{
          open,
          setOpen: setDrawerOpen,
          backRoutePath: match.url,
          serviceCompanyId: serviceCompanyProfile._id
        }}/>
      </Box>
})

export default ServiceCompanySummary
