import { Box, duration, Typography } from '@material-ui/core'
import { SectionHeader } from '../components/SectionHeader'
import Grid from '@material-ui/core/Grid'
import { FieldDisplay } from '../components/FieldDisplay'
import PhoneNumbersDisplay from '../components/PhoneNumbersDisplay'
import { useHistory, useParams } from 'react-router-dom'
import { FormSubmitButton } from '../components/FormSubmitButton'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServiceCompany, resetServiceCompanyState } from '../store/actions/service-company.actions'
import { sendChekhubOrgInvite } from '../store/actions/AddToTeams.actions'
import { getUserOrg } from '../util/users.util'
import toastShow, { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '../store/actions/toast-show'
import { isServiceErrorResult } from '../services/is-service-error-result.util'
import { closeDrawerAndTransition } from '../components/ServiceCompanyMemberDrawer.util'
import { OnboardingDocumentUploadSendInvite } from '../components/OnboardingDocumentUploadSendInvite'
import { getRequiredDocs } from '../store/actions/addRequiredDocs.actions'
import { fetchTechProfile, techProfileResetState } from '../store/actions/tech-profile.actions'


export const ServiceProviderSummarys = ({
  setOpen,
  backRoutePath,
  routeTransitionDelay = duration.leavingScreen,
  setBlurred,
  indy,
                                          teamOrProviderList

                                        }) => {
  const [open, setOpenModal] = useState(false)
  const [sentDocs, setSentDoc] = useState([])
  const {orgId, teamId} = useParams()
  const dispatch = useDispatch()
  const user = useSelector(({auth}) => auth.user)
  const memberId = getUserOrg(user, orgId)
  const filteredProvider = !indy ? teamOrProviderList.find(item => item._id === teamId) : teamOrProviderList.find(item => item.profile_data._id === teamId)
  const isTech = filteredProvider?.profile_type === 'independent_tech'
  const [uploadOpen, setUploadOpen] = useState(false)
  const [removeUploadConfirmOpen, setRemoveUploadConfirmOpen] = useState(false)
  const history = useHistory()
  const serviceProvider = !isTech ? useSelector(({serviceCompany}) => serviceCompany) :
    useSelector(({techProfile}) => techProfile)
  const [busy, setBusy] = useState(false)

  const handleInvitePreset = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
    setSentDoc([])
  }

  const openUpload = () => setUploadOpen(true)
  const closeUpload = () => {
    setUploadOpen(false)
    setSentDoc([])
  }
  const openRemoveConfirm = () => setRemoveUploadConfirmOpen(true)
  const closeRemoveConfirm = () => {
    setSentDoc([])
    setRemoveUploadConfirmOpen(false)
  }

  const requiredDocs = useSelector(({requiredDocsList}) => requiredDocsList)
  const chekhubOrgId = localStorage.getItem("orgId")?.replace(/['"]+/g, '')

  useEffect(  async () => {
    !isTech ? await dispatch(fetchServiceCompany(teamId)) : await dispatch(fetchTechProfile(teamId))
    await dispatch(getRequiredDocs({
      chekhub_org_id: chekhubOrgId
    }))
  }, [])

  useEffect(() => {
    dispatch(resetServiceCompanyState())
    dispatch(techProfileResetState())
  }, [])

  const handleCheckBoxClick = (e) => {
    const selected = e.target.value
    setSentDoc([...sentDocs, {
      filename: selected,
      name: e.target.name
    }])
  }

  const handleInviteSent = async () => {
    const result = await dispatch(sendChekhubOrgInvite({
      requirements: sentDocs,
      chekhub_org_id: chekhubOrgId,
      service_provider_id: serviceProvider._id
    }))

    const isError = isServiceErrorResult(result)
    dispatch(toastShow(
      isError ? TOAST_TYPE_ERROR : TOAST_TYPE_SUCCESS,
      isError ?
        'Invite to Team Failure -> contact support@connect.com' :
        'Invite to Team Success'
    ))
    closeDrawerAndTransition(
      setOpen,
      backRoutePath,
      history,
      routeTransitionDelay,
      setBlurred
    )
    setOpenModal(false)
    setBusy(false)
  }

  const handleAddToChekhubOrg = async () => {
    setBusy(true)
   const result = await dispatch(addToChekhubTeams(
      serviceProvider,
      orgId
    ))
    const isError = isServiceErrorResult(result)
    dispatch(toastShow(
      isError ? TOAST_TYPE_ERROR : TOAST_TYPE_SUCCESS,
      isError ?
        'The specified service provider cannot yet receive payments' :
        'Add to Team Success'
    ))
    closeDrawerAndTransition(
      setOpen,
      backRoutePath,
      history,
      routeTransitionDelay,
      setBlurred
    )
    setBusy(false)
  }



  return serviceProvider && <Box style={{
    padding: '10px'
  }}>
    <SectionHeader>
      Service Provider Information
    </SectionHeader>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FieldDisplay {...{
          label: 'Provider Name'
        }}/>
        <Typography variant={'h6'}>
          {serviceProvider.company_name || serviceProvider.full_name}
        </Typography>
      </Grid>
      {!isTech ? <Grid item xs={4}>
        <FieldDisplay {... {
          label: 'Provider EIN'
        }}/>
        <Typography variant={'h6'}>
          {serviceProvider.ein}
        </Typography>
      </Grid> :
        <Grid item xs={4}>
          <FieldDisplay {... {
            label: 'Provider Hour Rate'
          }}/>
          <Typography variant={'h6'}>
            {serviceProvider.hour_rate}
          </Typography>
        </Grid>
      }
      <Grid item xs={4}>
        <FieldDisplay {...{
          label: 'Provider Postal Code'
        }}/>
        <Typography variant={'h6'}>
          {serviceProvider.location.address?.postal_code}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FieldDisplay {...{
          label: 'Provider Address'
        }}/>
        <Typography variant={'h6'}>
          {serviceProvider.location.formatted_address}
        </Typography>
      </Grid>
      {!isTech ? <Grid item xs={6}>
        <FieldDisplay {... {
          label: 'Provider Industries'
        }}/>
        {serviceProvider.industries ? serviceProvider.industries.map(industry => {
          return <Typography variant={'h6'}>
            {industry}
          </Typography>
        }) : 'No Industries Available'}
      </Grid> :
        <Grid item xs={6}>
          <FieldDisplay {... {
            label: 'Provider Skills'
          }}/>
          {serviceProvider.skills ? serviceProvider.skills.map(skill => {
            return <Typography variant={'h6'}>
              {skill}
            </Typography>
          }) : 'No Skills Available'}
        </Grid>
      }
      <Grid item xs={6}>
        <FieldDisplay {...{
          label: 'Provider Phone Numbers'
        }}/>
        <PhoneNumbersDisplay {...{
          phones: serviceProvider.phones
        }}/>
      </Grid>
    </Grid>
    <FormSubmitButton {...{
      onClick: handleInvitePreset,
      useFab: true,
      stillBusy: busy
    }}>
      {'Invite Team'}
    </FormSubmitButton>
    <OnboardingDocumentUploadSendInvite {...{
      handleClose,
      open,
      uploadOpen,
      setUploadOpen,
      openUpload,
      closeUpload,
      removeUploadConfirmOpen,
      setRemoveUploadConfirmOpen,
      openRemoveConfirm,
      closeRemoveConfirm,
      requiredDocs,
      setSentDoc,
      handleCheckBoxClick,
      handleInviteSent
    }} />
  </Box>
}
