import {
  Box,
  CardActions,
  CardContent,
  Collapse,
  FormHelperText,
  IconButton,
  Link as LinkNative
} from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { InsuranceDisplay } from './InsuranceDisplay'
import Grid from '@material-ui/core/Grid'
import { FieldDisplay } from './FieldDisplay'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import useLandingViewStyles from './LandingViewStyles'
import { FormSubmitButton } from './FormSubmitButton'


export const CardContentList = ({
  team,
                                  handleInvitePreset
                                }) => {
  const styles = useLandingViewStyles()
  const [open, setOpen] = useState(false)
  return <>
    <CardActions disableSpacing style={{
      justifyContent: 'center'
    }}>
    <IconButton size="medium" onClick={() => setOpen(!open)} align={'center'}>
      {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
    </IconButton>
  </CardActions>
  <Collapse in={open} timeout="auto" unmountOnExit>
    <CardContent>
      <Grid container direction={'row'}>
      <Grid item xs={3}>
        <FieldDisplay {...{
          label: <span>Industries</span>
        }}/>
      {team.industries.length > 0 ? team?.industries.map(t => {
       return <Typography variant={'body1'}>
         {t}
        </Typography>
      }) : <Typography variant={'body1'}>
        No Industries Available
      </Typography>}
      </Grid>
      <Grid item xs={3}>
        <FieldDisplay {...{
          label: <span>EIN</span>
        }}/>
      <Typography variant={'body1'}>
        {team?.ein || 'No EIN Available'}
      </Typography>
      </Grid>
      <Grid item xs={3}>
        <FieldDisplay {...{
          label: <span>Insurance Policy</span>
        }}/>
      {team.insurance_policies.length > 0 ? <InsuranceDisplay {... {
        insur: team.insurance_policies
      }} /> : <Typography>
        No Insurance Policies Available
      </Typography>}
      </Grid>
      <Grid item xs={3}>
        <Typography
          {...{
            variant: 'body1'
          }} gutterBottom>
          <LinkNative
            href={team.linkedin_page}
            className={styles.linkedInLink} target={'_blank'}>
            <LinkedInIcon {...{
              fontSize: 'small',
              className: styles.icons
            }} /> {team.company_name}'s LinkedIn
          </LinkNative>
        </Typography>
      </Grid>
        <Grid item xs={12}>
          <FormSubmitButton onClick={() => handleInvitePreset(team)}>
            Invite To Team
          </FormSubmitButton>
        </Grid>
      </Grid>
    </CardContent>
  </Collapse>
  </>
}
