import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core'
import SignInForm from './SignInForm'
import { Link, useHistory } from 'react-router-dom'
import jumbo from "../assets/images/Chekhub_Connect_Gray.svg"
import makeStyles from '@material-ui/core/styles/makeStyles'
import { FormSubmitButton } from './FormSubmitButton'
import { SIGN_UP_PATH, SIGN_UP_PATH_INDY } from '../routing/route.constants'

const useSignInFrontStyles = makeStyles(theme => ({
  box: {
    marginTop: theme.spacing(1)
  }
}))
export const SignInFront = ({
  onChange,
  onSubmit,
  backgroundRunning,
  authError,
  useChekhubSignIn,
  styles,
  setForgotPassword,
  handlePasswordShow,
  showPassword
}) => {
  const classes = useSignInFrontStyles()
  const history = useHistory()
  return <Card>
    <CardContent>
    <Grid container justify={'center'} alignItems={'center'}>
      <Grid item>
        <Grid container justify={'center'}>
          <img
            src={jumbo}
            width={'100%'}
            alt={'connect-logo-orange'}
            style={{
              paddingTop: '20%'
            }}
            height={'auto'}
            className={styles.img}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          {...{
            variant: 'h4',
            style: {
              textAlign: 'center'
            }
          }} gutterBottom>
          {useChekhubSignIn ? 'Login to Source Talent' : 'Login to Find Work'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          {...{
            variant: 'subtitle1',
            className: styles.signInSpan
          }} gutterBottom>
          Enter your Chekhub account credentials below
        </Typography>
      </Grid>
    </Grid>
    <SignInForm {...{
      onChange,
      onSubmit,
      backgroundRunning,
      authError,
      useChekhubSignIn,
      styles,
      handlePasswordShow,
      showPassword,
      disableChekhubUserLogin: true
    }} />
    {authError &&
    <div style={{marginTop: '0.5em', color: '#d00000'}}>
      <span>Invalid email or password</span>
    </div>
    }
    <Box
      className={classes.box}
      display="flex"
      flexDirection="column"
      alignItems="flex-end">
      {/*8 <FormControlLabel {...{*/}
      {/*   control: <Checkbox {...{*/}
      {/*     onChange: handleRememberMeChecked,*/}
      {/*     name: 'checkHubSignIn',*/}
      {/*     color: 'primary',*/}
      {/*     checked: rememberThisUser,*/}
      {/*     className: styles.rememberMeCheck*/}
      {/*   }} />,*/}
      {/*   label:*/}
      {/*     <span>Remember Me</span>*/}
      {/* }} />*/}
      <Link onClick={() => {
        setForgotPassword(true)
      }}>Forgot Password</Link>
      {/*<Link to="/sign-up" style={{textDecoration: 'none'}}>*/}
      {/*  <Typography variant={'span'}>Create new Account</Typography>*/}
      {/*</Link>*/}
    </Box>
        <Grid item xs={12}>
          <Grid container justify={'center'} spacing={'1'}>
            <Grid item xs={12} style={{
              paddingTop: '12px',
              paddingBottom: '20px'
            }}>
              <Typography align={'center'} className={styles.header} style={{
                fontSize: '20px'
              }}>
                Don't have a Chekhub Account
              </Typography>
            </Grid>
            <Grid item xs={12} xl={6} sm={6} align={'center'}>
              <FormSubmitButton style={{}}
                                onClick={() => history.push(SIGN_UP_PATH)}
              >
                Sign up as a Service Company
              </FormSubmitButton>
            </Grid>
            <Grid item xs={12} xl={6} sm={6} align={'center'}>
              <FormSubmitButton style={{}}
                                onClick={() => history.push(SIGN_UP_PATH_INDY)}
              >
                Sign up as Independent Tech
              </FormSubmitButton>

            </Grid>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
}


