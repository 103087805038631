import { Route, Switch, withRouter } from 'react-router-dom'
import { RouteConnectedDrawer } from '../components/RouteConnectedDrawer'
import { ServiceProviderSummarys } from './ServiceProviderSummarys'
import { teamOrProvider } from '../components/sandboxes/kevin/examples/mock-data/service-company-mock'
import { duration } from '@material-ui/core'
import { useContext } from 'react'
import { Context as BlurContext } from '../contexts/BlurContext'


export const ServiceProviderDrawerUtil = withRouter(
  ({
     open,
    indy,
     setOpen,
     backRoutePath,
     match,
     setDrawerOpen,
     routeTransitionDelay = duration.leavingScreen,
     teamOrProviderList

   }) => {
    const {setBlurred} = useContext(BlurContext)
    return <Switch>
      <Route path={`${match.path}/:teamId/summary`}>
        <RouteConnectedDrawer {...{
          open,
          setOpen,
          anchor: 'right',
          title: 'View Service Provider',
          backRoutePath
        }}>
          <ServiceProviderSummarys {...{
            setOpen,
            indy,
            backRoutePath,
            routeTransitionDelay,
            setBlurred,
            teamOrProviderList

          }}/>
        </RouteConnectedDrawer>
      </Route>
    </Switch>
  }
)
