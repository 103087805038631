import {useDispatch, useSelector} from 'react-redux'
import UserProfile from '../components/UserProfile'
import useAsyncEffect from 'use-async-effect'
import {useHistory, useParams} from 'react-router-dom'
import {
  fetchUserProfile,
  updateUserProfile,
  userProfileResetState,
  userProfileSetState
} from '../store/actions/user-profile.actions'
import {SIGN_UP_COMPLETE_PROFILE_PATH} from '../routing/route.constants'
import {Grid} from '@material-ui/core'
import {getUserOrg} from "../util/users.util"
import {getLandingPath} from "../util/landingPath.util"

const UserProfileView = () => {
  const {userId} = useParams()
  const history = useHistory()
  const {authUser, profileUser} = useSelector(
    ({auth, userProfile}) => ({
      authUser: auth.user,
      profileUser: userProfile
    })
  )
  const dispatch = useDispatch()
  const editable = (profileUser._id === authUser._id)

  const userDefaultOrg = getUserOrg(authUser)
  const landingPath = getLandingPath(authUser, userDefaultOrg)

  useAsyncEffect(
    async () => dispatch(fetchUserProfile(userId)),
    async () => dispatch(userProfileResetState()),
    []
  )

  const onChange = e => {
    e.preventDefault()
    const {id: key, value} = e.target
    dispatch(userProfileSetState({
      ...profileUser,
      [key]: value,
    }))
  }

  const onSubmit = async e => {
    e.preventDefault()
    await dispatch(updateUserProfile(profileUser))
    if (history.location.pathname === SIGN_UP_COMPLETE_PROFILE_PATH) {
      history.push(landingPath)
    }
  }

  return <Grid container justify="center">
    <UserProfile {...{
      user: profileUser._id ? profileUser : authUser,
      editable,
      onSubmit,
      onChange
    }} />
  </Grid>
}
export default UserProfileView
