import {createReducer} from 'redux-create-reducer'
import {TECH_PROFILE_RESET_STATE, TECH_PROFILE_SET_STATE} from '../actions/tech-profile.actions'
import {TECH_PROFILE_INIT_STATE} from './tech-profile.constants'

const techProfileReducer = createReducer(
  TECH_PROFILE_INIT_STATE,
  {
    [TECH_PROFILE_SET_STATE]: (state, {techProfile}) => ({
      ...state,
      ...techProfile
    }),
    [TECH_PROFILE_RESET_STATE]: () => TECH_PROFILE_INIT_STATE
  }
)

export default techProfileReducer
