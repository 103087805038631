import noop from '../util/noop'
import { removeItemByIndex, replaceItemInList } from '../util/lists.util'
import { serviceCompanySetState } from "../store/actions/service-company.actions"

export const insuranceStateUtils = (
  [insur = [], setInsur = noop],
  [editInsurance, setEditInsurance = noop],
  [addInsurance, setAddInsurance = noop],
  [removeInsurance, setRemoveInsurance = noop],
  showAllowButtonOrNot = noop
) => ({
  insur,
  editInsurance,
  addInsurance,
  removeInsurance,
  setAddInsurance,
  setEditInsurance,
  handleInsurEdit: (insur, index) => () => {
    showAllowButtonOrNot(false)
    setEditInsurance({
    ...insur,
    index
  })},
  handleInsurEditSubmit: cert => {
    const newCerts = replaceItemInList(cert, insur, cert.index)
    setInsur(newCerts)
    setEditInsurance()
    showAllowButtonOrNot(true)
  },
  clearInsurEdit: () => {
    showAllowButtonOrNot(true)
    setEditInsurance()
  },
  handleInsurAdd: () => {
    showAllowButtonOrNot(false)
    return setAddInsurance({})
  },
  clearInsureAdd: () => {
    showAllowButtonOrNot(true)
    return setAddInsurance()
  },
  handleInsurAddSubmit: ins => {
    setInsur([
      ...insur,
      {...ins}
    ])
    setAddInsurance()
    showAllowButtonOrNot(true)
  },
  handleInsurRemove: (cert, index) => () => {
    setRemoveInsurance({...cert, index})
  },
  clearInsurRemove: () => setRemoveInsurance(),
  handleInsurRemoveConfirm: remInsr => () => {
    setInsur(removeItemByIndex(insur, remInsr.index))
    setRemoveInsurance()
  },
  onInsurUploadSave: (ins, files) => (
    addInsurance ?
      setAddInsurance : setEditInsurance
  )({
    ...ins,
    filename: files[0].name
  }),
  onInsurRemoveUpload: ins => {
    const newIns = {...ins}
    delete newIns.filename

    return (
      addInsurance ?
      setAddInsurance : setEditInsurance
    )(newIns)
  }
})


export const onUpdateInsurancePolicySC = (techProfile, dispatch) => insurance_policies => {
  dispatch(serviceCompanySetState({
    ...techProfile,
    insurance_policies
  }))
}
