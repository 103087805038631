import {generatePath, Link} from 'react-router-dom'
import {TECH_VIEW_PATH} from '../routing/route.constants'
import {ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import {userServiceCompanyMemberListItemStyles} from './ServiceCompanyMembersListStyles'
import {getUserOrg} from '../util/users.util'
import {UserAvatar} from './UserAvatar'
import {ServiceCompanyMemberActions} from './ServiceCompanyMemberActions'

const ServiceCompanyMemberListItem = ({
  member,
  orgContext,
  disableRemoval,
  onRemove,
}) => {
  const {
    _id,
    full_name
  } = member
  const listItemStyles = userServiceCompanyMemberListItemStyles()
  const org = getUserOrg(member, orgContext._id)
  const memberRoles = org?.roles || []
  const techViewPath = generatePath(
    TECH_VIEW_PATH,
    {userId: _id}
  )

  return <ListItem {...{
    alignItems: 'flex-start'
  }} >
    <ListItemAvatar>
      {
        <Link to={techViewPath}>
          <UserAvatar {...{
            user: member
          }} />
        </Link>
      }
    </ListItemAvatar>
    <ListItemText
      primary={
        <Link {...{
          className: listItemStyles.name,
          to: techViewPath
        }}>{full_name}</Link>
      }
      secondary={
        <>
          <Typography
            component="span"
            variant="body2"
            className={listItemStyles.roles}
          >
            {memberRoles.join(', ')}
          </Typography>
        </>
      }
    />
    <ListItemSecondaryAction>
      <ServiceCompanyMemberActions {...{
        member,
        disableRemoval,
        onRemove,
        size: 'small'
      }} />
    </ListItemSecondaryAction>
  </ListItem>
}

export default ServiceCompanyMemberListItem
