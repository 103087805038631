import { Card, CircularProgress, Hidden, Typography, } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useParams, withRouter } from 'react-router-dom'
import { getUserOrg } from '../util/users.util'
import { useAuthUser } from '../hooks/useAuthUser'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServiceCompanyList2, resetServiceCompanyListState } from '../store/actions/service-company.actions'
import { useBusyIndicatorStyles } from './BusyIndicator'
import Grid from '@material-ui/core/Grid'
import { CurrentTeamOrProviderTemplate } from './CurrentTeamOrProviderTemplate'
import { ChekhubEndUserSummary } from './ChekhubEndUserSummary'
import useLandingViewStyles from './LandingViewStyles'
import { useDimensionsContextStyles } from './DimensionsContext'
import useEditStyles from './ServiceCompanyViewStyles'
import useListStyles from './ListServiceCompanyStyles'
import { invitedTeamResetState } from '../store/actions/invited-teams-list.actions'

export const CurrentTeamOrProviderListEndUser = withRouter(({
  match,
  }) => {

  const [open, setDrawerOpen] = useState(false)
  const user = useSelector(({auth}) => auth.user)
  const orgIdFromRedux = useSelector(({userProfile}) => userProfile.user)
  const dispatch = useDispatch()
  const busyStyles = useBusyIndicatorStyles()
  const landingStyles = useLandingViewStyles()
  const teamOrProviderList = useSelector(({providerList}) => providerList)
  const authorizedUser = useAuthUser()
  const userDefaultOrg = getUserOrg(authorizedUser)
  const chekhubChecker = userDefaultOrg?.organization_type === 'customer_company'
  const [busy, setBusy] = useState(
    !teamOrProviderList)

  const orgId = localStorage.getItem("orgId").replace(/['"]+/g, '')
  const dimensionsContextStyles = useDimensionsContextStyles()
  const styles = useLandingViewStyles()
  const editStyles = useEditStyles()
  const listStyles = useListStyles()

  useEffect( async () => {
    await dispatch(fetchServiceCompanyList2({
      chekhub_orgId: orgId
    }))

  }, [, open])



  useEffect(() => {
    dispatch(resetServiceCompanyListState())
    dispatch(invitedTeamResetState())
  },[])


  return <Grid container>
    <Hidden smDown>
      <Grid item xs={3}>
         <ChekhubEndUserSummary />
      </Grid>
    </Hidden>
    <Grid
      item
      xs={12}
      sm={12}
      md={9}
      lg={9}
      xl={9}
      className={landingStyles.skeleton}
    >
      {
        teamOrProviderList.length <= 0 ?  <Card className={
          `${styles.membersListCard} ${dimensionsContextStyles.minHeight} ${dimensionsContextStyles.card}`
        } ><Typography variant={'h6'}>No teams available</Typography></Card> :
          <CurrentTeamOrProviderTemplate {... {
            teamOrProviderList,
            setDrawerOpen,
            open,
            user,
            orgId,
            chekhubChecker
          }}/>
      }
    </Grid>
  </Grid>


})

