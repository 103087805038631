import makeStyles from '@material-ui/core/styles/makeStyles'
import {Avatar} from '@material-ui/core'
import {getInitials} from '../util/get-initials.util'

const useUserAvatarStyles = makeStyles(theme => ({
  avatar: {
    fill: theme.palette.secondary.main,
    color: theme.palette.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  }
}))

export const UserAvatar = ({
  user: {
    profileImgSrc,
    full_name,
    first_name,
    last_name
  },
  ...restAvatarProps
}) => {
  const avatarStyles = useUserAvatarStyles()
  const avatarClasses = `${avatarStyles.avatar} ${
    restAvatarProps.className || ''
  }`

  return profileImgSrc ?
    <Avatar
      {...{
        alt: full_name,
        src: profileImgSrc,
        ...restAvatarProps,
        className: avatarClasses
      }} /> :
    <Avatar
      {...{
        ...restAvatarProps,
        className: avatarClasses,
      }} >{getInitials(first_name, last_name)}</Avatar>
}
