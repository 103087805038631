import React from 'react'
import {Grid} from '@material-ui/core'
import useNavBarStyles from './NavbarStyles'
import UserMenu from "./UserMenu"

const HeaderContent = () => {
  const styles = useNavBarStyles()
  return <Grid
    container
    className={styles.appBar}
    justify='flex-end'
    alignItems="center"
    direction="row"
    spacing={2} >
    <UserMenu />
  </Grid>
}

export default HeaderContent
