import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  Button,
  TableCell,
  TableRow,
} from '@material-ui/core'

import ClearIcon from '@material-ui/icons/Clear'
import DescriptionIcon from '@material-ui/icons/Description'
import formatAddress from '../shared-functions/format-address'
import { certIcon } from '../shared-functions/compact-display-lists'
import actions from '../../store/actions'

function TechListRowComponent({ profile, headers }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const objectToIcon = (obj) => {
    if (obj && Object.keys(obj).length > 0) {
      return <DescriptionIcon fontSize="small" />
    }
    return <ClearIcon fontSize="small" />
  }

  const getDisplayValue = (key) => {
    switch (key) {
      case 'phones':
        return profile[key]?.[0].raw_phone
      case 'location':
        return formatAddress(profile[key]?.address)
      case 'certifications':
        return certIcon(profile[key])
      case 'background_check':
        return objectToIcon(profile?.[key])
      case 'edit_button':
        return (<Button
            size="small"
            style={{ marginLeft: 'auto' }}
            variant="outlined"
            onClick={() => {
              dispatch(actions['IMPERSONATED-PROFILE/SET'](profile))
              history.push(`/profiles/techs/${profile._id}`)
            }}
          >
            View
          </Button>)
      default:
        return profile[key]
    }
  }

  return (
    <TableRow key={profile.user_id}>
      {headers.map((hdr) => (
        <TableCell
          component={hdr.header ? 'th' : 'td'}
          align={hdr.align}>
          {getDisplayValue(hdr.key)}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default TechListRowComponent
