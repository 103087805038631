import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  summaryComponent: {
    '& div': {
      margin: theme.spacing(1, 0),
    },
  },
  compactListItem: {
    fontSize: '0.8em',
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
  },
  centeredRow: {
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  workHistoryItemView: {
    '& button.delete-icon': {
      float: 'right',
    },
    border: 'solid 1px #bbb',
    borderRadius: '.2rem',
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1),
  },
  listControls: {
    display: 'flex',
    margin: theme.spacing(1, 0),
  },
  pageControls: {
    alignSelf: 'flex-end',
    flexGrow: 'initial',
    marginLeft: 'auto',
  },
  listSearchControls: {
    alignSelf: 'flex-beginning',
    flexGrow: 'initial',
    paddingLeft: theme.spacing(1),
  },
  loadingPaper: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(2),
  },
  ticketItem: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2),
  },
  ticketLoading: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  viewButtonContainer: {
    textAlign: 'right',
  },
  paddedPaper: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2),
  },
}))
