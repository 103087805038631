import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { Link, withRouter } from 'react-router-dom'
import { TeamProviderDrawerScUtil } from './TeamProviderDrawerScUtil'
import React from 'react'
import { TeamProviderDrawerUtil } from '../pages/TeamProviderDrawerUtil'


export const CurrentTeamOrProviderTemplate = withRouter(({
  teamOrProviderList,
  setDrawerOpen,
  open,
  user,
  orgId,
  match,
  chekhubChecker,
  userId,
  techProfile
                                              }) => {
  return teamOrProviderList && <Card variant={'outlined'} style={{
    minHeight: '85vh'
  }}>
    <Typography variant={'h5'}
                style={{
                  paddingLeft: '10px',
                  paddingTop: '10px'
                }}>
      My Current Teams
    </Typography>
    <TableContainer component={Box} style={{
      paddingTop: '10px'
    }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{user?.organizations[0]?.organization_type === 'service_company' ?
              'Team Organization Name' : 'Service Provider Name'}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        {<TableBody>
          {teamOrProviderList.map((row) => (
            <TableRow key={row.service_provider_name || row.full_name}>
              <TableCell component="th" scope="row">
                {row.chekhub_org_id !== orgId ? row.chekhub_org_name : row.service_provider_name || row.full_name}
              </TableCell>
              <TableCell align="right">
                <Link to={`${match.url}/${row.chekhub_org_id !== orgId  ? row.chekhub_org_id : row.service_provider_id}/summary`}>
                  View Provider
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>}
      </Table>
      {!chekhubChecker ? <TeamProviderDrawerScUtil {... {
        open,
        setOpen: setDrawerOpen,
        backRoutePath: match.url,
        teamOrProviderList
      }} /> :
        <TeamProviderDrawerUtil {... {
          open,
          setOpen: setDrawerOpen,
          backRoutePath: match.url,
          teamOrProviderList
        }} />
      }
    </TableContainer>
  </Card>
})
