import {
  Box, Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import noop, { noopThunk } from '../util/noop'
import { MinMaxDateField } from './MinMaxDateField'
import { WorkExperienceView } from "./WorkExperience"
import PhoneNumbersEdit from './PhoneNumbersEdit'
import { VeteranStatus } from './VeteranStatus'
import WorkingDaysEdit from "./WorkingDaysEdit"
import { CertificationsDisplay } from './CertificationsDisplay'
import { CertificationEdit } from './CertificationEdit'
import { certStateUtils } from './Certifications.util'
import { insuranceStateUtils } from './Insurance.util'
import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SkillsEdit } from './SkillsEdit'
import { InsurancePoliciesDisplay } from './InsurancePoliciesDisplay'
import { InsuranceEdit } from './InsuranceEdit'
import WorkingDaysDisplay from "./WorkingDaysDisplay"
import { SectionHeader } from "./SectionHeader"
import { HashLinkNavigationBar } from "./HashLinkNavigationBar"
import { PlacesAutoComplete } from './PlacesAutoComplete'
import { useValidations } from '../hooks/useValidations'
import { SSN_FIELDNAME, SsnInput, SsnInputValidations } from './SsnInput'
import { humanizeScreeningName } from './BackGroundCheckPackageCard'
import { mbeUppercase } from './ServiceCompany'
import { useDispatch } from 'react-redux'
import { techProfileSetState } from '../store/actions/tech-profile.actions'

const ADDRESS_FIELDNAME = 'address-autocomplete'

const TECHPROFILE_SCROLL_LINKS = [
  {
    name: 'Personal Information',
    url: '#personal_info'
  },
  {
    name: 'Pay Information',
    url: '#pay_info'
  },
  {
    name: 'Certificates and Insurance',
    url: '#certificates'
  },
  {
    name: 'Work Experience',
    url: '#work_experience'
  }
]

const useTechProfileFormStyles = makeStyles(theme => ({
  root: {
    '& span.MuiTypography-root.MuiTypography-overline' : {
      fontSize: '1rem'
    }
  },
  addressLabel: {
    width: '100%'
  },
  sectionHeaders: {
    textTransform: 'uppercase'
  },
  divider: {
    marginBottom: 0
  }
}))

const TECH_PROFILE_VALIDATIONS = {
}

const TechProfileForm = ({
  techProfile,
  onChange,
  onSubmit = noop,
  onAddressChange,
  editStyles,
  editable,
  onPhoneNumberUpdate,
  onPhoneNumberRemove,
  onPhoneNumberAdd,
  phoneNumbers,
  onChangeVetStatus,
  onChangeDD214Url,
  onRemoveDD214Url,
  onSave,
  setEditHoursFlag,
  editHoursFlag,
  onUpdateCertifications = noop,
  onUpdateInsurancePolicy= noop,
  onCertUploadChange = noopThunk,
  onInsurUploadChange = noopThunk,
  onUpdateSkills = noopThunk,
  setHasFieldErrors = noop,
  setAllowPhones,
  setAllowCerts,
  setAllowInsurance,
  setAllowWorkExperience,
  setAllowWorkingHours
}) => {
  const styles = useTechProfileFormStyles()
  const {
    onSubmitValidation,
    onBlurValidation,
    onChangeValidation,
    fieldErrors,
    hasFieldErrors
  } = useValidations({
    ...TECH_PROFILE_VALIDATIONS,
    [SSN_FIELDNAME]: SsnInputValidations
  })

  const {
    certifications,
    skills,
    insurance_policies,
    ssn
  } = techProfile || {}
  const {
    editCert,
    addCert,
    removeCert,
    setAddCert,
    setEditCert,
    handleEdit,
    handleEditSubmit,
    onEditChange,
    clearEdit,
    handleAdd,
    clearAdd,
    handleAddSubmit,
    onAddChange,
    handleRemove,
    clearRemove,
    handleRemoveConfirm,
    onRemoveUpload
  } = certStateUtils(
    [
      certifications,
      onUpdateCertifications
    ],
    useState(),
    useState(),
    useState(),
    setAllowCerts
  )
  const {
    editInsurance,
    addInsurance,
    setEditInsurance,
    setAddInsurance,
    handleInsurEdit,
    handleInsurAdd,
    handleInsurRemove,
    removeInsurance,
    handleInsurRemoveConfirm,
    clearInsurRemove,
    handleInsurEditSubmit,
    clearInsurEdit,
    handleInsurAddSubmit,
    clearInsureAdd,
    onInsurRemoveUpload
  } = insuranceStateUtils(
    [
      insurance_policies,
      onUpdateInsurancePolicy
    ],
    useState(),
    useState(),
    useState(),
    setAllowInsurance
  )
  const dispatch = useDispatch()
  useEffect(
    () => setHasFieldErrors(hasFieldErrors()),
    [fieldErrors]
  )

  const handleSsnOnBlur = ev => {
    const {detail} = ev
    onBlurValidation(ev, detail.value, detail)
  }

  const handleSsnOnChange = ev => {
    const {detail} = ev
    onChangeValidation(ev, detail.value, detail)
    onChange(ev)
  }

  const handleDiversity = ev => {
      let newProfile = {
        ...techProfile,
        diversity: {
          ...techProfile.diversity,
          [ev.target.name]: ev.target.checked
        }
      }
    dispatch(techProfileSetState(newProfile))

  }

  const handleOnAddressChange = (ev, addressDetails) => {
    onAddressChange(addressDetails)
  }

  const handleOnSubmit = ev => {
    ev.preventDefault()
    const formIsValid = onSubmitValidation(ev)
    !hasFieldErrors() && formIsValid && onSubmit(ev)
  }

  return <form {...{
    className: styles.root,
    onSubmit: handleOnSubmit
  }} >
    <HashLinkNavigationBar {...{
      hashLinks: TECHPROFILE_SCROLL_LINKS
    }}/>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField {...{
          fullWidth: true,
          id: 'job_title',
          size: 'small',
          label: 'Job Title',
          value: techProfile.job_title || null,
          disabled: !editable,
          onChange,
          InputLabelProps: {shrink: true},
          variant: 'outlined'
        }} />
      </Grid>
      <Grid item xs={12} id={'personal_info'}>
        <SectionHeader {...{
          dividerProps: {
            className: styles.divider
          }
        }}>
          <Typography variant={'h6'} className={styles.sectionHeaders}>Personal Information</Typography>
        </SectionHeader>
      </Grid>
      <Grid item xs>
        <FormControl {...{
          variant: 'outlined',
          size: 'small',
          className: styles.addressLabel
        }} >
          <InputLabel htmlFor="location.label" required>Address label</InputLabel>
          <Select
            native
            label="Address label"
            inputProps={{id: 'location.label'}}
            value={techProfile.location.label}
            disabled={!editable}
            onChange={onChange} >
            <option value="home">Home</option>
            <option value="work">Work</option>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={9}>
        <PlacesAutoComplete
          {...{
            id: ADDRESS_FIELDNAME,
            name: ADDRESS_FIELDNAME,
            size: 'small',
            label: 'Address',
            addressDetails: techProfile.location,
            onChange: handleOnAddressChange,
          }}/>
      </Grid>
      <Grid item xs={6}>
        <MinMaxDateField {...{
          fullWidth: true,
          id: 'birth_date',
          className: editStyles.dateField,
          size: 'small',
          label: 'Birth Date',
          disabled: !editable,
          value: techProfile.birth_date,
          minYear: 1900,
          InputLabelProps: {shrink: true},
          onChange,
          variant: 'outlined'
        }} />
      </Grid>
      <Grid item xs={6}>
        <SsnInput {...{
          id: SSN_FIELDNAME,
          fullWidth: true,
          ssn,
          onChange: handleSsnOnChange,
          onBlur: handleSsnOnBlur,
          error: Boolean(fieldErrors[SSN_FIELDNAME]),
          helperText: fieldErrors[SSN_FIELDNAME] &&
            <Typography component={'span'}>{fieldErrors[SSN_FIELDNAME]}</Typography>
        }} />
      </Grid>
      <Grid item xs={12}>
        <VeteranStatus {...{
          status: techProfile.veteran_status?.status,
          dd214_url: techProfile.veteran_status?.dd214_url,
          onChangeStatus: onChangeVetStatus,
          onChangeDD214Url,
          onRemoveDD214Url
        }} />
      </Grid>
      <Grid item xs={12}>
        <Typography>Phone Numbers</Typography>
        <PhoneNumbersEdit {...{
          onPhoneNumberUpdate,
          onPhoneNumberRemove,
          onPhoneNumberAdd,
          phoneNumbers,
          showAllowButtonOrNot: setAllowPhones
        }}/>
      </Grid>
      {<Grid item xs={12}>
        <FormControl>
          <Typography variant="overline">EOC</Typography>
          {Object.keys(techProfile.diversity).map((op, index) => {
            return <Grid item xs={12}>
              <Box>
                <Checkbox checked={techProfile.diversity[op]} onChange={handleDiversity} name={op} id={`diversity`}/>
                {op !== 'nonhispanic_white_or_euro_american' ?  humanizeScreeningName(op) : 'Non-Hispanic White or Euro American'}
              </Box>
            </Grid>
          })}
        </FormControl>
      </Grid>}
    </Grid>
    <Grid container justify='center' spacing={2}>
      <Grid item xs={12} id={'pay_info'}>
        <SectionHeader {...{
          dividerProps: {
            className: styles.divider
          }
        }}>
          <Typography variant={'h6'} className={styles.sectionHeaders}>Pay Information</Typography>
        </SectionHeader>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="hour_rate"
          size="small"
          type="number"
          label="Hour rate"
          value={techProfile.hour_rate ? techProfile.hour_rate : null}
          disabled={!editable}
          InputProps={{
           startAdornment: <InputAdornment position="start">$</InputAdornment>,
           endAdornment: <InputAdornment position="end">per hour</InputAdornment>,
          }}
          InputLabelProps={{shrink: true}}
          onChange={onChange}
          variant="outlined"/>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="service_radius"
          size="small"
          type="number"
          label="Service radius"
          value={techProfile.service_radius ? techProfile.service_radius : null}
          disabled={!editable}
          onChange={onChange}
          InputProps={{
            endAdornment: <InputAdornment position="start">miles</InputAdornment>,
          }}
          InputLabelProps={{shrink: true}}
          variant="outlined"/>
      </Grid>
      <Grid item xs={12}>
        <SkillsEdit {...{
          skills,
          setSkills: onUpdateSkills,
          size:'small'
        }} />
      </Grid>
      {techProfile?.pay_info?.working_hours && editHoursFlag ? <Grid item xs={12}>
        <WorkingDaysEdit {...{
          working_hours: techProfile?.pay_info?.working_hours,
          onSave,
          showAllowButtonOrNot: setAllowWorkingHours,
          setEditHoursFlag,
          title: <Typography variant="h6">Schedule</Typography>
        }}/>
      </Grid> : <Grid item xs={12}>
        <WorkingDaysDisplay {...{
        working_hours: techProfile?.pay_info?.working_hours,
        setEditHoursFlag,
        editHoursFlag,
        showAllowButtonOrNot: setAllowWorkingHours,
        title: <Typography variant="h6">Schedule</Typography>
      }}/>
      </Grid>}
      <Grid item xs={12} id={'certificates'}>
        <SectionHeader {...{
          dividerProps: {
            className: styles.divider
          }
        }}>
          <Typography variant={'h6'} className={styles.sectionHeaders}>Certificates</Typography>
        </SectionHeader>
        {/*TODO: try to create a <Certifications {...{onUpdateCertifications}} /> wrapper to simplify this a bit*/}
        {
          !editCert && !addCert && <CertificationsDisplay {...{
            certifications,
            onEdit: handleEdit,
            onAdd: handleAdd,
            onRemove: handleRemove,
            removeCert,
            onRemoveConfirm: handleRemoveConfirm,
            onRemoveCancel: clearRemove
          }} />
        }
        {
          editCert && <CertificationEdit {...{
            cert: editCert,
            onSubmit: handleEditSubmit,
            onCancel: clearEdit,
            onChange: onEditChange,
            onUploadSave: onCertUploadChange(setEditCert),
            onRemoveUpload
          }} />
        }
        {
          addCert && <CertificationEdit {...{
            title: 'Add Certification',
            cert: addCert,
            onSubmit: handleAddSubmit,
            onCancel: clearAdd,
            onChange: onAddChange,
            onUploadSave: onCertUploadChange(setAddCert),
            onRemoveUpload
          }} />
        }
      </Grid>
      <Grid item xs={12}>
      <SectionHeader {...{
        dividerProps: {
          className: styles.divider
        }
      }}>
        <Typography variant={'h6'} className={styles.sectionHeaders}>Insurance</Typography>
      </SectionHeader>
      {
        !editInsurance && !addInsurance && <InsurancePoliciesDisplay {...{
          insurance:insurance_policies,
          onEdit: handleInsurEdit,
          onAdd: handleInsurAdd,
          onRemove: handleInsurRemove,
          removeInsurance,
          onRemoveConfirm: handleInsurRemoveConfirm,
          onRemoveCancel: clearInsurRemove
        }} />
      }
      {
        editInsurance && !addInsurance && <InsuranceEdit {...{
          insur: editInsurance,
          onSubmit: handleInsurEditSubmit,
          onCancel: clearInsurEdit,
          onRemoveUpload: onInsurRemoveUpload,
          onUploadSave:onInsurUploadChange(setEditInsurance),
          label: "Edit insurance policy"
        }} />
      }
      {
        addInsurance && <InsuranceEdit {...{
          insur: addInsurance,
          onSubmit: handleInsurAddSubmit,
          onCancel: clearInsureAdd,
          onRemoveUpload: onInsurRemoveUpload,
          onUploadSave:onInsurUploadChange(setAddInsurance),
          label: "Add insurance policy"
        }} />
      }
      </Grid>
      <Grid item xs={12} id={'work_experience'}>
        <WorkExperienceView {...{
          techProfile,
          showAllowButtonOrNot: setAllowWorkExperience,
          title: <SectionHeader{...{
          dividerProps: {
            className: styles.divider
          }
        }}>
            <Typography variant={'h6'} className={styles.sectionHeaders}>
              Work Experience
            </Typography>
          </SectionHeader>
        }}/>
      </Grid>
    </Grid>
  </form>
}

export default TechProfileForm
