import { Box, Grid, TextField } from '@material-ui/core'
import { MinMaxDateField } from './MinMaxDateField'
import { CloudDownloadLink } from './CloudDownloadLink'
import { DropZoneDialogTrigger } from './DropZoneDialogTrigger'
import { MimeTypes } from 'http-headers-js'
import noop, { noopThunk } from '../util/noop'
import { EditRemoveButtons } from './EditRemoveButtons'
import { ConfirmationModal } from './ConfirmationModal'
import { FormSubmitButton } from './FormSubmitButton'
import { useState, useEffect } from 'react'
import {capitalize} from "./InsuranceDisplay"
import {useValidations} from '../hooks/useValidations'

const INSURANCE_ISSUER_FIELDNAME = 'issued_by'
const INSURANCE_VALIDATIONS = {
  [INSURANCE_ISSUER_FIELDNAME]: {
    required: 'Issuer is required'
  }
}

export const InsuranceForm = ({
  insurance,
  onSubmit = noopThunk,
  onChangeHandler = noop,
  onUploadSave = noop,
  onRemoveUpload = noop,
  setHasFieldErrors = noop
}) => {
  const {
    onChangeValidation,
    onBlurValidation,
    onSubmitValidation,
    fieldErrors,
    hasFieldErrors
  } = useValidations(INSURANCE_VALIDATIONS)

  useEffect(
    () => setHasFieldErrors(hasFieldErrors()),
    [fieldErrors]
  )

  const options = [
    {label: "Worker's Comp", value: "workers_comp"},
    {label: "Liability", value: "liability"},
    {label: "Auto", value: "auto"},
    {label: "Other", value: "other"}
  ]

  const [label, setLabel] = useState(insurance.insurance_type)
  const [uploadOpen, setUploadOpen] = useState(false)
  const [removeUploadConfirmOpen, setRemoveUploadConfirmOpen] = useState(false)
  const handleOnUploadSave = (targetInsur) => (files) => {
    onUploadSave(targetInsur, files)
    closeUpload()
  }
  const openUpload = () => setUploadOpen(true)
  const closeUpload = () => setUploadOpen(false)

  const openRemoveConfirm = () => setRemoveUploadConfirmOpen(true)
  const closeRemoveConfirm = () => setRemoveUploadConfirmOpen(false)
  const handleOnRemoveUploadConfirm = () => {
    onRemoveUpload(insurance)
    closeRemoveConfirm()
  }

  const handleOnSubmit = ev => {
    ev.preventDefault()
    const formIsValid = onSubmitValidation(ev)
    !hasFieldErrors() && formIsValid && onSubmit(ev)
  }

  if (!insurance.insurance_type) {
    onChangeHandler(options[0].value, 'insurance_type')
  }
  return insurance &&<form
      {...{
        onSubmit: handleOnSubmit,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth={true}
            id="type"
            select
            label="Type"
            value={label}
            onChange={(e) => {
              if (e.target.value !== 'other') onChangeHandler(e, 'insurance_type')
              else onChangeHandler(e, 'alt_insurance_type')
              setLabel(e.target.value)
            }}
            SelectProps={{
              native: true,
            }}
            size="small"
            variant="outlined"
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {capitalize(option.label)}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          {insurance.insurance_type === 'other' && (
            <TextField
              {...{
                fullWidth: true,
                id: 'alt_insurance_type',
                label: 'type',
                variant: 'outlined',
                value: insurance.alt_insurance_type,
                size: 'small',
                onChange: (e) => onChangeHandler(e, 'alt_insurance_type'),
              }}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...{
              fullWidth: true,
              id: INSURANCE_ISSUER_FIELDNAME,
              name: INSURANCE_ISSUER_FIELDNAME,
              label: 'Issuer',
              variant: 'outlined',
              value: insurance.issued_by,
              size: 'small',
              required: true,
              onChange: (e) => {
                onChangeValidation(e)
                onChangeHandler(e, INSURANCE_ISSUER_FIELDNAME)
              },
              onBlur: onBlurValidation,
              error: Boolean(fieldErrors[INSURANCE_ISSUER_FIELDNAME]),
              helperText: fieldErrors[INSURANCE_ISSUER_FIELDNAME] || null
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...{
              fullWidth: true,
              id: 'policy_number',
              label: 'Policy Number',
              variant: 'outlined',
              value: insurance.policy_number,
              size: 'small',
              onChange: (e) => onChangeHandler(e, 'policy_number'),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <MinMaxDateField
            {...{
              id: 'issued_on',
              fullWidth: true,
              size: 'small',
              label: 'Issue Date',
              type: 'date',
              value: insurance.issued_on,
              minYear: 1900,
              InputLabelProps: { shrink: true },
              variant: 'outlined',
              onChange: (e) => onChangeHandler(e, 'issued_on'),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <MinMaxDateField
            {...{
              id: 'expires_on',
              fullWidth: true,
              size: 'small',
              label: 'Expires',
              type: 'date',
              value: insurance.expires_on,
              minYear: 1900,
              InputLabelProps: { shrink: true },
              onChange: (e) => onChangeHandler(e, 'expires_on'),
              variant: 'outlined',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            {insurance.filename && (
              <>
                <CloudDownloadLink {...{ cloudFilename: insurance.filename }}>
                  <span>Insurance Policy</span>
                </CloudDownloadLink>
                <DropZoneDialogTrigger
                  {...{
                    dialogOpen: uploadOpen,
                    acceptedFiles: [MimeTypes.Application.PDF],
                    uploadDialogProps: {
                      dialogTitle: 'Upload Insurance Policy',
                    },
                    onClose: closeUpload,
                    onSave: handleOnUploadSave(insurance),
                  }}
                >
                  <EditRemoveButtons
                    {...{
                      editTitle: 'Edit Policy',
                      removeTitle: 'Remove Policy',
                      onEdit: openUpload,
                      onRemove: openRemoveConfirm,
                    }}
                  />
                </DropZoneDialogTrigger>
                <ConfirmationModal
                  {...{
                    open: removeUploadConfirmOpen,
                    onOk: handleOnRemoveUploadConfirm,
                    onClose: closeRemoveConfirm,
                  }}
                >
                  Are you sure you want to remove this Policy?
                </ConfirmationModal>
              </>
            )}
            {!insurance.filename && (
              <DropZoneDialogTrigger
                {...{
                  dialogOpen: uploadOpen,
                  acceptedFiles: [MimeTypes.Application.PDF],
                  submitButtonText: 'Upload Policy',
                  uploadDialogProps: {
                    dialogTitle: 'Upload Policy',
                  },
                  onClose: closeUpload,
                  onSave: handleOnUploadSave(insurance),
                }}
              >
                <FormSubmitButton
                  {...{
                    type: 'button',
                    variant: 'outlined',
                    onClick: openUpload,
                  }}
                >
                  Upload Policy
                </FormSubmitButton>
              </DropZoneDialogTrigger>
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
}
