export const formatCurrency = (
  value = 0,
  currency = 'USD',
  locale = 'en-US'
) => new Intl.NumberFormat(
  locale,
  {
    style: 'currency',
    currency
  }
).format(value)
