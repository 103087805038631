import makeStyles from '@material-ui/core/styles/makeStyles'
import {Chip} from '@material-ui/core'
import {CloudDownloadLink} from './CloudDownloadLink'

const useVeteranStatusDisplayStyles = makeStyles(theme => ({
  veteranChip: {
    marginRight: theme.spacing(1)
  }
}))

export const VeteranStatusDisplay = ({veteran_status}) => {
  const styles = useVeteranStatusDisplayStyles()
  const {status, dd214_url} = veteran_status || {}
  return <>
    {
      status && <Chip {...{
        variant: 'outlined',
        label: 'Veteran',
        size: 'small',
        className: styles.veteranChip
      }} />
    }
    {
      dd214_url && <CloudDownloadLink {...{cloudFilename: dd214_url}} >
        <span>DD214</span>
      </CloudDownloadLink>
    }
  </>
}
