import {CardContent, Typography} from '@material-ui/core'
import chekhubLogo from '../assets/images/logo_gray.svg'
import ActiveOrgSelector from './ActiveOrgSelector'

export const SignInChooseChekhubOrg = ({
  user,
  styles,
  activeOrgValue,
  activeOrgOnChange,
  backgroundRunning,
                                         hasPermissions
}) =>
  <CardContent {...{
    className: styles.backCardContent
  }}>
    <Typography {...{
      variant: 'h5',
      style: {
        textAlign: 'center'
      },
      gutterBottom: true
    }}>Choose a <img {...{
      alt: 'Chekhub Logo',
      src: chekhubLogo,
      className: styles.checkboxLabelLogo
    }} /> organization to continue...</Typography>
    <ActiveOrgSelector {...{
      className: styles.activeOrgSelector,
      value: activeOrgValue,
      onChange: activeOrgOnChange,
      backgroundRunning,
      hasPermissions
    }} />
  </CardContent>
