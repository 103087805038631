import {dispatchService} from "./dispatch-service.util"
import {fetchBackgroundCheckPackages, postBackgroundCheckPurchase} from "../../services/background-check.service"
import {techProfileSetState} from './tech-profile.actions'

export const SET_BACKGROUND_CHECK_PACKAGES_STATE = 'SET_BACKGROUND_CHECK_PACKAGES_STATE'
export const RESET_BACKGROUND_CHECK_PACKAGES_STATE = 'RESET_BACKGROUND_CHECK_PACKAGES_STATE'

export const setBackgroundCheckPackages = packages => ({
  type: SET_BACKGROUND_CHECK_PACKAGES_STATE,
  packages
})

export const resetBackgroundCheckState = () => ({
  type: RESET_BACKGROUND_CHECK_PACKAGES_STATE
})

export const setTechProfileBankGroundCheck = backgroundCheck =>
  (dispatch, getState) => {
    const {techProfile} = getState()
    return dispatch(techProfileSetState({
      ...techProfile,
      background_check: backgroundCheck
    }))
  }


export const getBackgroundCheckPackages = () => async dispatch =>
  await dispatchService(dispatch)(
    fetchBackgroundCheckPackages, setBackgroundCheckPackages
  )()

export const createBackgroundCheckPackage = (purchasePackage) => async dispatch =>
  await dispatchService(dispatch)(postBackgroundCheckPurchase, setTechProfileBankGroundCheck)(purchasePackage, purchasePackage.package_id)
