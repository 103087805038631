import {makeStyles} from '@material-ui/core/styles'
import {Button, FormHelperText, Link, Typography} from '@material-ui/core'
import {LaunchSharp} from '@material-ui/icons'
import {InlineCopyrightIcon} from './InlineCopyrightIcon'

const useConnectedAccountPendingCtaStyles = makeStyles(theme => ({
  noLinkedAccountBox: {
    padding: theme.spacing(2)
  },
  externalLink: {
    marginLeft: theme.spacing(1)
  },
}))

export const ConnectedAccountPendingCta = ({
  connectedAccounts = {}
}) => {
  const styles = useConnectedAccountPendingCtaStyles()
  const {
    account_link
  } = connectedAccounts
  return <>
    <Typography {...{
      variant: 'body1',
      gutterBottom: true
    }}>
      To receive payments in ChekHub Connect, you'll need to link a checking account.
    </Typography>
    <Link underline="none" href={account_link} target="_blank">
      <Button {...{
        variant: 'contained',
        color: 'primary'
      }}>Link Account Now<LaunchSharp
        className={styles.externalLink}
        fontSize="small"/>
      </Button>
    </Link>
    <FormHelperText>
      Powered by Stripe<InlineCopyrightIcon />
    </FormHelperText>
  </>
}
