import {Divider, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const useSectionHeaderStyles = makeStyles(theme => ({
  divider: {
    marginBottom: theme.spacing(2)
  }
}))
export const SectionHeader = ({
  children,
  dividerProps = {},
  variant = 'h6',
  ...restProps
}) => {
  const styles = useSectionHeaderStyles()
  const dividerClassName = `${
    dividerProps.className ? dividerProps.className : ''
  } ${styles.divider}`
  return <>
    <Typography {...{
      variant,
      ...restProps
    }}>
      {children}
    </Typography>
    <Divider {...{
      ...dividerProps,
      className: dividerClassName
    }} />
  </>
}
