import appConfig from '../config'
import { Headers, Methods } from 'http-headers-js'
import generateAuthHeader from './generate-auth-header.util'
import { generateUrlQueryString } from './generate-url-query-string.util'

const {chekhubConnect: {
  paths: {
    serviceCompanyProfileService: path,
    profilesService: profilesPath,
    connectTeamService: connectTeamPath,
    techProfileService: techProfilePath
  },
  baseURI,
  newURI,
  intergrationURI,
  defaultHeaders
}} = appConfig
const {chekhub: {
  baseURI2: chekhubAPIPath
}} = appConfig
const serviceBaseUri = `${baseURI}${path}`
const serviceBaseUriTech = `${baseURI}${techProfilePath}`
const profilesBaseUri = `${baseURI}${profilesPath}`
const serviceNewUri =  `${newURI}`
const intergrationBaseUri = `${intergrationURI}${connectTeamPath}`

export const getServiceCompany = async orgId =>
  await fetch(`${serviceBaseUri}/${orgId}`, {
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    method: Methods.GET
  })

export const getChekhubOrg = async orgId =>
  await fetch(`${chekhubAPIPath}/v2/organizations/${orgId}/reference`, {
    headers:{
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    }
  })

export const getChekhubOrgList = async () =>
  await fetch(`${chekhubAPIPath}/v2/system/login`, {
    headers:{
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
  })


export const getServiceCompanyListAll = async (searchParams = {}) =>
  await fetch(
    `${serviceBaseUri}`,
    {
      method: Methods.GET,
      headers: {
        ...defaultHeaders,
        [Headers.AUTHORIZATION]: await generateAuthHeader()
      }
    }
  )

export const getIndyTechsAll = async (searchParams = {}) =>
  await fetch(
    `${profilesBaseUri}${generateUrlQueryString(searchParams)}`,
    {
      headers: {
        ...defaultHeaders,
        [Headers.AUTHORIZATION]: await generateAuthHeader()
      }
    }
  )

export const getServiceCompanyList = async (orgId) =>
  await fetch(
    `${intergrationBaseUri}?service_provider_id=${orgId}`,
    {
      headers: {
        ...defaultHeaders,
        [Headers.AUTHORIZATION]: await generateAuthHeader()
      },
      method: Methods.GET
    }
  )
export const getServiceCompanyList2 = async (chekhub_orgId) =>
  await fetch(
    `${intergrationBaseUri}?chekhub_org_id=${chekhub_orgId}`,
    {
      headers: {
        ...defaultHeaders,
        [Headers.AUTHORIZATION]: await generateAuthHeader()
      },
      method: Methods.GET
    }
  )
export const getServiceCompanyList3 = async () =>
  await fetch(
    `${intergrationBaseUri}`,
    {
      headers: {
        ...defaultHeaders,
        [Headers.AUTHORIZATION]: await generateAuthHeader()
      },
      method: Methods.GET
    }
  )

// TODO: figure out how we should be handling "unset" optional params better
//   so we won't need this.
const cleanServiceCompanyData = serviceCompany => {
  let data = {...serviceCompany}
  if (!serviceCompany.website) delete data.website
  if (!serviceCompany.linkedin_page) delete data.linkedin_page
  if (!serviceCompany.ein) delete data.ein
  if (!serviceCompany.company_formation_year) delete data.company_formation_year
  return data
}

export const putServiceCompany = async serviceCompany =>
  await fetch( `${serviceBaseUri}/${serviceCompany._id}`, {
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader(),
    },
    method: Methods.PUT,
    body: JSON.stringify(cleanServiceCompanyData(serviceCompany))
  })

export const postServiceCompany = async serviceCompany =>
  await fetch( `${serviceBaseUri}`, {
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader(),
    },
    method: Methods.POST,
    body: JSON.stringify(cleanServiceCompanyData(serviceCompany))
  })

export const getMembersList = async (orgId, searchQueryParams) =>
  await fetch(`${serviceBaseUri}/${orgId}/members${
    generateUrlQueryString(searchQueryParams)
  }`, {
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    }
  })

export const postMember = async (member, orgId) =>
  await fetch(`${serviceBaseUri}/${orgId}/members`, {
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    method: Methods.POST,
    body: JSON.stringify(member)
  })

export const putMember = async (member, orgId) =>
  await fetch(`${serviceBaseUri}/${orgId}/members/${member._id}`, {
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    method: Methods.PUT,
    body: JSON.stringify(member)
  })

export const removeMember = async (userId, orgId) =>
  await fetch(`${serviceBaseUri}/${orgId}/members/${userId}`, {
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    method: Methods.DELETE,
  })
