import {useState} from 'react'
import {replaceItemInList} from '../../../../util/lists.util'
import {Typography} from '@material-ui/core'
import {ServiceCompanyManagementList} from '../../../ServiceCompanyManagementList'
import {makeStyles} from '@material-ui/core/styles'

const INIT_MANAGERS_STATE = [{
  '_id': 'SsMZUU1ENCPzqmiyiGOxP31pE4Y2',
  'created_at': '2021-04-23T21:43:57.727Z',
  'email': 'matt+dispatcherdiditwork@chekhub.com',
  'first_name': 'Dispatcher',
  'last_name': 'DidItWork',
  'organizations': [{
    'roles': ['admin'],
    'organization_type': 'service_company',
    'organization_id': '60766713cb0bd2d48f01c44c',
    'role': 'admin',
    'profiles': []
  }],
  'phones': [],
  'updated_at': '2021-04-23T21:43:57.727Z',
  'full_name': 'Dispatcher DidItWork'
}, {
  '_id': 'Z0KuS5Hjn0UfBzW86p5zqGqBTIP2',
  'created_at': '2021-04-23T20:26:16.522Z',
  'email': 'matt+some-admin@chekhub.com',
  'first_name': 'Some',
  'last_name': 'Admin',
  'organizations': [{
    'roles': ['admin', 'dispatcher'],
    'organization_type': 'service_company',
    'organization_id': '60766713cb0bd2d48f01c44c',
    'role': 'admin',
    'profiles': []
  }],
  'phones': [],
  'updated_at': '2021-04-23T20:26:16.522Z',
  'full_name': 'Some Admin'
}]

const useServiceCompanyManagementListExample = makeStyles(theme => ({
  header: {
    textTransform: 'uppercase'
  }
}))

export const ServiceCompanyManagementListExample = () => {
  const styles = useServiceCompanyManagementListExample()
  const orgId = '60766713cb0bd2d48f01c44c'
  const [serviceCompanyManagementList, setServiceCompanyManagementList] = useState(INIT_MANAGERS_STATE)
  const onRemove = member => setServiceCompanyManagementList(
    serviceCompanyManagementList.filter(m => m._id !== member._id)
  )

  const onEdit = member =>
    setServiceCompanyManagementList(replaceItemInList(
      member,
      serviceCompanyManagementList,
      serviceCompanyManagementList.findIndex(
        m => m._id === member._id
      )
    ))

  const onAdd = member => {
    return setServiceCompanyManagementList([
      ...serviceCompanyManagementList,
      {
        ...member,
        _id: serviceCompanyManagementList.length - 1,
        full_name: `${member.first_name} ${member.last_name}`,
        organizations: [
          {
            organization_id: orgId,
            organization_type: 'service_company',
            roles: member.roles
          }
        ]
      }
    ])
  }

  return <>
    <Typography
      variant='body1'
      className={styles.header}
    >Managers:</Typography>
    <ServiceCompanyManagementList {...{
      serviceCompanyManagementList,
      onAdd,
      onRemove,
      onEdit,
      orgId
    }} />
  </>
}
