import { Box, duration, Fab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import { Link, useHistory, withRouter } from 'react-router-dom'
import React from 'react'
import { RequiredDocsDrawerUtil } from './RequiredDocsDrawerUtil'
import { AddRequiredDocumentChekhubEndUserDrawerUtil } from './AddRequiredDocumentChekhubEndUserDrawerUtil'
import Typography from '@material-ui/core/Typography'
import { uploadFile } from '../store/actions/storage.actions'
import { isServiceErrorResult } from '../services/is-service-error-result.util'
import toastShow, { TOAST_TYPE_SUCCESS } from '../store/actions/toast-show'
import { addRequiredDocs } from '../store/actions/addRequiredDocs.actions'
import { closeDrawerAndTransition } from './ServiceCompanyMemberDrawer.util'
import { useDispatch } from 'react-redux'


export const RequiredDocsDisplay = withRouter(({
  match,
  open,
  setOpen,
  fabStyles,
                                                 requiredDocsList,
                                                 onDocumentNameChange,
                                                 onDialogOpen,
                                                 setOpenDialog,
                                                 documentName,
                                                 fileUncleanedName,
                                                 routeTransitionDelay = duration.leavingScreen,
                                                 setBlurred,
                                                 setDocumentName,
                                                 setUncleanedFileName,
  chekhubOrgId,
                                               }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const onDocumentSave = async (e) => {
    setUncleanedFileName(e[0])
    const result = await dispatch(uploadFile(e[0], 'RequiredDoc'))
    if (isServiceErrorResult(result)) {
      return result
    }else {
      dispatch(toastShow(
        TOAST_TYPE_SUCCESS,
        'File Uploaded Successfully'
      ))
    }
    await dispatch(addRequiredDocs({
      file_name: result,
      chekhub_org_id: chekhubOrgId,
      name: documentName
    }))
    closeDrawerAndTransition(
      setOpen,
      match.url,
      history,
      routeTransitionDelay,
      setBlurred
    )

    setOpenDialog(false)
    setDocumentName('')
    setUncleanedFileName('')
  }

  return <>{ requiredDocsList.length > 0 ? <TableContainer component={Box} style={{
    paddingTop: '10px'
  }}>
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell>{'Required Document Name'}</TableCell>
          <TableCell/>
        </TableRow>
      </TableHead>
      <TableBody>
        {requiredDocsList.map((row) => (
          <TableRow key={row.filename}>
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">
              <Link to={`${match.url}/${row._id}/summary`}>
                View Document
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer> : <Typography>
    No required docs
  </Typography>
  }
    <Link to={`${match.url}/add/document`}>
    <Fab {...{
      className: fabStyles.fab,
      color: 'primary',
      variant: 'extended'
    }}>
      <AddCircle/>
      Add a Document
    </Fab>
  </Link>
    <RequiredDocsDrawerUtil {... {
      open,
      setOpen,
      backRoutePath: match.url,
      requiredDocsList
    }} />
    <AddRequiredDocumentChekhubEndUserDrawerUtil {...{
      open,
      setOpen,
      backRoutePath: match.url,
      requiredDocsList,
      onDocumentSave,
      onDocumentNameChange,
      onDialogOpen,
      setOpenDialog,
      documentName,
      fileUncleanedName
    }} />
    </>
})
