import {MASTER_CARD, VISA} from '../../../CardBrandIcon'
import {SOURCE_TYPE_BANK_ACCOUNT} from '../../../PaymentSources'

export const MOCK_PAYMENT_SOURCES_DATA = [
  {
    'organization_id': '60766713cb0bd2d48f01c44c',
    'source_id': 'card_1IrWp3HjhblmTkwtSaXX8Err',
    'type': 'card',
    'status': 'verified',
    'stripe_data': {
      'id': 'card_1IrWp3HjhblmTkwtSaXX8Err',
      'object': 'card',
      'address_city': null,
      'address_country': null,
      'address_line1': null,
      'address_line1_check': null,
      'address_line2': null,
      'address_state': null,
      'address_zip': '00000',
      'address_zip_check': 'pass',
      'brand': VISA,
      'country': 'US',
      'customer': 'cus_JUVqjk5HtxLqKH',
      'cvc_check': 'pass',
      'dynamic_last4': null,
      'exp_month': 4,
      'exp_year': 2024,
      'fingerprint': 'UnsVJiek6EsmFJN9',
      'funding': 'credit',
      'last4': '4242',
      'metadata': {},
      'name': 'Some Admin',
      'tokenization_method': null
    }
  },
  {
    'organization_id': '60766713cb0bd2d48f01c44c',
    'source_id': 'card_1IsKX6HjhblmTkwtjWVQeORr',
    'type': 'card',
    'status': 'verified',
    'stripe_data': {
      'id': 'card_1IsKX6HjhblmTkwtjWVQeORr',
      'object': 'card',
      'address_city': null,
      'address_country': null,
      'address_line1': null,
      'address_line1_check': null,
      'address_line2': null,
      'address_state': null,
      'address_zip': '42424',
      'address_zip_check': 'pass',
      'brand': MASTER_CARD,
      'country': 'US',
      'customer': 'cus_JUVqjk5HtxLqKH',
      'cvc_check': 'pass',
      'dynamic_last4': null,
      'exp_month': 4,
      'exp_year': 2024,
      'fingerprint': 'UnsVJiek6EsmFJN9',
      'funding': 'credit',
      'last4': '4242',
      'metadata': {},
      'name': 'Matthew Marcus',
      'tokenization_method': null
    }
  },
  {
    'organization_id': '60766713cb0bd2d48f01c44c',
    'source_id': 'ba_1IsJqAHjhblmTkwtA9eRFxqc',
    'type': SOURCE_TYPE_BANK_ACCOUNT,
    'status': 'new',
    'stripe_data': {
      'id': 'ba_1IsJqAHjhblmTkwtA9eRFxqc',
      'object': 'bank_account',
      'account_holder_name': 'ACME Firearms Inc.',
      'account_holder_type': 'company',
      'bank_name': 'STRIPE TEST BANK',
      'country': 'US',
      'currency': 'usd',
      'customer': 'cus_JUVqjk5HtxLqKH',
      'fingerprint': 'wlcr9pf5mS5eQtH8',
      'last4': '6789',
      'metadata': {},
      'routing_number': '110000000',
      'status': 'new'
    }
  }
]
