import {dispatchService} from './dispatch-service.util'
import {getMembersList} from '../../services/service-company.service'
import {ADMIN_USER_ROLE, DISPATCHER_USER_ROLE} from '../../constants/user.constants'

export const SET_SERVICE_COMPANY_MANAGEMENT_LIST_STATE = 'SET_SERVICE_COMPANY_MANAGEMENT_LIST_STATE'
export const RESET_SERVICE_COMPANY_MANAGEMENT_LIST_STATE = 'RESET_SERVICE_COMPANY_MANAGEMENT_LIST_STATE'

export const setServiceCompanyManagementListState = list => ({
  type: SET_SERVICE_COMPANY_MANAGEMENT_LIST_STATE,
  list
})

export const resetServiceCompanyManagementListState = () => ({
  type: RESET_SERVICE_COMPANY_MANAGEMENT_LIST_STATE
})

export const fetchServiceCompanyManagementList = orgId => async dispatch =>
  await dispatchService(dispatch)(getMembersList, setServiceCompanyManagementListState)(orgId, {
    roles: [ADMIN_USER_ROLE, DISPATCHER_USER_ROLE]
  })
