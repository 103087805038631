import {createReducer} from 'redux-create-reducer'
import {
  ADD_PAYMENT_SOURCE,
  REMOVE_PAYMENT_SOURCE, RESET_CONNECTED_ACCOUNTS, RESET_DEFAULT_SOURCE_STATE, RESET_PAYMENT_SOURCES,
  RESET_WALLET_STATE, SET_CONNECTED_ACCOUNTS, SET_DEFAULT_SOURCE_STATE,
  SET_PAYMENT_SOURCES,
  SET_WALLET_STATE, UPDATE_PAYMENT_SOURCE
} from '../actions/wallet.actions'
import {replaceItemInList} from '../../util/lists.util'

export const WALLET_INIT_STATE = {
  sources: [],
  connectedAccounts: {},
  default_source: {}
}

export const walletReducer = createReducer(
  WALLET_INIT_STATE,
  {
    [SET_WALLET_STATE]: (_, {wallet}) => wallet,
    [RESET_WALLET_STATE]: () => ({...WALLET_INIT_STATE}),
    [RESET_DEFAULT_SOURCE_STATE]: (walletState) => ({
      ...walletState,
      default_source: WALLET_INIT_STATE.default_source
    }),
    [SET_DEFAULT_SOURCE_STATE]: (walletState, default_source) => ({
      ...walletState,
      default_source: default_source
    }),

    [SET_PAYMENT_SOURCES]: (walletState, {sources}) => ({
      ...walletState,
      sources
    }),
    [RESET_PAYMENT_SOURCES]: walletState => ({
      ...walletState,
      sources: WALLET_INIT_STATE.sources
    }),
    [ADD_PAYMENT_SOURCE]: (walletState, {source}) => ({
      ...walletState,
      sources: [
        ...walletState.sources,
        source
      ]
    }),
    [REMOVE_PAYMENT_SOURCE]: (walletState, {sourceId}) => ({
      ...walletState,
      sources: walletState.sources.filter(s => s.source_id !== sourceId)
    }),
    [UPDATE_PAYMENT_SOURCE]: (walletState, {source}) => ({
      ...walletState,
      sources: replaceItemInList(
        source,
        walletState.sources,
        walletState.sources.findIndex(s => s.source_id === source.source_id)
      )
    }),
    [SET_CONNECTED_ACCOUNTS]: (walletState, {connectedAccounts}) => ({
      ...walletState,
      connectedAccounts
    }),
    [RESET_CONNECTED_ACCOUNTS]: walletState => ({
      ...walletState,
      connectedAccounts: WALLET_INIT_STATE.connectedAccounts
    })
  }
)
