import {Box, Typography} from '@material-ui/core'
import {CloudDownloadLink} from './CloudDownloadLink'
import {getHumanReadableDate} from '../util/date-time.util'
import Grid from '@material-ui/core/Grid'

const FieldWithLabel = (({
  label,
  children
}) => {
  return <Grid item xs={6}>
    {label && <Typography variant="body1" display="block">{label}</Typography>}
    <Typography variant="body2" display="block">{children}</Typography>
  </Grid>
})

export const CertificateDisplay = ({cert}) => {
  const {
    certification_title,
    issued_by,
    issued_on,
    expires_on,
    start_date,
    end_date,
    description,
    filename
  } = cert || {}
  return <>
    <Grid item xs={12}>
      <Typography variant="body1" display="block">{certification_title}</Typography>
      <Typography variant="body2" display="block">{issued_by}</Typography>
    </Grid>
    {issued_on && <FieldWithLabel {...{label: 'Issue Date:'}}>
        {getHumanReadableDate(issued_on)}
      </FieldWithLabel>
    }
    {start_date && <FieldWithLabel {...{label: 'Start Date:'}}>
        {getHumanReadableDate(start_date)}
      </FieldWithLabel>
    }
    {end_date && <FieldWithLabel {...{label: 'End Date:'}}>
        {getHumanReadableDate(end_date)}
      </FieldWithLabel>
    }
    {expires_on && <FieldWithLabel {...{label: 'Expiration:'}}>
        {getHumanReadableDate(expires_on)}
      </FieldWithLabel>
    }
    <Grid item xs={12}>
      <Typography variant="body1" display="block" string="p" gutterBottom>{description}</Typography>
      {
        filename && <Box display="inline-flex" alignItems="center">
          <CloudDownloadLink {...{
            cloudFilename: filename
          }} >
            <span>Certificate</span>
          </CloudDownloadLink>
        </Box>
      }
    </Grid>
  </>
}
