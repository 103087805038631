import { Typography } from '@material-ui/core'
import { getHumanReadableDate } from '../util/date-time.util'
import { CloudDownloadLink } from './CloudDownloadLink'
import Grid from '@material-ui/core/Grid'

export const options = [
  {label: "Worker's Comp", value: "workers_comp"},
  {label: "Liability", value: "liability"},
  {label: "Auto", value: "auto"},
  {label: "Other", value: "other"}
]

const FieldWithLabel = (({
  label,
  children
}) => {
   return <Grid item xs={6}>
  {label && <Typography variant="body1" display="block">{label}</Typography>}
  <Typography variant="body2" display="block">{children}</Typography>
  </Grid>
})

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const InsuranceDisplay = ({insur}) => {

  const {
    insurance_type,
    alt_insurance_type,
    issued_by,
    issued_on,
    expires_on,
    filename,
    policy_number,
  } = insur[0] || {}
  const options = [
    {label: "Worker's Comp", value: "workers_comp"},
    {label: "Liability", value: "liability"},
    {label: "Auto", value: "auto"},
    {label: "Other", value: "other"}
  ]
  const displayInsuranceType = options.find(x => x.value == insurance_type) ? options.find(x => x.value == insurance_type).label : 'other'
  return <>
    <Grid item xs={12}>
     <Typography variant="body1" display="block">{ insurance_type === 'other' ? capitalize(alt_insurance_type) : capitalize(displayInsuranceType)}</Typography>
     <Typography variant="body2" display="block">{issued_by}</Typography>
     <Typography variant="body2" display="block">Policy Number:</Typography>
     <Typography variant="body1" display="block" string="p">{policy_number}</Typography>
    </Grid>
    {issued_on && <FieldWithLabel {...{label: 'Issued On: '}}>
          {getHumanReadableDate(issued_on)}
        </FieldWithLabel>
    }
    {expires_on && <FieldWithLabel {...{label: 'Expires On: '}}>
          {getHumanReadableDate(expires_on)}
        </FieldWithLabel>
    }
    <Grid item xs={12}>
    {
      insur?.filename && <Grid display="inline-flex" alignItems="center">
        <CloudDownloadLink {...{
          cloudFilename: filename
        }} >
          <span>Insurance Policy</span>
        </CloudDownloadLink>
      </Grid>
    }
    </Grid>
  </>
}
