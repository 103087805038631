import { Chip, FormControl, Input, InputLabel, MenuItem, Select, useTheme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useState } from 'react'


const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

function getStyles(name, industryName, theme) {
  return {
    fontWeight:
      industryName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
// const industries = [
//   'ATM Machines',
//   'Audio Visual',
//   'Alarm and Security',
//   'Desktop/Laptop',
//   'Electricians',
//   'Fiber Optic Cabling',
//   'Kiosk',
//   'Low Voltage Cabling',
//   'Merchandising Reset',
//   'Networking',
//   'Point of Sale',
//   'Printer',
//   'Server',
//   'Retail Fixtures and Displays',
//   'Telecom',
// ]

export const IndustriesDropDown = ({
  industries = [],
  handleIndustriesClose,
  scIndustries = []
                                   }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [industryName, setIndustryName] = useState(scIndustries)
  function handleIndustryChange(e) {
    setIndustryName(e.target.value)
  }
  return <FormControl
    fullWidth
    size={'small'}
  >
    <InputLabel>Capabilities</InputLabel>
    <Select
      id="industrySelection"
      multiple
      variant={'outlined'}
      value={industryName}
      onChange={handleIndustryChange}
      onClose={ev => handleIndustriesClose(industryName)}
      input={<Input id="select-multiple-chip" />}
      renderValue={(selected) => (
        <div className={classes.chips}>
          {selected.map((value) => (
            <Chip key={value} label={value} className={classes.chip} />
          ))}
        </div>
      )}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
    >
      {industries.map((name) => (
        <MenuItem key={name} value={name} style={getStyles(name, industryName, theme)}>
          {name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
}
