import { Box, duration, Typography } from '@material-ui/core'
import { SectionHeader } from '../components/SectionHeader'
import Grid from '@material-ui/core/Grid'
import { FieldDisplay } from '../components/FieldDisplay'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserOrg } from '../util/users.util'
import { requiredDocs } from './sandboxes/kevin/examples/mock-data/required-docs-mock'
import { useEffect } from 'react'
import { deleteInvitedTeam, getInvitedTeam } from '../store/actions/invited-teams-list.actions'
import { FormSubmitButton } from './FormSubmitButton'
import { humanizeScreeningName } from './BackGroundCheckPackageCard'
import { CloudDownloadLink } from './CloudDownloadLink'
import { isServiceErrorResult } from '../services/is-service-error-result.util'
import toastShow, { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '../store/actions/toast-show'
import { closeDrawerAndTransition } from './ServiceCompanyMemberDrawer.util'
import { getRequiredDocs } from '../store/actions/addRequiredDocs.actions'


export const RequiredDocsSummary = ({
                                      setOpen,
                                      backRoutePath,
                                      routeTransitionDelay = duration.leavingScreen,
                                      setBlurred,
                                      requiredDocsList

                                    }) => {

  const {orgId, docId} = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(({auth}) => auth.user)
  const doc = requiredDocsList.find(document => document._id === docId)
  const chekhubOrgId = localStorage.getItem("orgId")?.replace(/['"]+/g, '')
  useEffect(async () => {
    await dispatch(getRequiredDocs({
      chekhub_org_id: chekhubOrgId
    }))
  }, [, open])
  const deleteDoc = async () => {
   const result =  await dispatch(deleteInvitedTeam({
      docId: doc._id
    }))

    if (isServiceErrorResult(result)) {
      dispatch(toastShow(
        TOAST_TYPE_ERROR,
        'Deletion of Document Failed'
      ))
    }else {
      dispatch(toastShow(
        TOAST_TYPE_SUCCESS,
        'Deletion of Document Sucess'
      ))
    }
    closeDrawerAndTransition(
      setOpen,
      backRoutePath,
      history,
      routeTransitionDelay,
      setBlurred
    )
  }

  return <Box style={{
    padding: '10px'
  }}>
    <SectionHeader>
      Required Document Information
    </SectionHeader>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FieldDisplay {...{
          label: 'Document Name'
        }}/>
        <Typography variant={'h6'}>
          {doc.name}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <FieldDisplay {...{
          label: 'Download'
        }}/>
        <CloudDownloadLink {...{
          cloudFilename: doc.filename,
          docName: doc.name,
          style: {
            paddingLeft: '20px',
          }
        }}>
          <Typography style={{
            display: 'none',
          }}>
            {doc.name}
          </Typography>
        </CloudDownloadLink>
      </Grid>
      <FormSubmitButton {...{
        useFab: true,
        onClick: deleteDoc
      }} >
        {'Delete this Document'}
      </FormSubmitButton>
      </Grid>
  </Box>
}
