const fromString = (errorString) => {
  switch (errorString) {
    case 'auth/email-already-in-use':
      return 'This email address is already in use'
    default:
      return 'Unexpected Error'
  }
}

const fromObject = (errorObject) => {
  switch (errorObject?.pattern?.[0]) {
    case 'email':
      return 'Invalid email address'
    case 'phone':
      return 'Invalid phone number'
    default:
      return 'Unexpected Error'
  }
}

const mapToHuman = (error) => {
  switch (typeof error) {
    case 'string':
      return fromString(error)
    case 'object':
      return fromObject(error)
    default:
      return 'Unexpected Error'
  }
}

export default mapToHuman
