import noop from '../util/noop'
import {useState} from 'react'
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js'
import Grid from '@material-ui/core/Grid'
import {Box, Button, FormHelperText, TextField} from '@material-ui/core'
import {FormSubmitButton} from './FormSubmitButton'
import {makeStyles} from '@material-ui/core/styles'
import {useDispatch} from 'react-redux'
import {setBackendWorking, setBackendWorkingDone} from '../store/actions/backend-working.actions'

const usePaymentCardFormStyles = makeStyles(theme => ({
  cardBox: {
    padding: theme.spacing(1.5)
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  }
}))

export const PaymentCardForm = ({
  onSubmit = noop,
  onCancel = noop,
  autoTokenize = false
}) => {
  const styles = usePaymentCardFormStyles()
  const [tokenError, setTokenError] = useState()
  const [cardFieldComplete, setCardFieldComplete] = useState(false)
  const [cardHolderFieldValid, setCardHolderFieldValid] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const dispatch =  useDispatch()

  const clearTokenError = () => setTokenError()
  const shouldAutoTokenizeAndSubmit = (cardComplete = false, cardHolderValid = false) => {
    return autoTokenize && cardComplete && cardHolderValid
  }

  const onCardFieldChange = async element => {
    setCardFieldComplete(element.complete)
    if (shouldAutoTokenizeAndSubmit(element.complete, cardHolderFieldValid)) {
      await tokenizeCardAndSubmit(
        elements.getElement(CardElement),
        name
      )
    }
  }

  const onCardHolderChange = async ({target}) => {
    const {
      validity,
      value
    } = target || {}
    setCardHolderFieldValid(validity.valid)
    if (shouldAutoTokenizeAndSubmit(cardFieldComplete, validity.valid)) {
      await tokenizeCardAndSubmit(
        elements.getElement(CardElement),
        value
      )
    }
  }

  const tokenizeCardAndSubmit = async (card, name) => {
    dispatch(setBackendWorking())
    const {
      token,
      error
    } = await stripe.createToken(card, {
      name
    })
    dispatch(setBackendWorkingDone())

    if (error) {
      return setTokenError(error)
    }
    return onSubmit(token)
  }

  const handleOnSubmit = async ev => {
    ev.preventDefault()
    clearTokenError()
    const card = elements.getElement(CardElement)
    await tokenizeCardAndSubmit(card, ev.target.name.value)
  }

  return <form {...{
    onSubmit: handleOnSubmit,
  }}>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box {...{
          className: styles.cardBox,
          border: 1,
          borderColor: tokenError ? 'error.main' : 'secondary.light',
          borderRadius: 4
        }} >
          <CardElement {...{
            onChange: onCardFieldChange
          }}/>
        </Box>
        {
          tokenError && <FormHelperText error={true}>There was an error processing your card.</FormHelperText>
        }
      </Grid>
      <Grid item xs={12}>
        <TextField {...{
          fullWidth: true,
          variant: 'outlined',
          required: true,
          size: 'small',
          id: 'name',
          label: 'Card Holder',
          onChange: onCardHolderChange
        }} />
      </Grid>
      {
        !autoTokenize && <Grid item xs={12}>
          <Button {...{
            className: styles.cancelButton,
            onClick: onCancel,
            variant: 'contained'
          }}>Cancel</Button>
          <FormSubmitButton {...{
            disabled: !cardFieldComplete || !cardHolderFieldValid
          }}>Submit</FormSubmitButton>
        </Grid>
      }
    </Grid>
  </form>
}
