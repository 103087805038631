import { Route, Switch, withRouter } from 'react-router-dom'
import { RouteConnectedDrawer } from '../components/RouteConnectedDrawer'
import { duration } from '@material-ui/core'
import { useContext } from 'react'
import { Context as BlurContext } from '../contexts/BlurContext'
import { TeamProviderSummarySc } from './TeamProviderSummarySc'


export const TeamProviderDrawerScUtil = withRouter(
  ({
     open,
     setOpen,
     backRoutePath,
     match,
     setDrawerOpen,
     routeTransitionDelay = duration.leavingScreen,
     teamOrProviderList,
     onDialogOpen,
     setOpenDialog,
     onDocumentUploadRequirements,
     onModalOpen,
     setModalOpen,
     onRequirementSave,
     openUpload,
     closeUpload,
     openModal,
     closeModal,
     onAcceptance
   }) => {
    const {setBlurred} = useContext(BlurContext)
    return <Switch>
      <Route path={`${match.path}/:teamId/summary`}>
        <RouteConnectedDrawer {...{
          open,
          setOpen,
          anchor: 'right',
          title: 'View Chekhub End User Onboarding',
          backRoutePath
        }}>
          <TeamProviderSummarySc {...{
            teamOrProviderList,
            setOpen,
            backRoutePath,
            routeTransitionDelay,
            setBlurred,
            onDialogOpen,
            setOpenDialog,
            onDocumentUploadRequirements,
            onModalOpen,
            setModalOpen,
            onRequirementSave,
            openUpload,
            closeUpload,
            openModal,
            closeModal,
            onAcceptance
          }}/>
        </RouteConnectedDrawer>
      </Route>
    </Switch>
  }
)
