import React from 'react'

// import { useSelector } from 'react-redux'

import {
  Button,
} from '@material-ui/core'

import InsurancePolicyItem from './profile-InsurancePolicyItem'
import FileUploadOverlay from './profile-FileUploadOverlay'
import useFileUploadStyles from '../styles/fileUploadStyles'

export default function InsurancePolicyList({
  data,
  profileType = 'tech',
  readonly,
  disabled,
  userImpersonated = false,
}) {
  const uploadStyles = useFileUploadStyles()
  const {
    insurance_policies: insurancePolicies = [],
  } = data

  const initState = {
    overlayOpen: false,
    insurancePolicy: {
      filename: null,
      insurance_type: null,
      issued_by: null,
      issued_on: null,
      expires_on: null,
    },
  }
  const [state, setState] = React.useState(initState)

  // const downloadUrls = useSelector((store) => store.storage.downloadUrls)

  const handleAddRecordClick = () => setState({ ...initState, overlayOpen: true })
  const handleChange = () => (setState({ ...state, overlayOpen: false }))

  return (<div>
    <strong>Insurance Policies</strong>
    {readonly ? ('') : <Button
      className={uploadStyles.list}
      size="small"
      variant="outlined"
      style={{
      marginLeft: '10px', // for now until I come back to it
      }}
      disabled={disabled}
      onClick={handleAddRecordClick}>
      Add Insurance Policy
    </Button>}
    {!insurancePolicies.length
      ? <div style={{ margin: '0.5em 0' }}><i>The service provider hasn't uploaded any insurance policies yet.</i></div>
      : insurancePolicies.map((item) => {
        const { _id: itemId } = item
        return (<InsurancePolicyItem
          key={itemId}
          profileType={profileType}
          profile={data}
          userImpersonated={userImpersonated}
          policyDetails={item}
          insurancePoliciesList={insurancePolicies} />)
      })
    }
    <FileUploadOverlay open={state.overlayOpen}
      fileType="insurance_policy"
      profileType={profileType}
      profile={data}
      userImpersonated={userImpersonated}
      onSubmit={handleChange} />
  </div>)
}
