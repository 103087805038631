import {Button, Fade, Menu, MenuItem} from "@material-ui/core"
import {NavLink} from "react-router-dom"
import DashboardIcon from "@material-ui/icons/Dashboard"
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import actions from "../store/actions"
import {UserAvatar} from "./UserAvatar"
import {getUserOrg} from "../util/users.util"
import {getLandingPath} from "../util/landingPath.util"

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const userDefaultOrg = getUserOrg(auth?.user)
  const activeOrgObject = useSelector(({auth}) => auth.activeOrgObject)
  const {user} = auth
  const landingPath = getLandingPath(user, userDefaultOrg, activeOrgObject)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }


  const handleSignOut = () => {
    dispatch(actions['AUTH/SIGN-OUT']())
  }

  return user && <>
    <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
      <UserAvatar {...{user}} />
    </Button>
    <Menu
      id="fade-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: 'top', horizontal: "right" }}
      style={{
        position: 'absolute',
        left: '32px',
        cursor: 'pointer'
      }}
    >
      <MenuItem
        primaryText="Sign Out"
        onClick={handleSignOut}
        leftIcon={
          <ExitToAppIcon />
        }
      >Sign Out</MenuItem>
    </Menu>
  </>
}

export default UserMenu
