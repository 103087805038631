import createUser from './auth-sign-up-functions/create-user'
import updateUser from './upsert-functions/update-user'
import profileUpsertFunction from './upsert-functions/profile-upsert'
import mapToHuman from './shared-functions/map-error-to-readable'
import useAccessToken from './useAccessToken'
import {setBackendWorking, setBackendWorkingDone} from './backend-working.actions'

const dispatchErrorNotification = (dispatch, isNewProfile, errorMessage) => dispatch({
  type: 'TOAST/TRIGGER-NOTIFICATION',
  payload: {
    type: 'error',
    message: `There was an error ${isNewProfile ? 'creating' : 'updating'} this profile: ${errorMessage}`,
  },
})

const authTechSignUp = (
  credentialsProfile,
  isNewProfile,
) => async (dispatch, getState, getFirebase) => {
  dispatch(setBackendWorking())

  const {userType} = credentialsProfile
  const accessToken = await useAccessToken()

  const [user, userError] = isNewProfile
    ? await createUser(credentialsProfile, userType)
    : await updateUser(credentialsProfile, userType, accessToken)
  if (userError) {
    dispatch(setBackendWorkingDone())
    const humanMessage = mapToHuman(userError.response ? userError.response.data?.errors : '')
    dispatchErrorNotification(dispatch, isNewProfile, humanMessage)
    return undefined
  }
  if (user) dispatch({ type: 'AUTH/SIGN-UP-OK' })
  const userId = user ? user._id : credentialsProfile._id

  const [
    profile,
    profileError,
  ] = await profileUpsertFunction(credentialsProfile, accessToken, isNewProfile, userId)
  if (profileError) {
    const humanMessage = mapToHuman(profileError.response ? profileError.response.data?.errors : '')
    dispatchErrorNotification(dispatch, isNewProfile, humanMessage)
    dispatch({ type: 'IMPERSONATED-PROFILE/UPSERT-ERROR', payload: profileError.message })
    dispatch(setBackendWorkingDone())
    return undefined
  }
  dispatch({
    type: 'IMPERSONATED-PROFILE/UPSERT-OK',
    payload: {
      ...credentialsProfile,
      ...profile,
    },
  })

  dispatch(setBackendWorkingDone())
  dispatch({
    type: 'TOAST/TRIGGER-NOTIFICATION',
    payload: {
      type: 'success',
      message: `Technician profile successfully ${isNewProfile ? 'created' : 'updated'}`,
    },
  })
}

export default authTechSignUp
