import noop from '../util/noop'
import Grid from '@material-ui/core/Grid'
import {Button, FormControl, InputAdornment, InputLabel, OutlinedInput, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FormSubmitButton} from './FormSubmitButton'

const useVerifyAccountStyles = makeStyles(theme => ({
  inputAdornment: {
    marginRight: 0, marginTop: '0.1em'
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  }
}))

export const VerifyAccount = ({
  onSubmit = noop,
  onCancel = noop
}) => {
  const styles = useVerifyAccountStyles()
  const handleOnSubmit = ev => {
    ev.preventDefault()
    const {
      amount1: {value: amount1},
      amount2: {value: amount2}
    } = ev.target
    onSubmit(Number(amount1), Number(amount2))
  }
  return <form {...{
    onSubmit: handleOnSubmit
  }}>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography {...{
          variant: 'body1',
          gutterBottom: true
        }}>
          <strong>You must verify bank account before it can be used for payments on Chekhub Connect</strong>
        </Typography>
        <Typography {...{
          variant: 'body2',
          gutterBottom: true
        }}>
          To verify your account, enter the two micro deposits below. If you still don't see them, it may take up to 3
          business days to show up in your account.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl {...{
          fullWidth: true,
          required: true,
          variant: 'outlined',
          size: 'small'
        }} >
          <InputLabel htmlFor="amount1">Amount 1</InputLabel>
          <OutlinedInput {...{
            fullWidth: true,
            id: 'amount1',
            type: 'number',
            inputProps: {pattern: '[0-9]{2}', maxlength: 2},
            placeholder: '00',
            startAdornment: <InputAdornment
              className={styles.inputAdornment}
              position="start">$0.</InputAdornment>,
            labelWidth: 75
          }} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl {...{
          fullWidth: true,
          required: true,
          variant: 'outlined',
          size: 'small'
        }} >
          <InputLabel htmlFor="amount2">Amount 2</InputLabel>
          <OutlinedInput {...{
            id: 'amount2',
            type: 'number',
            inputProps: {pattern: '[0-9]{2}', maxlength: 2},
            placeholder: '00',
            startAdornment: <InputAdornment
              className={styles.inputAdornment}
              position="start">$0.</InputAdornment>,
            labelWidth: 75
          }} />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button {...{
          onClick: onCancel,
          variant: 'outlined',
          className: styles.cancelButton
        }}>Cancel</Button>
        <FormSubmitButton>Submit</FormSubmitButton>
      </Grid>
    </Grid>
  </form>
}
