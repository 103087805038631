import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  Grid,
} from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete'

import DateRender from './shared-DateRender'

import useFileUploadStyles from '../styles/fileUploadStyles'

import actions from '../store/actions'

export default function InsurancePolicyItem({
  insurancePoliciesList,
  profileType,
  profile,
  userImpersonated,
  policyDetails: {
    filename,
    insurance_type: insuranceType,
    issued_by: issuedBy,
    // updated_at: updatedAt,
    expires_on: expiresOn,
  },
}) {
  const uploadStyles = useFileUploadStyles()
  const dispatch = useDispatch()
  const { _id: profileId } = profile
  const downloadUrls = useSelector((store) => store.storage.downloadUrls)

  useEffect(() => {
    if (!downloadUrls[filename]) {
      dispatch(actions['STORAGE/GET-SIGNED-DOWNLOAD-URL'](filename))
    }
  }, [])

  const handleRemovePolicy = (target) => {
    const matchingPolicies = insurancePoliciesList.filter((item) => (item.filename === target))
    const idProp = '_id'
    matchingPolicies.map((item) => (dispatch(actions['PROFILE/SUBCOLLECTION-REMOVE-ITEM']({
      documentType: 'insurance-policies',
      isTechProfile: profileType === 'tech',
      profileId,
      userImpersonated,
      documentId: item[idProp],
    }))))
  }

  return (<Grid container direction='row' className={uploadStyles.list}>
    <div className={uploadStyles.button}>
      <DeleteIcon
        className={uploadStyles.deleteButton}
        onClick={() => handleRemovePolicy(filename)} />
    </div>
    <div>{insuranceType}</div>&nbsp;/&nbsp;
    <div>
      <i>Issued by </i>{issuedBy}&nbsp;/&nbsp;
    </div>
    <div>
      <i>Expires on </i><DateRender dateString={expiresOn} />
    </div>&nbsp;/&nbsp;
    <div>
      <a href={downloadUrls[filename]} download>Download</a>
    </div>
  </Grid>)
}
