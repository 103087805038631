import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {
  createPaymentSource,
  getPaymentSources,
  removePaymentSource, resetPaymentSources,
} from '../store/actions/wallet.actions'
import {isServiceErrorResult} from '../services/is-service-error-result.util'
import toastShow, {TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS} from '../store/actions/toast-show'
import {PaymentSources} from './PaymentSources'
import {SectionHeader} from './SectionHeader'
import {
  ArrowForwardSharp,
  AttachMoney
} from '@material-ui/icons'
import {IconWithAdornment} from './IconWithAdornment'
import {Box} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const useManagePaymentSourcesViewStyles = makeStyles(theme => ({
  headIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  boxWrapper: {
    padding: theme.spacing(1),
    paddingTop: 0
  }
}))

const ManagePaymentSources = () => {
  const styles = useManagePaymentSourcesViewStyles()
  const dispatch = useDispatch()
  const {orgId} = useParams()
  const {
    sources
  } = useSelector(
    ({
      wallet: {
        sources
      }
    }) => ({
      sources
    }) )

  useEffect(() => () => dispatch(resetPaymentSources()), [])

  const onAddCard = async token => {
    const createError = await dispatch(createPaymentSource(orgId, token.id))
    const isError = isServiceErrorResult(createError)
    dispatch(toastShow(
      !isError ? TOAST_TYPE_SUCCESS : TOAST_TYPE_ERROR,
      !isError ?
        'Your card is ready for use' :
        'Verification failed. Please check your card details'
    ))
  }

  const onAddBankAccount = async token => {
    const createError = await dispatch(createPaymentSource(orgId, token.id))
    const isError = isServiceErrorResult(createError)
    dispatch(toastShow(
      !isError ? TOAST_TYPE_SUCCESS : TOAST_TYPE_ERROR,
      !isError ?
        'Success. Your account needs to be verified before first use' :
        'Operation failed. Please check your account details'
    ))
  }

  const onRemove = source => {
    dispatch(removePaymentSource(orgId, source.source_id))
  }

  return <>
    <SectionHeader>
      <Box {...{
        display: 'flex',
        alignItems: 'center'
      }}>
        <IconWithAdornment {...{
          fontSize: 'medium',
          className: styles.headIcon,
          adornment: <ArrowForwardSharp/>
        }} >
          <AttachMoney/>
        </IconWithAdornment>
        <span>Payment Sources</span>
      </Box>
    </SectionHeader>
    <Box className={styles.boxWrapper}>
      <PaymentSources {...{
        sources,
        onAddCardSubmit: onAddCard,
        onAddBankAccountSubmit: onAddBankAccount,
        onRemove
      }} />
    </Box>
  </>
}

export default ManagePaymentSources
