import {
  getChekhubOrg, getChekhubOrgList, getIndyTechsAll,
  getServiceCompany,
  getServiceCompanyList, getServiceCompanyList2, getServiceCompanyList3, getServiceCompanyListAll,
  postServiceCompany,
  putServiceCompany
} from '../../services/service-company.service'
import toastShow, {TOAST_TYPE_SUCCESS} from './toast-show'
import {dispatchService} from './dispatch-service.util'
import {LINKEDIN_PAGE_FIELDNAME, prependProtocol, WEBSITE_FIELDNAME} from '../../pages/ServiceCompanyView.utils'
import {getTechProfileListForOrg} from '../../services/tech-profiles.service'
import {getUser} from '../../services/users.service'
import {updateUser} from './users.actions'
import {isServiceErrorResult} from '../../services/is-service-error-result.util'
import { setConnectedAccounts } from './wallet.actions'
import { getStripeAccount } from '../../services/wallet.service'
import { userProfileSetState } from './user-profile.actions'

export const SERVICE_COMPANY_SET_STATE = 'SERVICE_COMPANY_SET_STATE'
export const SERVICE_COMPANY_RESET_STATE = 'SERVICE_COMPANY_RESET_STATE'
export const SERVICE_COMPANY_LIST_SET_STATE = 'SERVICE_COMPANY_LIST_SET_STATE'
export const SERVICE_COMPANY_LIST_RESET_STATE = 'SERVICE_COMPANY_LIST_RESET_STATE'
export const PROVIDER_LIST_SET_STATE = 'PROVIDER_LIST_SET_STATE'
export const PROVIDER_LIST_RESET_STATE = 'PROVIDER_LIST_RESET_STATE'
export const CHEKHUB_LIST_SET_STATE = 'CHEKHUB_LIST_SET_STATE'
export const CHEKHUB_LIST_RESET_STATE = 'CHEKHUB_LIST_RESET_STATE'
export const SERVICE_COMPANY_SET_TECH_COUNT = 'SERVICE_COMPANY_SET_TECH_COUNT'
export const SERVICE_COMPANY_SET_PRIMARY_CONTACT = 'SERVICE_COMPANY_SET_PRIMARY_CONTACT'

export const providerListSetState = list => dispatch =>
  dispatch({
    type: PROVIDER_LIST_SET_STATE,
    list
  })

export const resetProviderListState = () => dispatch =>
  dispatch({
    type: PROVIDER_LIST_RESET_STATE
  })

export const chekhubListSetState = list => dispatch =>
  dispatch({
    type: CHEKHUB_LIST_SET_STATE,
    list
  })

export const resetChekubListState = () => dispatch =>
  dispatch({
    type: CHEKHUB_LIST_RESET_STATE
  })



export const serviceCompanySetState = serviceCompany => dispatch =>
  dispatch({
    type: SERVICE_COMPANY_SET_STATE,
    serviceCompany
  })

export const serviceCompanyListSetState = list => dispatch =>
  dispatch({
    type: SERVICE_COMPANY_LIST_SET_STATE,
    list
  })

export const serviceCompanySetTechCount = tech_count => dispatch =>
  dispatch({
    type: SERVICE_COMPANY_SET_TECH_COUNT,
    tech_count
  })

export const serviceCompanySetPrimaryContact = user => dispatch =>
  dispatch({
    type: SERVICE_COMPANY_SET_PRIMARY_CONTACT,
    user
  })

export const resetServiceCompanyState = () => dispatch =>
  dispatch({
    type: SERVICE_COMPANY_RESET_STATE
  })

export const resetServiceCompanyListState = () => dispatch =>
  dispatch({
    type: SERVICE_COMPANY_LIST_RESET_STATE
  })

export const fetchServiceCompany = orgId => async dispatch =>
  dispatchService(dispatch)(getServiceCompany, serviceCompanySetState)(orgId)

export const fetchChekhubOrg = orgId => async dispatch =>
  dispatchService(dispatch)(getChekhubOrg, userProfileSetState)(orgId)

export const fetchChekhubOrgList = () => async dispatch =>
  dispatchService(dispatch)(getChekhubOrgList, chekhubListSetState)()




const PARAMS_KEY_MAP = {
  orgId: 'owner_id',
  orgType: 'profile_types',
  search: 'search',
  skip: 'skip',
  limit: 'limit'
}

export const fetchStripeAccountForChekhubUser = chekhub_org_id => async dispatch =>
  dispatchService(dispatch)(getStripeAccount, setConnectedAccounts)(chekhub_org_id)

const generateServiceCompanyListParams = params =>
  Object.keys(params).reduce((result, k) =>
    params[k] !== undefined ? {
      ...result,
      [PARAMS_KEY_MAP[k]]: params[k]
    } : {...result},
    {}
  )

export const fetchServiceCompanyListAll = (
                                        ) => async dispatch =>
  dispatchService(dispatch)(getServiceCompanyListAll, serviceCompanyListSetState)()


export const fetchIndyTechsAll = ({
                                             orgId,
                                             orgType,
                                             search,
                                             skip,
                                             limit
                                           }) => async dispatch =>
  dispatchService(dispatch)(getIndyTechsAll, serviceCompanyListSetState)(
    generateServiceCompanyListParams({
      orgId,
      orgType,
      search,
      skip,
      limit
    })
  )

export const fetchServiceCompanyList = ({
  orgId
}) => async dispatch =>
  dispatchService(dispatch)(getServiceCompanyList, serviceCompanyListSetState)(
    orgId
  )

export const fetchServiceCompanyList2 = ({
                                          chekhub_orgId
                                        }) => async dispatch =>
  dispatchService(dispatch)(getServiceCompanyList2, providerListSetState)(
    chekhub_orgId
  )

export const fetchServiceCompanyList3 = () => async dispatch =>
  dispatchService(dispatch)(getServiceCompanyList3, serviceCompanyListSetState)()
const dealWithLazyUris = entity => {
  if (entity[WEBSITE_FIELDNAME]) {
    entity[WEBSITE_FIELDNAME] = prependProtocol(entity[WEBSITE_FIELDNAME])
  }
  if (entity[LINKEDIN_PAGE_FIELDNAME]) {
    entity[LINKEDIN_PAGE_FIELDNAME] = prependProtocol(entity[LINKEDIN_PAGE_FIELDNAME])
  }
  return {...entity}
}
export const updateServiceCompany = serviceCompany => async dispatch => {
  let result = await dispatchService(dispatch)
    (putServiceCompany, serviceCompanySetState)
    (dealWithLazyUris(serviceCompany))
  if (!isServiceErrorResult(result) && serviceCompany.primaryContact?._id) {
    result = await dispatch(updateUser(serviceCompany.primaryContact))
  }
  if (!isServiceErrorResult(result)) {
    dispatch(toastShow(TOAST_TYPE_SUCCESS, "Successfully updated Service Company"))
  }
  return result
}

export const fetchServiceCompanyTechCount = orgId => async dispatch =>
  dispatchService(dispatch)(
    getTechProfileListForOrg,
    respBody => dispatch => {
      dispatch(serviceCompanySetTechCount(respBody?.matching_docs))
    },
    true
  )(orgId, {
    // TODO: Put this back in once we can get an accurate count from the backend w/o
    //   fetching the whole list
    // limit: 1,
    skip: 0
  })

export const fetchServiceCompanyPrimaryContact = userId => async dispatch =>
  await dispatchService(dispatch)(getUser, serviceCompanySetPrimaryContact)(userId)

export const createServiceCompany = serviceCompany => async dispatch =>
  await dispatchService(dispatch)(postServiceCompany, serviceCompanySetState)(dealWithLazyUris(serviceCompany))
