import {createReducer} from 'redux-create-reducer'
import {
  SERVICE_COMPANY_RESET_STATE, SERVICE_COMPANY_SET_PRIMARY_CONTACT,
  SERVICE_COMPANY_SET_STATE,
  SERVICE_COMPANY_SET_TECH_COUNT
} from '../actions/service-company.actions'
import {LINKEDIN_PAGE_FIELDNAME, WEBSITE_FIELDNAME} from '../../pages/ServiceCompanyView.utils'

export const SERVICE_COMPANY_INIT_STATE = {
  is_connect_team_member: '',
  company_name: '',
  status: 'active',
  phones: [],
  location: {
    label: 'work',
    lat: null,
    long: null,
    address: {}
  },
  [WEBSITE_FIELDNAME]: '',
  [LINKEDIN_PAGE_FIELDNAME]: '',
  ein: '',
  company_formation_year: 0,
  industries: [],
  eoc: {
    small_business:false,
    disadvantaged_business:false,
    mbe_african_american:false,
    mbe_asian_american:false,
    mbe_asian_pacific_american:false,
    mbe_hispanic_american:false,
    mbe_native_american:false,
    women_owned:false,
    veteran_owned:false,
    disabled_veteran_owned:false,
    disibility_owned:false,
    hub_zone_located:false,
    lgbt_owned:false,
  }
}

const serviceCompanyReducer = createReducer(
  SERVICE_COMPANY_INIT_STATE,
  {
    [SERVICE_COMPANY_SET_STATE]: (state, {serviceCompany}) => ({
      ...state,
      ...serviceCompany
    }),
    [SERVICE_COMPANY_SET_TECH_COUNT]: (state, {tech_count}) => ({
      ...state,
      tech_count
    }),
    [SERVICE_COMPANY_SET_PRIMARY_CONTACT]: (state, {user: primaryContact}) => ({
      ...state,
      primary_contact_id: primaryContact._id,
      primaryContact,
    }),
    [SERVICE_COMPANY_RESET_STATE]: () => SERVICE_COMPANY_INIT_STATE
  }
)

export default serviceCompanyReducer
