import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  h1: {
    marginBottom: '1em',
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: '25em',
  },
  textField: {
    marginBottom: '1.5em',
  },
  signInGrid: {
    display: 'flex',
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.surfaces.paper,
    minWidth: theme.spacing(90),
  },
  signInButton: {
    backgroundColor: '#F5882F',
  },
  signUpButton: {
    backgroundColor: 'grey',
    color: 'snow',
  },
  backSignIn: {
    marginTop: theme.spacing(1),
  }
}))
