import {dispatchService} from './dispatch-service.util'
import {postMember, putMember, removeMember} from '../../services/service-company.service'
import {isServiceErrorResult} from '../../services/is-service-error-result.util'
import {fetchServiceCompanyTechCount} from './service-company.actions'

export const createServiceCompanyMember = (member, orgId, stateUpdateAction) => async dispatch => {
  return await dispatchService(dispatch)(postMember, stateUpdateAction)(member, orgId)
}

export const updateServiceCompanyMember = (member, orgId, stateUpdateAction) => async dispatch => {
  return await dispatchService(dispatch)(putMember, stateUpdateAction)(member, orgId)
}

export const removeServiceCompanyMember = (userId, orgId, stateUpdateAction) => async dispatch => {
  const result = await dispatchService(dispatch)(
    removeMember,
    stateUpdateAction
  )(userId, orgId)
  !isServiceErrorResult(result) && dispatch(fetchServiceCompanyTechCount(orgId))
  return result

}

