import noop from '../util/noop'
import {MemberRolesToggle} from './MembersRoleToggle'
import {InlineConfirm} from './InlineConfirm'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'

const useServiceCompanyEditMemberRoles = makeStyles(theme => ({
  root: {
    alignItems: 'center'
  },
  toggle: {
    width: 'auto',
    marginRight: theme.spacing(1)
  },
  confirmCancelButton: {
    height: 'auto',
    alignSelf: 'center'
  }
}))

export const ServiceCompanyEditMemberRoles = ({
  roles,
  setRoles = noop,
  onConfirm = noop,
  onCancel = noop,
  hideInlineConfirm = false,
  excludeRoles = [],
  ...restToggleProps
}) => {
  const styles = useServiceCompanyEditMemberRoles()
  return <Grid container className={styles.root}>
    <MemberRolesToggle {...{
      roles,
      setRoles,
      excludeRoles,
      className: styles.toggle,
      ...restToggleProps
    }} />
    {
      !hideInlineConfirm && <InlineConfirm {...{
        onConfirm,
        onCancel,
        confirmClassName: styles.confirmCancelButton,
        cancelClassName: styles.confirmCancelButton,
      }} />
    }
  </Grid>
}

