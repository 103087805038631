import {getResponseBody} from './get-response-body.util'

export const getRespError = async resp => {
  const respBody = await getResponseBody(resp)
  return new Error(
    respBody.errors ?
      Object.values(respBody.errors)
      .join(', ') : respBody
  )
}
