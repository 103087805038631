import {isEmpty} from 'lodash'
import {useFieldErrors} from './useFieldErrors'

const onBlurValidation = (
  validations,
  setFieldError,
  clearFieldError
) => (ev, val, validatorArgs) => {
  const {target} = ev
  const {
    name,
  } = target
  const value = val || target.value
  const validation = validations[name]
  if (validation) {
    const {required, validator} = validation
    if (required && isEmpty(value)) {
      return setFieldError(name, required)
    }
    const errorMsg = validator && (
      (!required && value && validator(value, validatorArgs)) ||
      (required && validator(value, validatorArgs))
    )
    if (errorMsg) {
      return setFieldError(name, errorMsg)
    }
  }
  clearFieldError(name)
}

const onChangeValidation = (validations, setFieldError, clearFieldError) => (ev, val, validatorArgs) => {
  const {target} = ev
  const {
    name
  } = target
  const value = val || target.value

  const validation = validations[name]
  if (validation) {
    const {validator, required} = validation
    const errMsg = validator && validator(value, validatorArgs)
    return (
      (!errMsg && !required) ||
      (required && !errMsg && value)
    ) && clearFieldError(name)
  }
}

const onSubmitValidation = (validations, setFieldError) => ev => {
  const {target} = ev
  const validationKeys = Object.keys(validations)
  const targetKeys = Object.keys(target)
  const fieldNames = validationKeys.filter(v => targetKeys.includes(v))
  let formIsValid = true
  fieldNames.forEach(fieldName => {
    const {value} = target[fieldName]
    const validation = validations[fieldName]
    if (validation) {
      const {required, validator} = validation
      if (required && isEmpty(value)) {
        formIsValid = false
        return setFieldError(fieldName, required)
      }
      const errorMsg = !required && value && validator && validator(value)
      if (errorMsg) {
        formIsValid = false
        return setFieldError(fieldName, errorMsg)
      }
    }
  })
  return formIsValid
}

export const useValidations = (
  validations = {}
) => {
  const {
    fieldErrors,
    setFieldError,
    clearFieldError,
    hasFieldErrors
  } = useFieldErrors()
  return {
    onChangeValidation: onChangeValidation(validations, setFieldError, clearFieldError),
    onBlurValidation: onBlurValidation(validations, setFieldError, clearFieldError),
    onSubmitValidation: onSubmitValidation(validations, setFieldError),
    fieldErrors,
    setFieldError,
    clearFieldError,
    hasFieldErrors
  }
}
