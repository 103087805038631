import { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core'
import { PaymentSourcesList } from './PaymentSourcesList'
import { PaymentCard } from './PaymentCard'
import { FormSubmitButton } from './FormSubmitButton'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandMore } from '@material-ui/icons'
import noop from '../util/noop'

const PAYMENT_CHOOSER_EXISTING = 'PAYMENT_CHOOSER_EXISTING'
const PAYMENT_CHOOSER_ADD_NEW = 'PAYMENT_CHOOSER_ADD_NEW'

const usePaymentChooserStyles = makeStyles(theme => ({
  root: {
    '& div.MuiAccordion-root:last-of-type': {
      marginBottom: 0
    }
  },
  accordionSummary: {
    minHeight: theme.spacing(5),
    '&.Mui-expanded': {
      minHeight: theme.spacing(5)
    },
    '& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordionSummary-content': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  accordionDetails:{
    paddingTop: 0,
    paddingBottom: 0
  },
  addNewAccordion: {
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    flexDirection: 'column'
  },
  submitBox: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end'
  }
}))

export const PaymentChooser = ({
  paymentSources = [],
  onSubmit = noop
}) => {
  const styles = usePaymentChooserStyles()
  const [expandedPanel, setExpandedPanel] = useState(
    paymentSources.length ?
      PAYMENT_CHOOSER_EXISTING : PAYMENT_CHOOSER_ADD_NEW
  )
  const [selectedSource, setSelectedSource] = useState()
  const [addCardSource, setAddCardSource] = useState({})
  const [sourceToken, setSourceToken] = useState()

  const clearAddCardSource = () => setAddCardSource()
  const handleCardOnSubmit = token => setSourceToken(token)
  const handleSelectSource = source =>
    setSelectedSource(
      selectedSource?.source_id === source.source_id ?
        undefined : source
    )

  const handleAccordionChange = panel => () => setExpandedPanel(panel)

  const handlePurchaseSubmit = () => {
    return onSubmit(
      sourceToken ? {
        ...sourceToken,
        is_source_token: true
      } : {
        ...selectedSource.stripe_data,
        is_source_token: false
      }
    )
  }

  return <Box className={styles.root}>
    <Accordion {...{
      disabled: Boolean(sourceToken) || !paymentSources.length,
      square: true,
      expanded: expandedPanel === PAYMENT_CHOOSER_EXISTING,
      onChange: handleAccordionChange(PAYMENT_CHOOSER_EXISTING),
      elevation: 0,
      variant: 'outlined'
    }}>
      <AccordionSummary {...{
        className: styles.accordionSummary,
        expandIcon: expandedPanel !== PAYMENT_CHOOSER_EXISTING && <ExpandMore />
      }}>
        <Typography>Saved Payment Sources</Typography>
      </AccordionSummary>
      <AccordionDetails {...{
        className: styles.accordionDetails
      }}>
        <PaymentSourcesList {...{
          sources: paymentSources,
          disableRemove: true,
          enableSelect: true,
          selected: selectedSource,
          setSelected: handleSelectSource
        }} />
      </AccordionDetails>
    </Accordion>
    <Accordion {...{
      disabled: Boolean(selectedSource),
      square: true,
      expanded: expandedPanel === PAYMENT_CHOOSER_ADD_NEW,
      onChange: handleAccordionChange(PAYMENT_CHOOSER_ADD_NEW),
      elevation: 0,
      variant: 'outlined'
    }}>
      <AccordionSummary {...{
        className: styles.accordionSummary,
        expandIcon: expandedPanel !== PAYMENT_CHOOSER_ADD_NEW &&  <ExpandMore />
      }}>
        <Typography>Add New Card</Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.addNewAccordion}>
        {
          addCardSource &&
            <PaymentCard {...{
              autoTokenize: true,
              onSubmit: handleCardOnSubmit,
              onCancel: clearAddCardSource
            }} />
        }
      </AccordionDetails>
    </Accordion>
    <Box {...{
      display: "flex",
      className: styles.submitBox
    }} >
      <FormSubmitButton {...{
        disabled: !selectedSource && !sourceToken,
        onClick: handlePurchaseSubmit
      }} >Submit</FormSubmitButton>
    </Box>
  </Box>
}
