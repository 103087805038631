import appConfig from "../config"
import {Headers, Methods} from "http-headers-js"
import generateAuthHeader from "./generate-auth-header.util"

const {
  defaultHeaders,
  baseURI,
  paths: {
    industriesService: industriesPath,
  },
} = appConfig.chekhubConnect

const serviceBaseUri = `${baseURI}${industriesPath}`

export const fetchIndustriesList = async () => await fetch(
  `${serviceBaseUri}`,
  {
    method: Methods.GET,
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    }
  }
)
