import { dispatchService } from './dispatch-service.util'
import {
  addDocRequirements,
  fetchRequiredDocs,
  uploadDocRequirements,
  uploadDocRequirementsChange
} from '../../services/addToTeam.service'
import { requiredDocsSetState } from './required-docs.actions'


export const addRequiredDocs = ({file_name, chekhub_org_id, name}) => async dispatch =>
  await dispatchService(dispatch)(addDocRequirements)(file_name, chekhub_org_id, name)

export const uploadRequiredDocs = ({upload, _id, requirement_id}) => async dispatch =>
  await dispatchService(dispatch)(uploadDocRequirements)(upload, _id, requirement_id)

export const uploadRequiredDocsChange = ({service_provider_id, chekhub_org_id, requirements}, requirement_id) => async dispatch =>
  await dispatchService(dispatch)(uploadDocRequirementsChange)(service_provider_id, chekhub_org_id, requirements, requirement_id)

export const getRequiredDocs = ({chekhub_org_id}) => async dispatch =>
  await dispatchService(dispatch)(fetchRequiredDocs, requiredDocsSetState)(chekhub_org_id)
