import {duration, Grid} from '@material-ui/core'
import {useContext} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Context as BlurContext} from '../contexts/BlurContext'
import { useHistory } from 'react-router-dom'
import { LANDING_PATH } from '../routing/route.constants'

const usePageWrapperStyles = blurred => makeStyles(theme => ({
  root: {
    ...blurred ? {
        filter: 'blur(2px)',
        transition: `${duration.short}ms filter linear`
      } : {},
    backgroundColor: theme.palette.grey[200],
  }
}))

export const PageWrapper = ({children}) => {
  const {blurred} = useContext(BlurContext)
  const styles = usePageWrapperStyles(blurred)()
  const history = useHistory()
  return <Grid container {...{
    className: styles.root,
    spacing:  0,
    direction: 'row',
    justify: 'center',
    alignItems: 'center'
  }} >{children}</Grid>
}
