import {Card, CardContent, Grid, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {ServiceCompanyManagementListExample} from './examples/ServiceCompanyManagementListExample'
import {ExampleLinks, ExampleRoutes} from '../examples.util'
import {VeteranStatusExample} from './examples/VeteranStatusExample'
import {CertificationsExample} from './examples/CertificationsExample'
import {SkillsEditExample} from './examples/SkillsEditExample'
import {PaymentSourcesExample} from './examples/PaymentSourcesExample'
import {ReceivingAccountExample} from './examples/ReceivingAccountExample'
import {PaymentChooserExample} from './examples/PaymentChooserExample'
import {PlacesAutoCompleteExample} from './examples/PlacesAutoCompleteExample'
import {SsnInputExample} from './examples/SsnInputExample'

const useMattsSandboxStyles = makeStyles(theme => ({
  header: {
    textTransform: 'uppercase'
  }
}))

const EXAMPLES = [
  ServiceCompanyManagementListExample,
  VeteranStatusExample,
  CertificationsExample,
  SkillsEditExample,
  PaymentSourcesExample,
  ReceivingAccountExample,
  PaymentChooserExample,
  PlacesAutoCompleteExample,
  SsnInputExample
]

export const SandboxMattView = () => {
  const styles = useMattsSandboxStyles()

  return <Grid container>
    <Grid item xs={12}>
      <Card variant='outlined'>
        <CardContent>
          <Typography
            variant='h4'
            className={styles.header}
          >Matt's Sandbox</Typography>

          <ExampleLinks {...{examples: EXAMPLES}} />
          <ExampleRoutes {...{examples: EXAMPLES}} />

        </CardContent>
      </Card>
    </Grid>
  </Grid>
}

