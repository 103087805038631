import { Box, Grid, TextField } from '@material-ui/core'
import { DropZoneDialogTrigger } from './DropZoneDialogTrigger'
import { MimeTypes } from 'http-headers-js'
import { FormSubmitButton } from './FormSubmitButton'
import { withRouter } from 'react-router-dom'


export const RequiredDocsAdd = withRouter(({

                                             onDocumentSave,
                                             onDocumentNameChange,
                                             onDialogOpen,
                                             setOpenDialog,
                                             documentName,
                                             fileUncleanedName
                                           }) => {

  const openUpload = () => setOpenDialog(true)
  const closeUpload = () => setOpenDialog(false)
  return <form
    style={{
      paddingLeft: '15px',
      paddingTop: '15px'
    }}
  >
    <Grid container spacing={2}>
      <Grid item xs={6}>
          <TextField
            {...{
              fullWidth: true,
              id: 'document_name',
              onChange: onDocumentNameChange,
              placeholder: 'Document Name',
              label: 'Document Name',
              variant: 'outlined',
              size: 'small',
            }}
          />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
              <DropZoneDialogTrigger
                {...{
                  dialogOpen: onDialogOpen,
                  acceptedFiles: [MimeTypes.Application.PDF],
                  submitButtonText: 'Upload Documents',
                  uploadDialogProps: {
                    dialogTitle: 'Upload Documents',
                  },
                  onClose: closeUpload,
                  onSave: onDocumentSave,
                }}
              >
              </DropZoneDialogTrigger>
              <FormSubmitButton
                {...{
                  disabled: documentName === '' || !documentName,
                  onClick: openUpload,
                  useFab: true,
                }}
              >
                {'Upload Documents'}
              </FormSubmitButton>
        </Box>
      </Grid>
    </Grid>
  </form>
})
