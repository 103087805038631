import Grid from '@material-ui/core/Grid'
import { Hidden } from '@material-ui/core'
import { ChekhubEndUserSummary } from '../components/ChekhubEndUserSummary'
import ListServiceCompanyView from './ListServiceCompany.view'
import useLandingViewStyles from '../components/LandingViewStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchChekhubOrgList } from '../store/actions/service-company.actions'
import { useParams } from 'react-router-dom'

export const ChekhubEndUserLanding = () => {
  const styles = useLandingViewStyles()

  return <Grid container>
    <Hidden smDown>
      <Grid item xs={3}>
      <ChekhubEndUserSummary {...{

      }}/>
      </Grid>
    </Hidden>
    <Grid
      item
      xs={12}
      sm={12}
      md={9}
      lg={9}
      xl={9}
      className={styles.skeleton}
    >
      <ListServiceCompanyView />
    </Grid>
  </Grid>

}
