import { makeStyles } from '@material-ui/core/styles'
import {MAX_PAGE_WIDTH} from '../theme'

export default makeStyles((theme) => ({
  mainWrapper: {
    background: theme.palette.secondary.main,
  },
  limitWrapper: {
    maxWidth: MAX_PAGE_WIDTH,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  span: {
    color: '#F5882F',
    fontSize: theme.spacing(4),
    paddingRight: theme.spacing(1),
  },
  bannerImg: {
    width: theme.spacing(35),
    height: theme.spacing(6),
  },
  backgroundSet: {
    backgroundColor: '#333',
    minHeight: 'calc(108vh - 100vh)',
  },
  bannerContainerWithoutNav: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
}))

