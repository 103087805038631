import {createReducer} from 'redux-create-reducer'
import {
  TECH_PROFILE_LIST_APPEND_PROFILE,
  TECH_PROFILE_LIST_RESET_STATE,
  TECH_PROFILE_LIST_SET_STATE
} from '../actions/tech-profile-list.actions'

export const TECH_PROFILE_LIST_INIT_STATE = []

const techProfileListReducer = createReducer(
  TECH_PROFILE_LIST_INIT_STATE,
  {
    [TECH_PROFILE_LIST_SET_STATE]: (state, {techProfileList}) => techProfileList,
    [TECH_PROFILE_LIST_APPEND_PROFILE]: (state, {techProfile}) => [
      ...state,
      techProfile
    ],
    [TECH_PROFILE_LIST_RESET_STATE]: () => TECH_PROFILE_LIST_INIT_STATE}
)

export default techProfileListReducer
