import appConfig from "../config"
import { Headers, Methods } from "http-headers-js"
import generateAuthHeader from "./generate-auth-header.util"
import useAccessToken from '../store/actions/useAccessToken'


const { chekhub: { baseURI, defaultHeaders, operations } } = appConfig
const { ticketFetchOne } = operations
const { path, method, authRequired } = ticketFetchOne
const serviceBaseUri = `${baseURI}${path}`

export const fetchTicket = async (ticketId) => await fetch(
  `${serviceBaseUri}/${ticketId}`,
  {
    method: Methods.GET,
    headers: {
      ...defaultHeaders,
      authorization: `Bearer ${await useAccessToken()}`
    },
  }
)
