import chekhubLogo from '../assets/images/logo_gray.svg'
import { Checkbox, FormControlLabel, IconButton, InputAdornment, TextField } from '@material-ui/core'
import {FormSubmitButton} from './FormSubmitButton'
import {validate as emailValidate} from 'email-validator'
import {useValidations} from '../hooks/useValidations'
import Typography from '@material-ui/core/Typography'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { CHEKHUB_END_USER_SIGN_IN } from '../routing/route.constants'

const signInValidations = {
  email: {
    required: 'Email is required',
    validator: (val) => {
      return !emailValidate(val) ? 'Invalid email format' : null
    }
  },
  password: {
    required: 'Password is required',
    validator: val => {
      return val?.length < 1 ?
        'Password must be at least 1 character in length' : null
    }
  }
}

const SignInForm = ({
  onChange,
  onSubmit,
  useChekhubSignIn,
  styles,
  handlePasswordShow,
  showPassword,
  disableChekhubUserLogin = true
}) => {
  const history = useHistory()
  const {
    onBlurValidation,
    onChangeValidation,
    onSubmitValidation,
    fieldErrors,
    hasFieldErrors
  } = useValidations(
    signInValidations
  )

  const handleOnChange = ev => {
    onChangeValidation(ev)
    onChange(ev)
  }

  const handleOnSubmit = ev => {
    ev.preventDefault()
    const formIsValid = onSubmitValidation(ev)
    !hasFieldErrors() && formIsValid && onSubmit(ev)
  }

  return <form onSubmit={handleOnSubmit}>
    <TextField {...{
      id: 'email',
      name: 'email',
      className: styles.textField,
      size: 'medium',
      label: 'Email',
      fullWidth: true,
      onChange: handleOnChange,
      onBlur: onBlurValidation,
      variant: 'outlined',
      error: Boolean(fieldErrors['email']),
      helperText: fieldErrors['email'] || null
    }} />
    <TextField {...{
      id: 'password',
      name: 'password',
      className: styles.textField,
      size: 'medium',
      label: 'Password',
      fullWidth: true,
      type: showPassword ? 'text' : 'password',
      onChange: handleOnChange,
      onBlur: onBlurValidation,
      variant: 'outlined',
      error: Boolean(fieldErrors['password']),
      helperText: fieldErrors['password'] || null,
      InputProps: {
        endAdornment: <InputAdornment position="end">
          <IconButton onClick={handlePasswordShow}>
          {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    }} />
    {/*{ history.location.pathname !== CHEKHUB_END_USER_SIGN_IN &&*/}
    {/*  <FormControlLabel {...{*/}
    {/*    control: <Checkbox {...{*/}
    {/*      onChange,*/}
    {/*      name: 'checkHubSignIn',*/}
    {/*      color: 'primary',*/}
    {/*      checked: useChekhubSignIn*/}
    {/*    }} />,*/}
    {/*    label:*/}
    {/*      <span>*/}
    {/*    Sign in as a*/}
    {/*    <img {...{*/}
    {/*      alt: 'Chekhub Logo',*/}
    {/*      src: chekhubLogo,*/}
    {/*      className: styles.checkboxLabelLogo*/}
    {/*    }} />*/}
    {/*    user*/}
    {/*  </span>*/}
    {/*  }} />*/}
    {/*}*/}
    <FormSubmitButton {...{
      id: 'sign-in-button',
      className: styles.signInButton,
      disableElevation: true,
      fullWidth: true,
      disabled: hasFieldErrors()
    }}>
      <Typography variant={'span'}>Sign In</Typography>
    </FormSubmitButton>
  </form>
}

export default SignInForm
