import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

export const useBusyIndicatorStyles = makeStyles(theme => ({
  spinner: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1)
  },
  spinnerInverse: {
    color: theme.palette.action.disabled
  }
}))

export const BusyIndicator = ({
  busy = false,
  inverse = false
}) => {
  const styles = useBusyIndicatorStyles()
  return busy &&
    <CircularProgress {...{
      size: '1em',
      className: `${styles.spinner}${
        inverse ? ` ${styles.spinnerInverse}` : ''
      }`,
      color: 'primary'
    }} />
}
