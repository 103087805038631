import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    width: '100%',
    maxWidth: theme.spacing(100),
  },
  card: {
    padding: '5px'
  },
}))
