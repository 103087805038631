import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { useHistory } from 'react-router-dom'

import {
  Button,
  Grid,
  Paper,
} from '@material-ui/core'

import useStyles from './SignInStyles'

import actions from '../store/actions'

const SelectOrgComponent = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector((state) => state.auth)
  const { user: { organizations }, userRole } = auth
  const orgs = organizations.filter((item) => (item.organization_id))

  return (
    <Grid container item direction="row" justify="center">
      <Paper className={styles.paper}>
        <h1 className={styles.h1}>Select The Active Organization</h1>
        <form>
          {orgs && orgs.map((org) => (
            <p>
              <Button key={org.organization_id} color="primary"
                type="submit"
                variant="contained"
                fullWidth={true}
                onClick={(e) => {
                  e.preventDefault()
                  dispatch(actions['AUTH/SET-ACTIVE-ORG'](org.organization_id, userRole))
                  history.push('/dashboard')
                }}>
                {org.name}
              </Button>
            </p>
          ))}

        </form>
      </Paper>
    </Grid>
  )
}

export default SelectOrgComponent
