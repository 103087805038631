const userRequirements = {}

const techRequirements = {
  birth_date: null,
  location: null,
  service_radius: null,
  hour_rate: null,
}

const serviceCompanyRequirements = {
  company_name: null,
  location: null,
  phones: null,
}

const locationRequirements = {
  label: null,
  lat: null,
  long: null,
  address: null,
}

const phoneRequirements = {
  country: null,
  raw_phone: null,
}

const validateRequired = (requiredFields, data) => {
  const result = Object.keys(requiredFields).map((key) => {
    switch (key) {
      case 'location':
        return !(data?.location && validateRequired(locationRequirements, data.location))
      case 'phones':
        return !(data?.phones?.[0] && validateRequired(phoneRequirements, data.phones[0]))
      default:
        return !data[key]
    }
  })
  return !result.includes(true)
}

const validateServiceCompanyRequired = (profile) => {
  return validateRequired(serviceCompanyRequirements, profile)
}

const validateTechRequired = (profile) => {
  return validateRequired(techRequirements, profile)
}

const validateUserRequired = (profile) => {
  return validateRequired(userRequirements, profile)
}

export {
  validateRequired,
  validateServiceCompanyRequired,
  validateTechRequired,
  validateUserRequired,
}
