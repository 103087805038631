import useLandingViewStyles from './LandingViewStyles'
import { useDimensionsContextStyles } from './DimensionsContext'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { getDefaultSource, resetConnectedAccounts } from '../store/actions/wallet.actions'
import { resetServiceCompanyState } from '../store/actions/service-company.actions'
import Grid from '@material-ui/core/Grid'
import { Card, CardContent, Fab, Hidden, Typography } from '@material-ui/core'
import { ChekhubEndUserSummary } from './ChekhubEndUserSummary'
import { AddBankAccountChekhubEndUser } from '../pages/AddBankAccountChekhubEndUser'
import { AddCircle } from '@material-ui/icons'
import { useServiceCompanyMembersStyles } from './ServiceCompanyMembers'
import { RequiredDocsDisplay } from './RequiredDocsDisplay'
import { addRequiredDocs, getRequiredDocs } from '../store/actions/addRequiredDocs.actions'
import { fetchRequiredDocs } from '../services/addToTeam.service'
import { uploadFile } from '../store/actions/storage.actions'
import { isServiceErrorResult } from '../services/is-service-error-result.util'
import toastShow, { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '../store/actions/toast-show'
import { Context as BlurContext } from '../contexts/BlurContext'
import { closeDrawerAndTransition } from './ServiceCompanyMemberDrawer.util'


export const RequiredDocumentsChekhubEndUser = () => {

  const [open, setOpen] = useState(false)
  const [onDialogOpen, setOpenDialog] = useState(false)
  const [documentName, setDocumentName] = useState('')
  const [fileUncleanedName, setUncleanedFileName] = useState('')
  const {setBlurred} = useContext(BlurContext)
  const fabStyles = useServiceCompanyMembersStyles()
  const styles = useLandingViewStyles()
  const dimensionsContextStyles = useDimensionsContextStyles()
  const dispatch = useDispatch()
  const auth = useSelector(({auth}) => auth)
  const {user} = auth
  const {orgId} = useParams()
  const activeOrgIndex = user?.organizations?.findIndex(org => org.organization_id === auth.activeOrg)

  const requiredDocsList = useSelector(({requiredDocsList}) => requiredDocsList)
  const chekhubOrgId = localStorage.getItem("orgId")?.replace(/['"]+/g, '')


  const onDocumentNameChange = async (e) => {
    const value = e.target.value
    setDocumentName(value)
  }

  useEffect(async () => {
    await dispatch(getRequiredDocs({
      chekhub_org_id: chekhubOrgId
    }))
  }, [, open])

  return <Grid container>
    <Hidden smDown>
      <Grid item xs={3}>
        <ChekhubEndUserSummary {...{
          user,
          activeOrgIndex
        }}/>
      </Grid>
    </Hidden>
    <Grid
      item
      xs={12}
      sm={12}
      md={9}
      lg={9}
      xl={9}
      className={styles.skeleton}
    >
      <Card className={
        `${styles.membersListCard} ${dimensionsContextStyles.minHeight} ${dimensionsContextStyles.card}`
      } >
        <CardContent>
          { <RequiredDocsDisplay {... {
            open,
            setOpen,
            fabStyles,
            requiredDocsList,
            onDocumentNameChange,
            onDialogOpen,
            setOpenDialog,
            documentName,
            fileUncleanedName,
            setDocumentName,
            setUncleanedFileName,
            chekhubOrgId,
            setBlurred
          }} />
          }
        </CardContent>
      </Card>
    </Grid>
  </Grid>
}
