import useLandingViewStyles from "../components/LandingViewStyles"
import {useDispatch, useSelector} from "react-redux"
import useAsyncEffect from "use-async-effect"
import {fetchServiceCompany, resetServiceCompanyState} from "../store/actions/service-company.actions"
import Grid from "@material-ui/core/Grid"
import {Hidden} from "@material-ui/core"
import ServiceCompanySummary from "../components/ServiceCompanySummary"
import {useParams} from "react-router-dom"
import {techProfileResetState} from "../store/actions/tech-profile.actions"

const ServiceCompanyLandingView = () => {
  const styles = useLandingViewStyles()
  const {
    serviceCompany: profile,
  } = useSelector(
    ({
      serviceCompany,
    }) => ({
      serviceCompany,
    })
  )
  const {orgId} = useParams()
  const dispatch = useDispatch()

  useAsyncEffect(
    async () => {
      orgId && dispatch(fetchServiceCompany(orgId))
    },
    async () => {
      await dispatch(resetServiceCompanyState())
      await dispatch(techProfileResetState())
    },
    []
  )
  return <Grid container>
    <Hidden smDown>
      <Grid item xs={3}>
        <ServiceCompanySummary {...{
          profile
        }} />
      </Grid>
    </Hidden>
    <Grid
      item
      xs={12}
      sm={12}
      md={9}
      lg={9}
      xl={9}
      className={styles.skeleton}
    >
      <Grid container justify={'center'} alignItems={'center'}>
        <h1>Search Companies to Apply for...</h1>
      </Grid>
    </Grid>
  </Grid>

}

export default ServiceCompanyLandingView
