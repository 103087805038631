import {
  Box,
  Card, CircularProgress, duration,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { teamOrProvider } from './sandboxes/kevin/examples/mock-data/service-company-mock'
import { CheckSharp, CloseSharp } from '@material-ui/icons'
import { Link, useHistory, useParams, withRouter } from 'react-router-dom'
import React from 'react'
import { TeamProviderDrawerScUtil } from './TeamProviderDrawerScUtil'
import { TeamProviderDrawerUtil } from '../pages/TeamProviderDrawerUtil'
import { uploadFile } from '../store/actions/storage.actions'
import { isServiceErrorResult } from '../services/is-service-error-result.util'
import toastShow, { TOAST_TYPE_SUCCESS } from '../store/actions/toast-show'
import { uploadRequiredDocs } from '../store/actions/addRequiredDocs.actions'
import { closeDrawerAndTransition } from './ServiceCompanyMemberDrawer.util'
import { useDispatch } from 'react-redux'
import { teamAcceptance, teamAcceptanceScRoute } from '../store/actions/AddToTeams.actions'
import { teamAcceptanceSc } from '../services/addToTeam.service'
import CheckIcon from '@material-ui/icons/Check'
import Grid from '@material-ui/core/Grid'
import { useBusyIndicatorStyles } from './BusyIndicator'


export const checkProperties = (arr) => {
  if(!arr) return true
  for (let i = 0; i < arr.length; i++) {
    if(!arr[i].upload){
      return false
    }
  }
  return true
}


export const CurrentInvitedTeamsTemplate = withRouter(({
  match,
  setDrawerOpen,
  open,
  chekhubChecker,
                                                         invitedTeams,
                                                         onDialogOpen,
                                                         setOpenDialog,
                                                         onDocumentUploadRequirements,
                                                         onModalOpen,
                                                         setModalOpen,
                                                         openUpload,
                                                         closeUpload,
                                                         openModal,
                                                         closeModal,
                                                         routeTransitionDelay = duration.leavingScreen,
  setBlurred,
                                                         chekhubOrgId


                                            }) => {

  const getChance = () => {
    return Math.floor(Math.random() * 2)
  }
  const history = useHistory()
  const dispatch = useDispatch()
  const {orgId} = useParams()
  const styles = useBusyIndicatorStyles()
  const onAcceptance = async (localizedTeam) => {
    const result = chekhubChecker ? await dispatch(teamAcceptance({
      chekhub_org_id: chekhubOrgId,
      onboarding_request_id: localizedTeam._id
    })) : await dispatch(teamAcceptanceScRoute({
      service_provider_id: orgId,
      onboarding_request_id: localizedTeam._id
    }))
    if (isServiceErrorResult(result)) {
      return result
    }else {
      dispatch(toastShow(
        TOAST_TYPE_SUCCESS,
        'Accepted Team'
      ))
    }
    closeDrawerAndTransition(
      setDrawerOpen,
      match.url,
      history,
      routeTransitionDelay,
      setBlurred
    )
  }

  const onRequirementSave = async (localizedTeam, e, docs) => {
    const result = await dispatch(uploadFile(e[0], 'ReUploaded'))

    if (isServiceErrorResult(result)) {
      return result
    }else {
      dispatch(toastShow(
        TOAST_TYPE_SUCCESS,
        'File Uploaded Successfully'
      ))
    }
    await dispatch(uploadRequiredDocs({
      upload: result,
      _id: localizedTeam._id,
      requirement_id: docs._id,
    }))
    setOpenDialog(false)
    setModalOpen(false)
  }

  return invitedTeams ? <Card variant={'outlined'} style={{
    minHeight: '82vh'
  }} >
    <Typography variant={'h5'}
                style={{
                  paddingLeft: '10px',
                  paddingTop: '10px'
                }}>
      {invitedTeams.length > 0 ? 'My Current Invites' : 'No Current Invites'}
    </Typography>
    <TableContainer component={Box} style={{
      paddingTop: '10px'
    }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{chekhubChecker ? 'Service Provider Name' : 'Chekhub End User Name'}</TableCell>
            <TableCell>{'Required Documents Uploaded'}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {invitedTeams.length > 0 ? invitedTeams.map((row) => {
            return <TableRow key={row.service_provider_name || row.full_name}>
            <TableCell component="th" scope="row">
          {chekhubChecker ? row.service_provider_name : row.chekhub_org_name}
            </TableCell>
            <TableCell>
          {checkProperties(row?.requirements) ? <CheckIcon /> : <CloseSharp />}
            </TableCell>
            <TableCell align="right">
            <Link to={`${match.url}/${row.chekhub_org_id !== orgId  ? row.chekhub_org_id : row.service_provider_id}/summary`}>
            View Provider
            </Link>
            </TableCell>
            </TableRow>
          }) : <Grid
            container
            justify={'center'}
          >
          </Grid>}
        </TableBody>
      </Table>
    </TableContainer>
    {!chekhubChecker ? <TeamProviderDrawerScUtil {... {
        open,
        setOpen: setDrawerOpen,
        backRoutePath: match.url,
        teamOrProviderList: invitedTeams,
        onDialogOpen,
        setOpenDialog,
        onDocumentUploadRequirements,
        onModalOpen,
        setModalOpen,
        onRequirementSave,
        openUpload,
        closeUpload,
        openModal,
        closeModal,
        onAcceptance
      }} /> :
      <TeamProviderDrawerUtil {... {
        open,
        setOpen: setDrawerOpen,
        backRoutePath: match.url,
        teamOrProviderList: invitedTeams,
        onAcceptance
      }} />
    }
  </Card> : <Card variant={'outlined'} style={{
    minHeight: '82vh'
  }} >
    <Grid
      container
      justify={'center'}
    >
      <CircularProgress {...{
        size: '2em',
        className: styles.spinner,
        color: 'primary'
      }} />
    </Grid>
  </Card>
})
