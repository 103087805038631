import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  Backdrop,
  Button,
  FormControl,
  InputLabel,
  TextField,
  Paper,
  Select,
  CircularProgress,
} from '@material-ui/core'

import {
  CloudUploadOutlined,
} from '@material-ui/icons'

import { useDropzone } from 'react-dropzone'

import { v4 as uuidv4 } from 'uuid'

import moment from 'moment'

import useStyles from './profile-FileUploadOverlayStyles'
import useRootStyles from '../styles/rootStyles'
import useFormStyles from '../styles/formStyles'

import actions from '../store/actions'

const fileTypes = {
  background_check: {
    subcollection: 'background-check',
    schema: [],
  },
  certification: {
    subcollection: 'certificates',
    schema: [{
      name: 'certification_title',
      label: 'Certification Title',
      fieldType: 'text',
      props: {},
      style: { width: '15em', marginRight: '1em' },
      required: true,
      lineBreak: false,
    }, {
      name: 'issued_by',
      label: 'Issued by',
      fieldType: 'text',
      props: {},
      required: true,
      lineBreak: true,
    }, {
      name: 'issued_on',
      label: 'Issued on',
      fieldType: 'date',
      hidden: true,
      duplicateOf: 'start_date',
      style: { width: '12em' },
      props: {},
      required: false,
      lineBreak: false,
    }, {
      name: 'expires_on',
      label: 'Expires on',
      fieldType: 'date',
      hidden: true,
      duplicateOf: 'end_date',
      style: { width: '12em' },
      props: {},
      required: false,
      lineBreak: false,
    }, {
      name: 'start_date',
      label: 'Start date',
      fieldType: 'date',
      style: { width: '12em' },
      props: {},
      required: true,
      lineBreak: false,
    }, {
      name: 'end_date',
      fieldType: 'date',
      label: 'End date',
      style: { width: '12em' },
      props: {},
      required: true,
      lineBreak: true,
    }],
    /*
    description: null, */
  },
  insurance_policy: {
    subcollection: 'insurance-policies',
    schema: [{
      name: 'insurance_type',
      label: 'Type of Insurance',
      fieldType: 'select',
      props: {},
      style: { width: '15em', marginRight: '1em' },
      required: true,
      lineBreak: true,
      options: [{
        value: 'workers_comp',
        label: 'Workers Compensation',
      }, {
        value: 'liability',
        label: 'Liability',
      }, {
        value: 'auto',
        label: 'Auto',
      }, {
        value: 'other',
        label: 'Other',
      }],
    }, {
      name: 'issued_by',
      label: 'Issued by',
      fieldType: 'text',
      props: {},
      required: true,
      lineBreak: true,
    }, {
      name: 'issued_on',
      label: 'Issued on',
      fieldType: 'date',
      style: { width: '12em' },
      props: {},
      required: true,
      lineBreak: false,
    }, {
      name: 'expires_on',
      label: 'Expires on',
      fieldType: 'date',
      style: { width: '12em' },
      props: {},
      required: true,
      lineBreak: true,
    }],
  },
}

const initState = {
  file: null,
  filename: null,
  filenameId: null,
  uploadRunning: false,
  fileDetails: {},
}

function DynamicInput(props) {
  const {
    className = '',
    style = {},
    options = [],
    fieldType,
    hidden = false,
    label,
    lineBreak,
    name,
    required = false,
    state = {},
    onChange = () => (undefined),
  } = props
  if (hidden) return ''
  switch (fieldType) {
    case 'select':
      return (<FormControl variant="outlined" size="small">
        <InputLabel htmlFor={name} required={required}>{label}</InputLabel>
        <Select native
          name={name}
          id={name}
          label={label}
          style={style}
          required={required}
          value={state.fileDetails[name] || ''}
          onChange={onChange}>
            <option value=""></option>
            {options.map((item, idx) => (
              <option key={idx} value={item.value}>{item.label}</option>
            ))}
        </Select>
      </FormControl>)
    default:
      return (<FormControl variant="outlined" size="small">
        <TextField
          className={className}
          style={style}
          type={fieldType}
          name={name}
          id={name}
          label={label}
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          required={required}
          value={state.fileDetails[name] || ''}
          onChange={onChange}
        />{lineBreak ? (<div style={{ marginTop: '1em' }}></div>) : ('')}
      </FormControl>)
  }
}

export default function FileUploadOverlay({
  fileType,
  fileDetails = {},
  open,
  onSubmit,
  profileType,
  profile,
  userImpersonated,
}) {
  const styles = useStyles()
  const rootStyles = useRootStyles()
  const formStyles = useFormStyles()
  const dispatch = useDispatch()

  const fileTypeDetails = fileTypes[fileType] || { subcollection: null, schema: [] }
  const { subcollection, schema } = fileTypeDetails
  const schemaMap = schema.reduce((acc, cur) => ({
    ...acc,
    [cur.name]: cur,
  }), {})

  const [state, setState] = React.useState({ ...initState, fileDetails })
  const uploadOk = useSelector((globalState) => globalState.storage.uploadOk)

  if (uploadOk[state.filenameId] && open) {
    setState(initState)
    onSubmit()
  }

  const missingRequired = () => {
    const requiredFields = schema.filter((item) => item.required)
    const { fileDetails: details } = state
    const verifyResult = requiredFields.map((item) => (
      details[item.name] === undefined || details[item.name].trim() === ''
    ))
    verifyResult.push(state.file === null)
    return verifyResult.includes(true)
  }

  const handleChange = (e) => {
    setState({
      ...state,
      fileDetails: {
        ...state.fileDetails,
        [e.target.name]: e.target.value,
      },
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (missingRequired()) return undefined

    const duplicateDetails = schema.filter((item) => item?.duplicateOf).reduce((acc, cur) => {
      return { ...acc, [cur.name]: state.fileDetails[cur.duplicateOf] }
    }, {})
    const newFileDetails = { ...state.fileDetails, ...duplicateDetails }
    const fileDetailsMigration = Object.keys(newFileDetails).reduce((acc, cur) => {
      if (schemaMap[cur]?.fieldType === 'date') return { ...acc, [cur]: moment(newFileDetails[cur]) }
      return { ...acc, [cur]: newFileDetails[cur] }
    }, {})

    dispatch(actions['STORAGE/UPLOAD-FILE']({
      file: state.file,
      filename: state.filenameId,
      fileType,
      profileType,
      userImpersonated,
      profile,
      documentType: subcollection,
      data: fileDetailsMigration,
    }))
    setState({ ...state, uploadRunning: true })
  }

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDropAccepted: (files) => files.map((file) => setState({
      ...state,
      file,
      filename: file.path,
      filenameId: `${uuidv4()}-${file.path}`,
    })),
  })

  return (<Backdrop
    open={open}
    style={{ zIndex: 999, color: '#000' }}>
    <Paper className={styles.paper}>
      <form onSubmit={handleSubmit}>
        {(schema && schema.length)
          ? (<div style={{ margin: '1em 0' }}>
            {schema.map((item) => (
              <DynamicInput
                className={rootStyles.textField}
                style={item.style}
                key={item.name}
                fieldType={item.fieldType}
                name={item.name}
                label={item.label}
                lineBreak={item.lineBreak}
                required={item.required}
                hidden={item.hidden}
                state={state}
                options={item.options}
                onChange={handleChange}
              />
            ))}
          </div>)
          : ('')}
        <section className="container">
          <div className={styles.baseStyle} {...getRootProps()}>
            <input {...getInputProps()} />
            <CloudUploadOutlined className={styles.icon} />
            <span style={{ fontWeight: 600 }}>
              Drag and drop file here or click to select one from your computer
            </span><br />
            {state.filename
              ? (<i>{state.filename}<br /></i>)
              : ('')
            }
          </div>
        </section>
        <Button type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: '1em', fontSize: '75%', minWidth: '10em' }}
          disabled={!state.filename || state.uploadRunning}
          >
            { state.uploadRunning
              ? <CircularProgress size={'1.7em'}
              className={formStyles.buttonProgress} />
              : 'Upload File'
            }
        </Button>
        <Button
          style={{ marginTop: '1em', marginLeft: '1em', fontSize: '75%' }}
          size="small"
          onClick={() => onSubmit()}>
          Cancel
        </Button>
      </form>
    </Paper>
  </Backdrop>)
}
