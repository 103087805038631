import makeStyles from '@material-ui/core/styles/makeStyles'
import noop, {noopThunk} from '../util/noop'
import {Button, Grid, List, ListItem, ListItemSecondaryAction} from '@material-ui/core'
import {CertificateDisplay} from './CertificateDisplay'
import {EditRemoveButtons} from './EditRemoveButtons'
import {AddCircleOutlineSharp} from '@material-ui/icons'
import {ConfirmationModal} from './ConfirmationModal'

const useCertificationsDisplayStyles = makeStyles(theme => ({
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}))

export const CertificationsDisplay = ({
  certifications = [],
  onEdit = noopThunk,
  onRemove = noopThunk,
  onAdd = noop,
  disableEdit = false,
  disableRemove = false,
  disableAdd = false,
  removeCert,
  onRemoveConfirm = noopThunk,
  onRemoveCancel = noop
}) => {
  const styles = useCertificationsDisplayStyles()

  return <>
    <List dense={true}>
      {
        certifications.map(
          (cert, index) =>
            <ListItem {...{
              key: index,
              disableGutters: true,
              className: styles.listItem
            }}>
              <Grid container spacing={2}>
                <CertificateDisplay {...{
                  cert
                }} />
                <ListItemSecondaryAction>
                  <EditRemoveButtons {...{
                    onEdit: onEdit(cert, index),
                    onRemove: onRemove(cert, index),
                    disableEdit,
                    disableRemove
                  }} />
                </ListItemSecondaryAction>
              </Grid>
            </ListItem>
          )
      }
      {
        !disableAdd && <ListItem {...{
          key: 'add-cert-button',
          disableGutters: true,
          className: styles.listItem
        }} >
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <Button {...{
                size: 'small',
                variant: 'outlined',
                color: 'primary',
                onClick: onAdd,
                startIcon: <AddCircleOutlineSharp fontSize="small" />
              }}>
                Add Certification
              </Button>
            </Grid>
          </Grid>
        </ListItem>
      }
    </List>
    <ConfirmationModal {...{
      open: removeCert,
      onOk: onRemoveConfirm(removeCert),
      onClose: onRemoveCancel
    }} >
      Remove certification, "{removeCert?.certification_title}"?
    </ConfirmationModal>
  </>
}
