import {Box, Card, CardContent, Fab} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ServiceCompanyMembersList from './ServiceCompanyMembersList'
import { AddCircle } from '@material-ui/icons'
import { Link, withRouter } from 'react-router-dom'
import { findAdminUsers } from '../util/users.util'
import { useState } from 'react'
import { ServiceCompanyMemberDrawerUtil } from './ServiceCompanyMemberDrawer'
import ServiceCompanyMembersTable from './ServiceCompanyMembersTable'
import useTableStyles from './ServiceCompanyMembersTableStyles'
import { makeStyles } from '@material-ui/core/styles'
import { DataDisplayToggle, LIST_DISPLAY, TABLE_DISPLAY } from './DataDisplayToggle'
import { ConfirmationModal } from './ConfirmationModal'
import { useDispatch } from 'react-redux'
import { removeServiceCompanyMember } from '../store/actions/service-company-members.actions'
import { userListRemoveUser } from '../store/actions/users.actions'
import { getTechFromListByUserId } from '../util/techs.util'
import { useDimensionsContextStyles } from "./DimensionsContext"

export const useServiceCompanyMembersStyles = makeStyles(theme => ({
  membersListCard: {
    width: '100%',
    height: '100%',
    border: 'none'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(6.75),
    right: theme.spacing(2),
    '& svg': {
      marginRight: theme.spacing(1)
    }
  }
}))

export const ServiceCompanyMembers = withRouter(({
  members,
  serviceCompany,
  match
}) => {
  const styles = useServiceCompanyMembersStyles()
  const dimensionsContextStyles = useDimensionsContextStyles()
  const dispatch = useDispatch()
  const [open, setDrawerOpen] = useState(false)
  const tableStyles = useTableStyles()
  const createLinkPath = `${match.url}/member/create`
  const [display, setDisplay] = useState(TABLE_DISPLAY)
  const handleToggle = (e, newDisplay) => setDisplay(newDisplay)
  const [removeTech, setRemoveTech] = useState()
  const disableAdminRemoval = findAdminUsers(members, serviceCompany._id).length <= 1

  const onRemove = member => {
    setRemoveTech(member)
  }

  const onOk = member => async () => {
    await dispatch(removeServiceCompanyMember(
      member.user_id || member._id,
      serviceCompany._id,
      () => (dispatch, getState) => {
        const {userList} = getState()
        const techProfile = getTechFromListByUserId(member.user_id || member._id, userList)
        return dispatch(userListRemoveUser(techProfile._id))
      }
    ))
    onClose()
  }

  const onClose = () => setRemoveTech()

  return  <Box {...{
    border: 0,
    className: dimensionsContextStyles.scrollContainer
  }}>
    <Card variant="outlined" className={
      `${styles.membersListCard} ${dimensionsContextStyles.minHeight}`
    }>
      <CardContent>
        <Typography
          {...{
            variant: 'h5',
            className: styles.header
          }} gutterBottom>
          <span>Technicians</span>
          <DataDisplayToggle {...{
            selected: display,
            onChange: handleToggle
          }} />
        </Typography>
        {
          display === LIST_DISPLAY ?
            <ServiceCompanyMembersList {...{
              members,
              serviceCompany,
              onRemove,
              disableAdminRemoval
            }} /> :
            <ServiceCompanyMembersTable {...{
              members,
              tableStyles,
              serviceCompany,
              onRemove,
              disableAdminRemoval
            }}/>
        }
        <Link to={createLinkPath}>
          <Fab {...{
            className: styles.fab,
            color: 'primary',
            variant: 'extended'
          }}>
            <AddCircle/>
            Add Tech
          </Fab>
        </Link>
      </CardContent>
    </Card>
    <ServiceCompanyMemberDrawerUtil {...{
      open,
      setOpen: setDrawerOpen,
      backRoutePath: match.url,
    }} />
    <ConfirmationModal {...{
      open: removeTech,
      onOk: onOk(removeTech),
      onClose,
    }}>Remove team member, {removeTech?.full_name}?</ConfirmationModal>
    </Box>
})
