import { Route, Switch, withRouter } from 'react-router-dom'
import { RouteConnectedDrawer } from '../components/RouteConnectedDrawer'
import { duration } from '@material-ui/core'
import { useContext } from 'react'
import { Context as BlurContext } from '../contexts/BlurContext'
import { TeamProviderSummary } from './TeamProviderSummary'
import { CHEKHUB_END_USER_ENGAGEMENTS_CURRENT_TEAMS } from '../routing/route.constants'


export const TeamProviderDrawerUtil = withRouter(
  ({
     open,
     setOpen,
     backRoutePath,
     match,
     setDrawerOpen,
     routeTransitionDelay = duration.leavingScreen,
     teamOrProviderList,
     onAcceptance
   }) => {
    const {setBlurred} = useContext(BlurContext)
    return <Switch>
      <Route path={`${match.path}/:teamId/summary`}>
        <RouteConnectedDrawer {...{
          open,
          setOpen,
          anchor: 'right',
          title: match.path !== CHEKHUB_END_USER_ENGAGEMENTS_CURRENT_TEAMS ?
            'View Service Company Onboarding' :
            'View Team Member',
          backRoutePath
        }}>
          <TeamProviderSummary {...{
            teamOrProviderList,
            setOpen,
            open,
            backRoutePath,
            routeTransitionDelay,
            setBlurred,
            onAcceptance
          }}/>
        </RouteConnectedDrawer>
      </Route>
    </Switch>
  }
)
