import makeStyles from '@material-ui/core/styles/makeStyles'
import {Chip} from '@material-ui/core'

const useSkillsDisplayStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    '& li:first-child > div.MuiChip-root': {
      marginLeft: 0
    }
  },
  chip: {
    margin: theme.spacing(0.25)
  }
}))

export const SkillsDisplay = ({
  skills,
  ...restProps
}) => {
  const styles = useSkillsDisplayStyles()
  return <ul className={styles.root}>
    {
      skills.map((skill, key) => <li {...{
        key
      }}>
        <Chip {...{
          className: styles.chip,
          label: skill,
          ...restProps
        }} />
      </li>)

    }
  </ul>
}
