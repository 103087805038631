import {Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import {generatePath, Link} from 'react-router-dom'
import {useState} from 'react'
import Typography from '@material-ui/core/Typography'
import {ServiceCompanyMemberActions} from './ServiceCompanyMemberActions'
import {VeteranStatusDisplay} from './VeteranStatusDisplay'
import {EmailLink} from './EmailLink'
import {makeStyles} from '@material-ui/core/styles'
import {TECH_VIEW_PATH} from '../routing/route.constants'
import PhoneNumbersDisplay from './PhoneNumbersDisplay'
import {SkillsDisplay} from './SkillsDisplay'

const useServiceCompanyMemberRowStyles = makeStyles(theme => ({
  emailLink: {
    maxWidth: theme.spacing(26),
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  techLink: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))

const ServiceCompanyMemberRow = ({
  member,
  onRemove,
  disableRemoval,
  tableStyles,
}) => {
  const styles = useServiceCompanyMemberRowStyles()
  const [open, setOpen] = useState(false)
  const {
    _id,
    first_name,
    last_name,
    email,
    hour_rate,
    location,
    service_radius,
    skills,
    phones,
    veteran_status
  } = member

  const techViewPath = generatePath(
    TECH_VIEW_PATH,
    {userId: _id}
  )

  return member && <>
    <TableRow key={_id} hover>
      <TableCell align={'center'}>
        <IconButton size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
        </IconButton>
      </TableCell>
      <TableCell>
        <Typography variant={'span'}>
        <Link to={techViewPath} className={styles.techLink}>{first_name}</Link>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'span'}>
        <Link to={techViewPath} className={styles.techLink}>{last_name}</Link>
        </Typography>
      </TableCell>
      <TableCell>
        <EmailLink {...{
          email,
          className: styles.emailLink
        }} />
      </TableCell>
      <TableCell>
        <Typography variant={'span'}>
        {hour_rate}
        </Typography>
      </TableCell>
      <TableCell>
        <ServiceCompanyMemberActions {...{
          member,
          disableRemoval,
          onRemove,
          size: 'small'
        }} />
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell className={tableStyles.collapseCell} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Address</TableCell>
                  <TableCell>Service Radius</TableCell>
                  <TableCell>Skills</TableCell>
                  <TableCell>Phone Numbers</TableCell>
                  <TableCell>Veteran</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={_id}>
                  <TableCell component="th" scope="row">
                    {location?.formatted_address}
                  </TableCell>
                  <TableCell>{service_radius}</TableCell>
                  <TableCell>
                    <SkillsDisplay {...{
                      skills,
                      size: 'small'
                    }} />
                  </TableCell>
                  <TableCell>
                    <PhoneNumbersDisplay {...{
                           phones,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <VeteranStatusDisplay {...{veteran_status}} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </>
}

export default ServiceCompanyMemberRow
