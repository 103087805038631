export const STEPPER_SET_ACTIVE_STEP = 'STEPPER_SET_ACTIVE_STEP'
export const STEPPER_RESET_STATE = 'STEPPER_RESET_STATE'
export const SET_STEPPER_TECHS_FLOW = 'SET_STEPPER_TECHS_FLOW'
export const SET_STEPPER_SC_FLOW = 'SET_STEPPER_SC_FLOW'
export const STEPPER_SET_COMPLETED_STEP = 'STEPPER_SET_COMPLETED_STEP'

export const setActiveStep = step => ({
  type: STEPPER_SET_ACTIVE_STEP,
  activeStep: step
})
export const setCompletedStep = step => ({
  type: STEPPER_SET_COMPLETED_STEP,
  completedStep: step
})

export const stepperResetState = () => ({
  type: STEPPER_RESET_STATE
})

export const setStepperTechsFlow = () => ({
  type: SET_STEPPER_TECHS_FLOW
})

export const setStepperSCFlow = () => ({
  type: SET_STEPPER_SC_FLOW
})

