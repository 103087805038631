import makeStyles from '@material-ui/core/styles/makeStyles'
import {InlineIcon} from '@iconify/react'
import {CreditCardSharp} from '@material-ui/icons'
import americanExpress from '@iconify-icons/cib/american-express'
import ccDiscover from '@iconify-icons/cib/cc-discover'
import ccVisa from '@iconify-icons/cib/cc-visa'
import ccMastercard from '@iconify-icons/cib/cc-mastercard'

export const AMERICAN_EXPRESS = 'American Express'
export const DISCOVER = 'Discover'
export const MASTER_CARD = 'MasterCard'
export const VISA = 'Visa'
const CARD_BRAND_ICONS = {
  [AMERICAN_EXPRESS]: americanExpress,
  [DISCOVER]: ccDiscover,
  [VISA]: ccVisa,
  [MASTER_CARD]: ccMastercard,
  default: CreditCardSharp
}

export const getCardBrandIcon = brand => CARD_BRAND_ICONS[brand]

const useCardBrandIconStyles = makeStyles(theme => ({
  root: {
    verticalAlign: '-0.125em'
  }
}))
export const CardBrandIcon = ({brand, ...restProps}) => {
  const styles = useCardBrandIconStyles()
  const cardBrandIconComponent = getCardBrandIcon(brand)
  const className = `${restProps.className ? restProps.className : ''} ${styles.root}`
  return cardBrandIconComponent ?
    <InlineIcon {...{
      icon: cardBrandIconComponent,
      ...restProps,
      className
    }} /> :
    <CreditCardSharp {...{
      fontSize: restProps.fontSize || 'large',
      ...restProps,
      className
    }} />
}
