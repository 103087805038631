import appConfig from "../config"
import { Headers, Methods } from "http-headers-js"
import generateAuthHeader from "./generate-auth-header.util"
import useAccessToken from '../store/actions/useAccessToken'


const { chekhub: { baseURI, defaultHeaders, operations } } = appConfig
const { ticketFetchMany, ticketFetchOne } = operations
const { path, method, authRequired } = ticketFetchMany
const serviceBaseUri = `${baseURI}${path}`

export const fetchTicketList = async ({orgId}) => await fetch(
  `${serviceBaseUri}/?chekhub_org_id=${orgId}`,
  {
    method: Methods.GET,
    headers: {
      ...defaultHeaders,
      authorization: `Bearer ${await useAccessToken()}`
    },
  }
)
