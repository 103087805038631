import React from 'react'

import { useSelector } from 'react-redux'

import { Grid } from '@material-ui/core'

import ProfileListComponent from '../components/profile-list'

const TechsList = () => {
  const auth = useSelector((state) => state.auth)
  const { user, activeOrg } = auth

  const { organizations } = user
  const orgId = activeOrg || organizations.find((org) => org.organization_id)?.organization_id

  return (<Grid container item xs={12} direction="row">
    <Grid item xs={12} sm={12} md={12}>
      <div>
        <ProfileListComponent userType='tech' orgId={orgId} backgroundRunning={auth.backgroundRunning} />
      </div>
    </Grid>
  </Grid>)
}

export default TechsList
