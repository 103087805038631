import { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ServiceCompany from '../components/ServiceCompany'
import useAsyncEffect from 'use-async-effect'
import {
  createServiceCompany,
  fetchServiceCompany,
  resetServiceCompanyState, serviceCompanySetState,
  updateServiceCompany
} from '../store/actions/service-company.actions'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import toastShow, {TOAST_TYPE_STANDARD} from '../store/actions/toast-show'
import {
  SERVICE_COMPANY_MANAGE_MEMBERS,
  SIGN_UP_COMPLETE_PROFILE_PATH,
  SIGN_UP_SC_CREATE_PATH
} from '../routing/route.constants'
import useFormStyles from '../styles/formStyles'
import useEditStyles from '../components/ServiceCompanyViewStyles'
import {fetchSignedInUser, fetchUserListForOrg, userListResetState} from '../store/actions/users.actions'
import useAccessToken from '../store/actions/useAccessToken'
import {Grid} from '@material-ui/core'
import {ServiceCompanyMembers} from '../components/ServiceCompanyMembers'
import {
  handleServiceCompanyAddressChange,
  handleServiceCompanyChange, handleServiceCompanyFormationYearChange,
  handleServiceCompanyProfileChange
} from './ServiceCompanyView.utils'
import {replaceItemInList} from "../util/lists.util"
import {onInsurUploadChange} from "./TechProfile.util"
import {onUpdateInsurancePolicySC} from "../components/Insurance.util"
import {useAuthUser} from '../hooks/useAuthUser'
import { getBackgroundCheckPackages } from '../store/actions/background-check.actions'
import { getIndustriesList, setServiceCompanyIndustriesState } from '../store/actions/industries.actions'
import { getUserOrg } from '../util/users.util'
import { getLandingPath } from '../util/landingPath.util'

const ServiceCompanyView = () => {
  const formStyles = useFormStyles()
  const editStyles = useEditStyles()
  const user = useAuthUser()
  const {
    serviceCompany: profile,
    userList: members,
    industries: industriesList
  } = useSelector(
    ({
      serviceCompany,
      userList,
      industries,
    }) => ({
      serviceCompany,
      userList,
      industries
    })
  )
  const phoneNumbers =  profile?.phones || []
  const insurance_policies = profile?.insurance_policies || []
  const industries = industriesList?.industries?.industries
  const scIndustries = profile?.industries?.industries
  const [validState, setValid] = useState(true)
  const [allowPhones, setAllowPhones] = useState(true)
  const [allowCerts, setAllowCerts] = useState(true)
  const [allowInsurance, setAllowInsurance] = useState(true)
  const [allowWorkExperience, setAllowWorkExperience] = useState(true)
  const [allowWorkingHours, setAllowWorkingHours] = useState(true)
  const [allowPrimaryContactPhones, setAllowPrimaryContactPhones] = useState(true)
  const {orgId} = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const isSignUpFlow = history.location.pathname === SIGN_UP_SC_CREATE_PATH
  console.log(profile)
  useAsyncEffect(
    async () => {
      orgId && dispatch(fetchServiceCompany(orgId))
      orgId && dispatch(fetchUserListForOrg(orgId))
    },
    async () => {
      await dispatch(resetServiceCompanyState())
      await dispatch(userListResetState())
    },
    []
  )
  useEffect(
    () => {
      (!industries || !industries.length) && dispatch(getIndustriesList())
    },
    [industries]
  )

  const handleChange = handleServiceCompanyChange(setValid, dispatch)
  const handleProfileChange = handleServiceCompanyProfileChange(profile, handleChange)
  const handleAddressChange = handleServiceCompanyAddressChange(profile, handleChange)
  const handleFormationYearChange = handleServiceCompanyFormationYearChange(profile, handleChange)

  const handleSaveChanges = async e => {
    e.preventDefault()
    // TODO: Replace validState w/ Formik form validation pattern

    if (!validState) {
      return dispatch(
        toastShow(TOAST_TYPE_STANDARD,
          'Validation failed. Please fill all fields marked with an *'
        )
      )
    }
    const scError = await dispatch(
      orgId ?
        updateServiceCompany(profile) :
        createServiceCompany(profile)
    )
    if (scError?.constructor === Error) {
      return
    }

    await useAccessToken(true)
    await dispatch(fetchSignedInUser())

    const shouldRouteToProfileComplete = history.location.pathname === SIGN_UP_SC_CREATE_PATH
    shouldRouteToProfileComplete &&
    history.push(SIGN_UP_COMPLETE_PROFILE_PATH)
  }
  const onPhoneNumberAdd = (info) => {
    const newPhones = [...profile?.phones, {
      label: info.label,
      raw_phone: "+" + info.raw_phone,
      country: 'us'
    }]
    updatePhoneNumbers(newPhones)
  }

  const onPhoneNumberRemove = (index) => {
    const newPhones = [...phoneNumbers].filter((p, i) => i !== index)
    updatePhoneNumbers(newPhones)
  }

  const onPhoneNumberUpdate = (index, info) => {
    const currentPhones = profile.phones
    const newPhones = replaceItemInList(info, currentPhones, index)
    updatePhoneNumbers(newPhones)
  }

  const updatePhoneNumbers = (newPhones) => {
    dispatch(serviceCompanySetState({
      ...profile,
      phones: newPhones
    }))
  }
  const handleIndustriesClose = (industriesArr) => {
    dispatch(setServiceCompanyIndustriesState({
      industries: industriesArr
    }))
  }

  return ((orgId && profile._id) || (!orgId && profile)) &&
    <Grid container spacing={1} justify={isSignUpFlow ? 'center' : null}>
      <Grid item xs={8}>
        <ServiceCompany {...{
          profile,
          user,
          handleSaveChanges, // TODO: get rid of the save button. It's just so 2010.
          validState,
          handleAddressChange,
          handleProfileChange,
          handleFormationYearChange,
          editStyles,
          formStyles,
          onPhoneNumberAdd,
          onPhoneNumberRemove,
          onPhoneNumberUpdate,
          updatePhoneNumbers,
          phoneNumbers,
          onUpdateInsurancePolicySC: onUpdateInsurancePolicySC(profile, dispatch),
          onInsurUploadChange: onInsurUploadChange(dispatch),
          insurance_policies,
          setValid,
          disablePrimaryContactEdit: true,
          allowPhones,
          setAllowPhones,
          allowCerts,
          setAllowCerts,
          allowInsurance,
          setAllowInsurance,
          allowWorkExperience,
          setAllowWorkExperience,
          allowWorkingHours,
          setAllowWorkingHours,
          allowPrimaryContactPhones,
          setAllowPrimaryContactPhones,
          handleIndustriesClose,
          industries,
          scIndustries
        }} />
      </Grid>
      {
        !isSignUpFlow && <Grid
          item
          xs={4}
          justify='center'
        >
          <ServiceCompanyMembers {...{
            editStyles,
            members,
            serviceCompany: profile
          }} />
        </Grid>
      }
    </Grid>
}

export default ServiceCompanyView
