import noop from '../util/noop'
import { Elements } from '@stripe/react-stripe-js'
import appConfig from '../config'
import { loadStripe } from '@stripe/stripe-js'
import { PaymentBankAccountForm } from './PaymentBankAccountForm'
import { useEffect } from 'react'

export const PaymentBankAccount = ({
  onSubmit = noop,
  onCancel = noop
}) => {
  const {stripeKey} = appConfig
  const stripePromise = loadStripe(stripeKey)
  return <Elements stripe={stripePromise}>
    <PaymentBankAccountForm {...{
      onSubmit,
      onCancel
    }} />
  </Elements>
}
