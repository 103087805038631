import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {

  },
  textField: {
    width: '16em',
    marginRight: theme.spacing(2),
  },
  smallTextField: {
    width: '10em',
    marginRight: theme.spacing(2),
  },
  largeTextField: {
    width: '20em',
    marginRight: theme.spacing(2),
  },
  dateField: {
    width: '12em',
    marginRight: theme.spacing(2),
  },
  select: {
    width: '10em',
    marginRight: '1em',
  },
  buttonProgress: {
    color: '#606060',
  },
  row: {
    flexDirection: 'row',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

}))
