import { InputAdornment, TextField, Toolbar } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import useTableStyles from './ServiceCompanyMembersTableStyles'

export const FuzzySearchBar = ({
   setQuery,
   query,
   label,
}) => {
  const tableStyles = useTableStyles()
  const handleSearchChange = e => setQuery(e.target.value)

  return <Toolbar className={tableStyles.toolbarTable}>
    <TextField {... {
      label: label,
      InputProps: {
        startAdornment: (<InputAdornment position="start">
          <Search/>
        </InputAdornment>)
      },
      className: tableStyles.searchInput,
      onChange: handleSearchChange,
      value: query,
      variant: 'outlined',
      size: 'small',
    }}/>
  </Toolbar>
}
