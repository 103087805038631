export const TRIGGER_TOAST_NOTIFICATION = 'TOAST/TRIGGER-NOTIFICATION'
export const TOAST_TYPE_STANDARD = 'standard'
export const TOAST_TYPE_ERROR = 'error'
export const TOAST_TYPE_SUCCESS = 'success'

const toastShow = (type, message) => (dispatch) =>
  dispatch({
    type: TRIGGER_TOAST_NOTIFICATION,
    payload: { type, message },
  })

export default toastShow
