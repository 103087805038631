import makeStyles from '@material-ui/core/styles/makeStyles'
import noop from '../util/noop'
import appConfig from '../config'
import usePlacesAutocomplete, {getDetails} from 'use-places-autocomplete'
import {useEffect, useState} from 'react'
import parse from 'autosuggest-highlight/parse'
import Grid from '@material-ui/core/Grid'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Typography from '@material-ui/core/Typography'
import Script from 'react-load-script'
import {Autocomplete} from '@material-ui/lab'
import {TextField} from '@material-ui/core'

const usePlacesAutoCompleteStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
}))

export const PlacesAutoComplete = ({
  addressDetails = {},
  onChange = noop,
  disabled = false,
  onBlur = noop,
  id = 'places-auto-complete',
  name = 'places-auto-complete',
  variant = 'outlined',
  label = 'Enter Address',
  helperText = null,
  error = false,
  required = false,
  ...restProps
}) => {
  const styles = usePlacesAutoCompleteStyles()
  const {googlePlaces: {key}} = appConfig
  const {
    ready,
    suggestions: {data},
    value: inputValue,
    setValue: setInputValue,
    init
  } = usePlacesAutocomplete({
    debounce: 300,
    initOnMount: false,
    defaultValue: addressDetails?.formatted_address || ''
  })
  const [value, setValue] = useState(addressDetails)
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const handleScriptLoaded = () => setScriptLoaded(true)

  useEffect(() => {
    !addressDetails.formatted_address && setValue({})
    !addressDetails.formatted_address && setInputValue('')
  }, [])
  useEffect(() => {
    setInputValue(addressDetails.formatted_address || '')
  }, [addressDetails.formatted_address])

  useEffect(() => {
    if (scriptLoaded) {
      init()
    }
  }, [scriptLoaded])

  const onInputChange = ev => {
    // Holy crap MUI is 💩! Why are you sending null events to change handlers?!?
    ev !== null && setInputValue(ev?.target?.value)
  }

  const handleOnBlur = ev => onBlur(ev, value)

  const handleSelect = async (ev, newValue) => {
    if (!newValue) {
      setValue({})
      setInputValue('')
      return onChange(ev)
    }
    const {
      place_id: placeId
    } = newValue || {}
    const {
      address_components: addressComponents,
      formatted_address,
      geometry
    } = await getDetails({
      placeId
    })
    const newAddressDetails = {
      addressComponents,
      lat: geometry.location.lat(),
      long: geometry.location.lng(),
      formatted_address
    }
    setValue(newAddressDetails)
    setInputValue(formatted_address)
    // We have to mutate the target to carry along the id and name of the input for validations
    ev.target = {
      ...ev.target,
      id,
      name
    }
    onChange(ev, newAddressDetails)
  }

  const renderOption = option => {
    const matches = option.structured_formatting.main_text_matched_substrings
    const parts = parse(
      option.structured_formatting.main_text,
      matches.map((match) => [match.offset, match.offset + match.length])
    )

    return (
      <Grid container alignItems="center">
        <Grid item>
          <LocationOnIcon className={styles.icon}/>
        </Grid>
        <Grid item xs>
          {parts.map((part, index) => (
            <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                  {part.text}
                </span>
          ))}

          <Typography variant="body2" color="textSecondary">
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return <>
    {
      !scriptLoaded && <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&language=en&region=US`}
        onLoad={handleScriptLoaded}
      />
    }
    <Autocomplete {...{
      id,
      options: data,
      getOptionLabel: (option) => option?.description || '',
      value: value?.formatted_address,
      includeInputInList: true,
      onChange: handleSelect,
      onInputChange,
      inputValue,
      disabled: !ready,
      renderInput: params => <TextField {...{
        ...params,
        onBlur: handleOnBlur,
        error,
        helperText,
        label,
        required,
        variant,
        name
      }} />,
      renderOption,
      ...restProps
    }} />
  </>
}
