import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  validateServiceCompanyRequired,
  validateTechRequired,
} from '../components/shared-functions/validate-required-fields'
import UserUploads from '../components/UserUploads'
import SaveCreateBtn from '../components/SaveCreateBtn'
import actions from '../store/actions'
import {ADMIN_USER_ROLE, SC_USER_TYPE, TECH_USER_TYPE} from '../constants/user.constants'

const ProfileEdit = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const user = useSelector((state) => state.auth.user)
  const [profileIsNew, setProfileIsNew] = useState(true)
  const [validState, setValid] = useState(false)
  const profileType = auth.userRole === ADMIN_USER_ROLE ? SC_USER_TYPE : auth.userRole

  const profileSelector = useSelector((state) => {
    const { profile: { data } } = state

    if (profileType === TECH_USER_TYPE) {
      return {
        ...data,
        phones: user.phones,
      }
    }

    return {
      ...data,
      primary_contact_id: data.primary_contact_id || user.id,
      primary_contact: data.primary_contact || user.id,
    }
  })

  const [profile, setProfile] = useState(profileSelector)

  useEffect(() => {
    setProfile(profileSelector)
  }, [
    profileSelector.insurance_policies,
    profileSelector.background_check,
    profileSelector.certifications,
  ])

  useEffect(() => {
    if (profileType === 'tech') {
      const { _id: profileId } = profile
      setProfileIsNew(!profileId)
    } else {
      const { organizations } = user
      setProfileIsNew(!organizations || !organizations.find((org) => org.organization_id))
    }
  }, [user, profile])

  const handleChange = (profileValue) => {
    if (auth.userRole === 'tech') {
      setValid(validateTechRequired(profileValue))
    } else {
      setValid(validateServiceCompanyRequired(profileValue))
    }

    setProfile(profileValue)
  }

  const handleSaveChanges = (e) => {
    e.preventDefault()
    // Remove empty optional values
    // eslint-disable-next-line prefer-const
    let completeProfile = profile
    if (!profile.website) delete completeProfile.website
    if (!profile.ein) delete completeProfile.ein
    if (!profile.company_formation_year) delete completeProfile.company_formation_year

    if (validState) {
      dispatch(actions['PROFILE/UPSERT'](auth.userRole, completeProfile, user))
    } else {
      dispatch(actions['TOAST/Show'](
        'standard', 'Validation failed. Please fill all fields marked with an *',
      ))
    }
  }

  return [
  <SaveCreateBtn {...{
      handleSaveChanges,
      auth,
      profile,
      handleChange,
      user,
      profileIsNew,
    }}/>,
    <UserUploads {...{
      auth,
      profileIsNew,
      profile,
      profileType,
      user,
    }}/>
    ]
}

export default ProfileEdit
