import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { FormSubmitButton } from '../../../FormSubmitButton'
import React, { useState } from 'react'
import { CheckBox, CheckSharp, CloseSharp } from '@material-ui/icons'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import { TeamProviderDrawerScUtil } from '../../../TeamProviderDrawerScUtil'
import { TeamProviderDrawerUtil } from '../../../../pages/TeamProviderDrawerUtil'
import { teamOrProvider } from './mock-data/service-company-mock'
import { InsurancePolicy } from '../../prahlad/Insurance'
import { OnboardingDocsExample } from './OnboardingDocsExample'
import { CloudDownloadLink } from '../../../CloudDownloadLink'
import { FieldDisplay } from '../../../FieldDisplay'


export const MockOnboardingProcess = () => {
  const [open, setOpen] = useState(false)

  const handleInvitePreset = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getChance = () => {
    return Math.floor(Math.random() * 2)
  }

  return <>
    <FormSubmitButton onClick={handleInvitePreset}>
    Invite Team
    </FormSubmitButton>
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Required Documents</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select preset required documents or set them in your Required Documents Tab (this will be a link)
        </DialogContentText>
        <FormControl>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox />}
              label="Preset 1"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox />}
              label="Preset 2"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox />}
              label="Preset 3"
            />
          </Grid>
        </Grid>
        </FormControl>
        <FieldDisplay label={'Add more documents'} />
        <TextField
          variant={'outlined'}
          size={'small'}
          fullWidth
          placeholder={'Name of Document'}
          style={{
            paddingTop: '10px',
            paddingBottom: '15px'
          }}
        />
        <FormSubmitButton>
          Upload Policy
        </FormSubmitButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClose} color="primary">
          Send Invite
        </Button>
      </DialogActions>
    </Dialog>
    <Card variant={'outlined'}  >
      <Typography variant={'h5'}
                  style={{
                    paddingLeft: '10px',
                    paddingTop: '10px'
                  }}>
        My Current Invites
      </Typography>
      <TableContainer component={Box} style={{
        paddingTop: '10px'
      }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>{'Service Provider Name'}</TableCell>
              <TableCell>{'Required Documents Uploaded'}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {teamOrProvider.map((row) => (
              <TableRow key={row.company_name || row.full_name}>
                <TableCell component="th" scope="row">
                  {row.company_name}
                </TableCell>
                <TableCell>
                  {getChance() === 0 ? <CloseSharp /> : <CheckSharp />}
                </TableCell>
                <TableCell align="right">
                  <Link to={``}>
                    View Provider
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
    <OnboardingDocsExample />
    </>
}
