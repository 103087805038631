import React, {useContext, useEffect, useRef} from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import useStyles from './AppHeaderStyles'
import banner from '../assets/images/Chekhub_Connect_White.svg'
import HeaderContent from './HeaderContent'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import {DisableableLink} from './DisableableLink'
import MainNavBar from "./MainNavBar"
import StepperUp from "./Stepper"
import {getUserOrg} from "../util/users.util"
import {getLandingPath} from '../util/landingPath.util'
import { AppBar, Box } from "@material-ui/core"
import {Context} from "./DimensionsContext"
import { useAuthUser } from '../hooks/useAuthUser'
import { useLocalStorage } from '../util/localStorageHack.util'
import { LANDING_PATH, SIGN_IN_PATH, SIGN_UP_PATH_INDY, SIGN_UP_SELECT_USER_TYPE } from '../routing/route.constants'
import { fetchChekhubOrgList } from '../store/actions/service-company.actions'

const AppHeader = () => {
  const auth = useSelector((state) => state.auth)
  const styles = useStyles()
  const {pathname} = useLocation()
  const history = useHistory()
  const enableNavigation = !(
    pathname.match('/sign-in') ||
    pathname.match('/sign-up') ||
    pathname.match('/sign-up-tech')
  )

  const showHeader = pathname.match(SIGN_IN_PATH) || history.location.pathname === LANDING_PATH
  const orgId = localStorage.getItem("orgId")?.replace(/['"]+/g, '') || useParams()
  const authUser = useAuthUser()
  const dispatch = useDispatch()
  const userDefaultOrg = getUserOrg(authUser)
  const header = useContext(Context).header
  const headerRef = useRef(null)
  const {setHeaderDimensions}= header
  const chekhubList = useSelector(({chekhubList}) => chekhubList)
  const activeOrgObject = chekhubList?.memberships?.find(org => org.organization._id === orgId)
  const headerDimensions = headerRef?.current?.getBoundingClientRect()
  const headerHeight = headerDimensions?.height
  useEffect(() =>
    setHeaderDimensions(headerRef?.current?.getBoundingClientRect() || {}),
    [headerRef.current, headerHeight]
  )

  return !showHeader && <AppBar position={'sticky'} ref={headerRef}><Grid
    container
    {...{
      spacing: 0,
      direction: 'row',
      justify: 'center',
      alignItems: 'center',
      className: styles.mainWrapper,
      elevation: 3
    }} >
    <Grid container {...{
      spacing: 2,
      direction: 'row',
      justify: 'center',
      alignItems: 'center',
      className: styles.limitWrapper
    }}>
      <Grid
        item
        xs={enableNavigation ? 6 : 12} style={{paddingLeft: 0}}>
          <DisableableLink {...{
            to: enableNavigation &&
              getLandingPath(auth?.user, userDefaultOrg, activeOrgObject),
            disabled: !enableNavigation  ||
              pathname.match('/select-org')
          }}>
            <img className={styles.bannerImg} src={banner} alt="checkhub-logo"/>
          </DisableableLink>
      </Grid>
      {
        enableNavigation &&
          <Grid
            item
            xs={6} >
            <HeaderContent />
          </Grid>
      }
    </Grid>
    {enableNavigation ? <MainNavBar /> :
      history.location.pathname !== SIGN_IN_PATH &&
      history.location.pathname !== SIGN_UP_SELECT_USER_TYPE ? <StepperUp /> : <Box/>}
  </Grid>
  </AppBar>
}
export default AppHeader
