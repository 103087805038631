import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1.25)
  },
  buttons: {
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  labels: {
      fontSize: theme.spacing(2),
  },
  largeIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}))

