import React from 'react'
import {
  Button,
} from '@material-ui/core'
import useFormStyles from '../styles/formStyles'
import BackgroundCheck from '../components/profile-BackgroundCheck'
import CertificateList from '../components/profile-CertificateList'
import InsurancePolicyList from '../components/profile-InsurancePolicyList'
import WorkHistoryList from '../components/profile-WorkHistoryList'

const UserUploads = ({
    auth,
    profileIsNew,
    profile,
    profileType,
    user,
}) => {
    const formStyles = useFormStyles()
    // TODO rip out all of these conditionals around 'tech' userRole
    //   since it's only ever going to be used in a tech profile from here
    //   on out, it's redundant.
    return (
        <div>
        {auth.userRole === 'tech' ? <div className={formStyles.row}>
        <WorkHistoryList data={profile} disabled={profileIsNew} user={user} />
        </div> : ('')}
        {auth.userRole === 'tech' ? <div className={formStyles.row}>
        <CertificateList data={profile} disabled={profileIsNew} />
        </div> : ('')}
        <div className={formStyles.row}>
        <InsurancePolicyList data={profile} profileType={profileType} disabled={profileIsNew}  />
        </div>
        {auth.userRole === 'tech' ? <div className={formStyles.row}>
        <BackgroundCheck data={profile} disabled={profileIsNew} />
        </div> : ('')}

        </div>
        )
}

export default UserUploads
