import {useState} from 'react'
import {VeteranStatus} from '../../../VeteranStatus'
import {useDispatch} from 'react-redux'
import {uploadFile} from '../../../../store/actions/storage.actions'

const MOCK_DD214_URL = 'https://www.dhrm.virginia.gov/docs/default-source/veteran-related/dd214-sample.pdf'

export const VeteranStatusExample = () => {
  const [status, setStatus] = useState(false)
  const [dd214Url, setDD214Url] = useState('')
  const dispatch = useDispatch()

  const onChangeStatus = ev => setStatus(ev.target.checked)
  const onRemoveDD214Url = () => setDD214Url()

  const onChangeDD214Url = async files => {
    // Do upload here, can use an async/await function
    const result = await dispatch(uploadFile(files[0]))
    setDD214Url(MOCK_DD214_URL)
  }

  return <>
    <VeteranStatus {...{
      status,
      dd214_url: dd214Url,
      onChangeStatus,
      onChangeDD214Url,
      onRemoveDD214Url
    }} />
  </>
}
