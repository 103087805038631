import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  span: {
    fontSize: theme.spacing(10),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2.5),
    color: theme.palette.primary.main
  },
  img: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  contentContainer: {
    padding: theme.spacing(3)
  },
  contentHolder: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3)
  },
  connect: {
    color: '#77C043'
  }

}))
