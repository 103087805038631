import { dispatchService } from "./dispatch-service.util"
import { createTicketPay, getAllPaidTickets, payTicketForChekhubEndUser } from '../../services/ticket.service'
import { setPaidTicketList } from './ticketsList-actions'

export const SET_ONE_TICKET = 'SET_ONE_TICKET'
export const RESET_ONE_TICKET = 'RESET_ONE_TICKET'

export const setOneTicket = ticket => ({
  type: SET_ONE_TICKET,
  ticket
})

export const resetBackgroundCheckState = () => ({
  type: RESET_ONE_TICKET
})


export const createTicketPayPackage = (ticketObj) => async dispatch =>
  await dispatchService(dispatch)(createTicketPay)(ticketObj)

export const payForATicketWithEndUserData = (chekhub_org_id, ticket_id) => async dispatch =>
  await dispatchService(dispatch)(payTicketForChekhubEndUser)(chekhub_org_id, ticket_id)

export const fetchAllPaidTicket = ({chekhub_org_id}) => async dispatch =>
  await dispatchService(dispatch)(getAllPaidTickets, setPaidTicketList)(chekhub_org_id)
