import noop from '../util/noop'
import {removeItemByIndex, replaceItemInList} from '../util/lists.util'
import {getISOFormattedDateString} from '../util/date-time.util'
import {getDateFromPickerString} from '../pages/TechProfile.util'

const DATE_FIELD_KEYS = [
  'issued_on',
  'start_date',
  'end_date',
  'expires_on'
]

const onFieldChange = (target, cert, setState) => {
  const {
    id: fieldName,
    value
  } = target
  const newCert = {
    ...cert,
    [fieldName]: DATE_FIELD_KEYS.includes(fieldName) ?
      getISOFormattedDateString(getDateFromPickerString(value)) : value
  }
  setState(newCert)
}

export const certStateUtils = (
  [certs = [], setCerts = noop],
  [editCert, setEditCert = noop],
  [addCert, setAddCert = noop],
  [removeCert, setRemoveCert = noop],
  showAllowButtonOrNot = noop
) => ({
  certs,
  editCert,
  addCert,
  removeCert,
  setAddCert,
  setEditCert,
  handleEdit: (cert, index) => () => {
    showAllowButtonOrNot(false)
    return setEditCert({
      ...cert,
      index
    })
  },
  handleEditSubmit: cert => () => {
    const newCerts = replaceItemInList(cert, certs, cert.index)
    setCerts(newCerts)
    setEditCert()
    showAllowButtonOrNot(true)
  },
  clearEdit: () => {
    showAllowButtonOrNot(true)
    setEditCert()
  },
  onEditChange: ev => {
    ev.preventDefault()
    onFieldChange(ev.target, editCert, setEditCert)
  },
  handleAdd: () => {
    showAllowButtonOrNot(false)
    return setAddCert({})
  },
  clearAdd: () => {
    showAllowButtonOrNot(true)
    return setAddCert()
  },
  handleAddSubmit: cert => () => {
    setCerts([
      ...certs,
      {...cert}
    ])
    setAddCert()
    showAllowButtonOrNot(true)
  },
  onAddChange: ev => {
    ev.preventDefault()
    onFieldChange(ev.target, addCert, setAddCert)
  },
  handleRemove: (cert, index) => () => {
    setRemoveCert({...cert, index})
  },
  clearRemove: () => setRemoveCert(),
  handleRemoveConfirm: remCert => () => {
    setCerts(removeItemByIndex(certs, remCert.index))
    setRemoveCert()
  },
  onUploadSave: (cert, files) => (
    addCert ?
      setAddCert : setEditCert
  )({
    ...cert,
    filename: files[0].name
  }),
  onRemoveUpload: cert => {
    const newCert = {...cert}
    delete newCert.filename

    return (
      addCert ?
      setAddCert : setEditCert
    )(newCert)
  }
})
