import {Box, Button, FormHelperText, Link} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import noop from '../util/noop'
import {InlineCopyrightIcon} from './InlineCopyrightIcon'

export const BackgroundCheckDisplay = ({
  backgroundCheck,
  handleReset = noop
}) => <Box>
  <Typography>
    Payment : {!backgroundCheck.independent_verification ? 'Declined' : 'Succeeded'}
  </Typography>
  {!backgroundCheck.independent_verification &&
  <Button onClick={handleReset} color={'primary'}>Try again</Button>
  }
  {backgroundCheck.independent_verification &&
  <Typography>
    Status: {backgroundCheck?.independent_verification.report_status}
  </Typography>
  }
  {backgroundCheck.independent_verification &&
  <Typography>
    Result: {backgroundCheck?.independent_verification.report_result}
  </Typography>
  }
  {
    backgroundCheck.independent_verification &&
    backgroundCheck.independent_verification.invitation_status !== 'complete' &&
    <Typography>
      Check your email or use this <Link
      target={'_blank'}
      href={backgroundCheck?.independent_verification?.invitation_link}>
      link
    </Link> to start your data collection
    </Typography>
  }
  <FormHelperText>Powered By Checkr<InlineCopyrightIcon/></FormHelperText>
</Box>
