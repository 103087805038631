import { useState } from 'react'
import { Divider, List, ListItem, makeStyles, Typography } from '@material-ui/core'
import { PaymentSource } from './PaymentSource'
import { AccountBalanceSharp } from '@material-ui/icons'
import { CardBrandIcon } from './CardBrandIcon'
import { ConfirmationModal } from './ConfirmationModal'
import noop, { noopThunk } from '../util/noop'
import { VerifyAccount } from './VerifyAccount'
import { verifyBankAccount } from '../store/actions/wallet.actions'
import { isServiceErrorResult } from '../services/is-service-error-result.util'
import toastShow, { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '../store/actions/toast-show'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { SOURCE_TYPE_BANK_ACCOUNT } from './PaymentSources'

const usePaymentSourcesListStyles = makeStyles(theme => ({
  listItems: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }
}))

const paymentSourcesUtils = (
  sources = [],
  [
    removeSource, setRemoveSource = noop
  ],
  [
    verifySource, setVerifySource = noop
  ],
  onRemove = noop,
  [
    selected, setSelected = noop
  ]
) => {
  const {orgId} = useParams()
  const dispatch = useDispatch()
  return ({
    removeSource,
    verifySource,
    handleOnRemove: source => () => setRemoveSource(source),
    handleOnRemoveCancel: () => setRemoveSource(),
    handleOnRemoveConfirm: () => {
      onRemove(removeSource)
      setRemoveSource()
    },
    handleOnVerify: source => () => setVerifySource(source),
    handleOnVerifySubmit: async (amount1, amount2) => {
      const verificationResult = await dispatch(verifyBankAccount(
        orgId,
        verifySource.source_id,
        [amount1, amount2]
      ))
      const isError = isServiceErrorResult(verificationResult)
      dispatch(toastShow(
        !isError ? TOAST_TYPE_SUCCESS : TOAST_TYPE_ERROR,
        !isError ? 'Account verification successfully completed' : 'Account verification failed. Please check amounts and try again.'
      ))
      !isError && setVerifySource()
    },
    handleOnVerifyCancel: () => setVerifySource(),
    handleOnSelect: source => () => setSelected(source)
  })
}

export const PaymentSourcesList = ({
  sources = [],
  onRemove = noop,
  disableRemove = false,
  enableSelect = false,
  selected,
  setSelected = noop
}) => {
  const styles = usePaymentSourcesListStyles()
  const {
    removeSource,
    verifySource,
    handleOnRemove = noopThunk,
    handleOnRemoveCancel = noop,
    handleOnRemoveConfirm = noop,
    handleOnVerify = noopThunk,
    handleOnVerifySubmit = noop,
    handleOnVerifyCancel = noop,
    handleOnSelect = noopThunk
  } = paymentSourcesUtils(
    sources,
    useState(),
    useState(),
    onRemove,
    [selected, setSelected]
  )

  return <>
    <List {...{
      dense: true
    }}>
      {
        (!sources || !sources.length) && <Typography {...{
          variant: 'body1'
        }} >
          No payment sources found...
        </Typography>
      }
      {
        sources.map((source, key) => {
          const {
            type,
            stripe_data = {},
            status
          } = source || {}
          const {
            bank_name,
            brand,
            last4
          } = stripe_data
          const isBankAccount = type === SOURCE_TYPE_BANK_ACCOUNT
          return <>
          {
            key > 0 && <Divider/>
          }
          <ListItem {...{
            key,
            disableGutters: true,
            className: styles.listItems
          }}>
            <PaymentSource {...{
              disableRemove,
              sourceId: source.source_id,
              onRemove: handleOnRemove(source),
              sourceName: isBankAccount ? bank_name : brand,
              last4,
              typeDisplay: isBankAccount ? 'Bank Account' : 'Credit Card',
              sourceType: type,
              sourceStatus: status,
              onVerify: handleOnVerify(source),
              icon: isBankAccount ?
                <AccountBalanceSharp fontSize="large"/> :
                <CardBrandIcon {...{brand}} />,
              enableSelect,
              selected,
              handleOnSelect: handleOnSelect(source)
            }} />
          </ListItem>
          </>
        })
      }
    </List>
    {
      verifySource && <ListItem>
        <VerifyAccount {...{
          onSubmit: handleOnVerifySubmit,
          onCancel: handleOnVerifyCancel
        }} />
      </ListItem>
    }
    {
      !disableRemove && <ConfirmationModal {...{
        open: removeSource,
        onClose: handleOnRemoveCancel,
        onOk: handleOnRemoveConfirm
      }}>
        <Typography variant="body1">
          Are you sure you want to remove this payment source?
        </Typography>
        <Typography variant="body2">(Last four: {`${removeSource?.stripe_data.last4}`})</Typography>
      </ConfirmationModal>
    }
  </>
}
