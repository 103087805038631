import appConfig from "../config"
import { Headers, Methods } from "http-headers-js"
import generateAuthHeader from "./generate-auth-header.util"

const {
  defaultHeaders,
  baseURI,
  intergrationURI,
  paths: {
    serviceCompanyProfileService: path,
    connectTeamService: connectTeamPath,
    onboardingPath: onboardingTeamPath,
    onboardingRequestsPath: onboardingRequestsPath
  },
} = appConfig.chekhubConnect

const serviceBaseUri = `${baseURI}${path}`
const intergrationBaseUri = `${intergrationURI}${connectTeamPath}`
const intergrationBaseUriAdd = `${intergrationURI}${onboardingTeamPath}`
const intergrationBaseUriRequests = `${intergrationURI}${onboardingRequestsPath}`

export const postToAddToTeamPath = async (service_provider_id, chekhub_org_id, requirements) => await fetch(
    `${intergrationBaseUriRequests}`,
    {
      method: Methods.POST ,
      headers: {
        ... defaultHeaders,
        [Headers.AUTHORIZATION]: await generateAuthHeader()
      },
      body: JSON.stringify({
        chekhub_org_id: chekhub_org_id,
        service_provider_id: service_provider_id,
        requirements: requirements
      })
    }
  )

  export const deleteToAddToTeamPath = async (service_provider, chekhub_org_id) => await fetch(
  `${intergrationBaseUri}`,
  {
    method: Methods.DELETE,
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    body: JSON.stringify({
      service_provider_id: service_provider.service_provider_id,
      chekhub_org_id: chekhub_org_id
    })
  }
)


export const addDocRequirements = async (file_name, chekhub_org_id, name) => await fetch(
  `${intergrationBaseUriAdd}?chekhub_org_id=${chekhub_org_id}`,
  {
    method: Methods.POST ,
    headers: {
      ... defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    body: JSON.stringify({
      filename: file_name,
      name: name,
      chekhub_org_id: chekhub_org_id
    })
  }
)

export const uploadDocRequirements = async (upload, _id, requirement_id) => await fetch(
  `${intergrationBaseUriRequests}/${_id}/requirements/${requirement_id}`,
  {
    method: Methods.PUT ,
    headers: {
      ... defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    body: JSON.stringify({
      upload: upload,
    })
  }
)

export const uploadDocRequirementsChange = async (service_provider_id, chekhub_org_id, requirements, requirement_id) => await fetch(
  `${intergrationBaseUriRequests}/${_id}/requirements/${requirement_id}`,
  {
    method: Methods.PATCH ,
    headers: {
      ... defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    body: JSON.stringify({
      upload: upload,
    })
  }
)

export const teamAccepts = async (chekhub_org_id, onboarding_request_id) => await fetch(
  `${intergrationBaseUriRequests}/${onboarding_request_id}/acceptance`,
  {
    method: Methods.POST ,
    headers: {
      ... defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    body: JSON.stringify({
      chekhub_org_id: chekhub_org_id,
    })
  }
)


export const teamAcceptanceSc = async (service_provider_id, onboarding_request_id) => await fetch(
  `${intergrationBaseUriRequests}/${onboarding_request_id}/acceptance`,
  {
    method: Methods.POST ,
    headers: {
      ... defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    body: JSON.stringify({
      service_provider_id: service_provider_id,
    })
  }
)

export const fetchRequiredDocs = async (chekhub_org_id) => await fetch(
  `${intergrationBaseUriAdd}?chekhub_org_id=${chekhub_org_id}`,
  {
    method: Methods.GET ,
    headers: {
      ... defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
  }
)


export const fetchInvitedTeams = async (chekhub_org_id) => await fetch(
  `${intergrationBaseUriRequests}?chekhub_org_id=${chekhub_org_id}`,
  {
    method: Methods.GET ,
    headers: {
      ... defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
  }
)
export const fetchInvitedTeamsRequests = async (_id) => await fetch(
  `${intergrationBaseUriRequests}/${_id}`,
  {
    method: Methods.GET ,
    headers: {
      ... defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
  }
)

export const fetchInvitedTeamsSc = async (service_provider_id) => await fetch(
  `${intergrationBaseUriRequests}?service_provider_id=${service_provider_id}`,
  {
    method: Methods.GET ,
    headers: {
      ... defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
  }
)
 // /integrations/connect/onboarding_requests/:_id


export const fetchOneRequiredDoc = async (docId) => await fetch(
  `${intergrationBaseUriAdd}/${docId}`,
  {
    method: Methods.GET ,
    headers: {
      ... defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
  }
)

export const deleteOneRequiredDoc = async (docId) => await fetch(
  `${intergrationBaseUriAdd}/${docId}`,
  {
    method: Methods.DELETE ,
    headers: {
      ... defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
  }
)


