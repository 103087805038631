import {makeStyles} from '@material-ui/core/styles'
import {Box} from '@material-ui/core'

// TODO: This could use some more comprehensive work to support different icons
//  in different contexts (like buttons)
const useAdornmentIconStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    position: 'relative',
    lineHeight: 0,
    '& svg:first-child': {
      fontSize: '2em'
    },
    '& svg:last-child': {
      bottom: '.1em',
      position: 'absolute',
      fontSize: '.8em',
      background: theme.palette.common.white,
      right: 0,
      borderRadius: '100%'
    }
  }
}))

export const IconWithAdornment = ({
  adornment = <></>,
  adornmentIconProps = {},
  children,
  ...restProps
}) => {
  const styles = useAdornmentIconStyles()
  return <Box {...{
    ...restProps,
    className: `${restProps.className ? restProps.className : ''} ${styles.root}`
  }}>
    {children}
    {adornment}
  </Box>
}
