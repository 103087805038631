import Grid from '@material-ui/core/Grid'
import { FormSubmitButton } from './FormSubmitButton'
import SignInJumbotron from './SignInJumbotron'
import { Box, Card, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import { CHEKHUB_END_USER_SIGN_IN, SIGN_IN_PATH, SIGN_UP_PATH, SIGN_UP_PATH_INDY } from '../routing/route.constants'
import { useContext, useEffect, useState } from 'react'
import { Context as BlurContext } from '../contexts/BlurContext'


const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#77C043',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: '#77C043',
    },
  },
  checkedIcon: {
    backgroundColor: '#77C043',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#77C043',
    },
  },
  button: {
    backgroundColor: '#77C043',
    marginBottom: '24px'
  }
});

// Inspired by blueprintjs
export function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
      icon={<span className={classes.icon}/>}
      {... props}
    />
  );
}


export const LandingPage = () => {
  const styles = useStyles()
  const history = useHistory()
  const [userType, setUserType] = useState('service_company')
  const {setBlurred} = useContext(BlurContext)

  useEffect(() => {
    localStorage.clear()
    setBlurred(false)
  }, [])

  const onChangeHandler = (e) => {
    setUserType(e.target.value)
  }

  return <Grid container style={{
    minHeight: '85vh'
  }}>
    <Grid container justify={'center'}>
      <Grid item xs={12}>
        <SignInJumbotron />
      </Grid>
    </Grid>
    <Grid item xs={2}>
      <Box />
    </Grid>
      <Grid item xs={8} alignContent={'center'}>
        <Card variant={'outlined'} style={{
          textAlign: 'center',
          backgroundColor: '#E0E0E0',
          paddingTop: '35px',
        }}>
            <Grid item xs={12}>
              <Typography variant={'h4'}>
                Find Jobs Near You
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h5'}>
                Get Sourced and Increase Profits
              </Typography>
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*  <FormControl component="fieldset">*/}
            {/*    <RadioGroup defaultValue={userType} aria-label="gender" name="customized-radios" onChange={onChangeHandler}>*/}
            {/*      <FormControlLabel value="service_company" control={<StyledRadio/>}*/}
            {/*                        label="I represent a service company"/>*/}
            {/*      <FormControlLabel value="independent" control={<StyledRadio/>}*/}
            {/*                        label="I'm an independent contractor "/>*/}
            {/*    </RadioGroup>*/}
            {/*  </FormControl>*/}
            {/*</Grid>*/}
            <Grid item xs={12} style={{
              paddingTop: '15px'
            }}>
              <FormSubmitButton {... {
                className: styles.button,
                onClick:() => history.push(SIGN_IN_PATH )
              }}>
                Find Work
              </FormSubmitButton>
            </Grid>
        </Card>
      </Grid>
    <Grid item xs={2}>
      <Box />
    </Grid>
      {/*<Grid item xs={5} alignContent={'center'}>*/}
      {/*  <Card variant={'outlined'} style={{*/}
      {/*    textAlign: 'center',*/}
      {/*    backgroundColor: '#E0E0E0',*/}
      {/*    paddingTop: '15px',*/}
      {/*  }}>*/}
      {/*    <Grid container justify={'center'}>*/}
      {/*      <Grid item xs={12}>*/}
      {/*        <Typography variant={'h4'}>*/}
      {/*          Get Work Done Now*/}
      {/*        </Typography>*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={12} style={{*/}
      {/*        paddingTop: '10px',*/}
      {/*        paddingLeft: '10px',*/}
      {/*        paddingRight: '10px'*/}
      {/*      }}>*/}
      {/*        <Typography variant={'h5'}>*/}
      {/*          Find skilled workers near you and start building your team.*/}
      {/*        </Typography>*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={12}>*/}
      {/*        <FormSubmitButton {... {*/}
      {/*          className: styles.button,*/}
      {/*          style: {*/}
      {/*            marginTop: '10px'*/}
      {/*          },*/}
      {/*          onClick: () => history.push(CHEKHUB_END_USER_SIGN_IN)*/}
      {/*        }}>*/}
      {/*          Login to Source Talent*/}
      {/*        </FormSubmitButton>*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*  </Card>*/}
      {/*</Grid>*/}
  </Grid>
}
