import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

import {Slide, Snackbar} from '@material-ui/core'

import { CheckCircle, Error } from '@material-ui/icons'

import actions from '../store/actions'

const SnackbarNotification = () => {
  const dispatch = useDispatch()
  const toast = useSelector((state) => state.toast)

  const handleClose = () => toast.open && dispatch(actions['TOAST/RESET']())

  const successIcon = (<CheckCircle onClick={handleClose} style={{ size: '1em', color: 'green', cursor: 'pointer' }} />)

  const errorIcon = (<Error onClick={handleClose} style={{ size: '1em', color: 'red', cursor: 'pointer' }} />)

  const action = () => {
    switch (toast.type) {
      case 'error':
        return errorIcon
      case 'success':
        return successIcon
      default:
        return ''
    }
  }
  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  return (<Snackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    open={toast.open}
    TransitionComponent={SlideTransition}
    onClose={handleClose}
    message={toast.message}
    action={action()}
  />)
}

export default SnackbarNotification
