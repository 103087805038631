const formatAddress = (components, lines = 1) => {
  if (!components || !Object.keys(components).length) return ''
  if (!lines) return undefined
  const {
    street_number: streetNumber,
    route,
    locality,
    administrative_area_level_1: stateProvince,
    postal_code: postalCode,
  } = components
  return `${streetNumber} ${route}, ${locality}, ${stateProvince} ${postalCode}`
}

export default formatAddress
