import {HashLink} from "react-router-hash-link";
import {Box, Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const useHashLinkNavigationBarStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1),
  }
}))
export const HashLinkNavigationBar = ({
  hashLinks = []
}) => {
  const styles = useHashLinkNavigationBarStyles()
  return <Box {...{
    display: 'flex',
    justifyContent: 'space-between',
    className: styles.root
  }} >
    {
      hashLinks.map(
        link => <Button>
          <HashLink to={link.url}>
            {link.name}
          </HashLink>
        </Button>
      )
    }
  </Box>
}
