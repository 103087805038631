import noop from '../util/noop'
import {useEffect, useState} from 'react'
import {TextField} from '@material-ui/core'

export const MinMaxDateField = ({
  value,
  onChange = noop,
  minYear,
  maxYear,
  minMonth,
  maxMonth,
  minDay,
  maxDay,
  ...props
}) => {
  value = value?.split('T')[0] // Get _just_ the date w/o time
  const [internalValue, setInternalValue] = useState(value)

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  const handleChange = e => {
    setInternalValue(e.target.value)
    const [year, month, day] = e.target.value.split('-')
    const minYearOk = !minYear || year >= minYear
    const maxYearOk = !maxYear || year <= maxYear
    const minMonthOk = !minMonth || month >= minMonth
    const maxMonthOk = !maxMonth || month <= maxMonth
    const minDayOk = !minDay || day >= minDay
    const maxDayOk = !maxDay || day <= maxDay
    minYearOk && maxYearOk && minMonthOk && maxMonthOk &&
    minDayOk && maxDayOk && onChange(e)
  }

  return <TextField
    onChange={handleChange}
    value={internalValue}
    type="date"
    {...props}
  />
}
