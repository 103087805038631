import {
  Checkbox,
  Chip,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  useTheme
} from '@material-ui/core'
import { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { IndustriesDropDown } from '../../../IndustriesDropDown'


const industries = [
  'ATM Machines',
  'Audio Visual',
  'Alarm and Security',
  'Desktop/Laptop',
  'Electricians',
  'Fiber Optic Cabling',
  'Kiosk',
  'Low Voltage Cabling',
  'Merchandising Reset',
  'Networking',
  'Point of Sale',
  'Printer',
  'Server',
  'Retail Fixtures and Displays',
  'Telecom',
]

export const IndustriesDropDownExample = () => {

  const [industryName, setIndustryName] = useState([])
  function handleChange(e) {
    setIndustryName(e.target.value)
  }


  return <IndustriesDropDown {...{
    industries,
    industryName,
    handleChange,
  }}/>
}
