import {useState} from 'react'

export const hasFieldErrors = fieldErrors => Boolean(
  Object.keys(fieldErrors).reduce(
    (count, key) => fieldErrors[key] ? count + 1 : count,
    0
  )
)

export const useFieldErrors = () => {
  const [fieldErrors, setFieldErrors] = useState({})
  return {
    fieldErrors,
    setFieldErrors,
    setFieldError: (fieldName, errorMsg) => setFieldErrors({
      ...fieldErrors,
      [fieldName]: errorMsg
    }),
    clearFieldError: fieldName => {
      const newFieldErrors = {
        ...fieldErrors
      }
      newFieldErrors[fieldName] && delete newFieldErrors[fieldName]
      return setFieldErrors(newFieldErrors)
    },
    hasFieldErrors: () => hasFieldErrors(fieldErrors)
  }
}
