import {
  Box,
  Button,
  Checkbox, Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel, FormHelperText
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { DropZoneDialogTrigger } from './DropZoneDialogTrigger'
import { MimeTypes } from 'http-headers-js'
import { FormSubmitButton } from './FormSubmitButton'
import { CloudDownloadLink } from './CloudDownloadLink'

export const ReUploadRequirementsModal = ({
                                            closeUpload,
  closeModal,
                                            onDialogOpen,
  requiredDocs,
  handleInviteSent,
  openUpload,
  onModalOpen,
                                            handleCheckBoxClick,
                                            onRequirementSave,
  localizedTeam,
                                            setRequiredDocs

                                          }) => {
  const handleCancel = () => {
    setRequiredDocs([])
    closeModal()
  }

  return <Dialog open={onModalOpen} onClose={closeModal} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Required Documents</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Upload each requirement so that the team that has invited you may accept you as a team member - If a requirement has already been uploaded, you may view it here as well
      </DialogContentText>
      <FormControl>
        <Grid container>
          <Grid item xs={6}>
                <Typography>
                {requiredDocs.name}
                </Typography>
                {!requiredDocs?.upload ? <Grid item xs={6}>
                  <DropZoneDialogTrigger
                  {... {
                    dialogOpen: onDialogOpen,
                    acceptedFiles: [MimeTypes.Application.PDF],
                    submitButtonText: 'Upload Document',
                    uploadDialogProps: {
                      dialogTitle: 'Upload Document',
                    },
                    onClose: closeUpload,
                    onSave: (e) => onRequirementSave(localizedTeam, e, requiredDocs),
                  }}
                >
                  <FormSubmitButton
                    {... {
                      type: 'button',
                      variant: 'outlined',
                      onClick: (e) => openUpload(localizedTeam, e, requiredDocs),
                    }}
                  >
                    Upload Documents
                  </FormSubmitButton>
                </DropZoneDialogTrigger>
                </Grid> :  <>
                  <FormHelperText>
                    This is the reuploaded file
                  </FormHelperText>
                  <CloudDownloadLink {...{
                  cloudFileName: requiredDocs?.upload
                }}>
                  {requiredDocs?.upload}
                </CloudDownloadLink>
                </>
                }
              </Grid>
          </Grid>
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} color="primary">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
}
