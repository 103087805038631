import {
  identityProviderCustomTokenSignIn,
  identityProviderCustomTokenSignInWithParams,
  identityProviderSignIn
} from './identity-provider-auth.actions'
import {fetchSignedInUser} from './users.actions'
import fetchActiveOrg from './auth-sign-in-functions/fetch-org-activation'
import useAccessToken from './useAccessToken'
import {isServiceErrorResult} from '../../services/is-service-error-result.util'
import { fetchChekhubOrgList } from './service-company.actions'
import { dispatchService } from './dispatch-service.util'
import { useSelector } from 'react-redux'
import { CHEKHUB_END_USER_LANDING, SIGN_UP_PATH } from '../../routing/route.constants'
import { generatePath, useHistory } from 'react-router-dom'
import { getAuthCustomToken, getChekhubCustomToken } from '../../services/identity-provider.service'
import { fetchTicketList } from '../../services/ticket-list.service'
import { setTicketList } from './ticketsList-actions'
import { userProfileSetState } from './user-profile.actions'
import { setIndustriesState } from './industries.actions'

const handlePostIdentitySignIn = () => async (dispatch, getState) => {
  const fetchUserResult = await dispatch(fetchSignedInUser())
  if (isServiceErrorResult(fetchUserResult)) {
    return fetchUserResult
  }

  // We need to do this on sign-in to ensure that any changes to auth token claims
  // in a release are picked up.
  await useAccessToken(true)

  const {auth: {user}} = getState()
  await dispatch({ type: 'AUTH/SET-ACTIVE-ORG', payload: fetchActiveOrg(user) })
   return user
}

export const signInAction = credentials => async (dispatch, auth) => {
  const identitySignInErr = await dispatch(identityProviderSignIn(credentials, auth))
  return identitySignInErr ?
    identitySignInErr :
    await dispatch(handlePostIdentitySignIn())
}

// export const chekhubCustomerSignInParams = (auth) => async dispatch => {
//   const token = await dispatch(getChekhubCustomToken(auth))
//   return token
// }

export const chekhubCustomerSignInParams = auth => async dispatch =>
  dispatchService(dispatch)(
    getChekhubCustomToken, setIndustriesState
  )(auth)

export const chekhubCustomerSignIn = (credentials) => async (dispatch) => {
  console.log('here')
  const identitySignInErr = await dispatch(identityProviderCustomTokenSignIn(credentials))
  return identitySignInErr ?
    identitySignInErr :
    await dispatch(handlePostIdentitySignIn()) && await dispatch(fetchChekhubOrgList())
}

export const chekhubCustomerSignInFromParams = (quickFix) => async (dispatch) => {
  console.log(quickFix)
  const identitySignInErr = await dispatch(identityProviderCustomTokenSignInWithParams(quickFix))
  return identitySignInErr ?
    identitySignInErr :
    await dispatch(handlePostIdentitySignIn()) && await dispatch(fetchChekhubOrgList())
}
