import { CurrentInvitedTeamsTemplate } from './CurrentInvitedTeamsTemplate'
import { Hidden } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { ChekhubEndUserSummary } from './ChekhubEndUserSummary'
import useLandingViewStyles from './LandingViewStyles'
import { useAuthUser } from '../hooks/useAuthUser'
import { getUserOrg } from '../util/users.util'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRequiredDocs } from '../store/actions/addRequiredDocs.actions'
import { getInvitedTeams, getInvitedTeamsSc, invitedTeamResetState } from '../store/actions/invited-teams-list.actions'
import ServiceCompanySummary from './ServiceCompanySummary'
import {
  fetchServiceCompany,
  resetServiceCompanyListState,
  resetServiceCompanyState
} from '../store/actions/service-company.actions'
import { useParams } from 'react-router-dom'
import { Context as BlurContext } from '../contexts/BlurContext'
import { INDEPENDENT_TECH_ORG_TYPE } from '../constants/user.constants'
import { fetchTechProfile } from '../store/actions/tech-profile.actions'


export const CurrentInvitedTeamsEndUser = () => {

  const [onDialogOpen, setOpenDialog] = useState(false)
  const [onModalOpen, setModalOpen] = useState(false)
  const [open, setDrawerOpen] = useState(false)
  const authorizedUser = useAuthUser()

  const userDefaultOrg = getUserOrg(authorizedUser)
  const isTech = userDefaultOrg?.organization_type === INDEPENDENT_TECH_ORG_TYPE
  const dispatch = useDispatch()
  const chekhubChecker = userDefaultOrg?.organization_type === 'customer_company'
  const styles = useLandingViewStyles()
  const profile = useSelector(({serviceCompany}) => serviceCompany)
  const invitedTeams = useSelector(({invitedTeamsList}) => invitedTeamsList)
  const {orgId} = useParams()
  const {userId} = useParams()
  const {setBlurred} = useContext(BlurContext)
  const chekhubOrgId = localStorage.getItem("orgId")?.replace(/['"]+/g, '')

  const openUpload = (localizedTeam, e, docs) => {
    setOpenDialog(true)
  }
  const closeUpload = () => setOpenDialog(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  useEffect(async () => {
    !chekhubOrgId ? await dispatch(fetchServiceCompany(orgId)) : isTech && await dispatch(fetchTechProfile(userId))
    await dispatch(getRequiredDocs({
      chekhub_org_id: chekhubOrgId
    }))
    chekhubOrgId ? await dispatch(getInvitedTeams({
      chekhub_org_id: chekhubOrgId
    })) : !isTech ? await dispatch(getInvitedTeamsSc({
      service_provider_id: orgId
    })) : await dispatch(getInvitedTeamsSc({
      service_provider_id: userId
    }))
  }, [, onDialogOpen, !open])

  useEffect(() => {
    dispatch(resetServiceCompanyListState())
    dispatch(resetServiceCompanyState())
    dispatch(invitedTeamResetState())
  }, [])

  return <Grid container>
    <Hidden smDown>
      <Grid item xs={3}>
        {chekhubOrgId ? <ChekhubEndUserSummary {... {}}/> :
        <ServiceCompanySummary {...{
          profile
        }}/>}
      </Grid>
    </Hidden>
    <Grid
      item
      xs={12}
      sm={12}
      md={9}
      lg={9}
      xl={9}
      className={styles.skeleton}
    >
      <CurrentInvitedTeamsTemplate {...{
        setDrawerOpen,
        open,
        chekhubChecker,
        invitedTeams,
        onDialogOpen,
        setOpenDialog,
        onModalOpen,
        setModalOpen,
        openUpload,
        closeUpload,
        openModal,
        closeModal,
        setBlurred,
        chekhubOrgId
      }} />
    </Grid>
  </Grid>
}
