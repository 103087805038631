import axios from 'axios'

import appConfig from '../../../config'

const createUser = async (credentials, userType) => {
  const { chekhubConnect: { baseURI, operations, defaultHeaders } } = appConfig
  const { usersCreate: { path, method } } = operations
  const axiosOptions = {
    baseURL: baseURI,
    url: `${path}`,
    method,
    headers: defaultHeaders,
    params: {
      user_type: userType,
      agent: 'react',
    },
    data: credentials,
  }

  try {
    const { status: creationStatus, data: body } = await axios(axiosOptions)

    if (creationStatus !== 200) throw new Error('unknown chekhubAuthError')
    return [body.data, null]
  } catch (err) {
    return [null, err]
  }
}

export default createUser
