import {TOAST_TYPE_STANDARD, TRIGGER_TOAST_NOTIFICATION} from '../actions/toast-show'

const initState = {
  type: 'standard',
  message: null,
  open: false,
}

const toastReducer = (state = initState, action) => {
  if ([TRIGGER_TOAST_NOTIFICATION].includes(action.type)) {
    return {
      ...state,
      type: action.payload.type || TOAST_TYPE_STANDARD,
      message: action.payload.message,
      open: true,
    }
  }
  if (['TOAST/RESET'].includes(action.type)) {
    return initState
  }
  return state
}

export default toastReducer
