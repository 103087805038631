import React from 'react'
import moment from 'moment'

import {
  IconButton,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import useRootStyles from '../styles/rootStyles'
import formatAddress from './shared-functions/format-address'

export default function WorkHistoryItemView({ value, onDelete, readonly }) {
  const styles = useRootStyles()
  return (
    <div className={styles.workHistoryItemView}>
      <div>
        { !readonly
          ? <IconButton
            className='delete-icon'
            aria-label="Add Phone"
            onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
          : ''
        }
      </div>
      <h4><b>Employer:</b> { value.employer_name }</h4>
      <p>
        <b>Job Title:</b> { value.job_title } &nbsp;
        <b>Employment Type:</b> { value.employment_type } &nbsp;
      </p>
      <p>
        <b>Start Date:</b> { moment(value.start_date).format('MM/DD/YYYY') } &nbsp;
        <b>End Date:</b> &nbsp;
        { value.end_date ? moment(value.start_date).format('MM/DD/YYYY') : '' }
        { value.is_current_job ? 'Current Job' : '' }
      </p>
      <p>
        <b>Employer Location:</b> { formatAddress(value.employer_location?.address) }
      </p>
      <p>
        <b>Job Description:</b> { value.job_description }
      </p>
    </div>)
}
