import {SERVICE_COMPANY_MANAGE_MEMBERS, TECH_VIEW_PATH} from '../routing/route.constants'

export const ADMIN_USER_ROLE = 'admin'
export const DISPATCHER_USER_ROLE = 'dispatcher'
export const TECH_USER_ROLE = 'tech'
export const SC_USER_TYPE = 'service_company'
export const INDEPENDENT_TECH_USER_TYPE = 'independent'
export const INDEPENDENT_TECH_ORG_TYPE = 'independent'
export const TECH_USER_TYPE = 'tech'
export const ROLE_BASED_SIGN_IN_ROUTES = {
  [TECH_USER_ROLE]: TECH_VIEW_PATH,
  [ADMIN_USER_ROLE]: SERVICE_COMPANY_MANAGE_MEMBERS,
  [DISPATCHER_USER_ROLE]: SERVICE_COMPANY_MANAGE_MEMBERS
}
export const TABLE_HEADERS = [
  {name: 'First Name', id: 'first_name'},
  {name: 'Last Name', id: 'last_name'},
  {name: 'Email', id: 'email'},
  {name: 'Hour Rate', id: 'hour_rate'},
]

export const workingDaysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

export const WORK_EXP_CONSTANTS = [
  'job_title',
  'employer_name',
  'employer_location',
  'employer_website',
  'employment_type',
  'job_description',
  'start_date',
  'end_date',
  'is_current_job',
  'termination_reason'
]

export const CURRENT_USER_ID = 'me'

export const GOOGLE_MAP_API_HREF = `https://www.google.com/maps/search/?api=1&query=`

export const labelList = [
  {
    id: 1,
    category: 'small_business',
    value: false,
  },
  {
    id: 2,
    category: 'b'},
  {
    id: 3,
    category: 'c'}
]
