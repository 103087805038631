import {Link} from 'react-router-dom'

export const DisableableLink = ({
  disabled,
  children,
  ...linkProps
}) => disabled ?
  children :
  <Link {...linkProps}>
    {children}
  </Link>
