import {useDispatch, useSelector} from 'react-redux'
import { Grid } from '@material-ui/core'
import ProfileSummaryComponent from '../components/dashboard-components/profile-summary'
import fetchActiveOrg from '../store/actions/auth-sign-in-functions/fetch-org-activation'
import {
  fetchServiceCompany,
  resetServiceCompanyState
} from '../store/actions/service-company.actions'
import {fetchTechProfile, techProfileResetState} from "../store/actions/tech-profile.actions"
import {ADMIN_USER_ROLE, INDEPENDENT_TECH_USER_TYPE, SC_USER_TYPE} from '../constants/user.constants'
import {useEffect} from 'react'
import {getTechProfileIdForOrgId} from '../util/users.util'

const DashboardView = () => {
  const auth = useSelector((state) => state.auth)
  const { user, userType } = auth
  const dispatch = useDispatch()
  const scProfile = useSelector((state) => state.serviceCompany) || {}
  const techProfile = useSelector((state) => state.techProfile) || {}
  // TODO: figure out this activeOrg nonsense in a better way globally in the app
  const {orgId, userRole} = fetchActiveOrg(auth.user)

  const initServiceCompanyDashboardData = async () => {
    await dispatch({type: 'AUTH/SET-ACTIVE-ORG', payload: {orgId, userRole}})
    await dispatch(fetchServiceCompany(orgId))
  }

  const iniITDashboardData = async () =>
    user._id &&
    await dispatch(fetchTechProfile(getTechProfileIdForOrgId(user)))

  const initMethod = orgId && userRole === ADMIN_USER_ROLE ?
    initServiceCompanyDashboardData :
    iniITDashboardData

  useEffect(initMethod, [])
  useEffect(() => () => {
    dispatch(resetServiceCompanyState())
    dispatch(techProfileResetState())
  }, [])

  const profile = scProfile._id ?
    scProfile : techProfile
  const profileType = scProfile.id ?
    SC_USER_TYPE : INDEPENDENT_TECH_USER_TYPE

  return <Grid container direction="row" >
    <Grid item xs={12} sm={12} md={12}>
      {
        profile._id &&
          <ProfileSummaryComponent
          {...{
            userType: userType || profileType,
            profile,
            user
          }} />
      }
    </Grid>
  </Grid>
}

export default DashboardView
