import noop from '../util/noop'
import appConfig from '../config'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import {PaymentCardForm} from './PaymentCardForm'

const stripe = loadStripe(appConfig.stripeKey)

export const PaymentCard = ({
  onSubmit = noop,
  onCancel = noop,
  autoTokenize = false
}) => <Elements {...{stripe}}>
  <PaymentCardForm {...{
    onSubmit,
    onCancel,
    autoTokenize
  }} />
</Elements>
