import axios from 'axios'

import appConfig from '../../config'
import useAccessToken from './useAccessToken'
import {setBackendWorking, setBackendWorkingDone} from './backend-working.actions'

const { chekhubConnect: { baseURI, defaultHeaders, paths } } = appConfig

const deleteProfileSubcollectionItem = ({
  documentType,
  documentId = null,
  isTechProfile,
  profileId,
  userImpersonated,
}) => async (dispatch, getState, getFirebase) => {
  dispatch(setBackendWorking())
  const eventPrefix = userImpersonated ? 'IMPERSONATED-' : ''
  const accessToken = await useAccessToken()

  const { serviceCompanyProfileService, techProfileService } = paths

  const upsertPath = isTechProfile ? techProfileService : serviceCompanyProfileService
  try {
    const { status, data: body } = await axios({
      baseURL: baseURI,
      url: `${upsertPath}/${profileId}/${documentType}/${documentId}`,
      method: 'DELETE',
      headers: {
        ...defaultHeaders,
        authorization: `Bearer ${accessToken}`,
      },
    })
    if (status !== 200) throw new Error('unknown profileSubcollectionDeleteItemError')
    dispatch({ type: `${eventPrefix}PROFILE/SUBCOLLECTION-DELETE-ITEM-OK`, payload: { documentType, profile: body.data } })
  } catch (err) {
    dispatch({
      type: 'TOAST/TRIGGER-NOTIFICATION',
      payload: {
        type: 'error',
        message: 'There was an error deleting this item',
      },
    })
    dispatch({ type: `${eventPrefix}PROFILE/SUBCOLLECTION-DELETE-ITEM-ERROR`, payload: err.response })
  }
  dispatch(setBackendWorkingDone())
}

export default deleteProfileSubcollectionItem
