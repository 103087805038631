import { FormHelperText, Grid, Typography } from '@material-ui/core'
import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { InlineConfirm } from './InlineConfirm'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import { useValidations } from '../hooks/useValidations'

const EDIT_PHONE_NUMBER_FIELDNAME = 'edit-phone'
const usePhoneNumberEditStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  gridContainer: {
    flexGrow: 1,
    alignItems: 'center'
  },
  select: {
    height: '40px',
    width: '95%',
  },
  phoneInput: {
    height: '2.7em'
  },
  phoneInputError: {
    '&.form-control': {
      borderColor: `${theme.palette.error.main} !important`
    }
  },
  inputBox: {
    height: '2.8em !important',
    width: '100% !important'
  },
  actionBtn: {
    justifyContent: 'flex-end',
    display: 'flex',
    height: '100%'
  },
  helperText: {
    marginBottom: theme.spacing(-2.75)
  },
  capitalize: {
    textTransform: 'capitalize'
  }
}))

const PHONE_NUMBER_VALIDATIONS = {
  [EDIT_PHONE_NUMBER_FIELDNAME]: {
    required: 'Field is required',
    validator: value => isMobilePhone(
      `${value.startsWith('+') ? '' : '+'}${value}`
    ) ?
      null :
      'Must be valid format'
  }
}

const PhoneNumberEdit = ({
  onRemoveNumber,
  onPhoneNumberAdd,
  onPhoneNumberUpdate,
  onChange,
  phoneNumber,
  options,
  type,
  labelSelect,
  country,
  show,
}) => {
  const styles = usePhoneNumberEditStyles()
  const [labelType, setLabelType] = useState(type)
  const {
    onChangeValidation,
    onBlurValidation,
    fieldErrors,
    onSubmitValidation,
    hasFieldErrors
  } = useValidations(PHONE_NUMBER_VALIDATIONS)

  const handleOnChange = value => {
    onChangeValidation({
      target: {
        name: EDIT_PHONE_NUMBER_FIELDNAME,
        value
      }
    })
    onChange(value)
  }

  const handleOnBlur = ev => onBlurValidation(ev)

  const handleOnSubmit = () => {
    const formIsValid = onSubmitValidation({
      target: {
        [EDIT_PHONE_NUMBER_FIELDNAME]: {
          name: EDIT_PHONE_NUMBER_FIELDNAME,
          value: phoneNumber
        }
      }
    })
    !hasFieldErrors() && formIsValid &&
      (show ? onPhoneNumberUpdate(labelType) : onPhoneNumberAdd())
  }

  const handleCancel = () => {
    onRemoveNumber()
  }

  return <Grid container className={styles.gridContainer} spacing={1}>
    <Grid item xs>
      <TextField
        fullWidth
        className={styles.select}
        id="labelSelect"
        select
        label="Label"
        value={labelType}
        onChange={(event) => {
            setLabelType(event.target.value)
            labelSelect(event)
          }
        }
        SelectProps={{
          native: true
        }}
        inputProps={{
          className: styles.capitalize
        }}
        size="small"
        variant="outlined"
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </TextField>
    </Grid>
    <Grid item xs={5}>
      <PhoneInput {...{
        fullWidth: true,
        inputProps: {
          name: EDIT_PHONE_NUMBER_FIELDNAME,
        },
        regions: ['north-america'],
        inputClass: `${styles.inputBox} ${
          fieldErrors[EDIT_PHONE_NUMBER_FIELDNAME] ? styles.phoneInputError : ''
        }`,
        country: country ? country : 'us',
        onChange: handleOnChange,
        onBlur: handleOnBlur,
        value: phoneNumber,
        countryCodeEditable: false
      }} />
      {
        fieldErrors[EDIT_PHONE_NUMBER_FIELDNAME] && <FormHelperText {...{
          className: styles.helperText,
          error: true
        }}>{fieldErrors[EDIT_PHONE_NUMBER_FIELDNAME]}</FormHelperText>
      }
    </Grid>
    <Grid item  className={styles.actionBtn} xs={2}>
      <InlineConfirm
        onConfirm={handleOnSubmit}
        onCancel={handleCancel}
        disableConfirm={hasFieldErrors()}
      />
    </Grid>
  </Grid>
}

export default PhoneNumberEdit
