import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab'
import {ADMIN_USER_ROLE, DISPATCHER_USER_ROLE, TECH_USER_ROLE} from '../constants/user.constants'
import {makeStyles} from '@material-ui/core/styles'

const AVAILABLE_ROLES = {
  [ADMIN_USER_ROLE]: null,
  [DISPATCHER_USER_ROLE]: null,
  [TECH_USER_ROLE]: null
}
const useMemberRolesSelectorStyles = makeStyles(theme => ({
  toggleButtonGroup: {
    marginBottom: theme.spacing(3)
  }
}))

export const MemberRolesToggle = ({
  roles,
  setRoles,
  excludeRoles = [],
  ...toggleButtonGroupStyles
}) => {
  const styleClasses = useMemberRolesSelectorStyles()
  return <ToggleButtonGroup {...{
    className: styleClasses.toggleButtonGroup,
    onChange: (e, v) => setRoles(v),
    value: roles,
    ...toggleButtonGroupStyles
  }}>
    {
      Object.keys(AVAILABLE_ROLES)
        .filter(r => !excludeRoles.includes(r))
        .map(
          (r, key) => <ToggleButton {...{
            key,
            value: r
          }}>{r}</ToggleButton>
        )
    }
  </ToggleButtonGroup>
}
