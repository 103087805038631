import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchChekhubOrgList } from '../store/actions/service-company.actions'
import { useDimensionsContextStyles } from './DimensionsContext'
import useLandingViewStyles from './LandingViewStyles'
import useEditStyles from './ServiceCompanyViewStyles'
import useListStyles from './ListServiceCompanyStyles'
import { Card, Typography } from '@material-ui/core'
import ActiveOrgSelector from './ActiveOrgSelector'
import { useLocalStorage } from '../util/localStorageHack.util'
import { setBackendWorking, setBackendWorkingDone } from '../store/actions/backend-working.actions'
import { useHistory } from 'react-router-dom'
import useSignInStyles from './SignInStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'

const useSelectOrgViewStyles = makeStyles(theme => ({
  activeOrgSelector: {
    padding: theme.spacing(3, 2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: 'center',
  },
  headerContent: {
    padding: theme.spacing(3, 2),
  }
}))

export const SelectOrgView = () => {

  const dimensionsContextStyles = useDimensionsContextStyles()
  const styles = useLandingViewStyles()
  const selectOrgStyles = useSelectOrgViewStyles()
  const editStyles = useEditStyles()
  const listStyles = useListStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    backgroundRunning,
  } = useSelector(
    ({auth}) => auth)
  const [activeOrgValue, setActiveOrgValue] = useState('')
  const [orgId, setOrgId] = useLocalStorage("orgId", activeOrgValue)
  const activeOrgOnChange = async ({
                                     target: {
                                       value: orgId,
                                       orgRole: orgRole,
                                     }
                                   }) => {
    // TODO: Abstract into a redux action to dispatch

    await dispatch(setBackendWorking())

    setActiveOrgValue(orgId)
    setOrgId(orgId)

    await dispatch({
      type: 'AUTH/SET-ACTIVE-ORG',
      payload: {
        activeOrgObject: {},
        orgId,
        userRole: orgRole || ''
      }
    })
    history.push(`/chekhub-user/${orgId}/landing`)
    dispatch(setBackendWorkingDone())
  }

  useEffect(async () => {
    await dispatch(fetchChekhubOrgList())
  }, [])

  return <Card
    className={
    `${styles.membersListCard} ${dimensionsContextStyles.minHeight} ${dimensionsContextStyles.card}`
  }>
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={'h5'} className={selectOrgStyles.headerContent}>
          You logged in as a Chekhub User, you must select a team that you are apart of from the dropdown to continue
        </Typography>
      </Grid>
      <Grid item xs={12}>
    <ActiveOrgSelector {...{
        className: selectOrgStyles.activeOrgSelector,
        value: activeOrgValue,
        onChange: activeOrgOnChange,
        backgroundRunning
  }} />
      </Grid>
    </Grid>
  </Card>
}
