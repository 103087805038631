import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  editProfileGrid: {
    display: 'flex',
    margin: theme.spacing(2),
    backgroundColor: theme.palette.surfaces.paper,
    minWidth: theme.spacing(90),
  },
  icons: {
    fontSize: theme.spacing(4),
  },
  h2: {
    fontSize: theme.spacing(4),
  },
  dateField: {
    marginBottom: theme.spacing(1),
  },
  select: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },

  paper: {
    maxWidth: theme.spacing(70),
  },
  techProfile:{
    paddingTop: theme.spacing(2),
  },
  smallTextField: {},
  insuranceHolder: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}))
