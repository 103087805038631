import {Card, CardContent, Grid} from '@material-ui/core'
import React from 'react'
import useFormStyles from '../styles/formStyles'
import useEditStyles from './UserProfileStyles'
import UserProfileForm from "./UserProfileForm"
import {makeStyles} from '@material-ui/core/styles'
import {useSelector} from 'react-redux'

const userUserProfileStyles = makeStyles(theme => ({
  card: {
    maxWidth: theme.spacing(70),
    border: 'none'
  }
}))

const UserProfile = ({
  user,
  editable,
  onChange,
  onSubmit
}) => {
  const formStyles = useFormStyles()
  const editStyles = useEditStyles()
  const userProfileStyles = userUserProfileStyles()
  const {backgroundRunning} = useSelector(
    ({auth:{backgroundRunning}}) => ({backgroundRunning})
  )
  return <Card variant="outlined" className={userProfileStyles.card}>
    <CardContent>
      <Grid item>
        <UserProfileForm {...{
          user,
          backgroundRunning,
          onSubmit,
          formStyles,
          editStyles,
          editable,
          onChange
        }} />
      </Grid>
    </CardContent>
  </Card>
}

export default UserProfile
