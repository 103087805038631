import {dispatchService} from './dispatch-service.util'
import {getTechProfile, putTechProfile} from '../../services/tech-profiles.service'
import {INIT_BIRTHDATE} from '../reducers/tech-profile.constants'
import {getISOFormattedDateString} from '../../util/date-time.util'
import {fetchServiceCompanyTechCount} from './service-company.actions'

export const TECH_PROFILE_SET_STATE = 'TECH_PROFILE_SET_STATE'
export const TECH_PROFILE_RESET_STATE = 'TECH_PROFILE_RESET_STATE'

export const techProfileSetState = techProfile => ({
  type: TECH_PROFILE_SET_STATE,
  techProfile: {
    ...techProfile,
    birth_date: techProfile.birth_date ?
      getISOFormattedDateString(techProfile.birth_date) : INIT_BIRTHDATE
  }
})

export const techProfileResetState = () => ({
  type: TECH_PROFILE_RESET_STATE
})

export const fetchTechProfile = userId => async dispatch =>
  await dispatchService(dispatch)(
    getTechProfile,
    techProfileSetState
  )(userId)


export const updateTechProfile = (techProfile, orgId) => async (dispatch, getState) => {
  const {serviceCompany} = getState()
  if (serviceCompany._id) {
    dispatch(fetchServiceCompanyTechCount(serviceCompany._id))
  }
  return await dispatchService(dispatch)(
    putTechProfile,
    techProfileSetState
  )(techProfile, orgId)
}

