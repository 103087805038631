import { useState } from 'react'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { ConfirmationModal } from './ConfirmationModal'
import { EditRemoveButtons } from './EditRemoveButtons'
import {makeStyles} from '@material-ui/core/styles'
import {Link} from '@material-ui/core'

const usePhoneNumberDisplayStyles = makeStyles(theme => ({
  listItemText: {
    textTransform: 'capitalize',
    marginTop: 0,
    marginBottom: 0
  }
}))

const PhoneNumberDisplay = ({
  phone,
  index,
  onPhoneNumberRemove,
  onEditNumber,
  disableActions = false,
  setValid,
}) => {
  const styles = usePhoneNumberDisplayStyles()
  const [removeNumber, setRemoveNumber] = useState()
  const [removeIndex, setRemoveIndex] = useState()
  const handleRemove = (remove, number) => {
    setRemoveIndex(number)
    setRemoveNumber(remove)
  }

  const onCancelRemove = () => {
    setRemoveNumber()
    setValid(true)
  }

  const onRemoveConfirm = () => () => {
    onPhoneNumberRemove(removeIndex)
    onCancelRemove()
    setValid(true)
  }

  return <>
    <ListItem disableGutters>
      <ListItemText
        className={styles.listItemText}
        primary={phone ? phone.label : null}
        secondary={
          phone && <Link {...{
            href: `tel:${phone.raw_phone}`
          }}>{phone.raw_phone}</Link>
        }
      />
    </ListItem>
    { !disableActions && <ListItemSecondaryAction>
        <EditRemoveButtons
          onEdit={() => {
            onEditNumber(index)
          }}
          onRemove={() => handleRemove(true, index)}
          editTitle="Edit"
          removeTitle="Remove"
        />
      </ListItemSecondaryAction>
    }
    {/* TODO: Move this up into the parent PhoneNumbersEdit component so it's not rendered for each and every phone number*/}
    <ConfirmationModal {...{
      open: removeNumber,
      user: removeNumber,
      onOk: onRemoveConfirm(removeNumber),
      onClose: onCancelRemove,
    }}>Remove Number?</ConfirmationModal>
  </>
}

export default PhoneNumberDisplay
