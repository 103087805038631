import moment from 'moment'

import {
  Tooltip,
} from '@material-ui/core'

import ClearIcon from '@material-ui/icons/Clear'
import DescriptionIcon from '@material-ui/icons/Description'
import useRootStyles from '../../styles/rootStyles'

const formatString = (str) => {
  if (str) {
    return (str[0].toUpperCase() + str.substring(1)).replaceAll('_', ' ')
  }
  return str
}

const compactInsuranceList = (list) => {
  const styles = useRootStyles()
  if (list && list.length > 0) {
    return list.map((item) => {
      const { insurance_type: insType, issued_by: by, expires_on: expiresOn } = item
      const exp = moment(expiresOn).format('MMM Do YYYY')
      const type = formatString(insType)
      return <Tooltip title={ `${type} / ${by} / ${exp}` } >
          <span className={styles.compactListItem}>
            <DescriptionIcon fontSize="small" />
            { `${type} ` }
          </span>
        </Tooltip>
    })
  }
  return <ClearIcon fontSize="small" />
}

const insuranceIcon = (list) => {
  const styles = useRootStyles()
  if (list && list.length > 0) {
    const toolTipTitle = list.reduce((prevVal, currVal, idx) => {
      const { insurance_type: insType, issued_by: by, expires_on: expiresOn } = currVal
      const exp = moment(expiresOn).format('MMM Do YYYY')
      const type = formatString(insType)
      return idx === 0 ? `${type} / ${by} / ${exp}` : `${prevVal}, ${type} / ${by} / ${exp}`
    }, '')
    return <Tooltip title={ toolTipTitle } >
      <span className={styles.compactListItem}>
        <DescriptionIcon fontSize="small" />
        { list.length }
      </span>
    </Tooltip>
  }
  return <ClearIcon fontSize="small" />
}

const compactCertList = (list) => {
  const styles = useRootStyles()
  if (list && list.length > 0) {
    return list.map((item) => {
      const { certification_title: title, end_date: endDate } = item
      const end = moment(endDate).format('MMM Do YYYY')
      return <Tooltip title={ `${title} / ${end}` } >
          <span className={styles.compactListItem}>
            <DescriptionIcon fontSize="small" />
            { `${title} ` }
          </span>
        </Tooltip>
    })
  }
  return <ClearIcon fontSize="small" />
}

const certIcon = (list) => {
  const styles = useRootStyles()
  if (list && list.length > 0) {
    const toolTipTitle = list.reduce((prevVal, currVal, idx) => {
      const { certification_title: title } = currVal
      return idx === 0 ? title : `${prevVal}, ${title}`
    }, '')
    return <Tooltip title={ toolTipTitle } >
      <span className={styles.compactListItem}>
        <DescriptionIcon fontSize="small" />
        { list.length }
      </span>
    </Tooltip>
  }
  return <ClearIcon fontSize="small" />
}

export {
  compactInsuranceList,
  compactCertList,
  certIcon,
  insuranceIcon,
}
