import {makeStyles} from '@material-ui/core/styles'

const useLandingViewStyles = makeStyles((theme) => ({
  disableVerticalPadding: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important'
  },
  sectionHeaderDivider: {
    marginBottom: theme.spacing(1)
  },
  summaryHolder: {
    boxShadow: 'none',
    borderTop: 'none',
    borderRight: 'none',
    height: '100%',
  },
  editLinkGridItem: {
    textAlign: 'right'
  },
  editLink: {
    color: theme.palette.secondary.light,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
    marginLeft: 'auto'
  },
  companyName: {
    lineHeight: 1
  },
  linkedInLink: {
    color: theme.palette.linkedInColor.main,
    '&:hover': {
      color: theme.palette.linkedInColor.secondary,
    }
  },
  icons: {
    verticalAlign: 'top'
  },
  avatar: {
    height: '3em',
    width: '3em',
    backgroundColor: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {fontSize: '2.5em'},
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  avatarHolder: {
    paddingTop: theme.spacing(1)
  },
  websiteLink: {
    cursor: 'pointer'
  },
  skeleton: {
    border: `1px solid ${theme.palette.divider}`,
    borderTop: 'none',
    '& > .MuiCard-root': {
      border: 'none'
    },
  },
  typo: {
    textDecoration: 'underline'
  },
  techHolder: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tagStyling : {
    marginBottom: 0,
  },
  card: {
    height: '100%',
    width: '100%',
  }
}))
export default useLandingViewStyles
