import {createReducer} from 'redux-create-reducer'
import {
  USER_LIST_SET_STATE,
  USER_LIST_RESET_STATE,
  USER_LIST_APPEND, USER_LIST_REMOVE_USER, USER_LIST_UPDATE_USER
} from '../actions/users.actions'

export const USER_LIST_INIT_STATE = []

const userListReducer = createReducer(
  USER_LIST_INIT_STATE,
  {
    [USER_LIST_SET_STATE]: (state, {list}) => [...list],
    [USER_LIST_APPEND]: (state, {user}) => [
      ...state,
      user
    ],
    [USER_LIST_UPDATE_USER]: (state, {user}) => state.map(
      u => u._id === user._id ? user : u
    ),
    [USER_LIST_REMOVE_USER]: (state, {userId}) => [
      ...state.filter(u => u._id !== userId)
    ],
    [USER_LIST_RESET_STATE]: () => USER_LIST_INIT_STATE
  }
)

export default userListReducer
