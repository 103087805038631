import {List} from '@material-ui/core'
import ServiceCompanyMemberListItem from './ServiceCompanyMembersListItem'
import {useServiceCompanyMembersListStyles} from './ServiceCompanyMembersListStyles'
import {useSelector} from 'react-redux'
import {shouldDisableMemberRemoval} from '../util/users.util'

const ServiceCompanyMembersList = ({
  members,
  serviceCompany,
  onRemove,
  disableAdminRemoval
}) => {
  const listStyles = useServiceCompanyMembersListStyles()
  const {
    authUser: authenticatedUser
  } = useSelector(({auth}) => ({
    authUser : auth.user
  }))

  return <List
    className={listStyles.root}
  >
    {members.map(
      (member, key) => <ServiceCompanyMemberListItem {...{
        member,
        orgContext: serviceCompany,
        key,
        listStyles,
        disableRemoval : shouldDisableMemberRemoval(
          member,
          serviceCompany,
          authenticatedUser,
          disableAdminRemoval
        ),
        onRemove,
      }} />
    )}
  </List>
}

export default ServiceCompanyMembersList
