import useLandingViewStyles from '../components/LandingViewStyles'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardContent, Hidden } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { ChekhubEndUserSummary } from '../components/ChekhubEndUserSummary'
import { useEffect, useState } from 'react'
import { getDefaultSource, resetConnectedAccounts } from '../store/actions/wallet.actions'
import { resetServiceCompanyState } from '../store/actions/service-company.actions'
import { useParams } from 'react-router-dom'
import { AddBankAccountChekhubEndUser } from './AddBankAccountChekhubEndUser'
import { useDimensionsContextStyles } from '../components/DimensionsContext'


export const RevenueBillingChekhubView = () => {
  const styles = useLandingViewStyles()
  const dimensionsContextStyles = useDimensionsContextStyles()
  const dispatch = useDispatch()
  const auth = useSelector(({auth}) => auth)
  const {user} = auth
  const {orgId} = useParams()
  const [forceRerender, setForceRerender] = useState(0)
  const activeOrgIndex = user?.organizations?.findIndex(org => org.organization_id === auth.activeOrg)
  const {
    connectedAccounts
  } = useSelector(
    ({
       wallet
     }) => ({
      connectedAccounts: wallet.connectedAccounts
    })
  )
  const default_source = useSelector(({wallet}) => wallet.default_source)


  useEffect( async () => {
    orgId && await dispatch(getDefaultSource(
      orgId,
    ))
  }, [, forceRerender])

  //create your forceUpdate hook
  function useForceUpdate(){// integer state
      return setForceRerender(forceRerender + 1) // update the state to force render

  }
  useEffect(() => () => {
    dispatch(resetServiceCompanyState())
    dispatch(resetConnectedAccounts())
  }, [])


  return default_source && <Grid container>
    <Hidden smDown>
      <Grid item xs={3}>
        <ChekhubEndUserSummary {...{
          user,
          activeOrgIndex
        }}/>
      </Grid>
    </Hidden>
    <Grid
      item
      xs={12}
      sm={12}
      md={9}
      lg={9}
      xl={9}
      className={styles.skeleton}
    >
      <Card className={
        `${styles.membersListCard} ${dimensionsContextStyles.minHeight} ${dimensionsContextStyles.card}`
      } >
        <CardContent>
          <AddBankAccountChekhubEndUser {... {
            default_source,
            useForceUpdate
          }} />
        </CardContent>
      </Card>
    </Grid>
  </Grid>
}
