import {createReducer} from "redux-create-reducer"
import {
  RESET_INDUSTRIES_STATE,
  SET_INDUSTRIES_STATE
} from "../actions/industries.actions"

export const INDUSTRIES_INIT_STATE = []

export const industriesReducer = createReducer(
  INDUSTRIES_INIT_STATE,
  {
    [SET_INDUSTRIES_STATE]: (state, {industries}) => ({
      ...state,
      industries
    }),
    [RESET_INDUSTRIES_STATE]: () => ({...INDUSTRIES_INIT_STATE})
  }
)
