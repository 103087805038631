export const SET_BACKEND_WORKING = 'SET_BACKEND_WORKING'

export const setBackendWorking = () => ({
  type: SET_BACKEND_WORKING,
  backendRunning: true
})

export const setBackendWorkingDone = () => ({
  type: SET_BACKEND_WORKING,
  backendRunning: false
})
