import {useContext, useEffect, useRef} from "react"
import { useHistory, useLocation } from "react-router-dom"
import {
  Box,
  Container,
  Toolbar,
  Typography
} from '@material-ui/core'
import AppBar from "@material-ui/core/AppBar"
import CopyrightIcon from '@material-ui/icons/Copyright'
import useFooterStyles from './AppFooterStyles'
import { LANDING_PATH, SIGN_IN_PATH } from "../routing/route.constants"
import {Context} from "./DimensionsContext"

const AppFooter = () => {
  const footerStyle = useFooterStyles()
  const {pathname} = useLocation()
  const history = useHistory()
  const showFooter = pathname.match(SIGN_IN_PATH)
  const changeFooterColor = history.location.pathname === LANDING_PATH
  const footer = useContext(Context).footer
  const footerRef = useRef(null)
  const {setFooterDimensions}= footer
  const footerDimensions = footerRef?.current?.getBoundingClientRect()
  const footerHeight = !changeFooterColor ? footerDimensions?.height : '95%'

  useEffect(() => {
      setFooterDimensions(footerRef?.current?.getBoundingClientRect() || {})
  },
    [ ,footerRef.current, footerHeight])

  return <AppBar className={!changeFooterColor ? footerStyle.footer : footerStyle.landingFooter} ref={footerRef}>
      <Container maxWidth="md">
        <Toolbar {...{className: footerStyle.toolbar}}>
          <Box {...{className: footerStyle.copyright}}>
            <Typography className={!changeFooterColor ? footerStyle.span : footerStyle.landingSpan} variant={'span'}>ChekHub Inc. 2021</Typography>
            <Typography variant="caption" className={!changeFooterColor ? footerStyle.regCaption :  footerStyle.caption}>
              <CopyrightIcon className={footerStyle.icon} />
            </Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>

}

export default AppFooter
