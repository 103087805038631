import { Route, Switch, withRouter } from 'react-router-dom'
import { RouteConnectedDrawer } from '../components/RouteConnectedDrawer'
import { duration } from '@material-ui/core'
import { useContext } from 'react'
import { Context as BlurContext } from '../contexts/BlurContext'
import { RequiredDocsSummary } from './RequiredDocsSummary'
import { RequiredDocsAdd } from './RequiredDocsAdd'


export const AddRequiredDocumentChekhubEndUserDrawerUtil = withRouter(
  ({
     open,
     setOpen,
     backRoutePath,
     match,
     routeTransitionDelay = duration.leavingScreen,
     requiredDocsList,
     onDocumentSave,
     onDocumentNameChange,
     onDialogOpen,
     setOpenDialog,
     documentName,
     fileUncleanedName
   }) => {
    const {setBlurred} = useContext(BlurContext)
    return <Switch>
      <Route path={`${match.path}/add/document`}>
        <RouteConnectedDrawer {...{
          open,
          setOpen,
          anchor: 'right',
          title: 'Add New Required Document',
          backRoutePath
        }}>
          <RequiredDocsAdd {...{
            requiredDocsList,
            setOpen,
            backRoutePath,
            routeTransitionDelay,
            setBlurred,
            onDocumentSave,
            onDocumentNameChange,
            onDialogOpen,
            setOpenDialog,
            documentName,
            fileUncleanedName
          }}/>
        </RouteConnectedDrawer>
      </Route>
    </Switch>
  }
)
