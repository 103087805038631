import {CopyrightSharp} from '@material-ui/icons'
import {makeStyles} from '@material-ui/core/styles'

const useInlineCopyrightIconStyles = makeStyles(theme => ({
  root: {
    fontSize: 'smaller'
  }
}))
export const InlineCopyrightIcon = props => {
  const styles = useInlineCopyrightIconStyles()
  return <CopyrightSharp {...{
    className: `${styles.root} ${props.className ? props.className : ''}`
  }}/>
}
