import {
  FormControl,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Checkbox, Divider, Grid, FormControlLabel
} from '@material-ui/core'
import Typography from "@material-ui/core/Typography"
import {useWorkExpDisplay} from "./TechWorkExpDisplay"
import {MinMaxDateField} from "./MinMaxDateField"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import addressToLocation from "./shared-functions/address-to-location"
import {ref_item} from "./sandboxes/kevin/examples/WorkExperienceExample"
import {InlineConfirm} from "./InlineConfirm"
import PhoneInput from "react-phone-input-2"
import {getISOFormattedDateString} from '../util/date-time.util'
import {getDateFromPickerString} from '../pages/TechProfile.util'
import {ListItemSegment} from "./WorkingDaysDisplay"
import {makeStyles} from '@material-ui/core/styles'
import {PlacesAutoComplete} from './PlacesAutoComplete'

const ADDRESS_FIELDNAME = 'exp-address-autocomplete'
const useTechWorkExpEditStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  employmentSelectLabel: {
    marginTop: theme.spacing(-1),
    lineHeight: 1.5
  },
  employmentTypeOtherField: {
    marginTop: theme.spacing(1.25)
  }
}))


const TechWorkExpEdit = ({
  workIndex: index,
  selectedWorkExp: exp,
  size = 'small',
  setSelectedWorkExp,
  addMode,
  onAddSave,
  onEditSave,
  setOpen,
  setRefIndex,
  setRemoveItem,
  setAddMode,
  setEditMode,
  showAllowButtonOrNot
}) => {
  const styles = useWorkExpDisplay()
  const techWorkExpEditStyles = useTechWorkExpEditStyles()
  const onWorkExpChange = (ev) => {
    let {id: key, value} = ev.target

    let newWorkExp = {
      ...exp,
      employer_location: {
        ...exp.employer_location,
        label: 'home'
      }
    }

    if (ev.target.name === 'employment_type') {
      newWorkExp = {
        ...newWorkExp,
        employment_type: ev.target.value,
        alt_employment_type: ''
      }
    } else if (ev.target.name === 'alt_employment_type') {
      newWorkExp = {
        ...newWorkExp,
        alt_employment_type: ev.target.value,
        employment_type: 'other'
      }
    } else if (ev.target.name === 'is_current_job') {
      newWorkExp = {
        ...newWorkExp,
        is_current_job: ev.target.checked,
      }
    } else if (['start_date', 'end_date'].includes(ev.target.id)) {
      newWorkExp = {
        ...newWorkExp,
        [ev.target.id]:
          getISOFormattedDateString(getDateFromPickerString(value)),
      }
    } else {
      newWorkExp = {
        ...newWorkExp,
        [key]: value
      }
    }
    setSelectedWorkExp(newWorkExp)
  }

  const onAddressChange = (ev, addressDetails) => {
    let newWorkExp = {
      ...exp
    }
    setSelectedWorkExp({
      ...newWorkExp,
      employer_location: addressToLocation(addressDetails, exp.employer_location)
    })
  }

  const onRefAdd = () => {
    let addedRef = {
      ...exp,
    }
    let newRef = {
      ...ref_item
    }
    addedRef = {
      ...addedRef,
      references: [
        ...addedRef.references,
        newRef
      ]
    }

    setSelectedWorkExp(addedRef)

  }

  const handleRefChanges = (ev, index) => {
        ev.preventDefault()
        let {id: key, value} = ev.target
        let newRefArr = [...exp.references]

        newRefArr[index] = {
            ...newRefArr[index],
            [key]: value
       }
        setSelectedWorkExp({
            ...exp,
            references: newRefArr
        })

  }

  const handleRefDelete = (index) => {
    let deletedIndexedRef = {
      ...exp
    }
    setRemoveItem(deletedIndexedRef)
    setOpen(true)
    setRefIndex(index)
  }
  const handleCancel = () => {
    setAddMode(false)
    setEditMode(false)
    setSelectedWorkExp()
    showAllowButtonOrNot(true)
  }


  const handlePhoneChange = (e, index) => {
    let newRefArr = [...exp.references]

    newRefArr[index] = {
      ...newRefArr[index],
      phone: {
        country: 'us',
        raw_phone: e
      }
    }
    setSelectedWorkExp({
      ...exp,
      references: newRefArr
    })

  }

  return <Grid container spacing={1} className={styles.list}>
      <Grid item xs={12}>
        <Typography {...{
          variant: 'h6',
          className: techWorkExpEditStyles.title
        }}>
          {addMode ? 'Add Work Experience' :'Edit Work Experience'}
          <InlineConfirm {...{
            onConfirm: addMode ? onAddSave(exp) : onEditSave(exp),
            onCancel: handleCancel,
            size: 'small'
          }}/>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={exp?.employer_name ? exp.employer_name : ''}
          label={'Employer Name'}
          id={'employer_name'}
          variant={'outlined'}
          size={size}
          onChange={ev => onWorkExpChange(ev, index, exp)} />
      </Grid>
      <Grid item xs={6}>
        <FormControl>
          <FormControlLabel {...{
            label: 'Current',
            control: <Checkbox {...{
              checked: !!exp?.is_current_job,
              // TODO: If unchecked, it should clear the end date
              onChange: ev => onWorkExpChange(ev, index),
              name: 'is_current_job',
              variant: 'outlined',
              color: 'primary',
              size: 'small'
            }} />
          }} />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <MinMaxDateField
          {...{
            fullWidth: true,
            id: 'start_date',
            size: 'small',
            label: 'Start Date',
            className: styles.dateFieldMargin,
            value: exp.start_date,
            minYear: 1900,
            InputLabelProps: {shrink: true},
            onChange: ev => onWorkExpChange(ev, index),
            variant: 'outlined'
          }}
        />
      </Grid>
      <Grid item xs={6}>
        {
          !exp?.is_current_job && <MinMaxDateField {...{
            fullWidth: true,
            id: 'end_date',
            size: 'small',
            label: 'End Date',
            value: exp.end_date,
            minYear: 1900,
            InputLabelProps: {shrink: true},
            onChange: ev => onWorkExpChange(ev, index),
            variant: 'outlined'
          }} />
        }
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={exp?.job_title ? exp.job_title : ''}
          label={'Job Title'}
          id={'job_title'}
          variant={'outlined'}
          size={size}
          onChange={ev => onWorkExpChange(ev, index)} />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={exp?.employer_website ? exp.employer_website : ''}
          label={'Employer Website'}
          size={size}
          onChange={ev => onWorkExpChange(ev, index)}
          id={'employer_website'}
          variant={'outlined'}/>
      </Grid>
      <Grid item xs={12}>
        <PlacesAutoComplete
          {...{
            id: ADDRESS_FIELDNAME,
            name: ADDRESS_FIELDNAME,
            size: 'small',
            label: 'Address',
            addressDetails: exp?.employer_location,
            onChange: onAddressChange,
          }}/>
      </Grid>
      <Grid item xs={4}>
        <FormHelperText className={techWorkExpEditStyles.employmentSelectLabel}>
          Employment Type
        </FormHelperText>
        <Select
          className={styles.employmentSelect}
          value={exp?.alt_employment_type ? 'other' : exp?.employment_type ? exp.employment_type : 'employee'}
          name={'employment_type'}
          onChange={ev => onWorkExpChange(ev, index)}
          size="small"
          variant={'outlined'} >
          <MenuItem value={'employee'}>Employee</MenuItem>
          <MenuItem value={'contractor'}>Contractor</MenuItem>
          <MenuItem value={'other'}>Other</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={8}>
        {
          exp?.employment_type === 'other' &&
            <TextField
              fullWidth
              className={techWorkExpEditStyles.employmentTypeOtherField}
              value={exp?.alt_employment_type || ''}
              label={'Other Employment'}
              size={size}
              onChange={ev => onWorkExpChange(ev, index)}
              name={'alt_employment_type'}
              variant={'outlined'} />
        }
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={exp?.job_description ? exp.job_description : ''}
          label={'Job Description'}
          fullWidth
          size={size}
          onChange={ev => onWorkExpChange(ev, index)}
          id={'job_description'}
          variant={'outlined'}/>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={exp?.termination_reason ? exp.termination_reason : ''}
          label={'Termination Reason'}
          fullWidth
          size={size}
          onChange={ev => onWorkExpChange(ev, index)}
          id={'termination_reason'} variant={'outlined'} />
      </Grid>
    <ListItem>
      <FormHelperText>
        References
      </FormHelperText>
      <ListItemSegment>
      <IconButton
        className={styles.buttonTransparent}
        edge={'end'}
        title={'Add Reference History'}
        size={'large'}
        onClick={() => onRefAdd()}
      >
        <AddIcon fontSize={'large'}/>
      </IconButton>
      </ListItemSegment>
    </ListItem>
    {/* TODO: Clean up this w/ Grids like above */}
    {exp?.references?.map((ref, index) => (
        <List key={index}>
        <Divider className={styles.refDivider}/>
        <ListItem className={styles.refListItem}>
          <TextField
            value={ref?.first_name ? ref.first_name : ''}
            label={'Reference First Name'}
            size={size}
            onChange={(ev) => handleRefChanges(ev, index)}
            id={'first_name'}
            variant={'outlined'}
          />
          <IconButton
            className={styles.refDeleteButton}
            edge={'end'}
            title={'Delete Ref History'}
            size={size}
            onClick={() => handleRefDelete(index)}
          >
            <DeleteIcon fontSize={size}/>
          </IconButton>
        </ListItem>
        <ListItem>
          <TextField
            value={ref?.last_name ? ref.last_name : ''}
            label={'Reference Last Name'}
            size={size}
            onChange={(ev) => handleRefChanges(ev, index)}
            id={'last_name'}
            variant={'outlined'}
          />
        </ListItem>
        <ListItem>
          <TextField
            value={ref?.title ? ref.title : ''}
            label={'Reference Title'}
            size={size}
            onChange={(ev) => handleRefChanges(ev, index)}
            id={'title'}
            variant={'outlined'}
          />
        </ListItem>
        <ListItem>
          <TextField
            value={ref?.email ? ref.email : ''}
            label={'Reference Email'}
            size={size}
            onChange={(ev) => handleRefChanges(ev, index)}
            id={'email'}
            variant={'outlined'}
          />
        </ListItem>
          <ListItem>
            <PhoneInput
              country={ref.phone.country ? ref.phone.country : 'us'}
              onChange={e => handlePhoneChange(e, index)}
              value={ref?.phone?.raw_phone} />
          </ListItem>
        </List>
    ))
    }
  </Grid>
}

export default TechWorkExpEdit
