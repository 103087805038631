import {
  Button,
} from "@material-ui/core"
import {useEffect, useState} from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import {getPaymentSources, resetWalletState} from "../../../../store/actions/wallet.actions"
import {useDispatch} from "react-redux"
import {getBackgroundCheckPackages, resetBackgroundCheckState} from "../../../../store/actions/background-check.actions"
import {fetchTechProfile} from '../../../../store/actions/tech-profile.actions'
import { BackgroundChecker } from '../../../BackgroundChecker'


export const packages = [
  {
    package_id: "tasker_standard",
    name: "Tasker Standard",
    screenings: [
      {
        type: "county_criminal_search",
        subtype: "current"
      },
      {
        type: "national_criminal_search",
        subtype: "standard"
      },
      {
        type: "sex_offender_search",
        subtype: null
      },
      {
        type: "ssn_trace",
        subtype: null
      },
      {
        type: "global_watchlist_search",
        subtype: null
      }
    ],
    currency: "USD",
    price: 27.77,
    price100: 2777
  },
  {
    package_id: "tasker_pro",
    name: "Tasker Pro",
    screenings: [
      {
        type: "county_criminal_search",
        subtype: "7years"
      },
      {
        type: "national_criminal_search",
        subtype: "standard"
      },
      {
        type: "sex_offender_search",
        subtype: null
      },
      {
        type: "ssn_trace",
        subtype: null
      },
      {
        type: "global_watchlist_search",
        subtype: null
      }
    ],
    currency: "USD",
    price: 38.88,
    price100: 3888
  }
]

export const content = {
  "screening_definitions": {
    "ssn_trace": {
      "name": "SSN Validation/Trace",
      "description": "SSN validation confirms the SSN is valid and identifies both the state and the year of issuance. The search also covers the national death index.",
      "url": "https://help.checkr.com/hc/en-us/articles/360001967227-Social-Security-Number-SSN-Trace"
    },
    "sex_offender_search": {
      "name": "Sex Offender Search",
      "description": "Checkr searches sex offender registries in all 50 states and the District of Columbia. Any known sex offender matching your candidate is listed, with identifying information, as well as details of the offense(s) depending on the state or reporting jurisdiction.",
      "url": "https://help.checkr.com/hc/en-us/articles/360001863428-Sex-Offender-Registry-Check"
    },
    "global_watchlist_search": {
      "name": "Global Watchlist",
      "description": "Checkr searches various US and international government watch lists, such as the Office of Foreign Asset Control, Interpol and Specially Designated Nationals.",
      "url": "https://help.checkr.com/hc/en-us/articles/360001951208-Global-Watchlist-Records-Check"
    },
    "national_criminal_search": {
      "name": "National Criminal Database Search",
      "description": "The national criminal search is a multi-jurisdictional search that encompasses numerous sources. It includes national security sources, numerous federal databases, and arrest and criminal data from various local, county and state agencies.",
      "url": "https://help.checkr.com/hc/en-us/articles/360001686567-National-Criminal-Records-Check"
    },
    "municipal_criminal_search": {
      "name": "Municipal Criminal Search",
      "description": "",
      "url": ""
    },
    "county_criminal_search": {
      "name": "County Criminal Search",
      "description": "​Checkr will perform a county criminal search in the applicant's most recent county of residence or all counties returned for that applicant over the last 7 year period, depending on the package acquired. This includes any legally reportable felony and misdemeanor convictions, pending cases and dismissed records.",
      "url": "https://help.checkr.com/hc/en-us/articles/360001686627-County-Criminal-Records-Check"
    },
    "state_criminal_search": {
      "name": "State Criminal Search",
      "description": "",
      "url": "https://help.checkr.com/hc/en-us/articles/360001668148-State-Criminal-Records-Check"
    },
    "federal_criminal_search": {
      "name": "Federal Criminal Search",
      "description": "Federal felony records check at the district level which can reveal cases involving fraud, embezzlement, tax evasion, kidnapping, and more on the federal level. The search performed will review records that extend back to a minimum of seven years from the date of search for convictions.",
      "url": "https://help.checkr.com/hc/en-us/articles/360001882627-Federal-Criminal-Records-Check"
    },
    "county_civil_search": {
      "name": "County Civil Search",
      "description": "",
      "url": "https://help.checkr.com/hc/en-us/articles/360010342574-County-Civil-Records-Check"
    },
  }
}

export const successResp = {
  "independent_verification": {
    "invitation_expires_at": "2021-05-26T06:59:59Z",
    "invitation_status": "pending",
    "invitation_link": "https://apply.checkr.com/invite/chekhub-inc-b9cbe9aded/5958ff58f37a6f03ef3029c38c714382/test"
  },
  "payment": {
    "id": "pi_1IsU0uHjhblmTkwt9oq9Eqj5",
    "status": "succeeded",
    "amount100": 3888,
    "amount": 38.88,
    "currency": "usd"

  }
}
export const declineResp = {
  "independent_verification": {
    "invitation_expires_at": "2021-05-26T06:59:59Z",
    "invitation_status": "pending",
    "invitation_link": "https://apply.checkr.com/invite/chekhub-inc-b9cbe9aded/5958ff58f37a6f03ef3029c38c714382/test"
  },
  "payment": {
    "id": "pi_1IsU0uHjhblmTkwt9oq9Eqj5",
    "status": "declined",
    "amount100": 3888,
    "amount": 38.88,
    "currency": "usd"

  }
}

export const independentVerificationCompleted = {
  report_status: 'pending',
}

export const independentVerificationPending = {
  report_status: 'pending',
  report_result: 'pending'
}

export const independentVerificationFailed = {
  report_status: 'completed',
  report_result: 'consider'
}
export const independentVerificationSuccess = {
  report_status: 'completed',
  report_result: 'clear'
}


export const useBackGroundCheckrStyles = makeStyles(theme => ({
  successfulResp: {
    color: 'green'
  },
  declinedResp: {
    color: 'red'
  }
}))


export const BackGroundCheckerExample = () => {
  const [response, setResponse] = useState(successResp)
  const [verification, setVerification] = useState(independentVerificationPending)
  const dispatch = useDispatch()

  useEffect(async () => {
    const result = await dispatch(getBackgroundCheckPackages()) // const result = await
    console.log(result)
    // dispatch(postPurchaseBgc(purchaseObj) will return action or result
    // await dispatch(fetchTechProfile(techProfileId))
  }, [])

  function changeResp() {
    setResponse(declineResp)
  }

  function changeSuccessResp() {
    setResponse(successResp)
  }
  function changeSuccessVerification() {
    setVerification(independentVerificationSuccess)
  }



  return <>
    <Button onClick={changeResp} color={'primary'}>Change payment to declined</Button>
    <Button onClick={changeSuccessResp} color={'primary'}>Change payment to success</Button>
    <Button onClick={changeSuccessVerification} color={'primary'}>Change Background Check to Success</Button>
    <BackgroundChecker {...{
      response,
      verification
      //eventually will be
      // backgroundCheck: techProfile.background_check,
      //   onPurchaseBgc: handlePurchaseBgc
    }}/>
  </>
}
