import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import ticketFetch from '../store/actions/ticket-fetch'
import { useParams, withRouter } from 'react-router-dom'
import { useAuthUser } from '../hooks/useAuthUser'
import { getUserOrg } from '../util/users.util'
import { Card, CircularProgress, Hidden, Typography } from '@material-ui/core'
import { getDefaultSource, resetDefaultSource } from '../store/actions/wallet.actions'
import Grid from '@material-ui/core/Grid'
import { useBusyIndicatorStyles } from '../components/BusyIndicator'
import { TicketListViewTemplate } from '../components/TicketListViewTemplate'
import ServiceCompanySummary from '../components/ServiceCompanySummary'
import { ChekhubEndUserSummary } from '../components/ChekhubEndUserSummary'
import useLandingViewStyles from '../components/LandingViewStyles'
import { fetchServiceCompany, resetServiceCompanyState } from '../store/actions/service-company.actions'
import { INDEPENDENT_TECH_ORG_TYPE } from '../constants/user.constants'
import { useDimensionsContextStyles } from '../components/DimensionsContext'
import useEditStyles from '../components/ServiceCompanyViewStyles'
import useListStyles from '../components/ListServiceCompanyStyles'
import { fetchTechProfile, techProfileResetState } from '../store/actions/tech-profile.actions'
import { fetchAllPaidTicket } from '../store/actions/ticket.actions'

export const centsToDollars = (cents) => {
  if(!cents) cents = 0;
  cents = parseInt(cents);
  let result = "";
  if(cents < 10) result = "$0.0" + cents.toString();
  else if(cents < 100) result = "$0." + cents.toString();
  else {
    let remainder = cents % 100;
    result = "$" + Math.floor(cents / 100) + "." + (remainder < 10? "0" + remainder : remainder);
  }
  return result;
}


export const TicketListView = withRouter(({
  match
                               }) => {

  const ticketsList = useSelector(({ticket}) => ticket.list)?.filter(item => item.is_connect === true) || []
  const {orgId} = useParams()
  const busyStyles = useBusyIndicatorStyles()
  const landingStyles = useLandingViewStyles()
  const dimensionsContextStyles = useDimensionsContextStyles()
  const dispatch = useDispatch()
  const [open, setDrawerOpen] = useState(false)
  const [selection, setSelection] = useState(0)
  const authorizedUser = useAuthUser()
  const userDefaultOrg = getUserOrg(authorizedUser)
  const chekhubChecker = userDefaultOrg?.organization_type === "customer_company"
  const source = useSelector(({wallet}) => wallet.default_source)
  const profile = useSelector(({serviceCompany}) => serviceCompany)
  const isTech = userDefaultOrg?.organization_type === INDEPENDENT_TECH_ORG_TYPE
  const {userId} = useParams()
  const techProfile = useSelector(({techProfile}) => techProfile)

  const chekhubOrgId = localStorage.getItem("orgId")?.replace(/['"]+/g, '')

  useEffect(async () => {
    !isTech && orgId && await dispatch(getDefaultSource(orgId))
    isTech && dispatch(fetchTechProfile(userId))
    !chekhubOrgId && orgId && !isTech && dispatch(fetchServiceCompany(orgId))
    !isTech ? orgId && await dispatch(ticketFetch({
      orgId: orgId
    })) : techProfile.chekhub_organization_id && await dispatch(ticketFetch({
      orgId: techProfile.chekhub_organization_id
    }))
    !isTech ? await dispatch(fetchAllPaidTicket({
      chekhub_org_id: orgId
    })) : techProfile.chekhub_organization_id && await dispatch(fetchAllPaidTicket({
      chekhub_org_id: techProfile.chekhub_organization_id
    }))
  }, [ , open])


  const handleChange = (ev) => {
    setSelection(ev.target.value)
  }


  useEffect(() => {
    dispatch(resetDefaultSource())
    dispatch(techProfileResetState())
    dispatch(resetServiceCompanyState())
  }, [])

  return <Grid container>
    { !isTech &&
      <Hidden smDown>
        <Grid item xs={3}>
          {!chekhubOrgId ? <ServiceCompanySummary {...{
            profile
          }}/> : <ChekhubEndUserSummary />}
        </Grid>
      </Hidden>
    }
    <Grid
      item
      xs={12}
      sm={12}
      md={!isTech && 9}
      lg={!isTech && 9}
      xl={!isTech && 9}
      className={landingStyles.skeleton}
    >
    {
      ticketsList ? ticketsList.length <= 0 ? <Card className={
      `${landingStyles.membersListCard} ${dimensionsContextStyles.minHeight} ${dimensionsContextStyles.card}`
    } ><Typography variant={'h6'}>No Tickets available</Typography></Card> : ticketsList.length > 0 ?
    <TicketListViewTemplate {... {
      handleChange,
      selection,
      open,
      setDrawerOpen,
      match,
      ticketsList,
      chekhubChecker,
      source,
    }}/> : <Grid
      container
      justify={'center'}
    >
      <CircularProgress {... {
        size: '2em',
        className: busyStyles.spinner,
        color: 'primary'
      }} />
    </Grid> : <Grid
        container
        justify={'center'}
      >
        <CircularProgress {... {
          size: '2em',
          className: busyStyles.spinner,
          color: 'primary'
        }} />
      </Grid>
}
    </Grid>
  </Grid>
})
