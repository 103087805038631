import {Button, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {AddCircleOutlineSharp} from '@material-ui/icons'
import noop from '../util/noop'
import {WorkingDayDisplayItem} from './WorkingDayDisplayItem'

export const useWorkingDaysStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    paddingLeft: 0,
    paddingRight: '10px',
    alignItems: 'baseline'
  },
  dayOfWeekSegment: {
    width: '25%',
    alignSelf: 'center'
  },
  timePadding: {
    paddingRight: '24px'
  },
  buttonSize: {
    fontSize: '24px'
  },
  largeIcon: {
    '& svg': {
      fontSize: 35
    }
  },
  keyboardTimePicker: {
    '& .MuiFormHelperText-root' :{
      marginBottom: theme.spacing(-3)
    }
  }
}))

export const ListItemSegment = ({children, className = '', ...restProps}) =>
  <Typography {...{
    variant: 'body1',
    variantMapping: {body1: 'span'},
    className,
    ...restProps
  }}>{children}</Typography>

const WorkingDaysDisplay = ({
  working_hours,
  setEditHoursFlag,
  editHoursFlag = true,
  showAllowButtonOrNot = noop,
  title
}) => {
  const setEditFlag = () => () => {
    setEditHoursFlag(true)
    showAllowButtonOrNot(false)
  }

  return <Grid container>
    <Grid container justify={'space-between'}>
      <Grid item xs={8}>
        {title}
      </Grid>
      {!editHoursFlag && <Grid item xs={4}>
        <Button {...{
          size: 'small',
          variant: 'outlined',
          color: 'primary',
          onClick: setEditFlag(),
          startIcon: <AddCircleOutlineSharp fontSize="small"/>
        }}>
          Edit Working Days
        </Button>
      </Grid>}
    </Grid>
    <Grid container direction={'row'}>
      {
        working_hours &&
        working_hours.map(
          (days, index) => days &&
            <WorkingDayDisplayItem {...{
              days,
              index,
              key: `day-${index}`
            }}/>
        )
      }
    </Grid>
  </Grid>
}

export default WorkingDaysDisplay



