import {setBackendWorking, setBackendWorkingDone} from './backend-working.actions'
import {getRespError} from '../../services/get-resp-error.util'
import toastShow, {TOAST_TYPE_ERROR} from './toast-show'
import {
  getNewUploadUrl,
  downloadStorageContent,
  uploadFileToUrl
} from '../../services/storage.service'
import {saveAs} from 'file-saver'

export const uploadFile = file => async dispatch => {
  let result
  dispatch(setBackendWorking())
  try {
    const urlResp = await getNewUploadUrl(file)
    if (!urlResp.ok) {
      throw await getRespError(urlResp)
    }
    const respBody = (await urlResp.json()).data
    const {upload_url, filename} = respBody
    const uploadResp = await uploadFileToUrl(file, upload_url)
    if (!uploadResp.ok) {
      throw await getRespError(uploadResp)
    }
    result = filename
  } catch (e) {
    result = e
    console.error(e)
    dispatch(toastShow(TOAST_TYPE_ERROR, e.message))
  } finally {
    dispatch(setBackendWorkingDone())
  }
  return result
}

export const downloadFile = (filename, docName) => async dispatch => {
  let result
  dispatch(setBackendWorking())
  try {
    const resp = await downloadStorageContent(filename)
    if (!resp.ok) {
       throw await getRespError(resp)
     }
     saveAs(
       await resp.blob(),
       !docName ? filename : docName
     )
  } catch (e) {
    result = e
    console.error(e)
    dispatch(toastShow(TOAST_TYPE_ERROR, e.message))
  } finally {
    dispatch(setBackendWorkingDone())
  }
  return result
}
