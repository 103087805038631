import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import actions from '../store/actions'
import { setBackendWorking, setBackendWorkingDone } from '../store/actions/backend-working.actions'
import queryString from 'query-string'
import {
  CHEKHUB_END_USER_LANDING,
  DASHBOARD_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  SIGN_UP_PATH_INDY
} from '../routing/route.constants'
import { chekhubCustomerSignIn, signInAction } from '../store/actions/sign-in.actions'
import { Box, Button, Card, Divider, Grid, Hidden, Typography } from '@material-ui/core'
import useSignInStyles from '../components/SignInStyles'
import Flippy, { BackSide, FrontSide } from 'react-flippy'
import { SignInFront } from '../components/SignInFront'
import { SignInChooseChekhubOrg } from '../components/SignInChooseChekhubOrg'
import SignInJumbotron from '../components/SignInJumbotron'
import { getDefaultRedirectPath } from '../util/users.util'
import { FLIPPY_TRANSITION_TIME } from '../constants/transition-times.constants'
import { SignInForgotPassword } from '../components/SignInForgotPassword'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { fetchChekhubOrgList } from '../store/actions/service-company.actions'
import { userProfileSetState } from '../store/actions/user-profile.actions'
import { useLocalStorage } from '../util/localStorageHack.util'
import { FormSubmitButton } from '../components/FormSubmitButton'
import { CHEKHUB_ORANGE } from '../theme'
import { Form } from 'formik'

const getQueryRedirectPath = location => {
  console.log('query')

  return queryString.parse(location.search).redirect
}
const useSignInViewStyles = makeStyles(theme => ({
  root: {
    height: '96vh',
  },
  cardFix: {
    backgroundColor: '#fafafa',
  }
}))
const SignInView = () => {
  const viewStyles = useSignInViewStyles()
  const styles = useSignInStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const firebase = useSelector((state) => state.firebase)
  const {
    user,
    backgroundRunning,
    authError
  } = useSelector(
    ({auth}) => auth)
  const INIT_CREDENTIALS_STATE = {
    email: null,
    password: null,
  }
  const [credentials, setCredentials] = useState(INIT_CREDENTIALS_STATE)
  const [useChekhubSignIn, setUseChekhubSignIn] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [activeOrgValue, setActiveOrgValue] = useState('')
  const [rememberThisUser, setToRememberThisUser] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const history = useHistory()
  const [orgId, setOrgId] = useLocalStorage("orgId", activeOrgValue)
  const renderRedirect = (
    !firebase.auth.isEmpty || !firebase.auth.isLoaded
  ) && user && !useChekhubSignIn

  const flip = Boolean(
    (useChekhubSignIn && !firebase.auth.isEmpty && user) ||
    forgotPassword
  )

  useEffect(() => {
    localStorage.clear()
  }, [])

  const onChange = ({target}) => {
    target.type === 'checkbox' ?
      setUseChekhubSignIn(target.checked)
      :
      setCredentials({
        ... credentials,
        [target.id]: target.value
      })
  }
  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(signInAction(credentials))
  }
  const activeOrgOnChange = async ({
                                     target: {
                                       value: orgId,
                                       orgRole: orgRole,
                                     }
                                   }) => {
    // TODO: Abstract into a redux action to dispatch

    await dispatch(setBackendWorking())

    setActiveOrgValue(orgId)
    setOrgId(orgId)

    await dispatch({
      type: 'AUTH/SET-ACTIVE-ORG',
      payload: {
        activeOrgObject: {},
        orgId,
        userRole: orgRole || ''
      }
    })
    history.push(`/chekhub-user/${orgId}/landing`)
    dispatch(setBackendWorkingDone())
  }


  const handleRememberMeChecked = (e) => {
    setToRememberThisUser(e.target.checked)
  }
  const handlePasswordShow = ({target}) => {
    showPassword ? setShowPassword(false) : setShowPassword(true)
  }

  return renderRedirect ?
    <Redirect to={
      history.location.pathname === SIGN_IN_PATH ? getDefaultRedirectPath(user, history.location.pathname) :
        getQueryRedirectPath(location)
    }/> :
    <Grid
      container
      direction={'row'}
      className={viewStyles.root}
    >
      <Grid container
            justify="center"
            className={styles.signInHolder}
      >
        <Grid item xs={9} style={{
          paddingTop: '15px'
        }}>
          <Flippy {... {
            style: {
              transition: `${FLIPPY_TRANSITION_TIME}ms`
            },
            isFlipped: flip
          }}>
            <FrontSide {... {
              // TODO: This was the only way to get the backside visibility hidden to work with floating labels and
              //  spinner svg's for some reason. Go open a GitHub issue on Flippy
              className: `${styles.flippyFront}${flip ? ' hideContent' : ''}`,
              animationDuration: FLIPPY_TRANSITION_TIME
            }}>
              <SignInFront {... {
                onChange,
                onSubmit,
                backgroundRunning,
                authError,
                useChekhubSignIn,
                styles,
                setForgotPassword,
                handleRememberMeChecked,
                rememberThisUser,
                handlePasswordShow,
                showPassword
              }} />
            </FrontSide>
            <BackSide {... {
              className: `${styles.flippyBack}${!flip ? ' hideContent' : ''}`,
              animationDuration: FLIPPY_TRANSITION_TIME
            }} >
              {
                useChekhubSignIn ? <SignInChooseChekhubOrg {... {
                  user,
                  styles,
                  activeOrgValue,
                  activeOrgOnChange,
                  backgroundRunning,
                }} /> : <SignInForgotPassword {... {
                  styles,
                  backgroundRunning,
                  setForgotPassword
                }} />
              }
            </BackSide>
          </Flippy>
        </Grid>
      </Grid>
    </Grid>

}

export default SignInView
