import axios from 'axios'

import appConfig from '../../../config'
import useAccessToken from '../useAccessToken'

const { chekhubConnect: { baseURI, defaultHeaders, paths } } = appConfig

const uploadFile = async ({
  file,
  filename,
  fileType,
  profileType,
}, getFirebase) => {
  const { storageService } = paths
  const accessToken = await useAccessToken()

  try {
    const { status: signedUrlStatus, data: body } = await axios({
      baseURL: baseURI,
      url: storageService,
      method: 'POST',
      headers: {
        ...defaultHeaders,
        authorization: `Bearer ${accessToken}`,
      },
      data: {
        filename,
        document_type: fileType,
        owner: {
          type: profileType,
        },
      },
    })
    if (signedUrlStatus !== 200) throw new Error('unknown signedUrlError')
    const { data: { filename: storageFilename, upload_url: uploadUrl } } = body

    const { status: uploadStatus } = await axios.put(uploadUrl, file) /* { Track upload progress
      onUploadProgress: (event) => {
        const {
          loaded,
          total,
        } = event
        const uploadProgress = Math.round(loaded / total * 100)
        dispatch({
          type: 'UPLOAD_PROGRESS',
          payload: {
            uploadProgress,
          },
        })
      }
    }) */
    if (uploadStatus !== 200) throw new Error('unknown uploadError')
    return [{
      key: filename,
      storageFilename,
    }, null]
  } catch (err) {
    return [null, err]
  }
}

export default uploadFile
