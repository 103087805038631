import { createContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const INIT_BLUR = false
// TODO: adjust this to have "pushBlur" and "popBlur" as well as blur for main wrapper
//  as well as drawer blur
export const Context = createContext(INIT_BLUR)
export const Provider = ({children}) => {
  const [blurred, setBlurred] = useState(INIT_BLUR)
  const history = useHistory()

  useEffect(() => history.listen(() => {
      if (history.action === 'POP') {
          setBlurred(false)
      }
    }), [history])

  return <Context.Provider {...{
    value: {
      blurred,
      setBlurred
    }
  }}>{children}</Context.Provider>
}
