import React from "react";
import Check from '@material-ui/icons/Check'
import useSignUpStepperStyles from '../styles/SignUpStepperStyles'

const SignUpStepper = (step) => {
  const classes = useSignUpStepperStyles();
  const { active, completed } = step;
  const wrapperDivClasses = active ?
    classes.active :
    completed ?
      classes.completed :
      classes.notCompleted
  return (
    <div
      {...{
        className: wrapperDivClasses
      }} >
      {
        completed ?
          <Check className={classes.completed} /> :
          <div className={classes.circle} />
      }
    </div>
  );
}

export default SignUpStepper
