import { setBackendWorking, setBackendWorkingDone } from './backend-working.actions'
import toastShow, { TOAST_TYPE_ERROR } from './toast-show'
import { getRespError } from '../../services/get-resp-error.util'
import { getResponseBody } from '../../services/get-response-body.util'

export const dispatchService = dispatch =>
  (serviceMethod, resultActionThunk, processFullRespBody = false) =>
    async (...serviceCallArgs) => {
      let error = undefined
      let setStatePromise
      dispatch(setBackendWorking())
      try {
        const resp = await serviceMethod(...serviceCallArgs)
        if (!resp.ok) {
          throw await getRespError(resp)
        }
        const respBody = await getResponseBody(resp)
        setStatePromise = resultActionThunk && await dispatch(resultActionThunk(
          !processFullRespBody ? respBody.data || respBody : respBody
        ))
      } catch (err) {
        error = err
        console.error(err)
        dispatch(toastShow(TOAST_TYPE_ERROR, err.message))
      } finally {
        dispatch(setBackendWorkingDone())
      }
      return error || setStatePromise
    }
