import {serviceCompanySetState} from '../store/actions/service-company.actions'
import addressToLocation from '../components/shared-functions/address-to-location'

export const handleServiceCompanyChange = (setValid, dispatch) => updatedProfile => {
  const valid = true
  setValid(valid)
  dispatch(serviceCompanySetState(updatedProfile))
}

export const COMPANY_FORMATION_YEAR_FIELD_NAME = 'company_formation_year'
export const WEBSITE_FIELDNAME = 'website'
export const LINKEDIN_PAGE_FIELDNAME = 'linkedin_page'
export const ADDRESS_FIELDNAME = 'address-autocomplete'
export const handleServiceCompanyProfileChange = (profile, handleChange) => (e) => {
  const numberParse = [COMPANY_FORMATION_YEAR_FIELD_NAME]
  const {id: key, value, checked, name} = e.target || {}
  let newValue = value
  if (key === 'eoc') {
    const newProfile = {
      ...profile,
      eoc: {
        ...profile.eoc,
        [name]: checked
      }
    }
    return handleChange(newProfile)
  }
  if (key === 'location.label') {
    const newProfile = {
      ...profile,
      location: {
        ...profile.location,
        label: value
      }
    }
    return handleChange(newProfile)
  }
  newValue = numberParse.includes(key) ? Number(value) : value
  const newProfile = {
    ...profile,
    [key]: newValue
  }
  return handleChange(newProfile)
}

export const handleServiceCompanyAddressChange = (profile, handleChange) => (addressDetails) =>
  handleChange({
    ...profile,
    location: addressToLocation(addressDetails, profile.location)
  })

export const handleServiceCompanyFormationYearChange = (profile, handleChange) => (date) =>
  handleChange({
    ...profile,
    company_formation_year: date.getFullYear()
  })

export const handleServiceCompanyPhoneChange = (phones, profile, handleChange) => (e) => {
  const [, idx, prop] = e.target.id.split('.')
  // TODO: make this method pure and stop mutating externally scoped objects
  phones[idx][prop] = e.target.value
  return handleChange({...profile, phones})
}

export const beginsWithProtocol = /^https?:\/\//i
export const prependProtocol = str => beginsWithProtocol.test(str) ? str : `http://${str}`
export const beginsWithLinkedIn = /^https?:\/\/(w{3}\.)?linkedin\.com/i
