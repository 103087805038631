import {createReducer} from 'redux-create-reducer'
import {
  SET_STEPPER_SC_FLOW,
  SET_STEPPER_TECHS_FLOW,
  STEPPER_RESET_STATE,
  STEPPER_SET_ACTIVE_STEP, STEPPER_SET_COMPLETED_STEP
} from '../actions/stepper.actions'

const SC_SIGN_UP_STEPS = [
  {
    label: 'Service Company Sign Up',
    active: true,
    completed: false,
    stepNumber: 0
  },
  {
    label: 'Create Service Company Profile',
    active: false,
    completed: false,
    stepNumber: 1
  },
]
const TECHS_SIGN_UP_STEPS = [
  {
    label: 'Independent Tech Sign Up',
    active: true,
    completed: false,
    stepNumber: 0
  },
  {
    label: 'Complete Independent Tech Profile',
    active: false,
    completed: false,
    stepNumber: 2
  },
]

export const STEPPER_INIT_STATE = [
  {
    label: 'Sign Up',
    active: true,
    completed: false,
    stepNumber: 0
  },
  {
    label: 'Create Service Company Profile',
    active: false,
    completed: false,
    stepNumber: 1
  },
]


const stepperReducer = createReducer(
  STEPPER_INIT_STATE,
  {
    [STEPPER_SET_ACTIVE_STEP]: (steps, {activeStep}) => {
      const activeIndex = steps.findIndex(step => step.stepNumber === activeStep)

      const clearedActives = [
        ...steps.map(
          step => ({...step, active: false})
        )
      ]
      return [
        ...clearedActives.slice(0, activeIndex),
        {
          ...steps[activeIndex],
          active: true
        },
        ...clearedActives.slice(activeStep + 1),
      ]
    },
    [STEPPER_SET_COMPLETED_STEP]: (steps, {completedStep}) => {
      const completedIndex = steps.findIndex(step => step.stepNumber === completedStep)
      return steps.map((step) => {
        if(step.stepNumber === completedIndex){
          return {
            ...step,
            completed: true
          }
        }
        return step
      })
    },
    [SET_STEPPER_SC_FLOW]: () => SC_SIGN_UP_STEPS,
    [SET_STEPPER_TECHS_FLOW]: () => TECHS_SIGN_UP_STEPS,
    [STEPPER_RESET_STATE]: () => STEPPER_INIT_STATE
  }
)

export default stepperReducer
