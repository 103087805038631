import React from 'react'

import moment from 'moment'

export default function DateRender({
  dateString = null,
  includeDate = true,
  includeRelativeDate = false,
  dateFormat = 'MMM Do YYYY',
}) {
  if (!dateString) return ('')
  const m = moment(dateString)
  const formattedDate = m.format(dateFormat)
  const relativeDate = m.fromNow()

  if (includeDate && !includeRelativeDate) return (<span>{formattedDate}</span>)
  if (!includeDate && includeRelativeDate) return (<span>{relativeDate}</span>)
  return (<span>{relativeDate} ({formattedDate})</span>)
}
