import axios from 'axios'

import appConfig from '../../config'
import useAccessToken from './useAccessToken'

const authFetchUserFunction = async (userId, dispatch, getState, getFirebase) => {
  const { chekhubConnect: { baseURI, operations, defaultHeaders } } = appConfig
  const { usersFetchOne: { path, method, authRequired } } = operations
  const token = authRequired ? await useAccessToken() : null

  try {
    const { status: userFetchStatus, data: body } = await axios({
      baseURL: baseURI,
      url: `${path}/${userId}`,
      method,
      headers: {
        ...defaultHeaders,
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
    })
    if (userFetchStatus !== 200) throw new Error('unknown chekhubAuthError')
    dispatch({ type: 'AUTH/FETCH-USER-OK', payload: body.data })
  } catch (err) {
    dispatch({
      type: 'TOAST/TRIGGER-NOTIFICATION',
      payload: {
        type: 'error',
        message: 'There was an error loading the user',
      },
    })
    dispatch({ type: 'AUTH/FETCH-USER-ERROR', payload: err })
  }
}

const authFetchUser = (userId) => async (dispatch, getState, getFirebase) => {
  authFetchUserFunction(userId, dispatch, getState, getFirebase)
}

export { authFetchUser, authFetchUserFunction }
