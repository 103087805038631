import {SERVICE_COMPANY_INIT_STATE} from './service-company.reducer'

const initState = {
  fetched: false,
  data: null,
  err: null
}

const techInitState = {
  birth_date: null,
  phones: null,
  service_radius: 20,
  hour_rate: null,
  location: {
    label: 'work',
    lat: null,
    long: null,
    address: {},
  },
  insurance_policies: [],
  certifications: [],
  work_experience: [],
  err: null
}

const profileReducer = (state = initState, action) => {
  if (['PROFILE/RESET'].includes(action.type)) {
    return initState
  }
  if (['PROFILE/FETCH-OK'].includes(action.type)) {
    const { payload: { isTechProfile, data } } = action
    return {
      ...state,
      fetched: true,
      data: data || (isTechProfile ? techInitState : SERVICE_COMPANY_INIT_STATE),
    }
  }
  if (['PROFILE/UPSERT-OK'].includes(action.type)) {
    return {
      ...state,
      data: action.payload,
    }
  }
  if (['PROFILE/SUBCOLLECTION-UPSERT-OK', 'PROFILE/SUBCOLLECTION-DELETE-ITEM-OK'].includes(action.type)) {
    return {
      ...state,
      data: action.payload.profile,
    }
  }
  if (['PROFILE/FETCH-ERROR'].includes(action.type)) {
    return {
      ...initState,
      err: action.payload
    }
  }
  return state
/*   switch (action.type) {
    case 'SESSION_INIT':
      return {
        ...state,
        ...action.payload,
        uploadSucess: null,
        uploadInProgress: true,
        uploadProgress: 0,
      }
    case 'SESSION_RESET':
      return {}
    case 'SESSION/SYNC_SUCCESS':
      return {
        ...state,
        ...action.payload,
      }
    case 'SESSION_SYNC_SUCCESS':
    return {
      ...state,
      ...action.payload,
    }
    case 'UPLOAD_PROGRESS':
      return {
        ...state,
        ...action.payload,
      }
    case 'UPLOAD_SUCCESS':
      return {
        ...state,
        uploadSucess: true,
        uploadInProgress: false,
      }
    case 'UPLOAD_FAILED':
      return {
        ...state,
        uploadSucess: false,
        uploadInProgress: false,
      }
    default:
      return state
  } */
}

export default profileReducer
