import {SET_BACKEND_WORKING} from '../actions/backend-working.actions'
import {IDENTITY_PROVIDER_SIGN_IN_OK} from '../actions/identity-provider-auth.actions'

const initState = {
  authError: null,
  backgroundRunning: false,
  userType: null,
  userRole: null,
  user: null,
  activeOrg: null,
  activeOrgObject: {}
}

const authReducer = (state = initState, action) => {
  if ([SET_BACKEND_WORKING].includes(action.type)) {
    // TODO: make this work for tracking multiple network activities
    return {
      ...state,
      backgroundRunning: action.backendRunning,
    }
  }
  if (['AUTH/FETCH-USER-OK'].includes(action.type)) {
    return {
      ...state,
      user: action.payload,
    }
  }
  if (['AUTH/SET-ACTIVE-ORG'].includes(action.type)) {
    return {
      ...state,
      activeOrg: action.payload.orgId,
      userRole: action.payload.userRole,
      activeOrgObject: action.payload.activeOrgObject
    }
  }
  if (['AUTH/CLEAR-ERROR'].includes(action.type)) {
    return {
      ...state,
      authError: null,
    }
  }
  if (['AUTH/SIGN-IN-ERROR'].includes(action.type)) {
    const {error} = action
    return {
      ...initState,
      authError: 'auth/sign-in-failed',
      lastError: error
    }
  }
  if (['AUTH/SIGN-IN-OK', 'AUTH/TOKEN-REFRESHED', IDENTITY_PROVIDER_SIGN_IN_OK].includes(action.type)) {
    return {
      ...state,
      authError: null,
      userType: action.payload || action.userType,
    }
  }
  if (['AUTH/SIGN-OUT-OK'].includes(action.type)) {
    return initState
  }
  if (['AUTH/SIGN-UP-ERROR'].includes(action.type)) {
    return {
      ...state,
      authError: 'auth/sign-up-failed',
    }
  }
  if (['AUTH/SIGN-UP-OK'].includes(action.type)) {
    return {
      ...state,
      authError: null,
    }
  }
  return state
}

export default authReducer
