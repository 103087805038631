import {RouteConnectedDrawer} from "./RouteConnectedDrawer"
import {Redirect, Route, withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux"
import {fetchServiceCompany} from "../store/actions/service-company.actions"
import ServiceCompanyEditView from "../pages/ServiceCompanyEdit.view"
import {isServiceCompanyManager} from '../util/users.util'
import {useAuthUser} from '../hooks/useAuthUser'

const ServiceCompanyDrawer = withRouter(
  ({
    open,
    setOpen,
    backRoutePath,
    match,
    serviceCompanyId
  }) => {
    const dispatch = useDispatch()
    const {
      serviceCompany
    } = useSelector(
      ({
        serviceCompany
      }) => ({
        serviceCompany
      })
    )
    const authorizedUser = useAuthUser()
    const isManager = isServiceCompanyManager(authorizedUser, serviceCompanyId)

    return <Route exact path={`${match.path}/service-company-edit`}>
      {
        isManager ? <RouteConnectedDrawer {...{
          open,
          setOpen,
          anchor: 'left',
          title: !serviceCompany.company_name ? '' : serviceCompany.company_name,
          onClose: () => dispatch(fetchServiceCompany(serviceCompanyId)),
          backRoutePath
        }}>
          {
            <ServiceCompanyEditView {...{
              setDrawerOpen: setOpen,
              backRoutePath
            }}/>
          }
        </RouteConnectedDrawer> :
        <Redirect to={match.url} />
      }
    </Route>
})

export default ServiceCompanyDrawer
