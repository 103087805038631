import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import {
  Paper,
  Grid,
  Button,
} from '@material-ui/core'

import useRootStyles from '../styles/rootStyles'

import actions from '../store/actions'
import appConfig from '../config'

function TicketViewComponent({ ticketId }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const styles = useRootStyles()
  const { chekhubFrontend: { baseURI } } = appConfig
  const ticket = useSelector((state) => state.ticket.ticket)
  const newLog = useSelector((state) => state.ticket.newLog)
  const { tasks } = ticket

  useEffect(() => {
    if (ticket?._id !== ticketId) {
      dispatch(actions['TICKET/FETCH']({ orgId: 0, ticketId }))
    }
  }, [])

  useEffect(() => {
    if (newLog) {
      window.open(
        `${baseURI}/dashboard?dialog=execute&log=${newLog._id}`,
        'chekhubWindow',
      )
      dispatch(actions['TICKET/CLEAR-LOG']())
    }
  }, [newLog])

  const formatStatus = (status) => {
    switch (status) {
      case ('in_progress'):
        return 'In Progress'
      default:
        return status[0].toUpperCase() + status.slice(1)
    }
  }

  return (
    <div>
      <Button
         variant="contained"
         onClick={() => {
           dispatch(actions['TICKET/SET'](null))
           history.push('/dashboard')
         }}
         >
         Back
      </Button>
      <Paper className={styles.paddedPaper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>{ticket.title}</Grid>
          <Grid item xs={12} sm={12} md={12}><b>Due:</b> {ticket.due && moment(ticket.due).format('MM/DD/YYYY')}</Grid>
          <Grid item xs={12}><b>Priority:</b> {ticket.priority}</Grid>
          <Grid item xs={12}><b>Status:</b> {formatStatus(ticket.status)}</Grid>
        </Grid>
      </Paper>

      {tasks
        ? tasks.map((task) => {
          return <Paper className={styles.paddedPaper}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>{task.checklist_name}</Grid>
              <Grid item xs={12} md={3}><b>Status:</b> {task.status}</Grid>
              <Grid item xs={12} md={12}>
                <b>Participants:</b>
                {task.participants.length > 1 ? <br /> : <span>&nbsp;</span>}
                {task.participants.map((person) => {
                  return `${person.first_name} ${person.last_name}`
                })}
              </Grid>
              { task.log
                ? <Grid item xs={12}>
                    <div>
                      <b>Started: </b>
                      {moment(task.log?.started_at).format('MM/DD/YYYY, HH:mm:ss A')}
                    </div>
                    <div>
                      <b>Started By: </b>
                      {task.log?.started_by.name}
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        href={`${baseURI}/dashboard?dialog=execute&log=${task.log_id}`}
                        target='_blank'
                      >
                        Resume
                      </Button>
                    </div>
                  </Grid>
                : <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        dispatch(actions['TICKET/CREATE-LOG']({
                          checklistId: task?.checklist_id,
                          locationId: task?.asset_or_location._id,
                          taskNumber: task?.number,
                          ticketId: ticket?._id,
                        }))
                      }}
                    >
                      Start
                    </Button>
                  </Grid>
              }
            </Grid>
          </Paper>
        })
        : 'No tasks are available'
      }
    </div>
  )
}

export default TicketViewComponent
