import axios from 'axios'

import appConfig from '../../../config'

const { chekhubConnect: { baseURI, defaultHeaders, paths } } = appConfig

const profileUpsertFunction = async (profile, accessToken, isNewProfile = false, userId = null) => {
  const { techProfileService } = paths

  try {
    const { status, data: body } = await axios({
      baseURL: baseURI,
      url: techProfileService + (isNewProfile ? '' : `/${userId}`),
      method: isNewProfile ? 'POST' : 'PUT',
      headers: {
        ...defaultHeaders,
        authorization: `Bearer ${accessToken}`,
      },
      data: {
        ...profile,
        _id: userId || profile._id,
      },
    })

    if (status !== 200) { throw new Error('unknown profileUpsertError') }
    return [body.data, null]
  } catch (err) {
    return [null, err]
  }
}

export default profileUpsertFunction
