import React, {useEffect} from "react"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import SignUpStepper from "../components/SignUpStepper"
import StepToConnector from '../components/SignUpStepper'
import StepLabel from "@material-ui/core/StepLabel"
import useStyles from '../styles/SignUpStyles'
import useStepperStyles from '../styles/SignUpStepperStyles'
import withStyles from "@material-ui/core/styles/withStyles"
import StepConnector from "@material-ui/core/StepConnector"
import {useDispatch, useSelector} from "react-redux"
import {useHistory} from "react-router-dom"
import {
  SIGN_UP_PATH,
  SIGN_UP_SC_CREATE_PATH,
  SIGN_UP_COMPLETE_PROFILE_PATH,
  SIGN_UP_COMPLETE_TECH_PROFILE_PATH
} from '../routing/route.constants'
import {setActiveStep, setCompletedStep} from "../store/actions/stepper.actions"
import {Paper} from "@material-ui/core"
import {useMainNavBarStyles} from "./MainNavBarStyles"

const StepperUp = () => {
  const classes = useStyles()
  const styles = useMainNavBarStyles()
  const stepperClasses = useStepperStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const stepper = useSelector(state => state.stepper)
  const activeStep = stepper.find(step => step.active)
  const activeStepNumber = activeStep.stepNumber

  useEffect(() => {
    switch (history.location.pathname) {
      case SIGN_UP_PATH:
        dispatch(setActiveStep(0))
        break
      case SIGN_UP_SC_CREATE_PATH:
        dispatch(setActiveStep(1))
        dispatch(setCompletedStep(0))
        break
      case SIGN_UP_COMPLETE_PROFILE_PATH:
        dispatch(setActiveStep(2))
        dispatch(setCompletedStep(1))
        break
      case SIGN_UP_COMPLETE_TECH_PROFILE_PATH:
        dispatch(setActiveStep(2))
        dispatch(setCompletedStep(1))
        break
    }
  },[history.location.pathname])



  const StepToConnector = withStyles(theme => ({

    alternativeLabel: {
      top: 6,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: theme.palette.secondary.main,
      },

    },
    completed: {
      '& $line': {
        borderColor: theme.palette.primary.main,
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }))(StepConnector)

  return (
    <Paper elevation={2} className={styles.paper} >
      <Stepper alternativeLabel activeStep={activeStepNumber} connector={<StepToConnector/>}>
        {stepper.map(step =>
          <Step key={step.stepNumber}>
          <StepLabel
            {...{
              className: step.completed && stepperClasses.completedLabel
            }}
            StepIconComponent={SignUpStepper}>{step.label}</StepLabel>
          </Step>
        )}
      </Stepper>
    </Paper>
  )
}

export default StepperUp
