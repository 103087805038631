import { Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { getHumanReadableDate } from '../util/date-time.util'
import { Link, withRouter } from 'react-router-dom'
import { humanizeScreeningName } from './BackGroundCheckPackageCard'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { centsToDollars } from '../pages/TicketList.view'
import { useSelector } from 'react-redux'


export const TicketList = withRouter(({
  ticketsList = [],
  chekhubChecker,
  match,
  selection,
                           }) => {
  const paidTickets = useSelector(({paidTickets}) => paidTickets)
  const inProgress = ticketsList?.filter(item =>
    item.status ===
    'in_progress')
  const assigned =  ticketsList?.filter(item =>
    item.status ===
    'assigned')
  const completed = ticketsList?.filter(item =>
    item.status ===
    'completed')
  const ticketList = selection === 0 ? [...inProgress, ...assigned].filter(item => !(paidTickets.some(e => e._id === item._id))) : selection === 1 ?
    completed.filter(item => !(paidTickets.some(e => e._id === item._id))) : [...paidTickets]


  return ticketList && <TableContainer component={Card} style={{
    minHeight: '85vh'
  }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          {ticketList.length > 0 ? <TableRow>
            <TableCell width={100}>Ticket Title</TableCell>
            <TableCell>Ticket Status</TableCell>
            <TableCell>Due</TableCell>
            <TableCell align="right" width={45}>Price</TableCell>
            <TableCell align="right">{!chekhubChecker ? 'Assigned By' : 'Assigned To'}</TableCell>
            <TableCell/>
          </TableRow> : <Grid container justify={'center'} alignContent={'center'} style={{
            marginTop: '5vh'
          }}><Typography variant={'h6'}>
            No Tickets
          </Typography>
          </Grid>}
        </TableHead>
        <TableBody>
          {ticketList.length > 0 && ticketList.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.title}
              </TableCell>
              <TableCell>{humanizeScreeningName(row.status)}</TableCell>
              <TableCell>{getHumanReadableDate(row.due)}</TableCell>
              <TableCell align="right" width={10}>{centsToDollars(row.billing_total)}</TableCell>
              <TableCell align="right">{row.owner.name}</TableCell>
              {
                <TableCell align="right">
                <Link to={`${match.url}/${row._id}/payment`}>
                  {row.status !== 'completed' || 'open' ? 'View Ticket' : 'Pay Now'}
                </Link>
              </TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

})
