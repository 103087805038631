import {
  deleteToAddToTeamPath,
  postToAddToTeamPath,
  teamAcceptanceSc,
  teamAccepts
} from '../../services/addToTeam.service'
import { dispatchService } from './dispatch-service.util'


export const checkResponse = (respBody) => dispatch => {
  console.log('firing', respBody)
}


export const sendChekhubOrgInvite = ({service_provider_id, chekhub_org_id, requirements}) => async dispatch =>
  dispatchService(dispatch)(postToAddToTeamPath, respBody => dispatch => {
    dispatch(checkResponse(respBody?.data))
  })
  (service_provider_id, chekhub_org_id, requirements)

export const deleteToChekhubTeams = (service_provider, chekhub_org_id) => async dispatch =>
  await dispatchService(dispatch)(deleteToAddToTeamPath)
  (service_provider, chekhub_org_id)

export const teamAcceptance = ({chekhub_org_id, onboarding_request_id}) => async dispatch =>
  dispatchService(dispatch)(teamAccepts)(chekhub_org_id, onboarding_request_id)

export const teamAcceptanceScRoute = ({service_provider_id, onboarding_request_id}) => async dispatch =>
  dispatchService(dispatch)(teamAcceptanceSc)(service_provider_id, onboarding_request_id)
