import { useState } from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'
import { FieldDisplay } from '../../../FieldDisplay'
import Grid from '@material-ui/core/Grid'
import { CloudDownloadLink } from '../../../CloudDownloadLink'
import { EditRemoveButtons } from '../../../EditRemoveButtons'
import { FormSubmitButton } from '../../../FormSubmitButton'


export const OnboardingDocsExample = () => {
  const [docs, setDocs] = useState([])

  return <Card style={{
    padding: '10px'
  }}>
    <Typography variant={'h5'}>
      Required Documents Presetting
    </Typography>
    <CardContent>
      <Grid container justify={'space-between'}>
      <Typography>
        Preset 1
      </Typography>
        <EditRemoveButtons />
      </Grid>
      <Grid container>
        <Grid item xs={6} style={{
          paddingTop: '10px'
        }}>
      <FieldDisplay label={'NDA'}/>
          <Typography  style={{
            paddingTop: '10px'
          }}>
            This is an NDA
          <CloudDownloadLink cloudFilename={'This is an NDA'} style={{
            marginTop: '4px',
            marginLeft: '5px'
          }}/>
          </Typography>
        </Grid>
        <Grid item xs={6} style={{
          paddingTop: '10px'
        }}>
          <FieldDisplay label={'Insurance'}/>
          <Typography  style={{
            paddingTop: '10px'
          }}>
            Insurance102
            <CloudDownloadLink cloudFilename={'Insurance102'} style={{
              marginTop: '4px',
              marginLeft: '5px'
            }}/>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{
          paddingTop: '10px'
        }}>
          <FormSubmitButton>
            Add a New Preset
          </FormSubmitButton>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
}
