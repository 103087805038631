import {
  Box,
  duration,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { SectionHeader } from '../components/SectionHeader'
import Grid from '@material-ui/core/Grid'
import { FieldDisplay } from '../components/FieldDisplay'
import PhoneNumbersDisplay from '../components/PhoneNumbersDisplay'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchChekhubOrg, resetServiceCompanyState } from '../store/actions/service-company.actions'
import { getUserOrg } from '../util/users.util'
import { CloudDownloadLink } from './CloudDownloadLink'
import { DropZoneDialogTrigger } from './DropZoneDialogTrigger'
import { MimeTypes } from 'http-headers-js'
import { FormSubmitButton } from './FormSubmitButton'
import { ReUploadRequirementsModal } from './ReUploadRequirementsModal'
import { getInvitedTeamRequets, getInvitedTeamsSc } from '../store/actions/invited-teams-list.actions'
import { CloseSharp } from '@material-ui/icons'
import CheckIcon from '@material-ui/icons/Check';
import { checkProperties } from './CurrentInvitedTeamsTemplate'
import { useMultiBusy } from 'react-multi-busy'
import { deleteToChekhubTeams } from '../store/actions/AddToTeams.actions'
import { isServiceErrorResult } from '../services/is-service-error-result.util'
import toastShow, { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '../store/actions/toast-show'
import { closeDrawerAndTransition } from './ServiceCompanyMemberDrawer.util'
import { useAuthUser } from '../hooks/useAuthUser'
import { INDEPENDENT_TECH_ORG_TYPE } from '../constants/user.constants'


export const  TeamProviderSummarySc = ({
                                         teamOrProviderList,
                                      setOpen,
                                      backRoutePath,
                                      routeTransitionDelay = duration.leavingScreen,
                                      setBlurred,
                                        onDialogOpen,
                                        setOpenDialog,
                                        onDocumentUploadRequirements,
                                        onModalOpen,
                                        setModalOpen,
                                        onRequirementSave,
                                        openUpload,
                                        closeUpload,
                                        openModal,
                                        closeModal,
                                        onAcceptance

                                    }) => {
  const {isBusy, startBusy, endBusy} = useMultiBusy()

  const {orgId, teamId, userId} = useParams()
  const dispatch = useDispatch()
  const user = useSelector(({auth}) => auth.user)
  const backgroundRunning = useSelector(({auth}) => auth.backgroundRunning)
  const invitedTeams = useSelector(({invitedTeamsList}) => invitedTeamsList)
  const memberId = getUserOrg(user, orgId)
  const history = useHistory()
  const authorizedUser = useAuthUser()
  const userDefaultOrg = getUserOrg(authorizedUser)
  const isTech = userDefaultOrg?.organization_type === INDEPENDENT_TECH_ORG_TYPE
  const {pathname} = useLocation()
  const serviceProvider = useSelector(({userProfile}) => userProfile)

  const localizedTeam = pathname.match('/invited-teams') ? teamOrProviderList?.find(item => item._id === teamId) :
    teamOrProviderList?.find(item => item.chekhub_org_id === teamId)
  console.log(localizedTeam)
  const [requiredDocs, setRequiredDocs] = useState([])
  const allowAccept = checkProperties(localizedTeam?.requirements)


  useEffect( async () => {
    teamId && dispatch(fetchChekhubOrg(localizedTeam?.chekhub_org_id))
    !isTech ? await dispatch(getInvitedTeamsSc({
      service_provider_id: orgId
    })) : await dispatch(getInvitedTeamsSc({
      service_provider_id: userId
    }))
  }, [])

  const handleBusy = () => {
    backgroundRunning ? startBusy() : endBusy()
  }

  const prepareModal = (doc, index) => {
    startBusy()
    setRequiredDocs(doc)
    setModalOpen(true)
    endBusy()
  }


  return serviceProvider && <Box style={{
    padding: '10px'
  }}>
    <SectionHeader>
      Chekhub End User Information
    </SectionHeader>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FieldDisplay {...{
          label: 'Provider Name'
        }}/>
        <Typography variant={'h6'}>
          {localizedTeam?.chekhub_org_name}
        </Typography>
      </Grid>
      <TableContainer component={Box} style={{
        paddingTop: '10px'
      }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>{'Document Name'}</TableCell>
              <TableCell>{'Uploaded Document'}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {localizedTeam && localizedTeam?.requirements.map((row, index) => (
              <TableRow key={row.filename}>
                <TableCell component="th" scope="row">
                  <CloudDownloadLink {...{
                    cloudFilename: row?.filename,
                    isBusy,
                    docName: row?.name,
                    style: {
                      color: '#77C043'
                    }
                  }}>
                    <Typography style={{
                      width: '90px'
                    }}>
                    {row?.name}
                    </Typography>
                  </CloudDownloadLink>
                </TableCell>
                <TableCell style={{
                  textAlign: 'center'
                }} >
                  {row?.upload ? <CloudDownloadLink {...{
                    cloudFilename: row?.upload,
                    onClick: () => handleBusy,
                    docName: row.name,
                    style: {
                      color: '#F5882F',
                      paddingTop: '10px',
                      alignContent: 'center'
                    }
                  }}>
                    <CheckIcon style={{
                      paddingRight: '5px',
                      fontSize: 'xx-large'
                    }}/>
                  </CloudDownloadLink> : <CloseSharp />}
                </TableCell>
                <TableCell align="right">
                  {!pathname.match('/current-teams') ? <FormSubmitButton
                    onClick={() => prepareModal(row, index, startBusy, endBusy,)}
                    isBusy={isBusy}
                  >
                    Upload Document
                  </FormSubmitButton> : <Typography>
                    Document Uploaded
                  </Typography>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {onModalOpen &&
        <ReUploadRequirementsModal {...{
          requiredDocs,
          onModalOpen,
          closeModal,
          closeUpload,
          openUpload,
          onDialogOpen,
          onRequirementSave,
          localizedTeam,
          setRequiredDocs
        }}/>
      }
      {!pathname.match('/current-teams') && <FormSubmitButton {... {
        onClick: () => onAcceptance(localizedTeam),
        useFab: true,
        disabled: !allowAccept
      }}>
        Accept Invitation
      </FormSubmitButton>}
      {/*{!pathname.match('/invited-teams') && <FormSubmitButton {... {*/}
      {/*  onClick: handleDeleteToChekhubOrg,*/}
      {/*  useFab: true,*/}
      {/*}}>*/}
      {/*  {'Remove From Team'}*/}
      {/*</FormSubmitButton>}*/}
    </Grid>
  </Box>
}
