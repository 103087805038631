import {Card, CardContent, Grid, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {ExampleLinks, ExampleRoutes} from "../examples.util"
import {WorkExperienceExample} from "./examples/WorkExperienceExample"
import {BackGroundCheckerExample} from "./examples/BackGroundCheckerExample"
import { PrimaryContactEditExample } from './examples/PrimaryContactEditExample'
import { IndustriesDropDownExample } from './examples/IndustriesDropDownExample'
import { NavMenuDropdown } from './examples/NavMenuDropdown'
import { MockOnboardingProcess } from './examples/MockOnboardingProcess'
import { LandingPage } from '../../LandingPage'
import { SelectUserTypeEndUser } from '../../SelectUserTypeEndUser'
import { CardViewScProviderList } from './examples/CardViewScProviderList'



const useMattsSandboxStyles = makeStyles(theme => ({
  header: {
    textTransform: 'uppercase'
  }
}))

const EXAMPLES = [
  WorkExperienceExample,
  BackGroundCheckerExample,
  PrimaryContactEditExample,
  IndustriesDropDownExample,
  NavMenuDropdown,
  MockOnboardingProcess,
  LandingPage,
  SelectUserTypeEndUser,
  CardViewScProviderList
]


export const SandboxKevinView = () => {
  const styles = useMattsSandboxStyles()


  return <Grid container>
    <Grid item xs={12}>
      <Card variant='outlined'>
        <CardContent>
          <Typography
            variant='h4'
            className={styles.header}
          >Kevin's Sandbox</Typography>

          <ExampleLinks {...{examples: EXAMPLES}} />
          <ExampleRoutes {...{examples: EXAMPLES}} />

        </CardContent>
      </Card>
    </Grid>
  </Grid>
}
