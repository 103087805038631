import {dispatchService} from "./dispatch-service.util"
import {fetchBackgroundCheckPackages, postBackgroundCheckPurchase} from "../../services/background-check.service"
import {techProfileSetState} from './tech-profile.actions'
import { serviceCompanySetState } from './service-company.actions'
import { fetchIndustriesList } from '../../services/industries.service'

export const SET_INDUSTRIES_STATE = 'SET_INDUSTRIES_STATE'
export const RESET_INDUSTRIES_STATE = 'RESET_INDUSTRIES_STATE'

export const setIndustriesState = industries => ({
  type: SET_INDUSTRIES_STATE,
  industries
})

export const resetIndustriesState = () => ({
  type: RESET_INDUSTRIES_STATE
})

export const setServiceCompanyIndustriesState = industry =>
  (dispatch, getState) => {
    const {serviceCompany} = getState()
    return dispatch(serviceCompanySetState(
      {
      ...serviceCompany,
      ...industry,
    }))
  }


export const getIndustriesList = () => async dispatch =>
  await dispatchService(dispatch)(
    fetchIndustriesList, setIndustriesState
  )()

