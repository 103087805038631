import axios from 'axios'
import appConfig from '../../../config'

const fetchProfile = async (userId, orgId, isTechProfile, accessToken) => {
  if (!isTechProfile && !orgId) return [null, null]

  const { chekhubConnect: { baseURI, operations, defaultHeaders } } = appConfig
  const { techProfileFetchOne, serviceCompanyProfileFetchOne } = operations
  const fetchOperation = isTechProfile ? techProfileFetchOne : serviceCompanyProfileFetchOne
  const { path, method } = fetchOperation

  try {
    const { status, data: body } = await axios({
      baseURL: baseURI,
      url: `${path}/${isTechProfile ? userId : orgId}`,
      method,
      headers: {
        ...defaultHeaders,
        authorization: `Bearer ${accessToken}`,
      },
    })
    if (status !== 200) throw new Error('unknown profileFetchError')
    return [body.data, null]
  } catch (err) {
    return [null, err]
  }
}

export default fetchProfile
