import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import authReducer from './auth'
import impersonatedProfileReducer from './impersonated-profile'
import profileReducer from './profile'
import profileListReducer from './profile-list'
import storageReducer from './storage'
import toastReducer from './toast'
import ticketReducer from './ticket'
import serviceCompanyReducer from './service-company.reducer'
import userProfileReducer from './user-profile.reducer'
import techProfileReducer from './tech-profile.reducer'
import techProfileListReducer from './tech-profile-list.reducer'
import stepperReducer from './stepper.reducer'
import { chekhubListReducer, providerListReducer, serviceCompanyListReducer } from './service-company-list.reducer'
import userListReducer from './user-list.reducer'
import {serviceCompanyManagementListReducer} from './service-company-management-list.reducer'
import {walletReducer} from './wallet.reducer'
import {backgroundCheckReducer} from "./background-check.reducer"
import { industriesReducer } from './industries.reducer'
import { paidTicketReducer, singleTicketReducer } from './ticketsList-reducer'
import { invitedTeamListReducer, invitedTeamsListReducer } from './invited-teams-list.reducer'
import { requiredDocReducer, requiredDocsReducer } from './required-docs.reducer'

export default combineReducers({
  firebase: firebaseReducer,
  auth: authReducer,
  impersonatedProfile: impersonatedProfileReducer,
  profile: profileReducer,
  profileList: profileListReducer,
  ticket: ticketReducer,
  storage: storageReducer,
  toast: toastReducer,
  stepper: stepperReducer,
  serviceCompany: serviceCompanyReducer,
  serviceCompanyList: serviceCompanyListReducer,
  providerList: providerListReducer,
  chekhubList: chekhubListReducer,
  userProfile: userProfileReducer,
  techProfile: techProfileReducer,
  techProfileList: techProfileListReducer,
  userList: userListReducer,
  serviceCompanyManagementList: serviceCompanyManagementListReducer,
  wallet: walletReducer,
  backgroundCheck: backgroundCheckReducer,
  industries: industriesReducer,
  singleTicket: singleTicketReducer,
  paidTickets: paidTicketReducer,
  invitedTeamsList: invitedTeamsListReducer,
  invitedTeam: invitedTeamListReducer,
  requiredDocsList: requiredDocsReducer,
  requiredDocs: requiredDocReducer,

})
