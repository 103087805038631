import noop, { noopThunk } from '../util/noop'
import { Typography } from '@material-ui/core'
import { InlineConfirm } from './InlineConfirm'
import { InsuranceForm } from './InsuranceForm'
import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getDateFromPickerString } from "../pages/TechProfile.util"
import { getISOFormattedDateString } from "../util/date-time.util"

const useStyles = makeStyles(theme => ({
    title: {
      display: 'flex',
      alignItems:'center',
      justifyContent: 'space-between'
    }
  }))

export const InsuranceEdit = ({
    insur ={},
    onSubmit = noopThunk,
    onCancel = noop,
    onUploadSave = noop,
    label,
    onRemoveUpload = noop,
    showAllowButtonOrNot = noop,
}) => {
  const styles = useStyles()
  const [hasFieldErrors, setHasFieldErrors] = useState(false)
    const [insurance, setInsurance] = useState(insur)
    const DATE_FIELD_KEYS = [
        'issued_on',
        'expires_on'
    ]

    useEffect(() => {
        setInsurance(insur)
    }, [insur])

    const onChangeHandler = (e, name) => {
        let insObj = {...insurance}
        if (e?.target) {
            if( name === 'alt_insurance_type'){
                insObj['insurance_type'] = 'other'
                insObj['alt_insurance_type'] = e.target.value == 'other' ? '' : e.target.value
            }else{
                insObj[name] = DATE_FIELD_KEYS.includes(name) ? getISOFormattedDateString(getDateFromPickerString(e.target.value)) : e.target.value
                insObj['alt_insurance_type'] = ''
            }
        } else {
            insObj[name] = e
        }
        setInsurance(insObj)
    }

    return <>
        <Typography
        className= {styles.title}
        variant="h5" gutterBottom>
            {label}<InlineConfirm {...{
                onConfirm: () => {
                  showAllowButtonOrNot(true)
                  onSubmit(insurance)},
                onCancel,
                disableConfirm: hasFieldErrors || !insurance.issued_by
            }} />
        </Typography>
        <InsuranceForm  {...{
            insurance,
            onChangeHandler,
            onUploadSave,
            onRemoveUpload,
            setHasFieldErrors
        }} />
    </>
}
