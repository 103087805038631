import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@material-ui/core'
import { TABLE_HEADERS } from "../constants/user.constants"
import ServiceCompanyMemberRow from "./ServiceCompanyMemberRow"
import { shouldDisableMemberRemoval } from '../util/users.util'
import { useSelector } from 'react-redux'
import { useState } from "react"
import Fuse from 'fuse.js'
import { FuzzySearchBar } from './FuzzySearchBar'
import Typography from '@material-ui/core/Typography'

const ServiceCompanyMembersTable = ({
  members,
  serviceCompany,
  onRemove,
  disableAdminRemoval,
  tableStyles
}) => {
  const fuse = new Fuse(members, {
    keys: [
      'full_name',
      'hour_rate',
      'location.formatted_address',
    ]
  })
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState()
  const [orderBy, setOrderBy] = useState()
  const [query, setQuery] = useState('')

  const fuseResults = fuse.search(query)
  const membersResults = query ? fuseResults.map(results => results.item) : members


  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  const {
    user: authenticatedUser
  } = useSelector(({auth: {user}}) => ({user}))

  const handleSortRequest = (id) => {
    const isAsc = orderBy === id && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(id)
  }

  function sortPage(array, comparator) {
    const stabilizer = array.map((el, index) => [el, index])
    stabilizer.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizer.map((el) => el[0])
  }

  function getComparator(order, orderBy) {
    return order === 'desc' ?
      (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }


  const sortAfterPaginationAndSorting = () => {
    return sortPage(membersResults, getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }


  return <>
    <FuzzySearchBar {...{
      setQuery,
      query,
      label: <Typography variant={'span'}>Search Techs</Typography>,
      tableStyles
    }}/>
    <TableContainer>
      <Table size={'medium'} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={tableStyles.cell}/>
            {
              TABLE_HEADERS.map((header, key) => {
                return <TableCell {...{key}} className={tableStyles.cell}>
                  <TableSortLabel
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : 'asc'}
                    onClick={() => handleSortRequest(header.id)
                    }>
                    {header.name}
                  </TableSortLabel>
                </TableCell>
              })
            }
            <TableCell className={tableStyles.cell}/>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            members && sortAfterPaginationAndSorting().map((member, index) =>
              <ServiceCompanyMemberRow {...{
                key: index,
                index,
                onRemove,
                member,
                tableStyles,
                disableRemoval: shouldDisableMemberRemoval(
                  member,
                  serviceCompany,
                  authenticatedUser,
                  disableAdminRemoval
                )
              }} />
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 20]}
      component="div"
      count={members.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  </>
}

export default ServiceCompanyMembersTable
