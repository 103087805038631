import { providerListSetState, resetServiceCompanyListState, resetServiceCompanyState } from './service-company.actions'
import { invitedTeamResetState } from './invited-teams-list.actions'
import ticketReset from './ticket-reset'

export const signOutActions = () => async (dispatch, getState, getFirebase) => {
  const firebase = getFirebase()
  try {
    await firebase
      .auth()
      .signOut()
    // TODO: abstract this out to an INIT_APP_STATE action
    await dispatch({type: 'PROFILE/RESET'})
    await dispatch({type: 'PROFILE-LIST/RESET'})
    await dispatch({type: 'IMPERSONATED-PROFILE/RESET'})
    await dispatch({type: 'TICKET/RESET'})
    await dispatch({type: 'TOAST/RESET'})
    await dispatch({type: 'STORAGE/RESET'})
    await dispatch({type: 'AUTH/SIGN-OUT-OK'})
    await dispatch(resetServiceCompanyState())
    await dispatch(resetServiceCompanyListState())
    await dispatch(invitedTeamResetState())
    await dispatch(ticketReset())
    await dispatch(providerListSetState())
    localStorage.clear()
  } catch (err) {
    dispatch({ type: 'AUTH/SIGN-OUT-ERROR', payload: err })
  }
}
