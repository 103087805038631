import { createReducer } from 'redux-create-reducer'
import {
  PAID_TICKETS_RESET_STATE,
  PAID_TICKETS_SET_STATE,
  TICKET_LIST_RESET_STATE,
  TICKET_LIST_SET_STATE,
  TICKET_RESET_STATE,
  TICKET_SET_STATE
} from '../actions/ticketsList-actions'
import { SERVICE_COMPANY_SET_STATE } from '../actions/service-company.actions'

export const INIT_TICKET_LIST_STATE = []
export const INIT_TICKET_STATE = {}

export const ticketReducer = createReducer(
  INIT_TICKET_LIST_STATE,
  {
    [TICKET_LIST_SET_STATE]: (state, {list}) => [
      ...list
    ],
    [TICKET_SET_STATE]: (state, ticket) => {
      ticket
    },
    [TICKET_LIST_RESET_STATE]: () => INIT_TICKET_LIST_STATE
  }
)

export const singleTicketReducer = createReducer(
  INIT_TICKET_STATE,
  {
    [TICKET_SET_STATE]: (state, {ticket}) => ({
      ...state,
      ...ticket
    }),
    [TICKET_RESET_STATE]: () => INIT_TICKET_STATE
  }
)

export const paidTicketReducer = createReducer(
  INIT_TICKET_LIST_STATE,
  {
    [PAID_TICKETS_SET_STATE]: (state, {tList}) => [
      ...tList
    ],
    [PAID_TICKETS_RESET_STATE]: () => INIT_TICKET_STATE
  }
)
