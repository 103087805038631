import {createContext, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'

export const Context = createContext({
    header: {},
    footer: {},
    dimensionsContextStyles: {}
  }
)

export const getUseDimensionsStyles = (
  {
    height: headerHeight = 0
  } = {},
  {
    height: footerHeight = 0
  } = {}) => makeStyles(theme => {
  const calculatedHeight = `calc(100vh - ${headerHeight + footerHeight}px)`
  return {
    scrollContainer: {
      maxHeight: calculatedHeight,
      overflow: 'auto',
    },
    minHeight: {
      minHeight: calculatedHeight
    },
    card: {
      padding: '5px'
    }
  }
})

// TODO: Kill this. Make everything pull these styles from the context
export let useDimensionsContextStyles = getUseDimensionsStyles()

export const DimensionsProvider = ({children}) => {
  const [headerDimensions, setHeaderDimensions] = useState()
  const [footerDimensions, setFooterDimensions] = useState()
  useDimensionsContextStyles = getUseDimensionsStyles(headerDimensions, footerDimensions)

  const dimensionsContextStyles = useDimensionsContextStyles()

  return <Context.Provider {...{
    value:{
      header: {
        headerDimensions,
        setHeaderDimensions
      },
      footer: {
        footerDimensions,
        setFooterDimensions
      },
      dimensionsContextStyles
    }
  }}>{children}</Context.Provider>
}
