import { Box, Card, CardContent } from '@material-ui/core'
import { useDimensionsContextStyles } from './DimensionsContext'
import useLandingViewStyles from './LandingViewStyles'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getDefaultSource } from '../store/actions/wallet.actions'
import { useHistory, useParams } from 'react-router-dom'
import { fetchChekhubOrgList } from '../store/actions/service-company.actions'
import { getUserOrg } from '../util/users.util'
import { useAuthUser } from '../hooks/useAuthUser'


export const ChekhubEndUserSummary = ({

                                      }) => {
  const dimensionsContextStyles = useDimensionsContextStyles()
  const styles = useLandingViewStyles()
  const dispatch = useDispatch()
  const orgIdFromRedux = useSelector(({userProfile}) => userProfile.user)
  const orgId = localStorage.getItem("orgId")?.replace(/['"]+/g, '') || useParams()
  const history = useHistory()
  const authorizedUser = useAuthUser()
  const path = history.location.pathname
  const userDefaultOrg = getUserOrg(authorizedUser,orgId,path)
  const auth = useSelector(({auth}) => auth)
  const chekhubList = useSelector(({chekhubList}) => chekhubList)
  const activeOrgObject = chekhubList?.memberships?.find(org => org.organization._id === orgId)

  useEffect(async () => {
    await dispatch(fetchChekhubOrgList())
  }, [])

  return <Box {...{
    border: 0,
    className: dimensionsContextStyles.scrollContainer
  }}>
    <Card className={styles.summaryHolder} variant={'outlined'}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <h3>Chekhub End User Summary</h3>
          </Grid>
          <Grid item xs={12}>
           <h4>{activeOrgObject?.organization?.name || ''}</h4>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Box>
}
