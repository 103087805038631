import appConfig from "../config"
import {Headers, Methods} from "http-headers-js"
import generateAuthHeader from "./generate-auth-header.util"

const {
  defaultHeaders,
  baseURI,
  paths: {
    backgroundCheckService: backgroundCheckPath,
  },
} = appConfig.chekhubConnect

const serviceBaseUri = `${baseURI}${backgroundCheckPath}`

export const fetchBackgroundCheckPackages = async () => await fetch(
  `${serviceBaseUri}/packages`,
  {
    method: Methods.GET,
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    }
  }
)

export const postBackgroundCheckPurchase = async (packagePurchase, package_id) => await fetch(
  `${serviceBaseUri}/packages/${package_id}/purchase`,
  {
    method: Methods.POST,
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    body: JSON.stringify(packagePurchase)
  }
)
