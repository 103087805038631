import {useDispatch, useSelector} from 'react-redux'
import {getFirebase, isEmpty} from 'react-redux-firebase'
import useAsyncEffect from 'use-async-effect'
import {Redirect} from 'react-router-dom'
import fetchUser from '../store/actions/auth-sign-in-functions/fetch-user'
import {setBackendWorking, setBackendWorkingDone} from '../store/actions/backend-working.actions'
import { LANDING_PATH, SIGN_IN_PATH } from '../routing/route.constants'

const AuthCheck = ({children}) => {
  const dispatch = useDispatch()
  const firebaseAuthEmpty = isEmpty(
    useSelector(({firebase}) => firebase.auth)
  )
  const connectAuth = useSelector(({auth}) => auth)

  // TODO: Clean this 💩 up w/ some sane state management around the Connect User
  useAsyncEffect(firebaseAuthEmpty ? () => {} : async () => {
    const firebase = getFirebase()
    dispatch(setBackendWorking())
    const firebaseAuthToken = await firebase.auth().currentUser.getIdToken()
    const [connectUserData, userError] = await fetchUser('me', firebaseAuthToken)
    userError ?
      dispatch({ type: 'AUTH/SIGN-IN-ERROR', payload: userError }) :
      dispatch({ type: 'AUTH/FETCH-USER-OK', payload: connectUserData })
    dispatch(setBackendWorkingDone())
  }, [])

  return firebaseAuthEmpty ?
    <Redirect {...{to: LANDING_PATH}} /> :
    connectAuth.user ?
      children : <div>Loading connect user...</div>
}

export default AuthCheck
