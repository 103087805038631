import { Fragment } from 'react'
import {generatePath, Link} from 'react-router-dom'
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid
} from '@material-ui/core'

import ClearIcon from '@material-ui/icons/Clear'
import DescriptionIcon from '@material-ui/icons/Description'
import formatAddress from '../shared-functions/format-address'
import { compactInsuranceList, compactCertList } from '../shared-functions/compact-display-lists'
import useRootStyles from '../../styles/rootStyles'
import {SERVICE_COMPANY_VIEW_PATH} from '../../routing/route.constants'

const ProfileSummaryComponent = ({ userType, profile, user }) => {
  const styles = useRootStyles()

  const objectToIcon = (obj) => {
    if (obj && Object.keys(obj).length > 0) {
      return <DescriptionIcon fontSize="small" />
    }
    return <ClearIcon fontSize="small" />
  }

  const content = () => {
    switch (userType) {
      case 'service_company':
        return (<Grid container spacing={2} className={styles.summaryComponent}>
              <Grid item xs={12}>
                <h3>{profile.company_name}</h3>
              </Grid>
              <Grid item xs={6}>
                <div><b>Primary Phone:</b> {profile.phones?.[0]?.raw_phone} </div>
                <div><b>Address:</b><br />{formatAddress(profile.location.address)}</div>
                {profile.website && <div><b>Website:</b> { profile.website }</div>}
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                {profile.company_formation_year
                  && <div><b>Formation Year:</b>&nbsp;
                    { profile.company_formation_year }
                    </div>}
                {profile.ein && <div><b>EIN:</b> { profile.ein }</div>}
                {profile.status && <div><b>Status:</b> { profile.status }</div>}
                {profile.insurance_policies && profile.insurance_policies.length &&
                  <div>
                    <b>Insurance:</b><br/>
                    {compactInsuranceList(profile.insurance_policies)}}
                  </div>
                }
              </Grid>
            </Grid>)
      case 'tech':
      case 'independent':
        return (<Grid container spacing={0}>
            <Grid item xs={12}>
              <h3>{user.full_name}</h3>
            </Grid>
            <Grid item xs={6}>
              <div><b>Email:</b> {user.email} </div>
              <div><b>Primary Phone:</b> {user.phones?.[0]?.raw_phone} </div>
              <div><b>Address:</b><br />
                {formatAddress(profile.location.address)}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <b>Certifications:</b><br />
                {compactCertList(profile.certifications)}
              </div>
              <div className={styles.centeredRow}>
                <b>Background Check:</b>
                {objectToIcon(profile.background_check)}
              </div>
              <div>
                <b>Insurance:</b><br />
                {compactInsuranceList(profile.insurance_policies)}
              </div>
            </Grid>
          </Grid>)
      default:
        return ''
    }
  }
  return (
    <Fragment>
      {userType !== 'customer_company'
        ? <Card variant="outlined" style={{ marginBottom: '1rem' }}>
          <CardContent>
            {content()}
          </CardContent>
          <CardActions>
            <Link to={generatePath(SERVICE_COMPANY_VIEW_PATH, {
              orgId: profile._id
            })} style={{
              textDecoration: 'none'
            }}>
              <Button variant="contained" color="primary">Edit</Button>
            </Link>
        </CardActions>
        </Card>
        : ''
      }
    </Fragment>)
}

export default ProfileSummaryComponent
