import React, { useState } from 'react'

import {
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

import AddressAutocomplete from './AddressAutocomplete'

import useFormStyles from '../styles/formStyles'

import formatAddress from './shared-functions/format-address'
import addressToLocation from './shared-functions/address-to-location'

export default function WorkHistoryItemEdit(props) {
  const { value } = props

  const formStyles = useFormStyles()

  const [workHistoryItem, setWorkHistoryItem] = useState(value)

  const saveHistory = (e) => {
    e.preventDefault()
    props.onSubmit(workHistoryItem)
  }

  const handleChange = (e) => {
    setWorkHistoryItem({
      ...workHistoryItem,
      [e.target.id]: e.target.value || e.target.checked,
    })
  }

  const handleAddressChange = (addressDetails) => {
    const newLocation = {
      ...addressToLocation(addressDetails, workHistoryItem.employer_location),
      label: 'work',
    }
    setWorkHistoryItem({
      ...workHistoryItem,
      employer_location: newLocation,
    })
  }

  const handleDateChange = (e) => {
    setWorkHistoryItem({
      ...workHistoryItem,
      [e.target.id]: new Date(e.target.value),
    })
  }

  return (
  <div style={{
    border: 'solid 1px #bbb',
    borderRadius: '.2rem',
    padding: '1.5rem',
    margin: '.5rem',
  }}>
    <form onSubmit={saveHistory}>
      <div className={formStyles.row}>
        <TextField id="employer_name"
          className={formStyles.textField}
          size="small"
          required
          label="Employer Name"
          defaultValue={workHistoryItem.employer_name || ''}
          onChange={handleChange}
          variant="outlined" />
      </div>
      <div className={formStyles.row}>
        <TextField id="job_title"
          className={formStyles.textField}
          size="small"
          required
          label="Job Title"
          defaultValue={workHistoryItem.job_title || ''}
          onChange={handleChange}
          variant="outlined" />
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor='employment_type' required>Employment Type</InputLabel>
          <Select native
            id='employment_type'
            label="Phone label"
            className={formStyles.select}
            value={workHistoryItem.employment_type || ''}
            required
            onChange={handleChange} >
              <option value="employee">Employee</option>
              <option value="contractor">Contractor</option>
              <option value="other">Other</option>
          </Select>
        </FormControl>
        </div>
        <div className={formStyles.row}>
          <TextField id="start_date"
            className={formStyles.dateField}
            size="small"
            required
            label="Start Date"
            type='date'
            InputLabelProps={{ shrink: true }}
            defaultValue={workHistoryItem.start_date || ''}
            onChange={handleDateChange}
            variant="outlined" />
          <TextField id="end_date"
            className={formStyles.dateField}
            size="small"
            label="End Date"
            type='date'
            InputLabelProps={{ shrink: true }}
            defaultValue={workHistoryItem.end_date || ''}
            disabled={workHistoryItem.is_current_job}
            onChange={handleDateChange}
            variant="outlined" />
            <FormControlLabel
              control={
                <Checkbox
                  id="is_current_job"
                  checked={workHistoryItem.is_current_job}
                  onChange={handleChange}
                  name="is_current_job"
                  color="default"
                />
              }
              label="Current Job"
            />
      </div>

      <div className={formStyles.row}>
      <AddressAutocomplete id="address-autocomplete"
          labelText="Company Address"
          required
          defaultValue={formatAddress(workHistoryItem.employer_location)}
          onChange={handleAddressChange} />
      </div>
      <div className={formStyles.row}>
      <InputLabel htmlFor='job_description' required>Job Description</InputLabel>
        <TextareaAutosize
          id="job_description"
          aria-label="minimum height"
          rowsMin={3}
          className={formStyles.textField}
          size="small"
          required
          label="job_description"
          defaultValue={workHistoryItem.job_description || ''}
          onChange={handleChange}
          variant="outlined" />
      </div>

      <div className={formStyles.row}>
        <Button color='primary'
            type='submit'
            variant='contained'
            >
            Done
          </Button>
      </div>
    </form>
  </div>)
}
