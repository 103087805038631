import makeStyles from "@material-ui/core/styles/makeStyles"

export const useServiceCompanyMembersListStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
    fontWeight: "bolder"
  },
  avatar: {
    fill: theme.palette.secondary.main
  },
  name: {
    color: theme.palette.primary.main
  }

}))

export const userServiceCompanyMemberListItemStyles = makeStyles(theme => ({
  roles: {
    display: 'inline',
  },
  nameLink: {
    color: theme.palette.primary.main
  }
}))
