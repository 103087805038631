import Grid from "@material-ui/core/Grid"
import jumbo from '../assets/images/Chekhub_Connect_Gray.svg'
import shutter1 from '../assets/images/solar_panel.svg'
import shutter2 from '../assets/images/data_center.svg'
import shutter3 from '../assets/images/tech.svg'
import useSignInJumboStyles from "./SignInJumbotronStyles"
import { Typography } from '@material-ui/core'


const SignInJumbotron = () => {
  const styles = useSignInJumboStyles()

  return shutter1 && shutter2 && shutter3 && <Grid
    container
    alignItems={'center'}>
    {/*TODO: MOVE THIS TO SEPERATE COMPONENT WITH MODES ON WHICH LOGO TO USE*/}
      <Grid container justify={'center'}>
        <img
          src={jumbo}
          alt={'connect-logo-orange'}
          width={'55%'}
          className={styles.img}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'h5'} style={{
          textAlign: 'center',
          paddingBottom: '25px'
        }}>
          We <span className={styles.connect}>connect</span> skilled professionals with people who need to get work done on location
        </Typography>
      </Grid>
    <Grid container direction={'row'} justify={'center'} style={{
      paddingBottom: '25px'
    }}>
      <Grid
        item
        xs={3}
      >
        <img
          src={shutter1}
          alt={'connect-logo-orange'}
          width={'100%'}
          height={'100%'}
          style={{
            objectFit: 'cover',
           // clipPath: 'polygon(31% 0, 100% 0, 100% 100%, 0 100%, 0% 38%)'
          }}
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <img
          src={shutter2}
          alt={'connect-logo-orange'}
          width={'100%'}
          height={'100%'}
          style={{
            objectFit: 'cover'
          }}
        />
      </Grid>
      <Grid
      item
      xs={3}
      >
      <img
        src={shutter3}
        alt={'connect-logo-orange'}
        width={'100%'}
        height={'100%'}
        style={{
          objectFit: 'cover',
          //clipPath: 'polygon(100% 0, 100% 55%, 70% 100%, 0 100%, 0 0)'
        }}
      />
    </Grid>
    </Grid>
  </Grid>
}
export default SignInJumbotron
