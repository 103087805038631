import appConfig from '../config'
import {Headers, Methods} from 'http-headers-js'
import generateAuthHeader from './generate-auth-header.util'
import {generateUrlQueryString} from './generate-url-query-string.util'

const {
  defaultHeaders,
  baseURI,
  intergrationURI,
  paths: {
    connectedAccounts: connectedAccountsPath,
    walletService: walletServicePath,
    connectWalletService: walletIntergrationServicePath,
    connectStripeService: stripeIntergrationServicePath
  },
} = appConfig.chekhubConnect
const serviceBaseUri = `${baseURI}${walletServicePath}`
const serviceIntergrationUri = `${intergrationURI}${stripeIntergrationServicePath}`

export const fetchPaymentSources = async orgId => await fetch(
  `${serviceBaseUri}/${orgId}/sources`,
  {
    method: Methods.GET,
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    }
  }
)

export const postPaymentSource = async (orgId, sourceTokenId) => await fetch(
  `${serviceBaseUri}/${orgId}/sources/${sourceTokenId}`,
  {
    method: Methods.POST,
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    }
  }
)

export const deletePaymentSource = async (orgId, sourceId) =>
  await fetch(
    `${serviceBaseUri}/${orgId}/sources/${sourceId}`,
    {
      method: Methods.DELETE,
      headers: {
        ...defaultHeaders,
        [Headers.AUTHORIZATION]: await generateAuthHeader()
      }
    }
  )

export const postVerifyBankAccount = async (orgId, sourceId, [amount1, amount2]) =>
  await fetch(
    `${serviceBaseUri}/${orgId}/sources/${sourceId}/verify`,
    {
      method: Methods.POST,
      headers: {
        ...defaultHeaders,
        [Headers.AUTHORIZATION]: await generateAuthHeader()
      },
      body: JSON.stringify({
        amounts: [
          amount1,
          amount2
        ]
      })
    }
  )

export const getConnectedAccounts = async orgId =>
  await fetch(
    `${baseURI}${connectedAccountsPath}${generateUrlQueryString(
      {organization_id: orgId}
      )}`,
    {
      method: Methods.GET,
      headers:{
        ...defaultHeaders,
        [Headers.AUTHORIZATION]: await generateAuthHeader()
      }
    }
  )

export const getConnectedAccountsIndy = async userId =>
  await fetch(
    `${baseURI}${connectedAccountsPath}${generateUrlQueryString(
      {organization_id: userId}
    )}`,
    {
      method: Methods.GET,
      headers:{
        ...defaultHeaders,
        [Headers.AUTHORIZATION]: await generateAuthHeader()
      }
    }
  )


export const createSourceIdChekhubEndUser = async (chekhub_org_id, source_id) =>
  await fetch(
    `${serviceIntergrationUri}/customers/${chekhub_org_id}/source`,
  {
    method: Methods.PUT,
    headers:{
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    body: JSON.stringify({source_id: source_id.id})
  },
)

export const fetchDefaultSourceForChekhubEndUser = async (chekhub_org_id) =>
  await fetch(
    `${serviceIntergrationUri}/customers/${chekhub_org_id}`,
    {
      method: Methods.GET,
      headers:{
        ...defaultHeaders,
        [Headers.AUTHORIZATION]: await generateAuthHeader()
      },
    },
  )

export const getStripeAccount = async chekhub_org_id =>
  await fetch(`${intergrationURI}/accounts?chekhub_org_id=${chekhub_org_id}`, {
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    }
  })

export const paymentCallToStripe = async ({customer_id, source_id, amount_1, amount_2}) => {
  console.log(customer_id)
  await fetch(`${intergrationURI}/stripe/customers/${customer_id.toString()}/source/verify`, {
    method: Methods.PUT,
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    body: JSON.stringify({
      source_id: source_id,
      amount_1: amount_1,
      amount_2: amount_2
    })
  })
}
