import {dispatchService} from './dispatch-service.util'
import {
  createSourceIdChekhubEndUser,
  deletePaymentSource, fetchDefaultSourceForChekhubEndUser,
  fetchPaymentSources, getConnectedAccounts, getConnectedAccountsIndy, paymentCallToStripe,
  postPaymentSource,
  postVerifyBankAccount
} from '../../services/wallet.service'

export const SET_WALLET_STATE = 'SET_WALLET_STATE'
export const RESET_WALLET_STATE = 'RESET_WALLET_STATE'
export const SET_DEFAULT_SOURCE_STATE = 'SET_DEFAULT_SOURCE_STATE'
export const RESET_DEFAULT_SOURCE_STATE = 'RESET_DEFAULT_SOURCE_STATE'
export const SET_PAYMENT_SOURCES = 'SET_PAYMENT_SOURCES'
export const RESET_PAYMENT_SOURCES = 'RESET_PAYMENT_SOURCES'
export const ADD_PAYMENT_SOURCE = 'ADD_PAYMENT_SOURCE'
export const REMOVE_PAYMENT_SOURCE = 'REMOVE_PAYMENT_SOURCE'
export const UPDATE_PAYMENT_SOURCE = 'UPDATE_PAYMENT_SOURCE'
export const SET_CONNECTED_ACCOUNTS = 'SET_CONNECTED_ACCOUNTS'
export const RESET_CONNECTED_ACCOUNTS = 'RESET_CONNECTED_ACCOUNTS'

export const setWalletState = wallet => ({
  type: SET_WALLET_STATE,
  wallet
})

export const resetWalletState = () => ({
  type: RESET_WALLET_STATE
})

export const setDefaultSource = default_source => ({
  type: SET_DEFAULT_SOURCE_STATE,
  default_source
})


export const resetDefaultSource = () => ({
  type: RESET_DEFAULT_SOURCE_STATE
})

export const setPaymentSources = sources => ({
  type: SET_PAYMENT_SOURCES,
  sources
})

export const resetPaymentSources = () => ({
  type: RESET_PAYMENT_SOURCES
})

export const addPaymentSourceToState = source => ({
  type: ADD_PAYMENT_SOURCE,
  source
})

export const removePaymentSourceFromState = sourceId => ({
  type: REMOVE_PAYMENT_SOURCE,
  sourceId
})

export const updatePaymentSourceState = source => ({
  type: UPDATE_PAYMENT_SOURCE,
  source
})

export const setConnectedAccounts = connectedAccounts => ({
  type: SET_CONNECTED_ACCOUNTS,
  connectedAccounts
})



export const resetConnectedAccounts = () => ({
  type: RESET_CONNECTED_ACCOUNTS
})

export const getPaymentSources = orgId => async dispatch =>
  await dispatchService(dispatch)(fetchPaymentSources, setPaymentSources)(orgId)

export const createPaymentSource = (orgId, sourceTokenId) => async dispatch =>
  await dispatchService(dispatch)(
    postPaymentSource,
    addPaymentSourceToState
  )(orgId, sourceTokenId)

export const removePaymentSource = (orgId, sourceId) => async dispatch =>
  dispatchService(dispatch)(
    deletePaymentSource,
    () => () => dispatch(removePaymentSourceFromState(sourceId))
  )(orgId, sourceId)

export const verifyBankAccount = (orgId, sourceId, [amount1, amount2]) => async dispatch =>
  await dispatchService(dispatch)(
    postVerifyBankAccount, updatePaymentSourceState
  )(orgId, sourceId, [amount1, amount2])

export const fetchConnectedAccounts = orgId => async dispatch =>
  await dispatchService(dispatch)(getConnectedAccounts, setConnectedAccounts)(orgId)

export const fetchConnectedAccountsIndy = userId => async dispatch =>
  await dispatchService(dispatch)(getConnectedAccountsIndy, setConnectedAccounts)(userId)


export const setSourceId = (chekhub_org_id, source_id) => async dispatch =>
  await dispatchService(dispatch)(createSourceIdChekhubEndUser)(chekhub_org_id, source_id)


export const getDefaultSource = (chekhub_org_id) => async dispatch =>
  await dispatchService(dispatch)(fetchDefaultSourceForChekhubEndUser, setDefaultSource)(chekhub_org_id)

export const payStripe = (customer_id, source_id, amount_1, amount_2) => async dispatch =>
  await dispatchService(dispatch)(paymentCallToStripe)(customer_id, source_id, amount_1, amount_2)
