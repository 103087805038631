// eslint-disable-next-line
import {STEPPER_RESET_STATE} from './stepper.actions'
import {
  resetChekubListState,
  resetServiceCompanyListState,
  resetServiceCompanyState,
  serviceCompanySetState
} from './service-company.actions'
import { resetDefaultSource } from './wallet.actions'
const initState = () => (dispatch, getState, getFirebase) => {
  dispatch({ type: 'PROFILE/RESET' })
  dispatch({ type: 'PROFILE-LIST/RESET' })
  dispatch({ type: 'IMPERSONATED-PROFILE/RESET' })
  dispatch({ type: 'TICKET/RESET' })
  dispatch({ type: 'TOAST/RESET' })
  dispatch({ type: 'STORAGE/RESET' })
  dispatch({ type: 'AUTH/CLEAR-ERROR' })
  dispatch({type: STEPPER_RESET_STATE})
  dispatch(resetServiceCompanyState())
  dispatch(resetServiceCompanyListState())
  dispatch(resetDefaultSource())
  dispatch(resetChekubListState())
}

export default initState
