import { useDispatch, useSelector } from 'react-redux'
import ServiceCompany from '../components/ServiceCompany'
import useFormStyles from '../styles/formStyles'
import useEditStyles from '../components/ServiceCompanyViewStyles'
import {useContext, useEffect, useState} from 'react'
import {
  fetchServiceCompanyPrimaryContact, serviceCompanySetPrimaryContact,
  serviceCompanySetState,
  updateServiceCompany
} from '../store/actions/service-company.actions'
import toastShow, { TOAST_TYPE_STANDARD } from '../store/actions/toast-show'
import { SERVICE_COMPANY_EDIT } from '../routing/route.constants'
import { useHistory } from 'react-router-dom'
import { closeDrawerAndTransition } from '../components/ServiceCompanyMemberDrawer.util'
import { Box, duration } from '@material-ui/core'
import {
  handleServiceCompanyAddressChange,
  handleServiceCompanyChange,
  handleServiceCompanyFormationYearChange,
  handleServiceCompanyProfileChange
} from './ServiceCompanyView.utils'
import { replaceItemInList } from "../util/lists.util"
import { onUpdateInsurancePolicySC } from "../components/Insurance.util"
import { onInsurUploadChange } from "./TechProfile.util"
import { Context as BlurContext } from '../contexts/BlurContext'
import {
  fetchServiceCompanyManagementList,
} from '../store/actions/service-company-management-list.actions'
import {useAuthUser} from '../hooks/useAuthUser'
import { getIndustriesList, setServiceCompanyIndustriesState } from '../store/actions/industries.actions'

const ServiceCompanyEditView = ({
  setDrawerOpen,
  backRoutePath,
  routeTransitionDelay = duration.leavingScreen,
}) => {
  const formStyles = useFormStyles()
  const editStyles = useEditStyles()
  const [validState, setValid] = useState(true)
  const [allowPhones, setAllowPhones] = useState(true)
  const [allowCerts, setAllowCerts] = useState(true)
  const [allowInsurance, setAllowInsurance] = useState(true)
  const [allowWorkExperience, setAllowWorkExperience] = useState(true)
  const [allowWorkingHours, setAllowWorkingHours] = useState(true)
  const [allowPrimaryContactPhones, setAllowPrimaryContactPhones] = useState(true)
  const dispatch = useDispatch()
  const history = useHistory()
  const editPath = history.location.pathname === SERVICE_COMPANY_EDIT
  const user = useAuthUser()
  const {
    serviceCompany: profile,
    serviceCompanyManagementList,
    industries: industriesList
  } = useSelector(
    ({
      serviceCompany,
      serviceCompanyManagementList,
      industries
    }) => ({
      serviceCompany,
      serviceCompanyManagementList,
      industries
    })
  )

  const phoneNumbers = profile.phones || []
  const industries = industriesList?.industries?.industries
  const scIndustries = profile?.industries?.industries
  const insurance_policies = profile?.insurance_policies || []
  const handleChange = handleServiceCompanyChange(setValid, dispatch)
  const handleProfileChange = handleServiceCompanyProfileChange(profile, handleChange)
  const handleAddressChange = handleServiceCompanyAddressChange(profile, handleChange)
  const handleFormationYearChange = handleServiceCompanyFormationYearChange(profile, handleChange)
  const {setBlurred} = useContext(BlurContext)

  useEffect(() => {
    profile.primary_contact_id &&
      dispatch(fetchServiceCompanyPrimaryContact(profile.primary_contact_id))
    profile._id && dispatch(fetchServiceCompanyManagementList(profile._id))
  }, [])

  useEffect(
    () => {
      (!industries || !industries.length) && dispatch(getIndustriesList())
    },
    []
  )

  const handleSaveChanges = async e => {
    // TODO: Abstract out the next 20 lines so we're not duplicating in ServiceCompany.view.js
    e.preventDefault()

    if (!validState) {
      return dispatch(
        toastShow(TOAST_TYPE_STANDARD,
          'Validation failed. Please fill all fields marked with an *'
        )
      )
    }

    const scError = await dispatch(updateServiceCompany(profile))
    if (scError?.constructor === Error) {
      return
    }

    closeDrawerAndTransition(
      setDrawerOpen,
      backRoutePath,
      history,
      routeTransitionDelay,
      setBlurred
    )
  }
  const onPhoneNumberAdd = (info) => {
    const newPhones = [...phoneNumbers, {
      label: info.label,
      raw_phone: "+" + info.raw_phone,
      country: 'us'
    }]
    updatePhoneNumbers(newPhones)
  }

  const onPhoneNumberRemove = (index) => {
    const newPhones = [...phoneNumbers].filter((p, i) => i !== index)
    updatePhoneNumbers(newPhones)
  }

  const onPhoneNumberUpdate = (index, info) => {
    const newPhones = replaceItemInList(info, phoneNumbers, index)
    updatePhoneNumbers(newPhones)
  }

  const updatePhoneNumbers = (newPhones) => {
    dispatch(serviceCompanySetState({
      ...profile,
      phones: newPhones
    }))
    setValid(true)
  }

  const handlePrimaryContactOnChange = ({
    target: {
      value: userId
    }
  } = {}) =>
    dispatch(
      serviceCompanySetPrimaryContact(
        serviceCompanyManagementList.find(m => m._id === userId)
      )
    )

  const handleUpdatePrimaryContactUser = user =>
    dispatch(
      serviceCompanySetPrimaryContact(user)
    )
  const handleIndustriesClose = (industriesArr) => {
    dispatch(setServiceCompanyIndustriesState(industriesArr))
  }


  return <Box>
    <ServiceCompany {...{
      profile,
      user,
      handleSaveChanges,
      validState,
      handleAddressChange,
      handleProfileChange,
      handleFormationYearChange,
      editStyles,
      formStyles,
      editPath,
      onPhoneNumberAdd,
      onPhoneNumberRemove,
      onPhoneNumberUpdate,
      updatePhoneNumbers,
      onUpdateInsurancePolicySC: onUpdateInsurancePolicySC(profile, dispatch),
      onInsurUploadChange: onInsurUploadChange(dispatch),
      insurance_policies,
      phoneNumbers,
      setValid,
        showAllowButtonOrNot: setValid,
      handlePrimaryContactOnChange,
      handleUpdatePrimaryContactUser,
      serviceCompanyManagementList,
      allowPhones,
      setAllowPhones,
      allowCerts,
      setAllowCerts,
      allowInsurance,
      setAllowInsurance,
      allowWorkExperience,
      setAllowWorkExperience,
      allowWorkingHours,
      setAllowWorkingHours,
      allowPrimaryContactPhones,
      setAllowPrimaryContactPhones,
      handleIndustriesClose,
      industries,
      scIndustries
    }}/>
  </Box>
}


export default ServiceCompanyEditView
