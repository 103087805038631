import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useFieldDisplayStyles = makeStyles(theme => ({
  fieldLabel: {
    color: theme.palette.grey['600'],
    textTransform: 'uppercase',
    marginRight: theme.spacing(1)
  }
}))

export const FieldDisplay = ({
  label,
  children,
  ...restProps
}) => {
  const styles = useFieldDisplayStyles()
  return <Typography {...{
    variant: 'body2',
    ...restProps
  }}><span {...{
    className: styles.fieldLabel
  }}>{label}</span>{children}</Typography>
}
