import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  appBar: {
    '& a': {
      color: theme.palette.secondary.contrastText
    },
    '& a.active': {
      color: theme.palette.primary.main
    }
  },
}))
