import {Typography} from '@material-ui/core'
import {getUserOrg} from '../util/users.util'
import {EmailLink} from './EmailLink'

export const ServiceCompanyManagerSecondaryText = ({
  member,
  orgId
}) => {
  const org = getUserOrg(member, orgId)
  const roles = (org?.roles || [])
    .sort((a, b) => a > b ? 1 : -1)
  return <>
    <Typography {...{
      component: 'span',
      variant: 'body2',
      color: 'textSecondary'
    }} >{
      roles.join(', ')
    }</Typography>
    <EmailLink {...{email: member.email}} />
  </>
}

