import {
  Box,
  Card,
  CardActions,
  CardContent, Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup, FormHelperText,
  FormLabel,
  Grid,
  TextField
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import PhoneNumbersEdit from './PhoneNumbersEdit'
import { InsurancePoliciesDisplay } from './InsurancePoliciesDisplay'
import { InsuranceEdit } from './InsuranceEdit'
import { insuranceStateUtils } from './Insurance.util'
import { useState } from 'react'
import { useValidations } from '../hooks/useValidations'
import { isValid } from 'ein-validator'
import { isWebUri } from 'valid-url'
import {
  ADDRESS_FIELDNAME,
  beginsWithLinkedIn,
  LINKEDIN_PAGE_FIELDNAME,
  prependProtocol,
  WEBSITE_FIELDNAME
} from '../pages/ServiceCompanyView.utils'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from "@date-io/date-fns"
import { getDateObjectFromYear } from '../util/date-time.util'
import { PlacesAutoComplete } from './PlacesAutoComplete'
import { FormSubmitButton } from './FormSubmitButton'
import { makeStyles } from '@material-ui/core/styles'
import { FieldDisplay } from './FieldDisplay'
import { PrimaryContactEdit } from './PrimaryContactEdit'
import { IndustriesDropDown } from './IndustriesDropDown'
import { useHistory } from 'react-router-dom'
import {
  PROFILES_SC_CREATE_PATH,
  SERVICE_COMPANY_EDIT,
  SERVICE_COMPANY_EDIT_DRAWER,
  SIGN_UP_SC_CREATE_PATH
} from '../routing/route.constants'
import { humanizeScreeningName } from './BackGroundCheckPackageCard'

const serviceCompanyValidations = {
  company_name: {
    required: 'Company name is required.'
  },
  ein: {
    validator: val => !isValid(val) ? 'EIN must be XX-XXXXXXX or XXXXXXXXX' : null
  },
  [WEBSITE_FIELDNAME]: {
    validator: val =>
      !isWebUri(prependProtocol(val)) ? 'Must be a valid website URL' : null
  },
  [LINKEDIN_PAGE_FIELDNAME]: {
    validator: val =>
      !beginsWithLinkedIn.test(isWebUri(prependProtocol(val))) ? 'Must be a valid LinkedIn URL' : null
  },
  [ADDRESS_FIELDNAME]: {
    required: 'Company address is required',
  }
}

export const mbeUppercase = (str) => {
  const newStr = humanizeScreeningName(str)
  const newStrArr = newStr.split(' ').splice(0)
  const returnedStr = newStrArr.splice(0)
  const mbeString = returnedStr[0].toUpperCase()
  const joinedArr = [mbeString, ...returnedStr.splice(1)]
  return joinedArr.join(' ')
}


const useServiceCompanyStyles = makeStyles(theme => ({
  fab: {
    right: 'auto'
  },
  fieldLabel: {
    marginBottom: theme.spacing(1)
  }
}))

const ServiceCompany = ({
  profile,
  handleSaveChanges,
  validState,
  handleAddressChange,
  handleProfileChange,
  handleFormationYearChange,
  editStyles,
  onPhoneNumberAdd,
  onPhoneNumberRemove,
  onPhoneNumberUpdate,
  updatePhoneNumbers,
  phoneNumbers,
  onUpdateInsurancePolicySC,
  onInsurUploadChange,
  insurance_policies,
  showAllowButtonOrNot,
  handlePrimaryContactOnChange,
  handleUpdatePrimaryContactUser,
  serviceCompanyManagementList,
  disablePrimaryContactEdit = false,
  allowPhones,
  setAllowPhones,
  allowInsurance,
  setAllowInsurance,
  allowPrimaryContactPhones,
  setAllowPrimaryContactPhones,
  handleIndustriesClose,
  industries,
  scIndustries
}) => {
  const styles = useServiceCompanyStyles()
  const {
    editInsurance,
    addInsurance,
    setEditInsurance,
    setAddInsurance,
    handleInsurEdit,
    handleInsurAdd,
    handleInsurRemove,
    removeInsurance,
    handleInsurRemoveConfirm,
    clearInsurRemove,
    handleInsurEditSubmit,
    clearInsurEdit,
    handleInsurAddSubmit,
    clearInsureAdd,
    onInsurRemoveUpload,
  } = insuranceStateUtils(
    [
      insurance_policies,
      onUpdateInsurancePolicySC
    ],
    useState(),
    useState(),
    useState(),
    setAllowInsurance
  )

  const {
    onBlurValidation,
    onChangeValidation,
    onSubmitValidation,
    fieldErrors,
    hasFieldErrors
  } = useValidations(
    serviceCompanyValidations
  )

  const {
    primaryContact = {}
  } = profile
  const history = useHistory()
  const handleOnChange = ev => {
    onChangeValidation(ev)
    handleProfileChange(ev)
  }

  const onAddressChange = (ev, addressDetails) => {
    onChangeValidation(ev, addressDetails)
    handleAddressChange(addressDetails)
  }

  const handleOnSubmit = ev => {
    ev.preventDefault()
    const formIsValid = onSubmitValidation(ev)
    !hasFieldErrors() && formIsValid && handleSaveChanges(ev)
  }

  const minYearDate = new Date('1900 12:00:00 GMT-0500')
  const maxYearDate = new Date()

  return profile && <Card variant="outlined">
    <form onSubmit={handleOnSubmit}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              {...{
                fullWidth: true,
                id: 'company_name',
                name: 'company_name',
                size: 'small',
                label: 'Company Name',
                value: profile.company_name,
                required: true,
                onChange: handleOnChange,
                onBlur: onBlurValidation,
                error: Boolean(fieldErrors['company_name']),
                helperText: fieldErrors['company_name'] || null,
                variant: 'outlined'
              }}/>
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...{
                fullWidth: true,
                id: 'ein',
                name: 'ein',
                size: 'small',
                label: 'EIN',
                value: profile.ein,
                inputProps: {maxlength: '10'},
                onChange: handleOnChange,
                onBlur: onBlurValidation,
                error: Boolean(fieldErrors['ein']),
                helperText: fieldErrors['ein'] || null,
                variant: 'outlined'
              }} />
          </Grid>
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                {...{
                  fullWidth: true,
                  views: ['year'],
                  label: 'Formation Year',
                  id: 'company_formation_year',
                  name: 'company_formation_year',
                  emptyLabel: 'Year',
                  value: getDateObjectFromYear(profile.company_formation_year),
                  inputVariant: 'outlined',
                  size: 'small',
                  onChange: handleFormationYearChange,
                  minDate: minYearDate,
                  maxDate: maxYearDate,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...{
                fullWidth: true,
                id: WEBSITE_FIELDNAME,
                name: WEBSITE_FIELDNAME,
                size: 'small',
                label: 'Company Website',
                value: profile[WEBSITE_FIELDNAME],
                onChange: handleOnChange,
                onBlur: onBlurValidation,
                error: Boolean(fieldErrors[WEBSITE_FIELDNAME]),
                helperText: fieldErrors[WEBSITE_FIELDNAME] || null,
                variant: 'outlined'
              }} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...{
                fullWidth: true,
                id: LINKEDIN_PAGE_FIELDNAME,
                name: LINKEDIN_PAGE_FIELDNAME,
                size: 'small',
                label: 'LinkedIn Page',
                value: profile[LINKEDIN_PAGE_FIELDNAME],
                onChange: handleOnChange,
                onBlur: onBlurValidation,
                error: Boolean(fieldErrors[LINKEDIN_PAGE_FIELDNAME]),
                helperText: fieldErrors[LINKEDIN_PAGE_FIELDNAME] || null,
                variant: 'outlined'
              }}/>
          </Grid>
          <Grid item xs={12}>
            <PlacesAutoComplete
              {...{
                id: ADDRESS_FIELDNAME,
                name: ADDRESS_FIELDNAME,
                size: 'small',
                required: true,
                label: 'Address',
                addressDetails: profile.location,
                onChange: onAddressChange,
                onBlur: onBlurValidation,
                error: Boolean(fieldErrors[ADDRESS_FIELDNAME]),
                helperText: fieldErrors[ADDRESS_FIELDNAME] || null
              }}/>
          </Grid>
          <Grid item xs={12}>
            <IndustriesDropDown {...{
              handleIndustriesClose,
              industries,
              scIndustries
            }}/>
          </Grid>
          <Grid item xs={12}>
            <PhoneNumbersEdit {...{
              phoneNumbers,
              onPhoneNumberAdd,
              onPhoneNumberRemove,
              onPhoneNumberUpdate,
              updatePhoneNumbers,
              setValid: setAllowPhones,
              showAllowButtonOrNot: setAllowPhones
            }}/>
          </Grid>
          {
            !disablePrimaryContactEdit && <Grid item xs={12}>
              <FieldDisplay {...{
                className: styles.fieldLabel,
                label: 'Primary Contact'
              }} />
              <PrimaryContactEdit {...{
                serviceCompanyManagementList,
                selectedUser: primaryContact,
                onChange: handlePrimaryContactOnChange,
                setValid: setAllowPrimaryContactPhones,
                onUserUpdate: handleUpdatePrimaryContactUser
              }} />
            </Grid>
          }
          <Grid item xs={12}>
            <Typography variant="overline">Insurance</Typography>
            {
              !editInsurance && !addInsurance && <InsurancePoliciesDisplay {...{
                insurance: insurance_policies,
                onEdit: handleInsurEdit,
                onAdd: handleInsurAdd,
                onRemove: handleInsurRemove,
                removeInsurance,
                onRemoveConfirm: handleInsurRemoveConfirm,
                onRemoveCancel: clearInsurRemove
              }} />
            }
            {
              editInsurance && !addInsurance && <InsuranceEdit {...{
                insur: editInsurance,
                onSubmit: handleInsurEditSubmit,
                onCancel: clearInsurEdit,
                onRemoveUpload: onInsurRemoveUpload,
                onUploadSave: onInsurUploadChange(setEditInsurance),
                label: "Edit insurance policy",
                showAllowButtonOrNot
              }} />
            }
            {
              addInsurance && <InsuranceEdit {...{
                insur: addInsurance,
                onSubmit: handleInsurAddSubmit,
                onCancel: clearInsureAdd,
                onRemoveUpload: onInsurRemoveUpload,
                onUploadSave: onInsurUploadChange(setAddInsurance),
                label: "Add insurance policy",
                showAllowButtonOrNot
              }} />
            }
          </Grid>
          {<Grid item xs={12}>
            <FormControl>
              <Typography variant="overline">EOC</Typography>
              {Object.keys(profile.eoc).map((op, index) => {
                return <Grid item xs={12}>
                  {Number([index]) === 2 && <Typography style={{
                    textIndent: Number([index]) >= 2 && Number([index]) <= 6 ? '50px' : null
                  }}>
                    Minority-Owned Business Enterprise (MBE)
                  </Typography>
                  }
                  <Box style={{
                    textIndent: Number([index]) >= 2 && Number([index]) <= 6 ? '50px' : null
                  }}>
                  <Checkbox checked={profile.eoc[op]} onChange={handleOnChange} name={op} id={`eoc`}/>
                  {op !== 'lgbt_owned' ? op.substring(0, 3) !== 'mbe' ? humanizeScreeningName(op) : mbeUppercase(op)  : 'LGBTQ Business Enterprise'}

                  {}

                  </Box>
                </Grid>
              })}
            </FormControl>
          </Grid>}
        </Grid>
      </CardContent>
      <CardActions className={editStyles.cardActions}>
        <FormSubmitButton {...{
          className: history.location.pathname !== SERVICE_COMPANY_EDIT ? styles.fab : '',
          disabled: hasFieldErrors() ||
            !validState ||
            !allowPhones ||
            !allowInsurance ||
            !allowPrimaryContactPhones
          ,
          useFab: Boolean(history.location.pathname !== SIGN_UP_SC_CREATE_PATH),
        }}>
          {profile._id ? 'Save' : 'Create'} Service Company
        </FormSubmitButton>
      </CardActions>
    </form>
  </Card>
}

export default ServiceCompany
