export const INIT_BIRTHDATE = ''
export const TECH_PROFILE_INIT_STATE = {
  _id: '',
  birth_date: INIT_BIRTHDATE,
  location: {
    label: 'work',
    formatted_address: ''
  },
  phones: [],
  service_radius: null,
  hour_rate: null,
  work_experience: [],
  certifications: [],
  insurance_policies: [],
  veteran_dd214: '',
  pay_info: {
    working_hours: [
      [null,null],
      [null,null],
      [null,null],
      [null,null],
      [null,null],
      [null,null],
      [null,null],
    ]
  },
  diversity: {
    nonhispanic_white_or_euro_american:false,
    black_afrocaribbean_africa_american:false,
    latino_hispanic_american:false,
    east_asian_asian_american:false,
    south_asian_indian_american:false,
    middle_eastern_arab_american:false,
    native_american_alaskan_native:false,
  },
  gender: {
    male: false,
    female: false,
    other: false,
  }
}
