import noop from '../util/noop'
import { Button, Typography } from '@material-ui/core'
import { IconWithAdornment } from './IconWithAdornment'
import { AccountBalanceSharp, AddCircleOutlineSharp, CreditCardSharp } from '@material-ui/icons'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const usePaymentSourceAddButtonsStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    color: theme.palette.secondary.light,
    '&:hover': {
      color: theme.palette.primary.dark
    }
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}))
export const enableAddBankAccount = false
export const PaymentSourceAddButtons = ({
  onAddBankAccountClick = noop,
  onAddCardAccountClick = noop
}) => {
  const styles = usePaymentSourceAddButtonsStyles()
  return <Grid
    container
    spacing={1}
    justify={enableAddBankAccount ? null : 'center'}
  >
    <Grid item xs={enableAddBankAccount ? 6: 12}>
      <Button {...{
        className: styles.button,
        variant: 'outlined',
        onClick: onAddCardAccountClick
      }}>
        <IconWithAdornment {...{
          fontSize: 'large',
          className: styles.icon,
          adornment: <AddCircleOutlineSharp/>
        }} >
          <CreditCardSharp/>
        </IconWithAdornment>
        <Typography {...{
          variant: 'body2'
        }}>Add Credit Card</Typography>
      </Button>
    </Grid>
    {enableAddBankAccount && <Grid item sm={6}>
      <Button {... {
        className: styles.button,
        variant: 'outlined',
        onClick: onAddBankAccountClick
      }}>
        <IconWithAdornment {... {
          fontSize: 'large',
          className: styles.icon,
          adornment: <AddCircleOutlineSharp/>
        }} >
          <AccountBalanceSharp/>
        </IconWithAdornment>
        <Typography {... {
          variant: 'body2'
        }}>Add Checking Account</Typography>
      </Button>
    </Grid>}
  </Grid>
}
