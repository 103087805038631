import {FormHelperText} from '@material-ui/core'
import {useState} from 'react'
import {replaceItemInList} from '../../../../util/lists.util'
import {FormSubmitButton} from '../../../FormSubmitButton'
import {data as userList} from './mock-data/sc-managers.json'
import {data as serviceCompany} from './mock-data/service-company.json'
import {PrimaryContactEdit} from '../../../PrimaryContactEdit'
import {FieldDisplay} from '../../../FieldDisplay'
import makeStyles from '@material-ui/core/styles/makeStyles'

const usePrimaryContactEditExampleStyles = makeStyles(theme => ({
  fieldLabel: {
    marginBottom: theme.spacing(1)
  }
}))
export const PrimaryContactEditExample = () => {
  const styles = usePrimaryContactEditExampleStyles()
  const {
    primary_contact_id
  } = serviceCompany
  const initSelectedUser = userList.find(
    u => u._id === primary_contact_id
  )
  const [selectedUser, setSelectedUser] = useState(initSelectedUser)
  const [isValid, setIsValid] = useState(true)
  const phoneNumbers = selectedUser.phones

  const updatePhoneNumbers = (newPhones) => {
    setSelectedUser({
      ...selectedUser,
      phones: newPhones
    })
    setIsValid(true)
  }

  const handleSelectChange = ({
    target: {
      value: userId
    }
  } = {}) => setSelectedUser(
    userList.find(u => u._id === userId)
  )

  const onPhoneNumberAdd = (info) =>
    updatePhoneNumbers([
      ...phoneNumbers,
      {
        label: info.label,
        raw_phone: `+${info.raw_phone}`,
        country: 'us'
      }
    ])

  const onPhoneNumberRemove = (index) => {
    const newPhones = [...phoneNumbers].filter((p, i) => i !== index)
    updatePhoneNumbers(newPhones)
  }

  const onPhoneNumberUpdate = (index, info) => {
    console.log(`info => `, info)
    const newPhones = replaceItemInList(info, phoneNumbers, index)
    updatePhoneNumbers(newPhones)
  }

  return <>
    <FieldDisplay {...{
      className: styles.fieldLabel,
      label: 'Primary Contact'
    }} />
    <PrimaryContactEdit {...{
      userList,
      selectedUser,
      onChange: handleSelectChange,
      onPhoneNumberUpdate,
      onPhoneNumberAdd,
      onPhoneNumberRemove,
      setIsValid
    }} />
    <FormSubmitButton {...{
      disabled: !isValid
    }}>
      Submit
    </FormSubmitButton>
    <FormHelperText>Only here to show enable/disable of parent form submit</FormHelperText>
  </>
}

