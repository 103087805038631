import {makeStyles} from '@material-ui/core/styles'
import noop from '../util/noop'
import {removeItemByIndex} from '../util/lists.util'
import ChipInput from 'material-ui-chip-input'

const SKILLS_EDIT_HELPER_TEXT = 'Press ENTER after each skill'
const useSkillsEditStyles = makeStyles(theme => ({
  chipInputSmall: {
    minHeight: theme.spacing(3),
    '& .MuiInputBase-root': {
      paddingTop: `${theme.spacing(0.75)}px`
    },
    '& .MuiInputBase-input': {
      marginTop: 0,
      marginBottom: 0
    },
    '& label.MuiInputLabel-root.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      top: 0
    },
    '& div.MuiButtonBase-root.MuiChip-root': {
      marginLeft: theme.spacing(0.25),
      marginRight: theme.spacing(0.25),
      height: `${theme.spacing(3.25)}px`,
      '&[style*="background-color"]': {
        // Have to use !important here because the chips lib is doing inline styling to control
        // the background color of a selected chip 🤦‍♂️
        backgroundColor: `${theme.palette.primary.main} !important`
      }
    },
    '& .MuiFormHelperText-root': {
      marginBottom: 0
    }
  }
}))
export const SkillsEdit = ({
  skills = [],
  setSkills = noop,
  ...restProps
}) => {
  const styles = useSkillsEditStyles()
  const onAdd = skill => setSkills([
    ...skills,
    skill
  ])
  const onDelete = (skill, index) => setSkills(removeItemByIndex(skills, index))

  const className = `${
    restProps.size === 'small' ? styles.chipInputSmall : ''
  } ${
    restProps.className || ''
  }`

  return <ChipInput {...{
    value: skills,
    onAdd,
    onDelete,
    label: 'Skills',
    placeholder: 'Enter skills...',
    variant: 'outlined',
    helperText: SKILLS_EDIT_HELPER_TEXT,
    fullWidth: true,
    ...restProps,
    className
  }} />
}
