import {
  Button,
  Checkbox,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel, TextField
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { FieldDisplay } from './FieldDisplay'
import { FormSubmitButton } from './FormSubmitButton'
import React from 'react'
import { MimeTypes } from 'http-headers-js'
import { EditRemoveButtons } from './EditRemoveButtons'
import { DropZoneDialogTrigger } from './DropZoneDialogTrigger'
import { requiredDocs } from './sandboxes/kevin/examples/mock-data/required-docs-mock'
import Typography from '@material-ui/core/Typography'


export const OnboardingDocumentUploadSendInvite = ({
  handleClose,
  openModal,
                                                     requiredDocs,
                                                     handleCheckBoxClick,
                                                     handleInviteSent,
  teamOrProviderList,
                                                     sentDocs
                                                   }) => {


  return <Dialog open={openModal} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Required Documents</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Select preset required documents or set them in your Required Documents Tab (this will be a link)
      </DialogContentText>
      <FormControl>
        <Grid container>
          <Grid item xs={12}>
            {/*<FormControlLabel*/}
            {/*  control={<Checkbox />}*/}
            {/*  label="Preset 1"*/}
            {/*/>*/}

            {requiredDocs.length > 0 ? requiredDocs.map(doc => {
              return <FormControlLabel control={<Checkbox onClick={handleCheckBoxClick} value={doc.filename} name={doc.name}/>} label={doc.name} />
            }) : <Typography style={{
              paddingTop: '5px',
              paddingBottom: '5px',
            }}>
              No Docs Set. Move to 'Required Docs' tab to add required documents
            </Typography>}
          </Grid>
        </Grid>
      </FormControl>
      {/*<FieldDisplay label={'Add more documents'} />*/}
      {/*<TextField*/}
      {/*  variant={'outlined'}*/}
      {/*  size={'small'}*/}
      {/*  fullWidth*/}
      {/*  placeholder={'Name of Document'}*/}
      {/*  style={{*/}
      {/*    paddingTop: '10px',*/}
      {/*    paddingBottom: '15px'*/}
      {/*  }}*/}
      {/*/>*/}
      {/*  <DropZoneDialogTrigger*/}
      {/*    {...{*/}
      {/*      dialogOpen: uploadOpen,*/}
      {/*      acceptedFiles: [MimeTypes.Application.PDF],*/}
      {/*      submitButtonText: 'Upload Policy',*/}
      {/*      uploadDialogProps: {*/}
      {/*        dialogTitle: 'Upload Policy',*/}
      {/*      },*/}
      {/*      onClose: closeUpload,*/}
      {/*     // onSave: handleOnUploadSave(insurance),*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <FormSubmitButton*/}
      {/*      {...{*/}
      {/*        type: 'button',*/}
      {/*        variant: 'outlined',*/}
      {/*        onClick: openUpload,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Upload Policy*/}
      {/*    </FormSubmitButton>*/}
      {/*  </DropZoneDialogTrigger>*/}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={ () => handleInviteSent(teamOrProviderList, sentDocs)} color="primary">
        Send Invite
      </Button>
    </DialogActions>
  </Dialog>
}
