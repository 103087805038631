import { Card, CardActionArea, CardContent, CardMedia, FormHelperText, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { teamOrProvider } from './mock-data/service-company-mock'
import { Form } from 'formik'
import img1 from '../../../../assets/images/profile_picture_user_icon_153847.svg'

export const CardViewScProviderList = () => {

  return <Grid container direction={'row'} justify={'space-evenly'} spacing={2}>
    {
      teamOrProvider.map(team => {
        return <Grid item xs={4}>
          <CardView {...{
            team
          }}/>
        </Grid>
      })
    }
  </Grid>

}


export const CardView = ({team}) => {
  return <Card elevation={1}>
    <CardActionArea>
      <CardMedia component={'img'}
                 image={team.company_logo || img1}
                 style={{
                   height: '100px'
                 }}
      />
      <CardContent>
          <Typography gutterBottom variant={'h6'}>
            {team.company_name}
          </Typography>
          {team.industries ? team.industries.map(ind => {
            return <FormHelperText>
              {ind}
            </FormHelperText>
          }) : <FormHelperText>
            No Industries Given
          </FormHelperText>}
        <FormHelperText>
          {team.location.formatted_address}
        </FormHelperText>
      </CardContent>
    </CardActionArea>
  </Card>
}
