import axios from 'axios'

import appConfig from '../../config'
import useAccessToken from './useAccessToken'

const profileFetch = (userId, orgId, isTechProfile) => async (dispatch, getState, getFirebase) => {
  if (!isTechProfile && !orgId) return dispatch({ type: 'PROFILE/FETCH-OK', payload: { isTechProfile } })

  const { chekhubConnect: { baseURI, operations, defaultHeaders } } = appConfig
  const { techProfileFetchOne, serviceCompanyProfileFetchOne } = operations
  const fetchOperation = isTechProfile ? techProfileFetchOne : serviceCompanyProfileFetchOne
  const { path, method, authRequired } = fetchOperation

  const token = authRequired ? await useAccessToken() : null

  try {
    const { status, data: body } = await axios({
      baseURL: baseURI,
      url: `${path}/${isTechProfile ? userId : orgId}`,
      method,
      headers: {
        ...defaultHeaders,
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
    })
    if (status !== 200) throw new Error('unknown profileFetchError')
    dispatch({ type: 'PROFILE/FETCH-OK', payload: { data: body.data } })
  } catch (err) {
    dispatch({
      type: 'TOAST/TRIGGER-NOTIFICATION',
      payload: {
        type: 'error',
        message: 'There was an error loading the profile',
      },
    })
    dispatch({ type: 'PROFILE/FETCH-ERROR', payload: err })
  }
}

export default profileFetch
