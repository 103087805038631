import appConfig from '../config'
import {Headers, Methods} from 'http-headers-js'
import generateAuthHeader from './generate-auth-header.util'
import generatePassword from '../components/shared-functions/generate-password'
import {generateUrlQueryString} from './generate-url-query-string.util'

const {chekhubConnect: {
  paths: {
    userService: path,
    passwordReset: passwordResetPath
  },
  baseURI,
  defaultHeaders
}} = appConfig
const serviceBaseUri = `${baseURI}${path}`

export const getUser = async (userId = 'me') => await fetch (
  `${serviceBaseUri}/${userId}`, {
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    }
  }
)

export const getUserList = async searchParams => await fetch(
  `${serviceBaseUri}${generateUrlQueryString(searchParams)}`,
  {
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    }
  }
)

export const postUser = async user => await fetch(
  `${serviceBaseUri}${generateUrlQueryString(user.searchParams)}`, {
    method: Methods.POST,
    headers: {
      ...defaultHeaders
    },
    body: JSON.stringify({
      ...user,
      password: user.password || generatePassword()
    })
  }
)

export const putUser = async user => await fetch(
  `${serviceBaseUri}/${user._id}`, {
    method: Methods.PUT,
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    body: JSON.stringify(user)
  }
)

export const removeUserFromOrg = async (userId, orgId) => await fetch(
  `${serviceBaseUri}/${userId}/organizations/${orgId}`, {
    method: Methods.DELETE,
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    }
  }
)

export const putUserPasswordReset = async email => await fetch(
  `${baseURI}${passwordResetPath}`, {
    headers: {
      ...defaultHeaders
    },
    method: Methods.PUT,
    body: JSON.stringify({
      email
    })
  }
)
