import {Box, Grid, TextField} from '@material-ui/core'
import {MinMaxDateField} from './MinMaxDateField'
import {CloudDownloadLink} from './CloudDownloadLink'
import {DropZoneDialogTrigger} from './DropZoneDialogTrigger'
import {MimeTypes} from 'http-headers-js'
import noop, {noopThunk} from '../util/noop'
import {EditRemoveButtons} from './EditRemoveButtons'
import {ConfirmationModal} from './ConfirmationModal'
import {FormSubmitButton} from './FormSubmitButton'
import {useEffect, useState} from 'react'
import {useValidations} from '../hooks/useValidations'

const CERT_TITLE_FIELDNAME = 'certification_title'
const CERT_ISSUED_BY = 'issued_by'

const CERT_VALIDATIONS = {
  [CERT_TITLE_FIELDNAME]: {
    required: 'Certification title required'
  },
  [CERT_ISSUED_BY]: {
    required: 'Certification issuer required'
  }
}
export const CertificateForm = ({
  cert,
  onSubmit = noopThunk,
  onChange = noop,
  onUploadSave = noop,
  onRemoveUpload = noop,
  setHasFieldErrors = noop
}) => {
  const {
    certification_title,
    issued_by,
    issued_on,
    start_date,
    end_date,
    filename,
    expires_on,
    description
  } = cert || {}

  const {
    onChangeValidation,
    onBlurValidation,
    onSubmitValidation,
    fieldErrors,
    hasFieldErrors
  } = useValidations(CERT_VALIDATIONS)

  useEffect(
    () => setHasFieldErrors(hasFieldErrors()),
    [fieldErrors]
  )

  const [uploadOpen, setUploadOpen] = useState(false)
  const [removeUploadConfirmOpen, setRemoveUploadConfirmOpen] = useState(false)
  const handleOnUploadSave = targetCert => files => {
    onUploadSave(targetCert, files)
    closeUpload()
  }
  const openUpload = () => setUploadOpen(true)
  const closeUpload = () => setUploadOpen(false)

  const openRemoveConfirm = () => setRemoveUploadConfirmOpen(true)
  const closeRemoveConfirm = () => setRemoveUploadConfirmOpen(false)
  const handleOnRemoveUploadConfirm = () => {
    onRemoveUpload(cert)
    closeRemoveConfirm()
  }

  const handleFieldOnChange = ev => {
    onChangeValidation(ev)
    onChange(ev)
  }

  const handleOnSubmit = ev => {
    ev.preventDefault()
    const formIsValid = onSubmitValidation(ev)
    !hasFieldErrors() && formIsValid && onSubmit(ev)
  }

  return <form {...{
    onSubmit: handleOnSubmit
  }}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField {...{
          fullWidth: true,
          id: CERT_TITLE_FIELDNAME,
          name: CERT_TITLE_FIELDNAME,
          label: 'title',
          variant: 'outlined',
          value: certification_title,
          size: 'small',
          required: true,
          onChange: handleFieldOnChange,
          onBlur: onBlurValidation,
          error: Boolean(fieldErrors[CERT_TITLE_FIELDNAME]),
          helperText: fieldErrors[CERT_TITLE_FIELDNAME] || null
        }} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...{
          fullWidth: true,
          id: CERT_ISSUED_BY,
          name: CERT_ISSUED_BY,
          label: 'Issuer',
          variant: 'outlined',
          value: issued_by,
          size: 'small',
          required: true,
          onChange: handleFieldOnChange,
          onBlur: onBlurValidation,
          error: Boolean(fieldErrors[CERT_ISSUED_BY]),
          helperText: fieldErrors[CERT_ISSUED_BY] || null
        }} />
      </Grid>
      <Grid item xs={6}>
        <MinMaxDateField {...{
          id: 'issued_on',
          fullWidth: true,
          size: 'small',
          label: 'Issue Date',
          type: 'date',
          value: issued_on,
          minYear: 1900,
          InputLabelProps: {shrink: true},
          onChange,
          variant: 'outlined'
        }} />
      </Grid>
      <Grid item xs={6}>
        <MinMaxDateField {...{
          id: 'expires_on',
          fullWidth: true,
          size: 'small',
          label: 'Expires',
          type: 'date',
          value: expires_on,
          minYear: 1900,
          InputLabelProps: {shrink: true},
          onChange,
          variant: 'outlined'
        }} />
      </Grid>
      <Grid item xs={6}>
        <MinMaxDateField {...{
          id: 'start_date',
          fullWidth: true,
          size: 'small',
          label: 'Start Date',
          type: 'date',
          value: start_date,
          minYear: 1900,
          InputLabelProps: {shrink: true},
          onChange,
          variant: 'outlined'
        }} />
      </Grid>
      <Grid item xs={6}>
        <MinMaxDateField {...{
          id: 'end_date',
          fullWidth: true,
          size: 'small',
          label: 'End Date',
          type: 'date',
          value: end_date,
          minYear: 1900,
          InputLabelProps: {shrink: true},
          onChange,
          variant: 'outlined'
        }} />
      </Grid>
      <Grid item xs={12}>
        <TextField {...{
          fullWidth: true,
          id: 'description',
          label: 'Description',
          multiline: true,
          variant: 'outlined',
          rows: 4,
          value: description,
          size: 'small',
          onChange
        }} />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          {
            filename &&
            <>
              <CloudDownloadLink {...{cloudFilename: filename}} >
                <span>Certificate</span>
              </CloudDownloadLink>
              <DropZoneDialogTrigger {...{
                dialogOpen: uploadOpen,
                acceptedFiles: [MimeTypes.Application.PDF],
                uploadDialogProps: {
                  dialogTitle: 'Upload Certificate'
                },
                onClose: closeUpload,
                onSave: handleOnUploadSave(cert),
              }}>
                <EditRemoveButtons {...{
                  editTitle: 'Edit Certificate',
                  removeTitle: 'Remove Certificate',
                  onEdit: openUpload,
                  onRemove: openRemoveConfirm
                }} />
              </DropZoneDialogTrigger>
              <ConfirmationModal {...{
                open: removeUploadConfirmOpen,
                onOk: handleOnRemoveUploadConfirm,
                onClose: closeRemoveConfirm
              }}>
                Are you sure you want to remove this certificate?
              </ConfirmationModal>
            </>
          }
          {
            !filename && <DropZoneDialogTrigger {...{
              dialogOpen: uploadOpen,
              acceptedFiles: [MimeTypes.Application.PDF],
              submitButtonText: 'Upload Certificate',
              uploadDialogProps: {
                dialogTitle: 'Upload Certificate'
              },
              onClose: closeUpload,
              onSave: handleOnUploadSave(cert)
            }}>
              <FormSubmitButton {...{
                type: 'button',
                variant: 'outlined',
                onClick: openUpload
              }}>Upload Certificate</FormSubmitButton>
            </DropZoneDialogTrigger>
          }
        </Box>
      </Grid>
    </Grid>
  </form>
}
