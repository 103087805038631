import appConfig from '../config'
import {Headers, Methods} from 'http-headers-js'
import generateAuthHeader from './generate-auth-header.util'
import {generateUrlQueryString} from './generate-url-query-string.util'

const {
  chekhubConnect: {
  paths: {
    techProfileService: path
  },
  baseURI,
  defaultHeaders
}} = appConfig
const serviceBaseUri = `${baseURI}${path}`

export const getTechProfile = async userId => fetch(
  `${serviceBaseUri}/${userId}`,
  {
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    }
  }
)

export const getTechProfileListForOrg = async (orgId, searchParams = {}) => fetch(
  `${serviceBaseUri}${
    generateUrlQueryString({
      organization_id: orgId,
      ...searchParams
    })
  }`,
  {
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    }
  }
)

export const postTechProfile = async techProfile => fetch(
  `${serviceBaseUri}`,
  {
    method: Methods.POST,
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    body: JSON.stringify(techProfile)
  }
)

const cleanDataBecauseWeStillHaventSolvedEmptyDataContract = data => {
  return Object.keys(data).reduce((result, key) => {
    if (![null, undefined, ''].includes(data[key])) {
      result[key] = data[key]
    }
    return {...result}
  }, {})
}

export const putTechProfile = async techProfile => fetch(
  `${serviceBaseUri}/${techProfile._id}`,
  {
    method: Methods.PUT,
    headers: {
      ...defaultHeaders,
      [Headers.AUTHORIZATION]: await generateAuthHeader()
    },
    body: JSON.stringify(
      cleanDataBecauseWeStillHaventSolvedEmptyDataContract(techProfile)
    )
  }
)
