import Grid from "@material-ui/core/Grid"
import {Avatar, Box, Card, CardContent, Divider, Typography, Link as LinkNative} from '@material-ui/core'
import useLandingViewStyles from "./LandingViewStyles"
import {Link, Redirect, Route, useParams, withRouter} from 'react-router-dom'
import CreateIcon from "@material-ui/icons/Create"
import {RouteConnectedDrawer} from './RouteConnectedDrawer'
import {ServiceCompanyMemberEditView} from './ServiceCompanyMemberEdit.view'
import {useState} from 'react'
import {VeteranStatusDisplay} from './VeteranStatusDisplay'
import {EmailLink} from './EmailLink'
import PhoneNumbersDisplay from "./PhoneNumbersDisplay"
import {WorkExperienceView} from "./WorkExperience"
import {CertificationsDisplay} from './CertificationsDisplay'
import WorkingDaysDisplay from "./WorkingDaysDisplay"
import {SkillsDisplay} from './SkillsDisplay'
import {getHumanReadableDate} from '../util/date-time.util'
import {InsurancePoliciesDisplay} from './InsurancePoliciesDisplay'
import {BackgroundChecker} from "./BackgroundChecker"
import { useDimensionsContextStyles } from "./DimensionsContext"
import {getUserOrgForTechProfileId, isServiceCompanyManager} from '../util/users.util'
import {useAuthUser} from '../hooks/useAuthUser'
import {getMaskedValue} from './SsnInput'
import { GOOGLE_MAP_API_HREF, INDEPENDENT_TECH_ORG_TYPE } from '../constants/user.constants'
import {formatCurrency} from '../util/number-formatting.util'
import {FieldDisplay} from './FieldDisplay'

const ScTechProfileView = withRouter(({
  tech,
  match,
  backgroundCheckPackages,
  backgroundCheck,
  handleBgPurchase,
  orgId
}) => {
  const styles = useLandingViewStyles()
  const dimensionsContextStyles = useDimensionsContextStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const {
    veteran_status,
    email,
    certifications = [],
    insurance_policies = [],
    skills = [],
    revenue = {}
  } = tech || {}
  const {userId} = useParams()
  const authorizedUser = useAuthUser()
  const isScManager = isServiceCompanyManager(authorizedUser, orgId)
  const techOrg = getUserOrgForTechProfileId(tech, userId)
  const isIndependentTechOrg = techOrg?.organization_type === INDEPENDENT_TECH_ORG_TYPE
  const canEditProfile = isScManager
  const showBackgroundCheck = isScManager && tech._id && Boolean(backgroundCheckPackages?.length)

  return <Box {...{
      border: 0,
      className: dimensionsContextStyles.scrollContainer
    }}>
      <Card className={styles.summaryHolder}>
        <CardContent>
          <Grid container direction={'row'} spacing={2}>
            <Grid
              {...{
                item:true,
                xs:12
              }}>
              <Box {...{
                display: 'flex',
                justifyContent: 'flex-start'
              }}>
                <Avatar {...{
                  className: styles.avatar
                }}>
                </Avatar>
                <Box>
                  <Typography variant={'h4'}>
                    {tech.full_name}
                  </Typography>
                  <Typography variant={'body1'} gutterBottom>
                    <EmailLink {...{email}} />
                  </Typography>
                  <Typography variant={'body1'}>
                    <Box display={'flex'}>
                      <VeteranStatusDisplay {...{
                        veteran_status
                      }} />
                    </Box>
                  </Typography>
                </Box>
                {
                  canEditProfile &&
                    <Link className={styles.editLink} to={`${match.url}/edit`}>
                      <CreateIcon/>
                    </Link>
                }
              </Box>
            </Grid>
            {
              tech.location.formatted_address && <Grid item xs={ isIndependentTechOrg ? 6 : 12}>
                <FieldDisplay {...{
                  label: <span>Address</span>
                }} />
                <LinkNative href={`${GOOGLE_MAP_API_HREF}${tech.location.formatted_address}`} target={'_blank'}>
                <Typography variant={'body1'}>
                  {tech.location.formatted_address}
                </Typography>
                </LinkNative>
              </Grid>
            }
            {
              isIndependentTechOrg && <Grid item xs={4}>
                <FieldDisplay {...{
                  label: <span>Connect Platform Revenue</span>
                }} />
                <Typography variant={'body2'}>
                  Year to Date: {
                    formatCurrency(revenue.ytd, revenue.currency)
                  }
                </Typography>
                <Typography variant={'body2'}>
                  Total: {
                    formatCurrency(revenue.lifetime, revenue.currency)
                  }
                </Typography>
              </Grid>
            }
            <Grid item xs={4}>
              <Grid item xs>
                <FieldDisplay {...{
                  label: <span>Job Title</span>
                }} />
                <Typography variant={'body1'}>
                  {!tech.job_title ? 'No Job Title Listed' : tech.job_title}
                </Typography>
              </Grid>
              <Grid item xs>
                <FieldDisplay {...{
                  label: <span>Birth Date</span>
                }} />
                <Typography display="block" variant={'body1'}>
                  {tech.birth_date && getHumanReadableDate(tech.birth_date)}
                </Typography>
              </Grid>
              <Grid item xs>
                <FieldDisplay {...{
                  label: <span>SSN</span>
                }} />
                <Typography variant={'body1'}>
                  {getMaskedValue(tech.ssn)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item xs>
                <FieldDisplay {...{
                  label: <span>Service Radius</span>
                }} />
                <Typography variant={'body1'}>
                  {tech.service_radius} miles
                </Typography>
              </Grid>
              <Grid item xs>
                <FieldDisplay {...{
                  label: <span>Hour Rate</span>
                }} />
                <Typography variant={'body1'}>
                  ${tech.hour_rate}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <FieldDisplay {...{
                label: <span>Phone Numbers</span>
              }} />
              <PhoneNumbersDisplay {...{
                phones: tech.phones
              }} />
            </Grid>
            {
              showBackgroundCheck && <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FieldDisplay {...{
                      label: <span>Background Check</span>
                    }} />
                  </Grid>
                  <BackgroundChecker {...{
                    backgroundCheckPackages,
                    backgroundCheck,
                    handleBgPurchase
                  }}/>
                </Grid>
              </Grid>
            }
          </Grid>
          <Divider className={styles.techHolder}/>
          <Grid
            container
            spacing={2}
            direction={'row'}>
            {
              tech?.pay_info?.working_hours && <Grid item xs={12} sm={6}>
                <FieldDisplay {...{
                  label: <span>Schedule</span>
                }} />
                <WorkingDaysDisplay {...{
                  working_hours: tech.pay_info.working_hours,
                }}/>
              </Grid>
            }
            <Grid item xs={12} sm={6}>
              <FieldDisplay {...{
                label: <span>Skills</span>
              }} />
              <SkillsDisplay {...{
                skills
              }} />
            </Grid>
            <Grid item xs={12}>
              <Divider className={styles.techHolder}/>
              <FieldDisplay {...{
                label: <span>Certifications</span>
              }} />
              <CertificationsDisplay {...{
                certifications,
                disableEdit: true,
                disableRemove: true,
                disableAdd: true
              }} />
            </Grid>
            <Grid item xs={12}>
              <FieldDisplay {...{
                label: <span>Insurance</span>
              }} />
              <InsurancePoliciesDisplay {...{
                insurance: insurance_policies,
                disableEdit: true,
                disableRemove: true,
                disableAdd: true
              }} />
            </Grid>
            <Grid item xs={12}>
              <Divider className={styles.techHolder}/>
              <FieldDisplay {...{
                label: <span>Work Experience</span>
              }} />
              <WorkExperienceView {...{
                title: null,
                techProfile: tech,
              }}/>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Route exact path={`${match.path}/edit`}>
        {
          canEditProfile ? <RouteConnectedDrawer {...{
            open: drawerOpen,
            setOpen: setDrawerOpen,
            anchor: 'right',
            title: tech.full_name,
            backRoutePath: match.url
          }}>
            {
              <ServiceCompanyMemberEditView {...{
                setDrawerOpen: setDrawerOpen,
                backRoutePath: match.url,
                techProfileId: tech._id
              }} />
            }
          </RouteConnectedDrawer> :
          <Redirect to={match.url} />
        }
      </Route>
    </Box>
})

export default ScTechProfileView
