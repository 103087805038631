import { authFetchUser } from './auth-fetch-user'
import authSetActiveOrg from './auth-set-active-org'
import {signOutActions} from './sign-out.actions'
import authSignUp from './auth-sign-up'
import authTechSignUp from './auth-tech-sign-up'
import impersonatedProfileReset from './impersonated-profile-reset'
import impersonatedProfileSet from './impersonated-profile-set'
import AddRemoveFromTeam from './profile-teams'
import profileFetch from './profile-fetch'
import impersonatedProfileFetch from './impersonated-profile-fetch'
import profileFetchList from './profile-fetch-list'
import profileSubcollectionRemoveItem from './profile-subcollection-remove-item'
import profileUpsert from './profile-upsert'
import storageFileUpload from './storage-file-upload'
import storageFileDownloadUrl from './storage-file-download-url'
import ticketFetch from './ticket-fetch'
import ticketSet from './ticket-set'
import ticketReset from './ticket-reset'
import ticketLogCreate from './ticket-log-create'
import ticketLogClear from './ticket-log-clear'
import toastReset from './toast-reset'
import toastShow from './toast-show'
import initState from './init-state'

import { upsertProfileSubcollection } from './profile-subcollection-upsert'

export default {
  'AUTH/FETCH-USER': authFetchUser,
  'AUTH/SET-ACTIVE-ORG': authSetActiveOrg,
  'AUTH/SIGN-OUT': signOutActions,
  'AUTH/SIGN-UP': authSignUp,
  'AUTH/SIGN-UP-TECH': authTechSignUp,
  'PROFILE/FETCH': profileFetch,
  'IMPERSONATED-PROFILE/FETCH': impersonatedProfileFetch,
  'PROFILE/FETCH-LIST': profileFetchList,
  'IMPERSONATED-PROFILE/RESET': impersonatedProfileReset,
  'IMPERSONATED-PROFILE/SET': impersonatedProfileSet,
  'PROFILE/ADD-TO-TEAM': AddRemoveFromTeam,
  'PROFILE/UPSERT': profileUpsert,
  'PROFILE/SUBCOLLECTION-UPSERT': upsertProfileSubcollection,
  'PROFILE/SUBCOLLECTION-REMOVE-ITEM': profileSubcollectionRemoveItem,
  'STORAGE/GET-SIGNED-DOWNLOAD-URL': storageFileDownloadUrl,
  'STORAGE/UPLOAD-FILE': storageFileUpload,
  'TICKET/FETCH': ticketFetch,
  'TICKET/SET': ticketSet,
  'TICKET/RESET': ticketReset,
  'TICKET/CREATE-LOG': ticketLogCreate,
  'TICKET/CLEAR-LOG': ticketLogClear,
  'TOAST/RESET': toastReset,
  'TOAST/Show': toastShow,
  'INIT-STATE': initState,
}
