import { Box, Button, Card, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { FormSubmitButton } from './FormSubmitButton'
import Typography from '@material-ui/core/Typography'
import useSignInStyles from './SignInStyles'
import { useDispatch, useSelector } from 'react-redux'
import jumbo from '../assets/images/Chekhub_Connect_Gray.svg'
import { CHEKHUB_ORANGE } from '../theme'
import {
  SIGN_UP_COMPLETE_TECH_PROFILE_PATH,
  SIGN_UP_PATH,
  SIGN_UP_PATH_INDY,
  SIGN_UP_SC_CREATE_PATH
} from '../routing/route.constants'
import {
  SET_STEPPER_TECHS_FLOW,
  setActiveStep,
  setCompletedStep, setStepperSCFlow,
  setStepperTechsFlow
} from '../store/actions/stepper.actions'
import { useHistory } from 'react-router-dom'


export const SelectUserTypeEndUser = () => {
  const styles = useSignInStyles()
  const auth = useSelector(({auth}) => auth)
  const dispatch = useDispatch()
  const history = useHistory()
  return <Grid container justify={'center'} alignItems={'center'}>
    <Card variant={'outlined'} style={{
      paddingBottom: '55px',
      paddingRight: '55px',
      paddingLeft: '55px'
    }}>
      <Grid container>
    <Grid item xs={12}>
        <img
          src={jumbo}
          alt={'connect-logo-orange'}
          style={{
            paddingTop: '20%'
          }}
          height={'auto'}
          className={styles.img}
        />
    </Grid>
    <Grid item xs={12}>
      <Typography
        {...{
          variant: 'h4',
          fullWidth: true,
          style: {
            textAlign: 'center'
          }
        }} gutterBottom>
        {'Create an Account to Find Work'}
      </Typography>
    </Grid>
    <TextField {... {
      id: 'email',
      name: 'email',
      className: styles.textField,
      size: 'medium',
      label: 'Email',
      value: auth.user.email || auth.user.full_name,
      fullWidth: true,
      variant: 'outlined',
    }} />
      </Grid>
    </Card>

    <Grid container className={styles.signInHolder} justify={'center'} style={{
      paddingTop: '15px'
    }}>
      <Card variant={'outlined'} style={{
        height: '150px',
        width: '75%',
      }}>
        <Grid item xs={12} style={{
          paddingBottom: '24px'
        }}>
          <Typography align={'center'} className={styles.header} style={{
            fontSize: '24px'
          }}>
            Select a User Type
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Box textAlign={'center'}>
              <Button variant={'contained'} style={{
                borderRadius: '15px',
                backgroundColor: CHEKHUB_ORANGE,
                align: 'center',
              }}
                      onClick={() => (dispatch(setStepperSCFlow()) && dispatch(setActiveStep(1)) &&
                        dispatch(setCompletedStep(0)) && history.push(SIGN_UP_SC_CREATE_PATH))}
              >
                Sign up as Service Company
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box textAlign={'center'}>
              <Button variant={'contained'} style={{
                borderRadius: '15px',
                backgroundColor: CHEKHUB_ORANGE,
                align: 'center',
              }}
                      onClick={() => (dispatch(setStepperTechsFlow()) && dispatch(setActiveStep(2)) &&
                        dispatch(setCompletedStep(1)) && history.push(SIGN_UP_COMPLETE_TECH_PROFILE_PATH))}
              >
                Sign up as Independent Tech
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  </Grid>
}
