import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  searchInput: {
    width: '100%',
  },
  cell: {
    borderBottom: 'none'
  },
  collapseCell: {
    borderBottom: 'none',
    padding: '0px'
  },
  toolbarTable: {
    padding: '0px',
  }
}))
