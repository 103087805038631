import React, { useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import {
  Button,
  Grid,
} from '@material-ui/core'

import DateRender from './shared-DateRender'
import FileUploadOverlay from './profile-FileUploadOverlay'

import useFileUploadStyles from '../styles/fileUploadStyles'

import actions from '../store/actions'

export default function BackgroundCheck({
  data = {},
  disabled = false,
  userImpersonated = false,
}) {
  const uploadStyles = useFileUploadStyles()
  const dispatch = useDispatch()
  const {
    background_check: backgroundCheck = {},
  } = data
  const {
    filename,
    updated_at: updatedAt,
  } = backgroundCheck

  const [state, setState] = useState({
    updatedAt,
  })

  const downloadUrls = useSelector((store) => store.storage.downloadUrls)

  const handleClick = () => setState({ ...state, overlayOpen: true })
  const handleChange = () => (setState({ ...state, overlayOpen: false }))

  if (!downloadUrls[filename]) {
    dispatch(actions['STORAGE/GET-SIGNED-DOWNLOAD-URL'](filename))
  }

  return (<div>
    <strong>Background Check</strong>
    <Button size="small"
      variant="outlined"
      className={uploadStyles.button}
      onClick={handleClick}
      disabled={disabled}>
      {state.filename ? 'Update' : 'Upload'} Report
    </Button><br />
    {filename
      ? <Grid container direction='row' className={uploadStyles.list}>
          <div>
            <i>Last updated:</i> &nbsp;
            <DateRender dateString={updatedAt} />
          </div>&nbsp;/&nbsp;
          <div>
            <a href={downloadUrls[filename]} download>Download</a>
          </div>
        </Grid>
      : ('')}
    <FileUploadOverlay open={state.overlayOpen}
      fileType="background_check"
      profileType="tech"
      profile={data}
      userImpersonated={userImpersonated}
      onSubmit={handleChange} />
  </div>)
}
