import { workingDaysOfWeek } from "../constants/user.constants"
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { List, ListItem } from "@material-ui/core"
import { ListItemSegment, useWorkingDaysStyles } from "./WorkingDaysDisplay"
import { InlineConfirm } from "./InlineConfirm"
import { useEffect, useState } from "react"
import {replaceItemInList} from '../util/lists.util'
import {isInValidDate} from '../util/date-time.util'

const WorkingDaysEdit = ({
    working_hours =[],
    onSave,
    setEditHoursFlag,
    showAllowButtonOrNot,
    title
}) => {
  const defaultTime = new Date().setHours(12,0)
  const styles = useWorkingDaysStyles()
  const [dayHours, setDayHours] = useState(working_hours)
  const [disableConfirm, setDisableConfirm] = useState(false)

  const NOT_SET_PLACEHOLDER = 'Not Set'
  const THIS_IS_NOT_A_PLACEHOLDER = '__:__ _M'

  useEffect(() => {
    setDayHours(working_hours)
  }, [working_hours])

  useEffect(() => () => setDayHours(working_hours), [])

  const setDayHour = (setDayHoursTimes, index) => {
    if (setDayHoursTimes.every(d => !isInValidDate(d))) {
      setDisableConfirm(false)
    } else {
      setDisableConfirm(true)
    }
    return setDayHours(
      replaceItemInList(setDayHoursTimes, dayHours, index)
    )
  }

  const handleStartChange = (index, date) => setDayHour(
    [
      date !== null ? date : null,
      dayHours[index][1]
    ],
    index
  )

  const handleEndChange = (index, date) => setDayHour(
    [
      dayHours[index][0],
      date !== null ? date : null
    ],
    index
  )

  const onCancel = () => {
    setEditHoursFlag(false)
    showAllowButtonOrNot(true)
  }

  const onClickSave = () => {
    onSave(dayHours)
    showAllowButtonOrNot(true)
    setEditHoursFlag(false)
  }

  return dayHours && <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <List {...{
      dense: true,
      className: styles.list
    }} >
      <ListItem style={{
        justifyContent: 'space-between',
      }}>
        <ListItemSegment>
          {title}
        </ListItemSegment>
        <ListItemSegment>
      <InlineConfirm{...{
        onConfirm:  onClickSave,
        onCancel,
        disableConfirm
      }}/>
        </ListItemSegment>
      </ListItem>
      {dayHours &&
        workingDaysOfWeek.map((day, index) => (
          <ListItem className={styles.listItem} key={index}>
            <ListItemSegment className={styles.dayOfWeekSegment}>{workingDaysOfWeek[index]}</ListItemSegment>
            <ListItemSegment className={styles.timePadding}>
              <KeyboardTimePicker
                className={styles.keyboardTimePicker}
                margin="dense"
                label="Start Time"
                value={dayHours[index][0] !== null  ? dayHours[index][0] : null}
                initialFocusedDate={defaultTime}
                placeholder={NOT_SET_PLACEHOLDER}
                mask={THIS_IS_NOT_A_PLACEHOLDER}
                onChange={hours => handleStartChange(index, hours)}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                  shrink: true
                }}
              />
            </ListItemSegment>
            <ListItemSegment>
              <KeyboardTimePicker
                className={styles.keyboardTimePicker}
                margin="dense"
                label="End Time"
                initialFocusedDate={defaultTime}
                mask={THIS_IS_NOT_A_PLACEHOLDER}
                value={dayHours[index][1] !== null ? dayHours[index][1] : null}
                placeholder={NOT_SET_PLACEHOLDER}
                onChange={hours => handleEndChange(index, hours)}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
              />
            </ListItemSegment>
          </ListItem>
        ))
      }
    </List>
  </MuiPickersUtilsProvider>
}

export default WorkingDaysEdit
