import makeStyles from '@material-ui/core/styles/makeStyles'
import noop, { noopThunk } from '../util/noop'
import {
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
} from '@material-ui/core'
import { InsuranceDisplay } from './InsuranceDisplay'
import { EditRemoveButtons } from './EditRemoveButtons'
import { AddCircleOutlineSharp } from '@material-ui/icons'
import { ConfirmationModal } from './ConfirmationModal'
import Grid from '@material-ui/core/Grid'
const useCertificationsDisplayStyles = makeStyles((theme) => ({
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

export const InsurancePoliciesDisplay = ({
  insurance = [],
  onEdit = noopThunk,
  onRemove = noopThunk,
  onAdd = noop,
  disableEdit = false,
  disableRemove = false,
  disableAdd = false,
  removeInsurance,
  onRemoveConfirm = noopThunk,
  onRemoveCancel = noop,
}) => {
  const styles = useCertificationsDisplayStyles()
  return (
    <>
      <List dense={true}>
        {insurance?.map((insur, index) => (
          <ListItem
            {...{
              key: index,
              disableGutters: true,
              className: styles.listItem,
            }}
          >
            <Grid container spacing={2}>
              <InsuranceDisplay
                {...{
                  insur,
                }}
              />
              <ListItemSecondaryAction>
                <EditRemoveButtons
                  {...{
                    onEdit: onEdit(insur, index),
                    onRemove: onRemove(insur, index),
                    disableEdit,
                    disableRemove,
                  }}
                />
              </ListItemSecondaryAction>
            </Grid>
          </ListItem>
        ))}
        {!disableAdd && (
          <ListItem
            {...{
              key: 'add-insur-button',
              disableGutters: true,
              className: styles.listItem,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  {...{
                    size: 'small',
                    variant: 'outlined',
                    color: 'primary',
                    onClick: onAdd,
                    startIcon: <AddCircleOutlineSharp fontSize={'small'} />,
                  }}
                >
                  Add Insurance Policies
                </Button>
              </Grid>
            </Grid>
          </ListItem>
        )}
      </List>
      <ConfirmationModal
        {...{
          open: removeInsurance,
          onOk: onRemoveConfirm(removeInsurance),
          onClose: onRemoveCancel,
        }}
      >
        Remove Policy, "{removeInsurance?.issued_by}"?
      </ConfirmationModal>
    </>
  )
}
