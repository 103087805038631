import {dispatchService} from './dispatch-service.util'
import {
  getTechProfileListForOrg,
} from '../../services/tech-profiles.service'

export const TECH_PROFILE_LIST_SET_STATE = 'TECH_PROFILE_LIST_SET_STATE'
export const TECH_PROFILE_LIST_APPEND_PROFILE = `TECH_PROFILE_LIST_APPEND_PROFILE`
export const TECH_PROFILE_LIST_RESET_STATE = 'TECH_PROFILE_LIST_RESET_STATE'

export const techProfileListSetState = techProfileList => ({
  type: TECH_PROFILE_LIST_SET_STATE,
  techProfileList
})

export const techProfileListAppendProfile = techProfile => ({
  type: TECH_PROFILE_LIST_APPEND_PROFILE,
  techProfile
})

export const techProfileListResetState = () => ({
  type: TECH_PROFILE_LIST_RESET_STATE
})

export const fetchTechProfileListForOrg = orgId => async dispatch =>
  await dispatchService(dispatch)(getTechProfileListForOrg, techProfileListSetState)(orgId)
