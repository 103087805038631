import useAccessToken from './useAccessToken'
import fetchUser from './auth-sign-in-functions/fetch-user'
import fetchProfile from './auth-sign-in-functions/fetch-profile'

const impersonatedProfileFetch = (
  userId,
  orgId,
  isTechProfile,
) => async (dispatch, getState, getFirebase) => {
  const accessToken = await useAccessToken()

  const [userData, userError] = await fetchUser(userId, accessToken)
  if (userError) {
    return undefined
  }

  const [profileData, profileError] = await fetchProfile(userId, orgId, isTechProfile, accessToken)
  if (profileError) {
    return undefined
  }

  const impersonatedProfile = {
    ...userData,
    ...profileData,
  }

  dispatch({ type: 'IMPERSONATED-PROFILE/FETCH-OK', payload: { data: impersonatedProfile } })
}

export default impersonatedProfileFetch
