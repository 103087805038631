import { Route, Switch, withRouter } from 'react-router-dom'
import { RouteConnectedDrawer } from '../components/RouteConnectedDrawer'
import { ConfirmationPayTicketScreen } from './ConfirmationPayTicketScreen'
import { ticketList } from './TicketList.view'
import { useContext } from 'react'
import { Context as BlurContext } from '../contexts/BlurContext'


export const TicketPaymentDrawerUtil = withRouter(
  ({
    open,
    setOpen,
    backRoutePath,
    match,
    chekhubChecker,
    ticketsList,
    source
   }) => {
    const {setBlurred} = useContext(BlurContext)
    return <Switch>
      <Route path={`${match.path}/:ticketId/payment`}>
        <RouteConnectedDrawer {...{
          open,
          setOpen,
          anchor: 'right',
          title: !chekhubChecker ? 'View Ticket' : 'Pay Ticket',
          backRoutePath
        }}>
          {ticketsList && <ConfirmationPayTicketScreen {... {
            ticketsList,
            chekhubChecker,
            setOpen,
            setBlurred,
            backRoutePath,
            source
          }} />}
        </RouteConnectedDrawer>
      </Route>
    </Switch>
  }
)
