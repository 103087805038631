import { Card, CardContent } from '@material-ui/core'
import TechProfileForm from "./TechProfileForm"
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import noop, { noopThunk } from '../util/noop'
import { useState } from 'react'
import { FormSubmitButton } from './FormSubmitButton'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useTechProfileStyles = makeStyles(theme => ({
  card: {
    paddingBottom: theme.spacing(6)
  }
}))

const TechProfile = ({
  techProfile,
  onSubmit,
  onChange,
  onAddressChange,
  backgroundRunning,
  formStyles,
  editStyles,
  editable,
  orgId,
  title,
  submitButtonLabel,
  onPhoneNumberUpdate,
  onPhoneNumberRemove,
  onPhoneNumberAdd,
  phoneNumbers,
  onChangeVetStatus,
  onChangeDD214Url,
  onRemoveDD214Url,
  allowSubmit,
  showAllowButtonOrNot,
  onSave,
  editHoursFlag,
  setEditHoursFlag,
  onUpdateCertifications = noop,
  onUpdateInsurancePolicy= noop,
  onCertUploadChange = noopThunk,
  onInsurUploadChange = noopThunk,
  onUpdateSkills = noopThunk,
  setAllowPhones,
  setAllowCerts,
  setAllowInsurance,
  setAllowWorkExperience,
  setAllowWorkingHours,
  allowPhones,
  allowCerts,
  allowInsurance,
  allowWorkExperience,
  allowWorkingHours
}) => {
  const styles = useTechProfileStyles()
  const [hasFieldErrors, setHasFieldErrors] = useState(false)
  const disableSubmit = !editable ||
    backgroundRunning ||
    !allowSubmit ||
    hasFieldErrors ||
    !allowPhones ||
    !allowCerts ||
    !allowInsurance ||
    !allowWorkExperience ||
    !allowWorkingHours

  return <Card {...{
    className: `${editStyles.paper} ${styles.card}`,
    variant: 'outlined'
  }}>
    <CardContent>
      {
        title &&
        <Typography
          {...{
            variant: 'h4',
          }} gutterBottom >
          {title} <AccountCircleIcon className={editStyles.icons} />
        </Typography>
      }
      <TechProfileForm {...{
        techProfile,
        onChange,
        onAddressChange,
        formStyles,
        editStyles,
        orgId,
        editable,
        onPhoneNumberUpdate,
        onPhoneNumberRemove,
        onPhoneNumberAdd,
        phoneNumbers,
        onChangeVetStatus,
        onChangeDD214Url,
        onRemoveDD214Url,
        showAllowButtonOrNot,
        onSave,
        editHoursFlag,
        setEditHoursFlag,
        onUpdateCertifications,
        onUpdateInsurancePolicy,
        onCertUploadChange,
        onInsurUploadChange,
        onUpdateSkills,
        setHasFieldErrors,
        setAllowPhones,
        setAllowCerts,
        setAllowInsurance,
        setAllowWorkExperience,
        setAllowWorkingHours,
      }} />
    </CardContent>
      <FormSubmitButton {...{
        onClick: onSubmit,
        disabled: disableSubmit,
        useFab: true
      }} >
        {submitButtonLabel}
      </FormSubmitButton>
  </Card>
}

export default TechProfile
