import Grid from '@material-ui/core/Grid'
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@material-ui/core'
import {InlineCopyrightIcon} from './InlineCopyrightIcon'

const PACKAGE_SELECT_EMPTY_VALUE = 'Please select a package...'
export const BackgroundCheckSelector = ({
  bgPackagesSelected,
  handlePackageSelection,
  backgroundCheckPackages: bgPackages,
}) => <Grid item xs={12}>
  <FormControl {...{
    fullWidth: true,
    variant: 'outlined',
    size: 'small',
  }}>
    <InputLabel>Checkr Packages</InputLabel>
    <Select {...{
      value: bgPackagesSelected?.name ? bgPackagesSelected.name : PACKAGE_SELECT_EMPTY_VALUE,
      onChange: ev => handlePackageSelection(ev),
      label: 'Checkr Packages'
    }} >
      <MenuItem value={PACKAGE_SELECT_EMPTY_VALUE} disabled>Please select a package...</MenuItem>
      {
        bgPackages.length > 0 && bgPackages?.map((bgcPackage, index) =>
          <MenuItem key={index} value={bgcPackage.name}>
            {bgcPackage.name}
          </MenuItem>
        )
      }
    </Select>
    <FormHelperText>Powered By Checkr<InlineCopyrightIcon/></FormHelperText>
  </FormControl>
</Grid>
