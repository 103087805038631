import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserOrg } from '../util/users.util'
import { getLandingPath } from '../util/landingPath.util'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'
import { useEffect } from 'react'


export const MiddleManSignUpPage = () => {

  const history = useHistory()
  const {authUser, profileUser} = useSelector(
    ({auth, userProfile}) => ({
      authUser: auth.user,
      profileUser: userProfile
    })
  )
  const dispatch = useDispatch()
  const editable = (profileUser._id === authUser._id)

  const userDefaultOrg = getUserOrg(authUser)
  const landingPath = getLandingPath(authUser, userDefaultOrg)
  useEffect(() => {
    history.push(landingPath)
  }, [])
  return <Box>
    <Typography>
    Loading...
  </Typography>
  </Box>
}
