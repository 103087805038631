const initState = {
  fetched: false,
  list: null,
  ticket: null,
  newLog: null,
  error: null,
}

const ticketReducer1 = (state = initState, action) => {
  if (['TICKET/RESET'].includes(action.type)) {
    return initState
  }
  if (['TICKET/FETCH-LIST-OK'].includes(action.type)) {
    const { payload: { data } } = action
    return {
      ...state,
      fetched: true,
      list: data,
    }
  }
  if (['TICKET/FETCH-OK'].includes(action.type)
  || ['TICKET/SET'].includes(action.type)) {
    const { payload: { data } } = action
    return {
      ...state,
      ticket: data,
    }
  }
  if (['TICKET/LOG-CREATED'].includes(action.type)) {
    const { payload: { data } } = action
    return {
      ...state,
      newLog: data,
    }
  }
  if (['TICKET/CLEAR-LOG'].includes(action.type)) {
    return {
      ...state,
      newLog: null,
    }
  }
  if (['TICKET/FETCH-ERROR'].includes(action.type)) {
    const { payload: { err } } = action
    const errorMessage = err ? err.message : 'Unknown Error'
    return {
      ...state,
      error: errorMessage,
    }
  }

  return state
}

export default ticketReducer1
