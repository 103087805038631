import addressToLocation from '../components/shared-functions/address-to-location'
import {techProfileSetState} from '../store/actions/tech-profile.actions'
import {userProfileSetState} from '../store/actions/user-profile.actions'
import {uploadFile} from '../store/actions/storage.actions'
import {isServiceErrorResult} from '../services/is-service-error-result.util'

const persistTechProfileState = (newProfile, profileSetStateAction, dispatch) =>
  dispatch(profileSetStateAction(newProfile))

export const techProfileOnChange = (userProfile, techProfile, dispatch, id) => e => {
  e.preventDefault()
  let {id: key, value, checked, name} = e.target || {}
  console.log(key)
  const [targetProfile, profileSetStateAction] = userProfile.hasOwnProperty(key) ?
    [userProfile, userProfileSetState] : [techProfile, techProfileSetState]

  if (key === 'hour_rate' || key === 'service_radius') {
    value = Number(value)
  }

  if (key === 'birth_date') {
    value = getDateFromPickerString(value)
  }

  let newProfile = {
    ...targetProfile
  }


  if (key === 'location.label') {
    newProfile = {
      ...newProfile,
      location: {
        ...newProfile.location,
        label: value
      }
    }
  } else {
    newProfile = {
      ...newProfile,
      [key]: value
    }
  }

  return persistTechProfileState(newProfile, profileSetStateAction, dispatch)
}


export const techProfileOnAddressChange = (techProfile, dispatch) => (addressDetails) =>
  persistTechProfileState({
    ...techProfile,
    location: addressToLocation(addressDetails, techProfile.location),
  }, techProfileSetState, dispatch)

export const techProfileWorkExpOnAddressChange = (techProfile, dispatch, id) => (addressDetails) =>
  persistTechProfileState({
    ...techProfile,
    work_experience: [
      {
        ...techProfile.work_experience[id],
        employer_location: addressToLocation(addressDetails, techProfile.work_experience[id].employer_location),
      }

]
  }, techProfileSetState, dispatch)

export const getDateFromPickerString = (dateString) =>
  new Date(
    dateString.replace(/-/g, '\/')
  )

export const onChangeVetStatus = (techProfile, dispatch) => ev => {
  const status = ev.target.checked
  const newVetStatus = {
    ...techProfile.veteran_status,
    status
  }
  if (!newVetStatus.status) {
    delete newVetStatus.dd214_url
  }
  dispatch(techProfileSetState({
    ...techProfile,
    veteran_status: newVetStatus
  }))
}

export const onChangeDD214Url = (techProfile, dispatch) => async files => {
  const result = await dispatch(uploadFile(files[0]))
  if (isServiceErrorResult(result)) {
    return result
  }
  dispatch(techProfileSetState({
    ...techProfile,
    veteran_status: {
      ...techProfile.veteran_status,
      dd214_url: result
    }
  }))
}

export const onRemoveDD214Url = (techProfile, dispatch) => () => {
  const newVetStatus = {
    ...techProfile.veteran_status
  }
  delete newVetStatus.dd214_url
  dispatch(techProfileSetState({
    ...techProfile,
    veteran_status: newVetStatus
  }))
}

export const onUpdateCertifications = (techProfile, dispatch) => certifications => {
  dispatch(techProfileSetState({
    ...techProfile,
    certifications
  }))
}

export const onUpdateInsurancePolicy = (techProfile, dispatch) => insurance_policies => {
  dispatch(techProfileSetState({
    ...techProfile,
    insurance_policies
  }))
}

export const onCertUploadChange = dispatch => setCert => async (cert, files) => {
  const result = await dispatch(uploadFile(files[0]))
  if (isServiceErrorResult(result)) {
    return result
  }
  setCert({
    ...cert,
    filename: result
  })
}

export const onInsurUploadChange = dispatch => setIns => async (ins, files) => {
  const result = await dispatch(uploadFile(files[0], 'InsurancePolicy'))
  if (isServiceErrorResult(result)) {
    return result
  }
  setIns({
    ...ins,
    filename: result
  })
}

export const onUpdateSkills = (techProfile, dispatch) => skills =>
  dispatch(techProfileSetState({
    ...techProfile,
    skills
  }))
