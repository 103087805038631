import {useDispatch} from 'react-redux'
import {useEffect, useState} from 'react'
import {requestPasswordResetEmail} from '../store/actions/users.actions'
import {isServiceErrorResult} from '../services/is-service-error-result.util'
import {FLIPPY_TRANSITION_TIME} from '../constants/transition-times.constants'
import {CardContent, Link, TextField, Typography} from '@material-ui/core'
import {CheckSharp} from '@material-ui/icons'
import ChevronLeftSharpIcon from '@material-ui/icons/ChevronLeftSharp'
import {makeStyles} from '@material-ui/core/styles'
import {FormSubmitButton} from './FormSubmitButton'
import {validate as emailValidate} from 'email-validator'
import {useValidations} from '../hooks/useValidations'

const PASSWORD_RESET_EMAIL_FIELD_ID = 'passwordResetEmail'
const useForgotPasswordStyles = makeStyles(theme => {
  return ({
    form: {
      width: '100%'
    },
    backLink: {
      marginTop: theme.spacing(2),
      display: 'inline-flex',
      alignItems: 'center',
      alignSelf: 'start'
    },
    successHeaderText: {
      display: 'inline-flex',
      alignItems: 'center',
      '& > span': {
        marginRight: theme.spacing(1)
      }
    },
    checkmark: {
      color: theme.palette.primary.main
    }
  })
})

const forgotPasswordValidations = {
  [PASSWORD_RESET_EMAIL_FIELD_ID]: {
    required: 'Email is required',
    validator: (val) => !emailValidate(val) ? 'Invalid email format' : null
  }
}
export const SignInForgotPassword = ({
  styles,
  setForgotPassword
}) => {
  const forgotPasswordStyles = useForgotPasswordStyles()
  const dispatch = useDispatch()
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const {
    onBlurValidation,
    onChangeValidation,
    onSubmitValidation,
    fieldErrors,
    hasFieldErrors
  } = useValidations(
    forgotPasswordValidations
  )

  const handleOnSubmit = ev => {
    ev.preventDefault()
    const formIsValid = onSubmitValidation(ev)
    !hasFieldErrors() && formIsValid && onSubmit(ev)
  }

  const onSubmit = async e => {
    const {
      value: email
    } = e.target.elements[PASSWORD_RESET_EMAIL_FIELD_ID]
    e.preventDefault()
    const result = await dispatch(requestPasswordResetEmail(email))
    setShowSuccessMessage(!isServiceErrorResult(result))
  }

  const backLinkOnClick = e => {
    e.preventDefault()
    setForgotPassword(false)
    setTimeout(() => {
      setShowSuccessMessage(false)
    }, FLIPPY_TRANSITION_TIME)
  }

  return <CardContent {...{
    className: styles.backCardContent
  }}>
    {
      showSuccessMessage ?
        <>
          <Typography {...{
            variant: 'h5',
            gutterBottom: true,
            align: 'center',
            className: forgotPasswordStyles.successHeaderText
          }}>
            <span>Email Sent</span>
            <CheckSharp className={forgotPasswordStyles.checkmark}/>
          </Typography>
          <Typography {...{
            variant: 'body2',
            gutterBottom: true
          }}>Please check your inbox for an email from us.</Typography>
        </> :
        <form {...{
          onSubmit: handleOnSubmit,
          className: forgotPasswordStyles.form
        }}>
          <Typography {...{
            variant: 'h5',
            gutterBottom: true,
            align: 'center'
          }} >
            Forgot your password?
          </Typography>
          <Typography {...{
            variant: 'body2',
            gutterBottom: true
          }} >
            Give us your email and, if an account is associated, we'll send you a link to reset your password...
          </Typography>
          <TextField {...{
            name: PASSWORD_RESET_EMAIL_FIELD_ID,
            className: styles.textField,
            size: 'small',
            label: 'Email Address',
            fullWidth: true,
            variant: 'outlined',
            type: 'email',
            error: Boolean(fieldErrors[PASSWORD_RESET_EMAIL_FIELD_ID]),
            helperText: fieldErrors[PASSWORD_RESET_EMAIL_FIELD_ID] || null,
            onChange: onChangeValidation,
            onBlur: onBlurValidation
          }} />
          <FormSubmitButton {...{
            className: styles.signInButton,
            disableElevation: true,
            fullWidth: true,
            disabled: hasFieldErrors()
          }}>
            <span>Submit</span>
          </FormSubmitButton>
        </form>
    }
    <Link {...{
      component: 'button',
      className: forgotPasswordStyles.backLink,
      onClick: backLinkOnClick
    }} >
      <ChevronLeftSharpIcon fontSize="small"/>
      <span>Back to Sign In</span>
    </Link>
  </CardContent>
}
