export const INIT_ADDRESS_DETAILS = {
  lat: null,
  long: null,
  addressComponents: [],
  formatted_address: ''
}

const addressToLocation = (
  addressDetails = INIT_ADDRESS_DETAILS,
  location
) => {
  const { lat, long, addressComponents, formatted_address } = addressDetails
  const locationObject = [
    'street_number',
    'route',
    'locality',
    'administrative_area_level_1',
    'country',
    'postal_code',
  ].reduce((acc, cur) => {
    const curDetail = addressComponents.filter((item) => item.types.includes(cur))
    const zipCodeSuffix = addressComponents.filter((item) => item.types.includes('postal_code_suffix'))
    if (!curDetail.length) return acc
    const { address: accAddress } = acc
    let updatedAddress = {
      ...accAddress,
      [cur]: curDetail[0].short_name,
    }
    if (['administrative_area_level_1', 'country'].includes(cur)) {
      updatedAddress = {
        ...updatedAddress,
        [`${cur}_abbr`]: updatedAddress[cur],
        [cur]: curDetail[0].long_name,
      }
    }
    if (cur === 'postal_code' && zipCodeSuffix.length) {
      updatedAddress = {
        ...updatedAddress,
        [cur]: `${updatedAddress[cur]}-${zipCodeSuffix[0].short_name}`,
      }
    }
    return {
      ...acc,
      address: updatedAddress,
    }
  }, {
    ...location,
    lat,
    long,
    formatted_address
  })

  return locationObject
}

export default addressToLocation
