import noop from '../util/noop'
import {useState} from 'react'
import {Box, Checkbox, FormControlLabel} from '@material-ui/core'
import {DropZoneDialogTrigger} from './DropZoneDialogTrigger'
import {MimeTypes} from 'http-headers-js'
import {EditRemoveButtons} from './EditRemoveButtons'
import {ConfirmationModal} from './ConfirmationModal'
import {FormSubmitButton} from './FormSubmitButton'
import {CloudDownloadLink} from './CloudDownloadLink'


export const UPLOAD_NEW_DD214_TEXT = 'Upload New DD214'
export const VeteranStatus = ({
  status = false,
  dd214_url = '',
  onChangeStatus = noop,
  onChangeDD214Url = noop,
  onRemoveDD214Url = noop
}) => {
  const [uploadOpen, setUploadOpen] = useState()
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState(false)
  const openUpload = (e) => {
    e.preventDefault()
    setUploadOpen(true)
  }
  const closeUpload = () => setUploadOpen(false)
  const showDD214UrlUI = status && dd214_url
  const showUploadButton = status && !dd214_url

  const onUploadSave = files => {
    onChangeDD214Url(files)
    closeUpload()
  }

  const onRemoveConfirm = () => {
    onRemoveDD214Url()
    closeRemoveConfirm()
  }

  const openRemoveConfirm = () => setRemoveConfirmOpen(true)
  const closeRemoveConfirm = () => setRemoveConfirmOpen(false)
  const onRemoveCancel = () => closeRemoveConfirm()

  return <Box display="flex" alignItems="center">
    <FormControlLabel
      control={
        <Checkbox {...{
          checked: status,
          onChange: onChangeStatus,
          name: 'status',
          color: 'primary'
        }} />
      }
      label="Veteran"
    />
    {
      showDD214UrlUI &&
      <>
        <CloudDownloadLink {...{cloudFilename: dd214_url}} >
          <span>DD214</span>
        </CloudDownloadLink>
        <DropZoneDialogTrigger {...{
          dialogOpen: uploadOpen,
          acceptedFiles: [MimeTypes.Application.PDF],
          onClose: closeUpload,
          onSave: onUploadSave,
          uploadDialogProps: {
            dialogTitle: UPLOAD_NEW_DD214_TEXT
          }
        }}>
          <EditRemoveButtons {...{
            editTitle: UPLOAD_NEW_DD214_TEXT,
            removeTitle: 'Remove DD124',
            onEdit: openUpload,
            onRemove: openRemoveConfirm
          }} />
        </DropZoneDialogTrigger>
        <ConfirmationModal {...{
          open: removeConfirmOpen,
          onOk: onRemoveConfirm,
          onClose: onRemoveCancel
        }}>
          Are you sure you want to remove this DD214?
        </ConfirmationModal>
      </>
    }
    {
      showUploadButton && <DropZoneDialogTrigger {...{
        dialogOpen: uploadOpen,
        acceptedFiles: [MimeTypes.Application.PDF],
        submitButtonText: 'Upload DD214',
        onClose: closeUpload,
        onSave: onUploadSave
      }}>
        <FormSubmitButton {...{
          onClick: openUpload,
          variant: 'outlined',
        }}>Upload DD214</FormSubmitButton>
      </DropZoneDialogTrigger>
    }
  </Box>
}

