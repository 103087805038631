import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  icons: {
    fontSize: theme.spacing(4),
    verticalAlign: 'bottom'
  },
  h2: {
    fontSize: theme.spacing(4),
  },
  topHeader: {
    fontSize: theme.spacing(4),
    paddingLeft: theme.spacing(15),
  },
  select: {
    paddingTop: theme.spacing(1),
  },
  smallTextField: {
    marginTop: theme.spacing(2),
    width: theme.spacing(50),
  },
  insuranceHolder: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardActions: {
    padding: theme.spacing(2)
  },
}))
