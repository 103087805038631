import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { Link, useParams, withRouter } from 'react-router-dom'
import { getUserOrg } from '../util/users.util'
import { useAuthUser } from '../hooks/useAuthUser'
import { useDispatch, useSelector } from 'react-redux'
import { TeamProviderDrawerUtil } from '../pages/TeamProviderDrawerUtil'
import { useBusyIndicatorStyles } from './BusyIndicator'
import Grid from '@material-ui/core/Grid'
import { CurrentTeamOrProviderTemplate } from './CurrentTeamOrProviderTemplate'
import { useDimensionsContextStyles } from './DimensionsContext'
import useLandingViewStyles from './LandingViewStyles'
import useEditStyles from './ServiceCompanyViewStyles'
import useListStyles from './ListServiceCompanyStyles'
import { fetchServiceCompanyList2, resetServiceCompanyListState } from '../store/actions/service-company.actions'
import { fetchTechProfile, techProfileResetState } from '../store/actions/tech-profile.actions'
import { invitedTeamResetState } from '../store/actions/invited-teams-list.actions'

export const CurrentTeamOrProviderListIndy = withRouter(({
                                                              match,

                                                            }) => {

  const {userId} = useParams()
  const dimensionsContextStyles = useDimensionsContextStyles()
  const styles = useLandingViewStyles()
  const editStyles = useEditStyles()
  const listStyles = useListStyles()
  const teamOrProviderList = useSelector(({serviceCompanyList}) => serviceCompanyList)
  const [open, setDrawerOpen] = useState(false)
  const user = useSelector(({auth}) => auth.user)
  const dispatch = useDispatch()
  const busyStyles = useBusyIndicatorStyles()
  const authorizedUser = useAuthUser()
  const userDefaultOrg = getUserOrg(authorizedUser)
  const techProfile = useSelector(({techProfile}) => techProfile)
  const chekhubChecker = userDefaultOrg?.chekhub_member_id

  useEffect(async () => {
    userId && await dispatch(fetchTechProfile(userId))
  }, [])

  useEffect( async () => {
     await dispatch(fetchServiceCompanyList2({
      chekhub_orgId: user._id
    }))

  }, [, open])

  useEffect(() => {
    dispatch(resetServiceCompanyListState())
    dispatch(techProfileResetState())
    dispatch(invitedTeamResetState())
  },[])



  return teamOrProviderList.length <= 0  ? <Card className={
      `${styles.membersListCard} ${dimensionsContextStyles.minHeight} ${dimensionsContextStyles.card}`
    } ><Typography variant={'h6'}>No teams available</Typography></Card> :
    teamOrProviderList.length > 0  ? <CurrentTeamOrProviderTemplate {... {
      teamOrProviderList,
      setDrawerOpen,
      open,
      user,
      userId,
      chekhubChecker,
      techProfile
    }}/>  :  <Grid
    container
    justify={'center'}
  >
    <CircularProgress {...{
      size: '2em',
      className: busyStyles.spinner,
      color: 'primary'
    }} />
  </Grid>

})

