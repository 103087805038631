import {Box, IconButton} from '@material-ui/core'
import {CheckSharp, CloseSharp} from '@material-ui/icons'
import noop from '../util/noop'
import {makeStyles} from '@material-ui/core/styles'

const useInlineConfirmStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block'
  },
  confirmButton: {
    '&:disabled': {
      opacity: .2,
      '& svg': {
        fill: theme.palette.secondary.main
      }
    }
  }
}))

export const InlineConfirm = ({
  onConfirm = noop,
  onCancel = noop,
  confirmClassName = '',
  cancelClassName = '',
  disableConfirm = false
}) => {
  const styles = useInlineConfirmStyles()
  return <Box className={styles.root}>
    <IconButton {...{
      edge: 'end',
      title: 'Apply',
      size: 'small',
      onClick: onConfirm,
      className: `${confirmClassName} ${styles.confirmButton}`,
      disabled: disableConfirm
    }} >
      <CheckSharp fontSize={'small'} color={'primary'}/>
    </IconButton>
    <IconButton {...{
      edge: 'end',
      title: 'Cancel',
      size: 'small',
      onClick: onCancel,
      className: cancelClassName,
    }} >
      <CloseSharp fontSize={'small'}/>
    </IconButton>
  </Box>
}
