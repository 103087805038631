import axios from 'axios'

import appConfig from '../../config'
import useAccessToken from './useAccessToken'
import {setBackendWorking, setBackendWorkingDone} from './backend-working.actions'

const ticketLogCreate = ({
  checklistId,
  locationId,
  taskNumber,
  ticketId,
}) => async (dispatch, getState, getFirebase) => {
  const { chekhub: { baseURI, defaultHeaders, operations } } = appConfig
  const { logCreate } = operations
  const { path, method, authRequired } = logCreate

  const token = authRequired ? await useAccessToken() : null

  dispatch(setBackendWorking())

  try {
    const axiosParams = {
      baseURL: baseURI,
      url: path,
      method,
      headers: {
        ...defaultHeaders,
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
      data: {
        checklist_id: checklistId,
        location_id: locationId,
        task_number: taskNumber,
        ticket_id: ticketId,
      },
    }

    const { status, data: body } = await axios(axiosParams)
    if (status !== 200) throw new Error('unknown fetchError')

    dispatch({
      type: 'TICKET/LOG-CREATED',
      payload: {
        data: body.data,
      },
    })
  } catch (err) {
    const errorMessage = err?.response?.data?.message
    dispatch({
      type: 'TOAST/TRIGGER-NOTIFICATION',
      payload: {
        type: 'error',
        message: `There was an error creating the log:
          ${errorMessage || 'Unexpected Error'}`,
      },
    })
    dispatch({ type: 'TASK/FETCH-ERROR', payload: err })
  }
  dispatch(setBackendWorkingDone())
}

export default ticketLogCreate
