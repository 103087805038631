import Grid from '@material-ui/core/Grid'
import React, {useState} from 'react'
import {Typography} from '@material-ui/core'
import {PaymentSources} from '../../../PaymentSources'
import {MOCK_PAYMENT_SOURCES_DATA} from './mock-payment-sources.data'

export const PaymentSourcesExample = () => {
  const [sources, setSources] = useState(MOCK_PAYMENT_SOURCES_DATA)

  const onUpdateSources = newSources => setSources(newSources)
  const onRemove = source => setSources(
    sources.filter(s => s.source_id !== source.source_id)
  )

  const onAddCardSubmit = ({
    id: source_id,
    type,
    card: stripe_data
  } = {}) => onUpdateSources([
    ...sources,
    {
      organization_id: '60766713cb0bd2d48f01c44c',
      source_id,
      type,
      status: 'new',
      stripe_data
    }
  ])

  const onAddBankAccountSubmit = ({
    id: source_id,
    type,
    bank_account: stripe_data
  } = {}) => onUpdateSources([
    ...sources,
    {
      organization_id: '60766713cb0bd2d48f01c44c',
      source_id,
      type,
      status: 'new',
      stripe_data
    }
  ])

  return <Grid container spacing={1} >
    <Grid item xs={12}>
      <Typography {...{
        variant: 'h5'
      }}>Payment Sources</Typography>
      <PaymentSources {...{
        sources,
        onAddCardSubmit,
        onAddBankAccountSubmit,
        onRemove
      }} />
    </Grid>
  </Grid>
}

