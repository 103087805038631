import {getFirebase} from 'react-redux-firebase'
const useAccessToken = (forceRefresh = false) => new Promise((resolve) => {
  const firebase = getFirebase()
  firebase.auth()
    .onAuthStateChanged((user) => {
      if (!user) return resolve(null)
      user.getIdToken(forceRefresh).then((idToken) => resolve(idToken))
    })
})
export default useAccessToken
