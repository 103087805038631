import {getFirebase} from 'react-redux-firebase'
import appConfig from '../config'
import {Methods} from 'http-headers-js'
import {generateUrlQueryString} from './generate-url-query-string.util'

export const CUSTOMER_COMPANY_USER_TYPE = 'customer_company'
const {
  chekhub: {
    baseURI2,
  },
  chekhubConnect: {
  paths: {authTokenService: path},
  baseURI,
  defaultHeaders
}} = appConfig
const serviceBaseUri = `${baseURI}${path}`
const chekhubBaseUri = `${baseURI2}/integrations/connect/token`
export const identityProviderAuth = async ({email, password}) =>
  await getFirebase()
    .auth()
    .signInWithEmailAndPassword(email, password)

export const identityProviderSignInWithCustomToken = async token =>
  await getFirebase()
  .auth()
  .signInWithCustomToken(token)

export const getAuthCustomToken = async ({email, password}) =>
  await fetch(
    `${serviceBaseUri}${
      generateUrlQueryString({
        email,
        password,
        user_type: CUSTOMER_COMPANY_USER_TYPE
      })
    }`,
    {
      headers: {
        ...defaultHeaders
      },
      method: Methods.GET
    }
  )

export const getChekhubCustomToken = async (auth) =>
  await fetch(
    `${chekhubBaseUri}`,
    {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${auth}`,
      },
      method: Methods.POST
    }
  )
