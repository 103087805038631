import {makeStyles} from '@material-ui/core/styles'
import noop from '../util/noop'
import {DropzoneDialog} from 'material-ui-dropzone'

export const useDropZoneDialogTriggerStyles = makeStyles(theme => ({
  dialogOverrides: {
    '& .MuiDialogTitle-root': {
      padding: theme.spacing(2)
    },
    '& .MuiDialogContent-root': {
      paddingTop: 0,
      paddingRight: theme.spacing(2),
      paddingBottom: 0,
      paddingLeft: theme.spacing(2)
    }
  }
}))

export const DropZoneDialogTrigger = ({
  acceptedFiles = [],
  cancelButtonText = 'Cancel',
  submitButtonText = 'Upload',
  onDialogOpen = noop,
  onClose = noop,
  onSave = noop,
  uploadDialogProps = {},
  children,
  dialogOpen = false,
  filesLimit = 1,
  maxFileSize = 5000000,
}) => {
  const styles = useDropZoneDialogTriggerStyles()
  const closeDialog = (e) => {
    onClose(e)
  }

  return <>
    {children}
    <DropzoneDialog {...{
      acceptedFiles,
      filesLimit,
      maxFileSize,
      cancelButtonText,
      submitButtonText,
      open: dialogOpen,
      onOpen: onDialogOpen,
      onClose: closeDialog,
      onSave,
      ...uploadDialogProps,
      dialogProps: {
        className: styles.dialogOverrides,
        ...uploadDialogProps.dialogProps
      }
    }} />
  </>
}
