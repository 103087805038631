import {useHistory, useParams} from 'react-router-dom'
import useFormStyles from '../styles/formStyles'
import useEditStyles from '../components/UserProfileStyles'
import {userProfileResetState} from '../store/actions/user-profile.actions'
import {techProfileResetState, techProfileSetState, updateTechProfile} from '../store/actions/tech-profile.actions'
import {useDispatch, useSelector} from 'react-redux'
import {
  onCertUploadChange,
  onInsurUploadChange,
  onChangeDD214Url,
  onChangeVetStatus, onRemoveDD214Url, onUpdateCertifications, onUpdateSkills,
  techProfileOnAddressChange, onUpdateInsurancePolicy,
  techProfileOnChange
} from '../pages/TechProfile.util'
import {TECH_USER_ROLE} from '../constants/user.constants'
import {Box, Card, CardActions, CardContent, duration} from '@material-ui/core'
import noop from '../util/noop'
import {isServiceErrorResult} from '../services/is-service-error-result.util'
import UserProfileForm from './UserProfileForm'
import TechProfileForm from './TechProfileForm'
import {useServiceCompanyMemberDrawerStyles} from './ServiceCompanyMemberDrawerStyles'
import {FormSubmitButton} from './FormSubmitButton'
import {createServiceCompanyMember} from '../store/actions/service-company-members.actions'
import {getTechProfileIdForOrgId} from '../util/users.util'
import {useContext, useEffect, useState} from 'react'
import {closeDrawerAndTransition} from './ServiceCompanyMemberDrawer.util'
import {userListAppend} from '../store/actions/users.actions'
import {replaceItemInList} from '../util/lists.util'
import {Context as BlurContext} from '../contexts/BlurContext'

export const ServiceCompanyMemberAdd = ({
  setDrawerOpen = noop,
  routeTransitionDelay = duration.leavingScreen,
  backRoutePath
}) => {
  const {orgId} = useParams()
  const {
    userProfile,
    techProfile,
  } = useSelector(({
    userProfile,
    techProfile
  }) => ({
    userProfile,
    techProfile,
  }))
  const history = useHistory()
  const dispatch = useDispatch()
  const editStyles = useEditStyles()
  const formStyles = useFormStyles()
  const serviceCompanyMemberDrawerStyles = useServiceCompanyMemberDrawerStyles()
  const showUserProfileForm = !techProfile._id
  const showTechProfileForm = techProfile._id
  const phoneNumbers = techProfile.phones || []
  const [editHoursFlag, setEditHoursFlag] = useState(false)
  const [allowSubmit, setAllowSubmitState] = useState(true)
  const [hasFieldErrors, setHasFieldErrors] = useState(false)
  const {setBlurred} = useContext(BlurContext)
  const showAllowButtonOrNot = (bool) => {
    setAllowSubmitState(bool)
  }

  useEffect(() => () => {
    dispatch(userProfileResetState())
    dispatch(techProfileResetState())
  }, [])

  const onUserProfileSubmit = async e => {
    e.preventDefault()
    await dispatch(createServiceCompanyMember({
      ...userProfile,
      roles: [TECH_USER_ROLE]
    }, orgId, techProfileSetState))
  }

  const onTechProfileSubmit = async e => {
    e.preventDefault()
    const techProfileId = getTechProfileIdForOrgId(techProfile, orgId)
    const techResult = await dispatch(updateTechProfile({
      ...techProfile,
      _id: techProfileId
    }))


    if (!isServiceErrorResult(techResult)) {
      dispatch(userListAppend(techProfile))
      closeDrawerAndTransition(
        setDrawerOpen,
        backRoutePath,
        history,
        routeTransitionDelay,
        setBlurred
      )
    }
  }

  // TODO: @prahlad these need to be abstracted out to a phones.util.js file so we're not copying them all over
  const onPhoneNumberAdd = (info) => {
    const newPhones = [...techProfile?.phones, {
      label: info.label,
      raw_phone: "+" + info.raw_phone,
      country: 'us'
    }]
    updatePhoneNumbers(newPhones)
  }

  const onPhoneNumberRemove = (index) => {
    const newPhones = [...phoneNumbers].filter((p, i) => i !== index)
    updatePhoneNumbers(newPhones)
  }

  const onPhoneNumberUpdate = (index, info) => {
    const currentPhones = techProfile.phones
    const newPhones = replaceItemInList(info, currentPhones, index)
    updatePhoneNumbers(newPhones)
  }

  const updatePhoneNumbers = (newPhones) => {
    techProfile.phones = newPhones
    dispatch(techProfileSetState({
      ...techProfile,
      phones: newPhones
    }))
  }
  const onSave = (dayHours) =>{
    let newProfile = {
      ...techProfile
    }
    dispatch(techProfileSetState({
      ...newProfile,
      pay_info: {
        ...newProfile.pay_info,
        hour_rate_overtime: 0,
        technician_role: '',
        working_hours: dayHours
      }
    }))
    showAllowButtonOrNot(true)
    setEditHoursFlag(false)
  }


  return <Box>
    <Card {...{
    elevation: 0
  }}>
    <CardContent>
      {
        showUserProfileForm && <UserProfileForm {...{
          user: userProfile,
          onChange: techProfileOnChange(userProfile, techProfile, dispatch),
          formStyles,
          editStyles,
          editable: true,
        }} />
      }
      {
        showTechProfileForm && <>
          <TechProfileForm {...{
            techProfile,
            onChange: techProfileOnChange(userProfile, techProfile, dispatch),
            onAddressChange: techProfileOnAddressChange(techProfile, dispatch),
            formStyles,
            editStyles,
            orgId,
            editable: true,
            onPhoneNumberAdd,
            onPhoneNumberRemove,
            onPhoneNumberUpdate,
            updatePhoneNumbers,
            // TODO: @prahlad why are we passing `phoneNumbers` as a separate prop, when we're also sending
            //  `techProfile` as a prop, and it's got .phones on it already?
            phoneNumbers,
            onChangeVetStatus: onChangeVetStatus(techProfile, dispatch),
            onChangeDD214Url : onChangeDD214Url(techProfile, dispatch),
            onRemoveDD214Url: onRemoveDD214Url(techProfile, dispatch),
            showAllowButtonOrNot,
            onSave,
            setEditHoursFlag,
            editHoursFlag,
            onUpdateCertifications: onUpdateCertifications(techProfile, dispatch),
            onUpdateInsurancePolicy : onUpdateInsurancePolicy(techProfile, dispatch),
            onCertUploadChange: onCertUploadChange(dispatch),
            onInsurUploadChange: onInsurUploadChange(dispatch),
            onUpdateSkills: onUpdateSkills(techProfile, dispatch),
            setHasFieldErrors
          }} />
        </>
      }
    </CardContent>
      <CardActions {...{
        className: serviceCompanyMemberDrawerStyles.cardActions
      }}>
        <FormSubmitButton {...{
          onClick: showUserProfileForm ?
            onUserProfileSubmit :
            onTechProfileSubmit,
          disabled: !allowSubmit || hasFieldErrors,
          useFab: true
        }}>
        <span>{
          showUserProfileForm ? 'Add Tech' : 'Save Tech Profile'
        }</span>
        </FormSubmitButton>
      </CardActions>
  </Card>
  </Box>
}
