import {Link} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const useEmailLinkStyles = makeStyles(theme => ({
  link: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block'
  }
}))
export const EmailLink = ({email, ...restProps}) => {
  const styles = useEmailLinkStyles()
  return <Link {...{
    href: `mailto:${email}`,
    ...restProps,
    className: `${styles.link} ${restProps.className || ''}`
  }}>{email}</Link>
}
