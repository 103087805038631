import {Grid, TextField} from '@material-ui/core'
import noop from '../util/noop'

export const FIRST_NAME_FIELDNAME = 'first_name'
export const LAST_NAME_FIELDNAME = 'last_name'
export const EMAIL_FIELDNAME = 'email'
export const UserProfileFormFields = ({
  user,
  styles,
  editable,
  onChange = noop,
  onBlur = noop,
  fieldErrors = []
}) => {
  return <>
    <Grid item xs={6}>
      <TextField
        fullWidth
        id={FIRST_NAME_FIELDNAME}
        name={FIRST_NAME_FIELDNAME}
        required
        label='First Name'
        value={user.first_name || ''}
        size='small'
        variant="outlined"
        {...{
          onChange,
          onBlur,
          disabled: !editable,
          error: Boolean(fieldErrors[FIRST_NAME_FIELDNAME]),
          helperText: fieldErrors[FIRST_NAME_FIELDNAME] || null
        }}/>
    </Grid>
    <Grid item xs={6}>
      <TextField
        fullWidth
        id={LAST_NAME_FIELDNAME}
        name={LAST_NAME_FIELDNAME}
        required
        label='Last Name'
        value={user.last_name || ''}
        size='small'
        variant="outlined"
        {...{
          onChange,
          onBlur,
          disabled: !editable,
          error: Boolean(fieldErrors[LAST_NAME_FIELDNAME]),
          helperText: fieldErrors[LAST_NAME_FIELDNAME] || null
        }}/>
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth
        id={EMAIL_FIELDNAME}
        name={EMAIL_FIELDNAME}
        required
        className={styles.smallTextField}
        label='Email Address'
        size='small'
        variant="outlined"
        value={user.email || ''}
        {...{
          onChange,
          onBlur,
          disabled: !editable,
          error: Boolean(fieldErrors[EMAIL_FIELDNAME]),
          helperText: fieldErrors[EMAIL_FIELDNAME] || null
        }}/>
    </Grid>
  </>
}
