import {ADMIN_USER_ROLE, TECH_USER_ROLE, TECH_USER_TYPE} from '../constants/user.constants'
import {generatePath} from "react-router-dom"
import { CHEKHUB_END_USER_LANDING, SERVICE_COMPANY_MANAGE_MEMBERS, TECH_VIEW_PATH } from "../routing/route.constants"
import { useSelector } from 'react-redux'
import { isEmpty } from '../components/WorkExperience'

export const getLandingPath = (user = {}, userDefaultOrg = {}, activeOrgObject = {}) => {
  // TODO: Move these out to the user.utils.js


  const hasAdminRole = userDefaultOrg.roles?.includes(ADMIN_USER_ROLE)
  const hasTechRole = userDefaultOrg.roles?.includes(TECH_USER_ROLE)
  const isIndependentTech = userDefaultOrg?.organization_id &&
    user?._id && userDefaultOrg?.organization_id === user?._id
  const renderSCLandingPath = isEmpty(activeOrgObject) && userDefaultOrg &&
    !isIndependentTech &&
    hasAdminRole && userDefaultOrg?.organization_id

  return (renderSCLandingPath) ?
    generatePath(SERVICE_COMPANY_MANAGE_MEMBERS, {
      orgId: userDefaultOrg?.organization_id
    }) :
    (
      (hasTechRole || isIndependentTech) ?
        generatePath(TECH_VIEW_PATH, {
          userId: userDefaultOrg?.profiles
          .find(({profile_type}) => profile_type === TECH_USER_TYPE)?.profile_id
        }) : activeOrgObject.organization ? generatePath(CHEKHUB_END_USER_LANDING, {
          orgId: activeOrgObject?.organization?._id
        }) : ''
    )
}
