import {createMuiTheme} from '@material-ui/core/styles'
export const CHEKHUB_ORANGE = '#F5882F'
export const MAX_PAGE_WIDTH = '1140px'

export default createMuiTheme({
  palette: {
    primary: {
      main: CHEKHUB_ORANGE,
      contrastText: '#FFF',
    },
    secondary: {
      main: '#333',
      contrastText: '#FFF',
    },
    linkedInColor: {
      main: '#0072b1',
      secondary: '#008fdd'
    },
    surfaces: {
      paper: {
        background: '#fff'
      }
    }
    // error: {},
    // warning: {},
    // info: {},
    // success: {},
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          textDecoration: 'none',
          color: CHEKHUB_ORANGE
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '4px'
      }
    },
    MuiButtonBase: {
      root: {
        disableRipple: true,
        borderRadius: '0',
        boxShadow: 'none',
      },
      contained: {
        boxShadow: 'none',
        disableRipple: true,
      }
    },
    MuiButton: {
      root: {
        disableRipple: true,
        borderRadius: '0',
      },
      contained: {
        boxShadow: 'none',
        disableRipple: true,
      }
    },
    MuiCard: {
      root: {
        borderRadius: '0',
      }
    },
    MuiPaper: {
      root: {
        borderRadius: '0',
      },
      rounded: {
        borderRadius: '0',
      },
      elevation1: {
        borderRadius: '0',
      }
    },
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 30px white inset !important',
          WebkitTextFillColor: '#333',
        },
      },
    },

  },
  MuiDrawer: {
    outline: '20px red',
    '& .MuiDrawer-paperAnchorRight': {
      maxWidth: '45vw'
    }
  },
  typography: {
    fontFamily: '"SF Pro Display", "Segoe UI", "Roboto", sans-serif',
  },
})


