import { useHistory, useParams } from 'react-router-dom'
import { Box, duration, Typography } from '@material-ui/core'
import { SectionHeader } from '../components/SectionHeader'
import { FieldDisplay } from '../components/FieldDisplay'
import Grid from '@material-ui/core/Grid'
import { getHumanReadableDate } from '../util/date-time.util'
import { FormSubmitButton } from '../components/FormSubmitButton'
import { humanizeScreeningName } from '../components/BackGroundCheckPackageCard'
import { PaymentBankAccount } from '../components/PaymentBankAccount'
import { useEffect, useState } from 'react'
import { fetchServiceCompanyList2, fetchStripeAccountForChekhubUser } from '../store/actions/service-company.actions'
import { useDispatch, useSelector } from 'react-redux'
import { isServiceErrorResult } from '../services/is-service-error-result.util'
import toastShow, { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '../store/actions/toast-show'
import { closeDrawerAndTransition } from '../components/ServiceCompanyMemberDrawer.util'
import { payForATicketWithEndUserData } from '../store/actions/ticket.actions'
import { getDefaultSource, resetDefaultSource } from '../store/actions/wallet.actions'
import ticketFetch from '../store/actions/ticket-fetch'
import { BusyIndicator } from '../components/BusyIndicator'
import { fetchTicket } from '../services/ticket-one.service'
import { getTicket, resetTicket } from '../store/actions/ticketsList-actions'
import { centsToDollars } from './TicketList.view'


export const ConfirmationPayTicketScreen = ({
  ticketsList = [],
  chekhubChecker,
  setOpen,
  routeTransitionDelay = duration.leavingScreen,
  setBlurred,
  backRoutePath,
  source
                                            }) => {
  const [tokenized, setToken] = useState()
  const dispatch = useDispatch()
  const history = useHistory()
  const {ticketId} = useParams()
  const {orgId} = useParams()
  const ticket = ticketsList.find(el => el._id === ticketId)
  const singleTicket = useSelector(({singleTicket}) => singleTicket)



  useEffect(() => {
    ticketId && dispatch(getTicket(ticketId))
  }, [])

  useEffect(() => {
    dispatch(resetTicket())
  } , [])
  const handleTicketPay = async () => {
    const result = await dispatch(payForATicketWithEndUserData(
      orgId,
      ticketId
    ))
    const isError = isServiceErrorResult(result)
    dispatch(toastShow(
      isError ? TOAST_TYPE_ERROR : TOAST_TYPE_SUCCESS,
      isError ?
        'Payment Rejected' :
        'Payment Approved'
    ))
    closeDrawerAndTransition(
      setOpen,
      backRoutePath,
      history,
      routeTransitionDelay,
      setBlurred
    )
  }

  return singleTicket && <Box style={{
    padding: '10px'
  }}>
    <SectionHeader {...{
    }}>
      Ticket Information
    </SectionHeader>
    {!source?.default_source?.id && <Typography>
    You must add a bank account in the `Revenue and Billing` tab before
    being able make a payment
  </Typography> || <Grid container spacing={2}>
      <Grid item xs={4}>
        <FieldDisplay {... {
          label: 'Ticket Title'
        }}/>
        <Typography variant={'h6'}>
          {singleTicket?.title}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <FieldDisplay {... {
          label: 'Ticket Price'
        }}/>
        <Typography variant={'h6'}>
          {centsToDollars(singleTicket?.statement?.totals?.total_cost)}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <FieldDisplay {... {
          label: 'Ticket Due Date'
        }}/>
        <Typography variant={'h6'}>
          {singleTicket?.due && getHumanReadableDate(singleTicket?.due)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FieldDisplay {... {
          label: !chekhubChecker ? 'Ticket Assigned By' : 'Ticket Assigned To'
        }}/>
        <Typography variant={'h6'}>
          {singleTicket?.owner?.name}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FieldDisplay {... {
          label: 'Ticket Status'
        }}/>
        <Typography variant={'h6'} style={{
          textTransform: 'uppercase'
        }}>
          {singleTicket?.status}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FieldDisplay {... {
          label: 'Ticket Creation Date'
        }}/>
        <Typography variant={'h6'}>
          {singleTicket?.created_at && getHumanReadableDate(singleTicket?.created_at)}
        </Typography>
      </Grid>
    </Grid>
    }
    {chekhubChecker && singleTicket.status === 'completed' && <FormSubmitButton {... {
      onClick: ev => handleTicketPay(tokenized, singleTicket),
      useFab: true,
      disabled: !source.default_source
    }}>
      Confirm Payment
    </FormSubmitButton>}
  </Box>
}
