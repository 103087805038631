import {Link, useParams, withRouter} from 'react-router-dom'
import {IconButton} from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import {makeStyles} from '@material-ui/core/styles'
import {getTechProfileIdForOrgId} from "../util/users.util"

const useMemberRowActionsStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    '& > *': {
      marginRight: theme.spacing(.25)
    },
    '& :last-child': {
      marginRight: 0
    },
    '& button.MuiButtonBase-root:hover': {
      color: theme.palette.primary.dark
    }
  }
}))

export const ServiceCompanyMemberActions = withRouter(({
  member,
  disableEdit,
  disableRemoval,
  editTitle = 'Edit Tech Profile',
  removeTitle = 'Remove member from team',
  editComponent,
  onRemove,
  size = 'medium',
  match
}) => {
  const styles = useMemberRowActionsStyles()
  const {orgId} = useParams()
  const memberId = getTechProfileIdForOrgId(member, orgId)
  return <div {...{
    className: styles.root
  }}>
    {
      !disableEdit &&
      (
        editComponent || <Link to={`${match.url}/${memberId}/edit`}>
          <IconButton {...{
            edge: 'end',
            title: editTitle,
            size
          }} >
            <CreateIcon fontSize={size}/>
          </IconButton>
        </Link>
      )
    }
    {
      !disableRemoval && <IconButton {...{
        edge: 'end',
        title: removeTitle,
        size,
        onClick: () => onRemove(member)
      }}>
        <DeleteIcon fontSize={size}/>
      </IconButton>
    }
  </div>
})
