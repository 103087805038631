import { useEffect, useState } from "react"
import TechWorkExpDisplay from "../../../TechWorkExpDisplay"
import TechWorkExpEdit from "../../../TechWorkExpEdit"
import { ConfirmationModal } from "../../../ConfirmationModal"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchTechProfile, techProfileResetState,
  techProfileSetState,
} from "../../../../store/actions/tech-profile.actions"
import { replaceItemInList } from "../../../../util/lists.util"


export const work_experience_item = {
  references: []
}

export const mock_data = [
  {
    _id: 0,
    job_title: 'Transporter',
    employer_name: `Jim Higgins`,
    employer_location: {
      formatted_address: '213 Ed Harris Road'
    },
    employer_website: 'www.creekview.com',
    employment_type: 'Employee',
    job_description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo',
    start_date: '2008-02-13',
    end_date: '2009-12-02',
    is_current_job: true,
    termination_reason: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo',
    references: [
      {
        first_name: 'Kevin',
        last_name: 'Eslick',
        title: 'Sir',
        email: 'kevin+chekhub.com',
        phone: '615-715-2861'
      }
    ]
  },
  {
    _id: 1,
    job_title: 'Meat Cutter',
    employer_name: `Matt Marcus`,
    employer_location: {
      formatted_address: '4578 Pepeg Landing'
    },
    employer_website: '',
    employment_type: 'Contractor',
    job_description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo',
    start_date: '2008-02-13',
    end_date: '2009-12-02',
    is_current_job: true,
    termination_reason: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo',
    references: [
      {
        first_name: 'Jon',
        last_name: 'Trout',
        title: 'Mr',
        email: 'jon@chekhub.com',
        phone: '615-715-2861'
      }
    ]
  }
]

export const ref_item = {
  first_name: '',
  last_name: '',
  title: '',
  email: '',
  phone: {
    country: '',
    raw_phone: ''
  }
}


export const WorkExperienceExample = () => {

  {/*Remove some Global State and Pull in the techProfile*/
  }

  const techProfile = useSelector(({techProfile}) => techProfile)
  const work_experience = useSelector(({techProfile}) => techProfile.work_experience)
  const user = useSelector(({auth}) => auth.user)

  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [workIndex, setWorkIndex] = useState(0)
  const [refIndex, setRefIndex] = useState(0)
  const [selectedWorkExp, setSelectedWorkExp] = useState()
  const [addMode, setAddMode] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [removeItem, setRemoveItem] = useState(false)
  const techProfileId = user?.organizations[0]?.profiles[0]?.profile_id


  let newWorkExpArr = []

  useEffect(() => {
    techProfileId && dispatch(fetchTechProfile(techProfileId))
  }, [])
  useEffect(() => {
    dispatch(techProfileResetState())
  }, [])

  const onAddSave = (experience) => () => {
    let changingWorkExpArrayForAdd = [
      ... newWorkExpArr,
      {
        ... experience,
        _id: newWorkExpArr.length,
      }
    ]
    setState(changingWorkExpArrayForAdd)
  }

  const onEditSave = experience => () => {
    let editingAWorkExpForEdit = (replaceItemInList(
      experience,
      newWorkExpArr,
      workIndex
    ))
    setState(editingAWorkExpForEdit)
  }
  const handleWorkExpDeleteClick = (index) => {
    let deletingAWorkExpForDelete =
      [
        ... newWorkExpArr.slice(0, index),
        ... newWorkExpArr.slice(index + 1)
      ]
    setState(deletingAWorkExpForDelete)
  }

  const setState = (settingStateForNewArrayForWorkExp) => {
    newWorkExpArr = [... settingStateForNewArrayForWorkExp]
    techProfile.work_experience = newWorkExpArr

    dispatch(techProfileSetState({
      ... techProfile,
      work_experience: newWorkExpArr
    }))

    setSelectedWorkExp()
    setRemoveItem()
    setAddMode(false)
    setEditMode(false)
  }

  const onOkFunc = () => {
    setOpen(false)
  }

  const removeObjectFromWorkExpRef = () => {
    let removingWorkExpReferenceAfterYouCloseDrawer = removeItem?.references?.splice(refIndex, 1)
    setState(removingWorkExpReferenceAfterYouCloseDrawer)

    setSelectedWorkExp(removeItem)
    setOpen(false)
  }

  const onCancelRemove = () => {
    setRemoveItem()
    setOpen(false)
    setRefIndex()
  }

  const onAddCancelRemove = () => {
    setWorkIndex()
    setSelectedWorkExp()
    setOpen(false)
    setAddMode(false)
  }

  return <>
    {!selectedWorkExp && <TechWorkExpDisplay {... {
      work_experience,
      workIndex,
      setWorkIndex,
      setSelectedWorkExp,
      setAddMode,
      setOpen,
      setEditMode,
      handleWorkExpDeleteClick
    }}/>}
    {selectedWorkExp && <TechWorkExpEdit {... {
      selectedWorkExp,
      onAddSave,
      setSelectedWorkExp,
      setEditMode,
      addMode,
      onEditSave,
      setRefIndex,
      setOpen,
      setRemoveItem,
      workIndex
    }}/>}
    <ConfirmationModal {... {
      open: open,
      onOk: addMode ? onOkFunc : removeObjectFromWorkExpRef,
      onClose: addMode ? onAddCancelRemove : onCancelRemove,
    }}>{addMode ? 'Add Another Work Experience?' : 'Delete This?'}</ConfirmationModal>
  </>
}

