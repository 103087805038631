import { Provider, useSelector } from 'react-redux'
import { isLoaded, ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { MultiBusyProvider } from 'react-multi-busy'
import { store } from './store'
import config from './config'
import theme from './theme'
import Snackbar from './components/layout-Snackbar'
import SignInView from './pages/SignIn.view'
import Private from './pages/Private'
import AppHeader from './components/AppHeader'
import AppFooter from './components/AppFooter'
import AuthCheck from './components/AuthCheck'
import {
  CATCH_ALL_PATH,
  CHEKHUB_END_USER_SIGN_IN, CHEKHUB_END_USER_SIGN_IN_WITH_AUTH, CHEKHUB_END_USER_SIGN_IN_WITH_AUTH_AND_ORG,
  LANDING_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  SIGN_UP_PATH_INDY
} from './routing/route.constants'
import SignUp from './pages/SignUp'
import ContentWrapper from './components/ContentWrapper'
import { PageWrapper } from './components/PageWrapper'
import { DimensionsProvider } from './components/DimensionsContext'
import { Provider as BlurProvider } from './contexts/BlurContext'
import { LandingPage } from './components/LandingPage'
import SignUpComponent from './components/auth-sign-up-service-provider'
import SignUpComponentIndy from './components/auth-sign-up-service-providerIndy'
import SignInViewChekhubEndUser from './pages/SignInChekhubEndUser.view'

const {firebase} = config
const rrfProps = {firebase, config: {}, dispatch: store.dispatch}

function AuthIsLoaded({children}) {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return <div>splash screen...</div>
  return children
}

const App = () => <Provider store={store}>
  <ReactReduxFirebaseProvider {... rrfProps}>
    <ThemeProvider theme={theme}>
      <DimensionsProvider>
        <CssBaseline/>
        <MultiBusyProvider>
        <BrowserRouter>
          <BlurProvider>
            <PageWrapper>
              <AppHeader/>
              <ContentWrapper>
                <AuthIsLoaded>
                  <Switch>
                    <Route exact path={LANDING_PATH} component={LandingPage}/>
                    <Route exact path={SIGN_IN_PATH} component={SignInView}/>
                    <Route exact path={CHEKHUB_END_USER_SIGN_IN} component={SignInViewChekhubEndUser}/>
                    <Route exact path={CHEKHUB_END_USER_SIGN_IN_WITH_AUTH} component={SignInViewChekhubEndUser}/>
                    <Route exact path={CHEKHUB_END_USER_SIGN_IN_WITH_AUTH_AND_ORG} component={SignInViewChekhubEndUser}/>
                    <Route exact path={SIGN_UP_PATH} component={SignUpComponent}/>
                    <Route exact path={SIGN_UP_PATH_INDY} component={SignUpComponentIndy}/>
                    <Route path={CATCH_ALL_PATH}>
                      <AuthCheck>
                        <Private/>
                      </AuthCheck>
                    </Route>
                  </Switch>
                </AuthIsLoaded>
              </ContentWrapper>
              <AppFooter/>
            </PageWrapper>
          </BlurProvider>
        </BrowserRouter>
        </MultiBusyProvider>
        <Snackbar/>
      </DimensionsProvider>
    </ThemeProvider>
  </ReactReduxFirebaseProvider>
</Provider>

export default App
