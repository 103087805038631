import noop from '../util/noop'
import {SecureMaskedInput} from 'react-control-library'
import TextField from '@material-ui/core/TextField'

const SsnInputMask = [
  /^[0-9]*$/,
  /^[0-9]*$/,
  /^[0-9]*$/,
  '-',
  /^[0-9]*$/,
  /^[0-9]*$/,
  '-',
  /^[0-9]*$/,
  /^[0-9]*$/,
  /^[0-9]*$/,
  /^[0-9]*$/
]

export const SSN_FIELDNAME = 'ssn'
export const SsnInputValidations = {
  validator: (val, detail) =>
    !detail.isValid ? 'Must be a valid SSN format' : null
}

export const getMaskedValue = (value = '') =>
  value.length &&
  value.replaceAll(/-/ig, '').length === 9 ?
    `•••-••-${value.substring(
      value.length - 4,
      value.length
    )}` : ''

export const getValue = ({value}, data) => {
  if (!data) return data
  const maskedValue = getMaskedValue(value)
  return maskedValue.length ? maskedValue : data
}

export const SsnInput = ({
  name = SSN_FIELDNAME,
  ssn = '',
  onChange = noop,
  onBlur = noop,
  size = 'small',
  className = '',
  ...restProps
}) => {
  return <SecureMaskedInput
    {...{
      inputTag: TextField,
      label: 'SSN',
      mask: SsnInputMask,
      exactLength: 9,
      value: ssn,
      name,
      variant: 'outlined',
      secure: {
        getValue
      },
      onChange,
      onBlur,
      size,
      className,
      ...restProps
    }} />
}
