import uploadFileFunction from './storage-file-upload-functions/storage-file-upload'

import { upsertProfileSubcollectionFunction } from './profile-subcollection-upsert'

const uploadFile = ({
  file,
  filename,
  fileType,
  documentType,
  documentId = null,
  profileType,
  profile,
  userImpersonated,
  data,
}) => async (dispatch, getState, getFirebase) => {
  const [fileUploadRes, fileUploadError] = await uploadFileFunction({
    file,
    filename,
    fileType,
    profileType,
  }, getFirebase)
  if (fileUploadError) {
    console.info('STORAGE/FILE-UPLOAD-ERROR', fileUploadError.message)
    dispatch({
      type: 'TOAST/TRIGGER-NOTIFICATION',
      payload: {
        type: 'error',
        message: 'There was an error uploading the file',
      },
    })
    return dispatch({
      type: 'STORAGE/FILE-UPLOAD-ERROR',
      payload: {
        key: filename,
        status: false,
      },
    })
  }
  const { storageFilename } = fileUploadRes
  dispatch({
    type: 'STORAGE/FILE-UPLOAD-OK',
    payload: {
      key: filename,
      status: true,
      storageFilename,
    },
  })
  dispatch({
    type: 'TOAST/TRIGGER-NOTIFICATION',
    payload: {
      type: 'success',
      message: 'File uploaded successfully',
    },
  })
  await upsertProfileSubcollectionFunction({
    documentType,
    documentId,
    isTechProfile: profileType === 'tech',
    profile,
    userImpersonated,
    data: {
      ...data,
      filename: storageFilename,
    },
  }, dispatch, getState, getFirebase)
}

export default uploadFile
