import { useHistory, useParams } from 'react-router-dom'
import useFormStyles from '../styles/formStyles'
import useEditStyles from '../components/UserProfileStyles'
import {
  fetchTechProfile,
  techProfileResetState,
  techProfileSetState,
  updateTechProfile
} from '../store/actions/tech-profile.actions'
import { replaceItemInList } from '../util/lists.util'
import { useDispatch, useSelector } from 'react-redux'
import {
  onCertUploadChange,
  onChangeDD214Url,
  onChangeVetStatus,
  onInsurUploadChange,
  onRemoveDD214Url,
  onUpdateCertifications,
  onUpdateInsurancePolicy,
  onUpdateSkills,
  techProfileOnAddressChange,
  techProfileOnChange
} from '../pages/TechProfile.util'
import TechProfile from './TechProfile'
import { Box, duration } from '@material-ui/core'
import noop from '../util/noop'
import { isServiceErrorResult } from '../services/is-service-error-result.util'
import { useContext, useEffect, useState } from 'react'
import { userListUpdateUser } from '../store/actions/users.actions'
import { closeDrawerAndTransition } from './ServiceCompanyMemberDrawer.util'
import { getUserOrg } from '../util/users.util'
import { Context as BlurContext } from '../contexts/BlurContext'

export const ServiceCompanyMemberEditView = ({
  setDrawerOpen = noop,
  routeTransitionDelay = duration.leavingScreen,
  backRoutePath,
  techProfileId
}) => {
  const {profileId} = useParams()
  const {
    userProfile,
    techProfile,
  } = useSelector(({
    userProfile,
    techProfile
  }) => ({
    userProfile,
    techProfile,
  }))
  const history = useHistory()
  const dispatch = useDispatch()
  const editStyles = useEditStyles()
  const formStyles = useFormStyles()
  const org = getUserOrg(techProfile)
  const phoneNumbers = techProfile.phones || []
  const [editHoursFlag, setEditHoursFlag] = useState(false)
  const [allowSubmit, setAllowSubmitState] = useState(true)
  const {setBlurred} = useContext(BlurContext)
  const [allowPhones, setAllowPhones] = useState(true)
  const [allowCerts, setAllowCerts] = useState(true)
  const [allowInsurance, setAllowInsurance] = useState(true)
  const [allowWorkExperience, setAllowWorkExperience] = useState(true)
  const [allowWorkingHours, setAllowWorkingHours] = useState(true)
  const showAllowButtonOrNot = (bool) => {
    setAllowSubmitState(bool)
  }

  useEffect(() => {
    dispatch(fetchTechProfile(profileId || techProfileId))
  }, [])

  useEffect(() => () => {
    dispatch(techProfileResetState())
  }, [])

  const onSubmit = async e => {
    e.preventDefault()

    const techError = techProfile._id && await dispatch(updateTechProfile({
      ...techProfile, // TODO: Prolly don't need to be creating new obj here.  But I'm tired of working on this, so remove the object copy later.
    }, org?.organization_id))

    if (!isServiceErrorResult(techError)) {
      dispatch(userListUpdateUser(techProfile))
      closeDrawerAndTransition(
        setDrawerOpen,
        backRoutePath,
        history,
        routeTransitionDelay,
        setBlurred
      )
    }
  }

  // TODO: @prahlad these need to be abstracted out to a phones.util.js file so we're not copying them all over
  const onPhoneNumberAdd = (info) => {
    const newPhones = [...techProfile?.phones, {
      label: info.label,
      raw_phone: "+" + info.raw_phone,
      country: 'us'
    }]
    updatePhoneNumbers(newPhones)
  }

  const onPhoneNumberRemove = (index) => {
    const newPhones = [...phoneNumbers].filter((p, i) => i !== index)
    updatePhoneNumbers(newPhones)
  }

  const onPhoneNumberUpdate = (index, info) => {
    const currentPhones = techProfile.phones
    const newPhones = replaceItemInList(info, currentPhones, index)
    updatePhoneNumbers(newPhones)
  }

  const updatePhoneNumbers = (newPhones) => {
    dispatch(techProfileSetState({
      ...techProfile,
      phones: newPhones
    }))
    setAllowPhones(true)
  }

  const onSave = (dayHours) =>{
    dispatch(techProfileSetState({
      pay_info: {
        ...techProfile.pay_info,
        hour_rate_overtime: 0,
        technician_role: '',
        working_hours: dayHours
      }
    }))
    showAllowButtonOrNot(true)
  }


  return <Box>
    <TechProfile {...{
      editStyles,
      formStyles,
      onSubmit,
      onAddressChange: techProfileOnAddressChange(techProfile, dispatch),
      onChange: techProfileOnChange(userProfile, techProfile, dispatch),
      techProfile,
      editable: true,
      submitButtonLabel: 'Update Technician',
      onPhoneNumberUpdate,
      onPhoneNumberRemove,
      onPhoneNumberAdd,
      phoneNumbers,
      onChangeVetStatus: onChangeVetStatus(techProfile, dispatch),
      onChangeDD214Url: onChangeDD214Url(techProfile, dispatch),
      onRemoveDD214Url: onRemoveDD214Url(techProfile, dispatch),
      allowSubmit,
      showAllowButtonOrNot,
      onSave,
      editHoursFlag,
      setEditHoursFlag,
      onUpdateCertifications: onUpdateCertifications(techProfile, dispatch),
      onUpdateInsurancePolicy: onUpdateInsurancePolicy(techProfile, dispatch),
      onCertUploadChange: onCertUploadChange(dispatch),
      onInsurUploadChange: onInsurUploadChange(dispatch),
      onUpdateSkills: onUpdateSkills(techProfile, dispatch),
      setAllowPhones,
      setAllowCerts,
      setAllowInsurance,
      setAllowWorkExperience,
      setAllowWorkingHours,
      allowPhones,
      allowCerts,
      allowInsurance,
      allowWorkExperience,
      allowWorkingHours,
    }} />
  </Box>
}

