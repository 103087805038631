import {makeStyles} from '@material-ui/core/styles'
import {getReadableDateHours} from '../util/date-time.util'
import Grid from '@material-ui/core/Grid'
import {Box, Typography} from '@material-ui/core'
import {workingDaysOfWeek} from '../constants/user.constants'

const getTimeDisplay = (dateA, dateB, defaultDisplay = '') =>
  dateA ?
    getReadableDateHours(dateA) :
    dateB ?
      null : defaultDisplay

const useWorkingDayDisplayItemStyles = makeStyles(theme => ({
  dayOfWeekDisplay: {
    alignSelf: 'center',
    display: 'inline-block'
  },
  timesWrapBox: {
    display: 'inline-flex',
    justifyContent: 'center',
    width: '100%',
    '& span': {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2)
    },
    '& > span:first-child, & > span:last-child': {
      marginRight: 0,
      marginLeft: 0,
      display: 'inline-block',
      width: '4.375em',
      minWidth: '4.375em'
    },
    '& > span.day-off': {
      width: 'auto'
    }
  }
}))

export const WorkingDayDisplayItem = ({
  days = [null, null],
  index
}) => {
  const styles = useWorkingDayDisplayItemStyles()
  return <>
    <Grid item xs={4}>
      <Typography {...{
        className: styles.dayOfWeekDisplay,
        variant: 'body1',
        component: 'span'
      }}>
        {workingDaysOfWeek[index]}
      </Typography>
    </Grid>
    <Grid item xs={8}>
      <Box className={styles.timesWrapBox}>
        {
          !days[0] && !days[1] ?
            <Typography {...{
              className: 'day-off',
              variant: 'body1',
              component: 'span'
            }}>Day Off</Typography> :
            <>
              <Typography {...{
                variant: 'body1',
                component: 'span'
              }}>
                {getTimeDisplay(days[0], days[1], 'Day Off')}
              </Typography>
              {
                (days[0] || days[1]) &&
                <Typography {...{
                  variant: 'body1',
                  component: 'span'
                }}>-</Typography>
              }
              <Typography {...{
                variant: 'body1',
                component: 'span'
              }}>
                {getTimeDisplay(days[1], days[0])}
              </Typography>
            </>
        }
      </Box>
    </Grid>
  </>
}
