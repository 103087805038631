import {createReducer} from 'redux-create-reducer'
import {
  INVITED_TEAM_RESET_STATE,
  INVITED_TEAM_SET_STATE,
  INVITED_TEAMS_RESET_STATE,
  INVITED_TEAMS_SET_STATE
} from '../actions/invited-teams-list.actions'


export const INIT_INVITED_TEAMS_STATE = []

export const invitedTeamsListReducer = createReducer(
  INIT_INVITED_TEAMS_STATE,
  {
    [INVITED_TEAMS_SET_STATE]: (state, {list}) => [
      ...list
    ],
    [INVITED_TEAMS_RESET_STATE]: () => INIT_INVITED_TEAMS_STATE
  }
)



export const INIT_INVITED_TEAM_STATE = []

export const invitedTeamListReducer = createReducer(
  INIT_INVITED_TEAM_STATE,
  {
    [INVITED_TEAM_SET_STATE]: (state, {team}) => {
      team
    },
    [INVITED_TEAM_RESET_STATE]: () => INIT_INVITED_TEAM_STATE
  }
)




