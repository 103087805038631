import {
  Avatar,
  Box, Card, CardActions, CardContent,
  CircularProgress, Collapse, IconButton, List, ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { Suspense, useState } from "react"
import { Link, withRouter } from 'react-router-dom'
import { useBusyIndicatorStyles } from './BusyIndicator'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { UserAvatar } from './UserAvatar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ExpandMore } from '@material-ui/icons'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { CardContentList } from './CardContentList'
import PhoneNumbersDisplay from './PhoneNumbersDisplay'
import { OnboardingDocumentUploadSendInvite } from './OnboardingDocumentUploadSendInvite'
import { FieldDisplay } from './FieldDisplay'

const useUserAvatarStyles = makeStyles(theme => ({
  avatar: {
    fill: theme.palette.secondary.main,
    color: theme.palette.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  card: {
  }
}))



export const ServiceProviderCardList = withRouter(({
                                          teamOrProviderList,
                                          match,
                                          indy,
                                                     handleClose,
                                                     openModal,
                                                     uploadOpen,
                                                     setUploadOpen,
                                                     openUpload,
                                                     closeUpload,
                                                     removeUploadConfirmOpen,
                                                     setRemoveUploadConfirmOpen,
                                                     openRemoveConfirm,
                                                     closeRemoveConfirm,
                                                     requiredDocs,
                                                     setSentDoc,
                                                     handleCheckBoxClick,
                                                     handleInviteSent,
                                                     sentDocs,
                                                     handleInvitePreset
                                        }) => {
  const styles = useBusyIndicatorStyles()

  const avatarStyles = useUserAvatarStyles()


  return teamOrProviderList.length > 0 ? <Box>
    {teamOrProviderList.map(team => {
      return !indy && team?._id ?
          <Card elevation={2} className={avatarStyles.card} style={{
            marginBottom: '6px',
          }}>
            <CardContent>
              <Grid container direction={'row'}>
                <Grid item xs={1} style={{
                  paddingBottom: '10px'
                }}>
                  <Avatar
                    {...{
                      alt: team.company_name || team.profile_data.full_name,
                      src: team.company_logo,
                      className: avatarStyles.avatar
                    }} />
                </Grid>
                <Grid item xs={5} justify={'center'}>
                  <FieldDisplay {...{
                    label: <span>Company Name</span>
                  }}/>
                  <Typography variant={'h6'}>
                    {team.company_name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <FieldDisplay {...{
                    label: <span>Formation Year</span>
                  }}/>
                  <Typography variant={'body1'}>
                    {team.company_formation_year || 'No Formation Date Given'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <FieldDisplay {...{
                    label: <span>Number of Techs</span>
                  }}/>
                  <Typography variant={'body1'}>
                    {team.num_techs || 'No Techs Available'}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{
                  paddingTop: '10px'
                }}>
                  <FieldDisplay {...{
                    label: <span>Address</span>
                  }}/>
                  <Typography variant={'body1'}>
                    {team.location.formatted_address}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <FieldDisplay {...{
                    label: <span>Zip Codes Services</span>
                  }}/>
                  <Typography variant={'body1'}>
                    {team.service_metros || 'Zip Codes Not Available'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <FieldDisplay {...{
                    label: <span>Phone Numbers</span>
                  }}/>
                  <PhoneNumbersDisplay {...{
                    phones: team.phones,
                  }}/>
                </Grid>
              </Grid>
              <CardContentList {...{
                team,
                handleInvitePreset
              }}/>
            </CardContent>
          </Card> :
        <Card elevation={3} className={avatarStyles.card} style={{
          marginBottom: '6px',
        }}>
          <CardContent>
            <Grid container direction={'row'} spacing={1}>
              <Grid item xs={2}>
                <Avatar
                  {...{
                    alt: team.company_name || team.profile_data.full_name,
                    src: team.company_logo,
                    className: avatarStyles.avatar
                  }} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant={'body1'}>
                  {team.company_name}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant={'body1'}>
                  {team.company_formation_year || 'No Formation Date Given'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'body1'}>
                  {team.location.formatted_address}
                </Typography>
              </Grid>
              <Grid item xs={2}>
              <PhoneNumbersDisplay {...{
                phones: team.phones
              }}/>
            </Grid>
            </Grid>
            <CardContentList {...{
              team
            }}/>
          </CardContent>
        </Card>
    })}
      <OnboardingDocumentUploadSendInvite {...{
        handleClose,
        openModal,
        uploadOpen,
        setUploadOpen,
        openUpload,
        closeUpload,
        removeUploadConfirmOpen,
        setRemoveUploadConfirmOpen,
        openRemoveConfirm,
        closeRemoveConfirm,
        requiredDocs,
        setSentDoc,
        handleCheckBoxClick,
        handleInviteSent,
        teamOrProviderList,
        sentDocs
      }} />
      </Box>
    : <Grid
    container
    justify={'center'}
  >
    <CircularProgress {...{
      size: '2em',
      className: styles.spinner,
      color: 'primary'
    }} />
  </Grid>
})

