import React, { useState } from 'react'

import WorkHistoryItemEdit from './profile-WorkHistoryItemEdit'
import WorkHistoryItemView from './profile-WorkHistoryItemView'

export default function WorkHistoryItem(props) {
  const {
    index,
    value,
    readonly = false,
    editing = false,
  } = props

  const [isEditable, setEditable] = useState(!readonly && editing)

  const handleDelete = () => props.onSubmit(index, null)

  const handleSubmit = (newWorkHistoryItem) => {
    setEditable(false)
    props.onSubmit(index, newWorkHistoryItem)
  }

  return (<div>
    {isEditable
      ? <WorkHistoryItemEdit value={value} onSubmit={handleSubmit} />
      : <WorkHistoryItemView value={value} onDelete={handleDelete} readonly={readonly} />
    }
  </div>)
}
