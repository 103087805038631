import React from 'react'

// import { useSelector } from 'react-redux'

import {
  Button,
} from '@material-ui/core'

import CertificateItem from './profile-CertificateItem'
import FileUploadOverlay from './profile-FileUploadOverlay'
import useFileUploadStyles from '../styles/fileUploadStyles'

export default function CertificateList({
  data,
  profileType = 'tech',
  readonly,
  disabled,
  userImpersonated = false,
}) {
  const uploadStyles = useFileUploadStyles()
  const {
    certifications = [],
  } = data

  const initState = {
    overlayOpen: false,
    certificate: {
      filename: null,
      certification_title: null,
      issued_by: null,
      issued_on: null,
      expires_on: null,
      start_date: null,
      end_date: null,
      description: null,
    },
  }
  const [state, setState] = React.useState(initState)

  // const downloadUrls = useSelector((store) => store.storage.downloadUrls)

  const handleAddRecordClick = () => setState({ ...initState, overlayOpen: true })
  const handleChange = () => (setState({ ...state, overlayOpen: false }))

  return (<div>
    <strong>Certifications</strong>
    {readonly ? ('') : <Button
      className={uploadStyles.button}
      size="small"
      variant="outlined"
      disabled={disabled}
      onClick={handleAddRecordClick}>
      Add Certification
    </Button>}
    {certifications.map((item) => {
      const { _id: itemId } = item
      return (<CertificateItem
        key={itemId}
        profileType={profileType}
        profile={data}
        userImpersonated={userImpersonated}
        certificateDetails={item}
        certificateList={certifications} />)
    })}
    <FileUploadOverlay
      open={state.overlayOpen}
      fileType="certification"
      profileType={profileType}
      profile={data}
      userImpersonated={userImpersonated}
      onSubmit={handleChange} />
  </div>)
}
