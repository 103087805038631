import {makeStyles} from '@material-ui/core/styles'
import noop from '../util/noop'
import Grid from '@material-ui/core/Grid'
import {UserProfileFormFields} from './UserProfileFormFields'
import {InlineConfirm} from './InlineConfirm'
import {ServiceCompanyEditMemberRoles} from './ServiceCompanyEditMemberRoles'
import {TECH_USER_ROLE} from '../constants/user.constants'

const useAddServiceCompanyManagerFormStyles = makeStyles(theme => ({
  inlineContainer: {width: 'auto'},
  form: {
    width: '100%'
  },
  confirmCancelButton: {
    height: 'auto',
    alignSelf: 'center'
  },
  rolesToggle: {
    marginBottom: 0
  }
}))

export const AddServiceCompanyManagerForm = ({
  member,
  onSubmit = noop,
  onCancel = noop,
  onChange = noop
}) => {
  const styles = useAddServiceCompanyManagerFormStyles()
  const handleSetMemberRoles = roles => onChange({
    target: {
      id: 'roles',
      value: roles
    }
  })
  return <form {...{
    className: styles.form,
    onSubmit
  }}>
    <Grid container spacing={1}>
      <UserProfileFormFields {...{
        user: member,
        styles: {},
        editable: true,
        onChange
      }} />
      <Grid item className={styles.inlineContainer}>
        <ServiceCompanyEditMemberRoles {...{
          roles: member.roles || [],
          setRoles: handleSetMemberRoles,
          hideInlineConfirm: true,
          size: 'small',
          className: styles.rolesToggle,
          excludeRoles: [TECH_USER_ROLE]
        }} />
      </Grid>
      <Grid container className={styles.inlineContainer}>
        <InlineConfirm {...{
          onConfirm: onSubmit,
          onCancel,
          confirmClassName: styles.confirmCancelButton,
          cancelClassName: styles.confirmCancelButton,
        }}/>
      </Grid>
    </Grid>
  </form>
}
