import noop, {noopThunk} from '../util/noop'
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core'
import {useSelector} from 'react-redux'
import {useContext} from 'react'
import {Context as BlurContext} from '../contexts/BlurContext'

export const ConfirmationModal = ({
  open = false,
  onOk = noopThunk,
  onClose = noop,
  children
}) => {
  const {backendRunning} = useSelector(
    ({auth: {backendRunning}}) => ({backendRunning})
  )
  const {setBlurred} = useContext(BlurContext)
  const handleOnClose = () => {
    setBlurred(false)
    onClose()
  }

  const handleOnOk = async () => {
    await onOk()
    setBlurred(false)
  }

  const onEnter = () => setBlurred(true)

  return <Dialog {...{
    maxWidth: 'xs',
    open,
    onEnter,
    onClose: handleOnClose,
    disableEscapeKeyDown: backendRunning,
    disableBackdropClick: backendRunning
  }}>
    <DialogContent>
      <DialogContentText>
        {children}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button {...{
        onClick: handleOnClose,
        disabled: backendRunning
      }}>Cancel</Button>
      <Button {...{
        color: 'primary',
        onClick: handleOnOk,
        disabled: backendRunning
      }} >{
        backendRunning ?
          <CircularProgress size={'1.7em'}/> :
          'Confirm'
      }</Button>
    </DialogActions>
  </Dialog>
}
