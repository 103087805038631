import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Button,
} from '@material-ui/core'

import WorkHistoryItem from './profile-WorkHistoryItem'

import actions from '../store/actions'

export default function WorkHistoryList(props) {
  const {
    data: profile,
    readonly = false,
    disabled,
    userImpersonated = false,
    user = null,
  } = props
  const [workHistory, setHistory] = useState(profile.work_experience)
  const [newHistoryItems, setNewHistory] = useState([])
  const dispatch = useDispatch()

  const addRecord = () => {
    const newHistory = [
      ...newHistoryItems,
      {
        job_title: '',
        employer_name: '',
        employer_location: null,
        employment_type: 'employee',
        job_description: '',
        start_date: null,
      },
    ]
    setNewHistory(newHistory)
  }

  const removeValue = (index, arr) => [
    ...arr.slice(0, index),
    ...arr.slice(index + 1),
  ]

  const handleSubmit = (index, value) => {
    const updatedHistory = value !== null
      ? [value, ...workHistory]
      : removeValue(index, workHistory)

    const updatedNewHistory = value !== null
      ? removeValue(index, newHistoryItems)
      : newHistoryItems

    setHistory(updatedHistory)
    setNewHistory(updatedNewHistory)

    if (userImpersonated) {
      dispatch(actions['AUTH/SIGN-UP-TECH']({
        ...profile,
        work_experience: updatedHistory,
      },
      false))
    } else {
      dispatch(actions['PROFILE/UPSERT'](
        'tech',
        {
          ...profile,
          work_experience: updatedHistory,
        },
        user,
      ))
    }
  }

  return (<div>
    <strong>Work History</strong>

    {!readonly
      ? <Button
          style={{ marginLeft: '1em' }}
          size="small"
          variant="outlined"
          disabled={disabled}
          onClick={addRecord}
          >
            Add Employment Record
        </Button>
      : ''
    }
    {newHistoryItems.length > 0 && newHistoryItems.map((item, idx) => {
      return (
        <WorkHistoryItem
          key={`newWork${idx}`}
          index={idx}
          value={item}
          readonly={readonly}
          editing={true}
          onSubmit={handleSubmit}
        />)
    })}
    {workHistory.map((item, idx) => {
      return (
        <WorkHistoryItem
          key={item?._id || `work${idx}`}
          index={idx}
          value={item}
          readonly={readonly}
          editing={false}
          onSubmit={handleSubmit}
        />)
    })}
  </div>)
}
