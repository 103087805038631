import {List, ListItem, Divider, IconButton, FormHelperText, Chip, Button, Box, Link} from '@material-ui/core'
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/core/styles/makeStyles"
import {useHistory} from 'react-router-dom'
import CreateIcon from "@material-ui/icons/Create"
import AddIcon from '@material-ui/icons/Add'
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import DeleteIcon from "@material-ui/icons/Delete"
import {work_experience_item} from "./sandboxes/kevin/examples/WorkExperienceExample"
import {getHumanReadableDate} from '../util/date-time.util'
import {SIGN_UP_COMPLETE_TECH_PROFILE_PATH} from "../routing/route.constants"
import {Book, PhoneSharp} from '@material-ui/icons'
import Grid from '@material-ui/core/Grid'

export const useWorkExpDisplay = makeStyles(theme => ({
  topDivider: {
    marginTop: theme.spacing(1)
  },
  list: {
    padding: theme.spacing(0),
  },
  listNested: {
    paddingLeft: theme.spacing(6)
  },
  refNested: {
    paddingLeft: theme.spacing(8)
  },
  referenceListItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& svg.MuiSvgIcon-root': {
      verticalAlign: 'middle'
    }
  },
  addButton: {
    alignItems: 'center',
    '& span': {
      marginRight: theme.spacing(.5)
    }
  },
  buttonTransparent: {
    backgroundColor: "transparent"
  },
  currentChip: {
    marginLeft: theme.spacing(1)
  },
  employeeNameSpacing: {
    paddingRight: theme.spacing(0)
  },
  iconButtonToRight: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(1.4),
    width: '15%'
  },
  editButton: {
    paddingRight: theme.spacing(.5),
  },
  jobTitle: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1)
  },
  endDate: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  address: {
    paddingRight: theme.spacing(1),
  },
  currentJobHeader: {
    paddingTop: '0px',
  },
  inline: {
    justifyContent: 'flex-end',
  },
  largeIcon: {
    '& svg': {
      fontSize: 35
    }
  },
  editAddress: {
    paddingBottom: '0px',
  },
  employmentPadding: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  listSelect: {
    paddingTop: theme.spacing(.3),
  },
  employmentSelect: {
    height: theme.spacing(5),
    width: '100%'
  },
  dateFieldMargin: {
    marginRight: theme.spacing(2.5)
  },
  refDivider: {
    background: 'lightgray',
    marginBottom: theme.spacing(1.2)
  },
  refListItem: {
    justifyContent: 'space-between'
  },
  refDeleteButton: {
    backgroundColor: 'transparent',
    justifyContent: 'flex-end'
  },
  refPhone: {
    paddingBottom: theme.spacing(2)
  }
}))

const TechWorkExpDisplay = ({
  work_experience,
  size = 'small',
  setWorkIndex,
  setSelectedWorkExp,
  setAddMode,
  setEditMode,
  handleWorkExpDeleteClick,
  techProfileEditPath,
  serviceCompanyEditPath,
  serviceCompanyAddPath,
  showAllowButtonOrNot,
  title =
    <Typography variant={'h6'} display={'inline'}>
      Work Experience
    </Typography>
}) => {
  const styles = useWorkExpDisplay()
  const subtitle1 = 'subtitle1'
  const history = useHistory()

  const addNewWorkExp = () => {
    setWorkIndex(work_experience.length - 1)
    setSelectedWorkExp(work_experience_item)
    setAddMode(true)
    showAllowButtonOrNot(false)
  }

  const handleSelectedEdit = (index) => () => {
    setWorkIndex(index)
    setSelectedWorkExp(work_experience[index])
    setEditMode(true)
    showAllowButtonOrNot(false)
  }

  return work_experience &&
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box {...{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
            {title}
            {
              (
                history.location.pathname === techProfileEditPath ||
                history.location.pathname === serviceCompanyEditPath ||
                history.location.pathname === serviceCompanyAddPath ||
                history.location.pathname === SIGN_UP_COMPLETE_TECH_PROFILE_PATH
              ) && <Button
                className={styles.addButton}
                edge={'end'}
                title={'Add Work History'}
                variant="outlined"
                size="small"
                color="primary"
                onClick={addNewWorkExp}
              >
                <span>Add Work History</span><AddCircleOutlineSharpIcon fontSize={'small'}/>
              </Button>
            }
          </Box>
        </Grid>
      </Grid>
      {title && <Divider className={styles.topDivider} />}
      <List className={styles.list} dense>
        {
          work_experience.map((exp, index) => exp &&
              <ListItem key={index} disableGutters={true}>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <Typography variant={'h6'} className={styles.employeeNameSpacing}>
                      {exp?.employer_name}
                      {
                        exp?.is_current_job && <Chip
                          {...{
                            label: 'Current',
                            size: 'small',
                            color: 'primary',
                            className: styles.currentChip
                          }} />
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    {
                      history.location.pathname ===
                      techProfileEditPath ||
                      history.location.pathname ===
                      serviceCompanyEditPath ||
                      history.location.pathname === serviceCompanyAddPath ||
                      history.location.pathname === SIGN_UP_COMPLETE_TECH_PROFILE_PATH ?
                        <Box {...{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          className: styles.editRemoveButtons
                        }} >
                          <IconButton
                            className={styles.editButton}
                            title={'Edit Work History'}
                            size={size}
                            onClick={
                              handleSelectedEdit(index)
                            }
                          >
                            <CreateIcon fontSize={size}/>
                          </IconButton>
                          <IconButton
                            className={styles.buttonTransparent}
                            title={'Delete Work History'}
                            size={size}
                            onClick={() => handleWorkExpDeleteClick(index)}
                          >
                            <DeleteIcon fontSize={size}/>
                          </IconButton>
                        </Box> :
                        null
                    }
                  </Grid>
                  <Grid item xs={6}>
                    {
                      exp?.job_title &&
                      <Typography variant={subtitle1}>
                        <span className={styles.jobTitle}>{exp?.job_title}</span>
                        {exp?.start_date && <span>{getHumanReadableDate(exp?.start_date)}</span>}
                        {exp?.end_date && <span> to </span>}
                        {exp?.end_date && <span>{getHumanReadableDate(exp?.start_date)}</span>}
                      </Typography>
                    }
                    {
                      exp?.employer_location?.formatted_address &&
                      <Typography variant={subtitle1} className={styles.address}>
                        {exp?.employer_location?.formatted_address}
                      </Typography>
                    }
                    {
                      exp.employer_website &&
                      <Typography variant={subtitle1}>
                        <a target="_blank" href={exp.employer_website}>{exp.employer_website}</a>
                      </Typography>
                    }
                    {
                      <>
                        <FormHelperText>Employment Type</FormHelperText>
                        <Typography variant="body1" style={{
                          textTransform: 'capitalize'
                        }}>
                          {
                            exp?.employment_type === 'other' ?
                              exp.alt_employment_type : exp.employment_type
                          }
                        </Typography>
                      </>
                    }
                    {
                      exp?.job_description && <>
                        <FormHelperText>
                          Job Description
                        </FormHelperText>
                        <Typography variant="body1">
                          {exp?.job_description}
                        </Typography>
                      </>
                    }
                    {
                      exp?.termination_reason && <>
                        <FormHelperText>
                          Termination Reason
                        </FormHelperText>
                        <Typography variant="body1">
                          {exp?.termination_reason}
                        </Typography>
                      </>
                    }
                  </Grid>
                  <Grid item xs={6}>
                    <FormHelperText>
                      References
                    </FormHelperText>
                      {
                        exp?.references?.map((ref, index) => {
                          const {
                            first_name,
                            last_name,
                            title,
                            email,
                            phone: {
                              raw_phone
                            } = {}
                          } = ref || {}
                          // TODO: Move this out to a WorkExpReference component please
                          return <ListItem key={index} className={styles.referenceListItem}>
                            {
                              (first_name || last_name) && <Typography variant={'h6'}>
                                {first_name ? `${first_name} ` : ''}{last_name}
                              </Typography>
                            }
                            {title && <Typography gutterBottom variant={'body2'}>{title}</Typography>}
                            {
                              email && <Typography variant={'body2'} gutterBottom>
                                <Link href={`mailto:${email}`}>{email}</Link>
                              </Typography>
                            }
                            {
                              raw_phone && <Typography variant={'body2'}>
                                <Link href={`tel:${raw_phone}`}>
                                  <PhoneSharp size="small"/>{raw_phone}
                                </Link>
                              </Typography>
                            }
                            <Divider />
                          </ListItem>
                        })
                      }
                  </Grid>
                </Grid>
              </ListItem>
          )
        }
      </List>
    </>
}

export default TechWorkExpDisplay
