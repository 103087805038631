import {useRef} from 'react'
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from '@material-ui/core'

import useStyles from './SignUpStyles'
import UserTypeToggle from './UserTypeToggle'
import {Link} from 'react-router-dom'
import {SIGN_IN_PATH} from '../routing/route.constants'
import {SC_USER_TYPE} from '../constants/user.constants'
import {useValidations} from '../hooks/useValidations'
import {validate as emailValidate} from 'email-validator'
import Typography from '@material-ui/core/Typography'

const EMAIL_FIELDNAME = 'email'
const COMPANY_NAME_FIELDNAME = 'company_name'
const FIRST_NAME_FIELDNAME = 'first_name'
const LAST_NAME_FIELDNAME = 'last_name'
const PASSWORD_FIELDNAME = 'password'
const CONFIRMPASSWORD_FIELDNAME = 'confirmPassword'

const fieldValuesDontMatch = (refA, refB) =>
  (refA.current?.value || refB.current?.value) &&
  refA.current?.value !== refB.current?.value

const SignUpForm = ({
  handleChange,
  handleSubmit,
  userType,
  auth,
}) => {
  const styles = useStyles()
  const passwordRef = useRef(null)
  const confirmPasswordRef = useRef(null)
  const passwordsMismatch = fieldValuesDontMatch(
    passwordRef,
    confirmPasswordRef
  )
  const {
    onChangeValidation,
    onBlurValidation,
    onSubmitValidation,
    fieldErrors,
    clearFieldError,
    hasFieldErrors
  } = useValidations({
    [EMAIL_FIELDNAME]: {
      required: 'Email is required',
      validator: (val) => !emailValidate(val) ? 'Invalid email format' : null
    },
    [COMPANY_NAME_FIELDNAME]: {
      required: 'Company name is required'
    },
    [FIRST_NAME_FIELDNAME]: {
      required: 'First name is required'
    },
    [LAST_NAME_FIELDNAME]: {
      required: 'Last name is required'
    },
    [PASSWORD_FIELDNAME]: {
      required: 'Password is required',
      validator: () => passwordsMismatch ? 'Passwords must match' : null
    },
    [CONFIRMPASSWORD_FIELDNAME]: {
      required: 'Confirm Password is required',
      validator: () => passwordsMismatch ? 'Passwords must match' : null
    }
  })

  const handleOnChange = ev => {
    onChangeValidation(ev)
    handleChange(ev)
  }

  const handleOnSubmit = ev => {
    ev.preventDefault()
    const formIsValid = onSubmitValidation(ev)
    !hasFieldErrors() && formIsValid && handleSubmit(ev)
  }

  const onChangePasswordChange = ev => {
    const mismatch = fieldValuesDontMatch(
      passwordRef,
      confirmPasswordRef
    )
    const sisterFieldName = ev.target.name === PASSWORD_FIELDNAME ?
      CONFIRMPASSWORD_FIELDNAME : PASSWORD_FIELDNAME
    if(hasFieldErrors() && !mismatch) {
      clearFieldError(
        ev.target.name
      )
      clearFieldError(
        sisterFieldName
      )
    }
    onChangeValidation(ev)
    handleChange(ev)
  }

  const onPasswordBlur = ev => {
    const sisterFieldName = ev.target.name === PASSWORD_FIELDNAME ?
      CONFIRMPASSWORD_FIELDNAME : PASSWORD_FIELDNAME
    hasFieldErrors() && !passwordsMismatch && clearFieldError(
      sisterFieldName
    )
    onBlurValidation(ev)
  }

  // TODO: Move the Grid and Paper wrappers up into the parent component.
  //  better yet, use the MUI Card component ;-)
  return <Grid
    container
    justify='center'
    className={styles.signInGrid}>
    <Paper className={styles.paper}>
      <Typography variant={'h5'} style={{
        textAlign: 'center',
        padding: '15px'
      }}>
        {userType === 'service_company' ? 'Start a Service Company Profile' :
        'Start a Independent Tech Profile'}
      </Typography>
      <form onSubmit={handleOnSubmit}>
        {
          userType === SC_USER_TYPE ?
          <TextField {...{
            id: COMPANY_NAME_FIELDNAME,
            name: COMPANY_NAME_FIELDNAME,
            className: styles.textField,
            size: 'small',
            required: true,
            label: 'Company Name',
            fullWidth: true,
            variant: 'outlined',
            onChange: handleOnChange,
            onBlur: onBlurValidation,
            error: Boolean(fieldErrors[COMPANY_NAME_FIELDNAME]),
            helperText: fieldErrors[COMPANY_NAME_FIELDNAME] || null
          }} /> : null
        }
        <TextField {...{
          id: FIRST_NAME_FIELDNAME,
          name: FIRST_NAME_FIELDNAME,
          className: styles.textField,
          size: 'small',
          required: true,
          label: 'First Name',
          fullWidth: true,
          onChange: handleOnChange,
          variant: 'outlined',
          onBlur: onBlurValidation,
          error: Boolean(fieldErrors[FIRST_NAME_FIELDNAME]),
          helperText: fieldErrors[FIRST_NAME_FIELDNAME] || null
        }} />
        <TextField
          {...{
            id: LAST_NAME_FIELDNAME,
            name: LAST_NAME_FIELDNAME,
            className: styles.textField,
            size: 'small',
            required: true,
            label: 'Last Name',
            fullWidth: true,
            onChange: handleOnChange,
            variant: 'outlined',
            onBlur: onBlurValidation,
            error: Boolean(fieldErrors[LAST_NAME_FIELDNAME]),
            helperText: fieldErrors[LAST_NAME_FIELDNAME] || null
          }} />
        <TextField {...{
          id: EMAIL_FIELDNAME,
          name: EMAIL_FIELDNAME,
          className: styles.textField,
          size: 'small',
          required: true,
          label: 'Email',
          fullWidth: true,
          variant: 'outlined',
          onChange: handleOnChange,
          onBlur: onBlurValidation,
          error: Boolean(fieldErrors[EMAIL_FIELDNAME]),
          helperText: fieldErrors[EMAIL_FIELDNAME] || null
        }} />
        <TextField {...{
          id: PASSWORD_FIELDNAME,
          name: PASSWORD_FIELDNAME,
          inputRef: passwordRef,
          className: styles.textField,
          size: 'small',
          required: true,
          label: 'Password',
          fullWidth: true,
          type: 'password',
          inputProps: {minLength: 6 },
          variant: 'outlined',
          onChange: onChangePasswordChange,
          onBlur: onPasswordBlur,
          error: Boolean(fieldErrors[PASSWORD_FIELDNAME]),
          helperText: fieldErrors[PASSWORD_FIELDNAME] || null
        }} />
        <TextField {...{
          id: CONFIRMPASSWORD_FIELDNAME,
          name: CONFIRMPASSWORD_FIELDNAME,
          inputRef: confirmPasswordRef,
          className: styles.textField,
          size: 'small',
          required: true,
          label: 'Confirm Password',
          fullWidth: true,
          type: 'password',
          inputProps: {minLength: 6 },
          variant: 'outlined',
          onChange: onChangePasswordChange,
          onBlur: onPasswordBlur,
          error: Boolean(fieldErrors[CONFIRMPASSWORD_FIELDNAME]),
          helperText: fieldErrors[CONFIRMPASSWORD_FIELDNAME] || null
        }} />
        <Button {...{
          color: 'primary',
          type: 'submit',
          variant: 'contained',
          disableElevation: true,
          disabled: hasFieldErrors() || auth.backgroundRunning || passwordsMismatch,
          fullWidth: true
        }} >
          Create User
          {auth.backgroundRunning && <CircularProgress size={20}
            style={{ position: 'absolute', marginRight: '-90%', color: '#606060' }} />}
        </Button>
      </form>
      {auth.authError &&
        <div style={{marginTop: '0.5em', color: 'red'}}>
          <span>Error Creating Account</span>
        </div>
      }
      <Link to={SIGN_IN_PATH}>Back to Sign In</Link>
    </Paper>
  </Grid>
}

export default SignUpForm
