import Grid from "@material-ui/core/Grid"
import { generatePath, NavLink, useHistory, useParams } from 'react-router-dom'
import { useMainNavBarStyles } from './MainNavBarStyles'
import { Paper } from '@material-ui/core'
import {
  CHEKHUB_END_USER_REQUIRED_DOCS,
  CHEKHUB_END_USER_WALLET,
  INDY_TECH_WALLET,
  SELECT_ORG,
  SERVICE_COMPANY_WALLET
} from '../routing/route.constants'
import { getUserOrg, isServiceCompanyManager } from '../util/users.util'
import { getLandingPath } from "../util/landingPath.util"
import { useAuthUser } from '../hooks/useAuthUser'
import { INDEPENDENT_TECH_ORG_TYPE } from '../constants/user.constants'
import { NavMenuDropdown } from './sandboxes/kevin/examples/NavMenuDropdown'
import { useEffect } from 'react'
import { fetchChekhubOrgList } from '../store/actions/service-company.actions'
import { useDispatch, useSelector } from 'react-redux'
import { userProfileSetState } from '../store/actions/user-profile.actions'
import { useLocalStorage } from '../util/localStorageHack.util'
import { isEmpty } from './WorkExperience'

// /v1/service-companies/industries

const MainNavBar = () => {
  const styles = useMainNavBarStyles()
  const authorizedUser = useAuthUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const path = history.location.pathname
  const orgId = localStorage.getItem("orgId")?.replace(/['"]+/g, '') || useParams()
  const user = useSelector(({auth}) => auth.user)
  const chekhubList = useSelector(({chekhubList}) => chekhubList)
  const orgIdFromState = useParams()
  const activeOrgObject = chekhubList?.memberships?.find(org => org.organization._id === orgId)
  const userDefaultOrg = getUserOrg(authorizedUser,orgId,path)
  const isManager = isServiceCompanyManager(authorizedUser, userDefaultOrg?.organization_id)
  const landingPath = getLandingPath(authorizedUser, userDefaultOrg,activeOrgObject)
  const chekhubChecker = userDefaultOrg?.organization_type === 'customer_company'
  const isTech = userDefaultOrg?.organization_type === INDEPENDENT_TECH_ORG_TYPE
  const isServiceTech = userDefaultOrg?.profiles[0]?.profile_type === 'tech'

  useEffect(async () => {
    history.location.pathname.includes('/chekhub-user') && await dispatch(fetchChekhubOrgList())
  }, [])

  return <Paper elevation={2} className={styles.paper} >
    <Grid container justify={'center'} className={styles.navWrapper}>
      <nav className={styles.nav}>
        <Grid container>
          <Grid item className={styles.links} xs={'auto'}>
            {history.location.pathname !== SELECT_ORG && <NavLink to={landingPath}>Home</NavLink>}
          </Grid>
          {<Grid item className={styles.links} xs={'auto'}>
            {history.location.pathname !== SELECT_ORG && <NavMenuDropdown/>}
          </Grid>}
          {!chekhubChecker && <Grid item className={styles.links} xs={'auto'}>
            {history.location.pathname !== SELECT_ORG && userDefaultOrg && <NavLink to={!isTech && !chekhubChecker ?
              generatePath(SERVICE_COMPANY_WALLET, {
                orgId: userDefaultOrg?.organization_id
              }) : generatePath(INDY_TECH_WALLET, {
                userId: userDefaultOrg?.profiles[0]?.profile_id
              })
            }>Revenue/Billing</NavLink>}
          </Grid>}
          {<Grid item className={styles.links} xs={'auto'}>
            {history.location.pathname !== SELECT_ORG && activeOrgObject && <NavLink to={
              generatePath(CHEKHUB_END_USER_WALLET, {
                orgId: orgId || activeOrgObject?.organization?._id
              })
            }>Revenue/Billing</NavLink>}
          </Grid>}
          {<Grid item className={styles.links} xs={'auto'}>
            {history.location.pathname !== SELECT_ORG && activeOrgObject &&  <NavLink to={
              generatePath(CHEKHUB_END_USER_REQUIRED_DOCS, {
                orgId: orgId ||  activeOrgObject?.organization?._id
              })
            }>Required Documents</NavLink>}
          </Grid>}
        </Grid>
      </nav>
    </Grid>
  </Paper>
}

export default MainNavBar
