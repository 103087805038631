import axios from 'axios'

import appConfig from '../../../config'

const fetchUser = async (userId = 'me', accessToken = null) => {
  const { chekhubConnect: { baseURI, operations, defaultHeaders } } = appConfig
  const { usersFetchOne: { path, method } } = operations

  try {
    const { status: userFetchStatus, data: body } = await axios({
      baseURL: baseURI,
      url: `${path}/${userId}`,
      method,
      headers: {
        ...defaultHeaders,
        authorization: `Bearer ${accessToken}`,
      },
    })
    if (userFetchStatus !== 200) throw new Error('unknown chekhubAuthError')
    return [body.data, null]
  } catch (err) {
    return [null, err]
  }
}

export default fetchUser
