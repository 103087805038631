export const REQUIRED_DOCS_SET_STATE = 'REQUIRED_DOCS_SET_STATE'
export const REQUIRED_DOCS_RESET_STATE = 'REQUIRED_DOCS_RESET_STATE'
export const REQUIRED_DOC_SET_STATE = 'REQUIRED_DOC_SET_STATE'
export const REQUIRED_DOC_RESET_STATE = 'REQUIRED_DOC_RESET_STATE'


export const requiredDocsSetState = list => dispatch =>
  dispatch({
    type: REQUIRED_DOCS_SET_STATE,
    list
  })

export const requiredDocsResetState = () => dispatch =>
  dispatch({
    type: REQUIRED_DOCS_RESET_STATE
  })



export const requiredDocSetState = list => dispatch =>
  dispatch({
    type: REQUIRED_DOC_SET_STATE,
    list
  })

export const requiredDocResetState = () => dispatch =>
  dispatch({
    type: REQUIRED_DOC_RESET_STATE
  })

