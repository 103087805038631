import {Grid} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React, {useContext} from 'react'
import {MAX_PAGE_WIDTH} from '../theme'
import {withRouter} from 'react-router-dom'
import {ROOT_PATH, SIGN_IN_PATH} from "../routing/route.constants"
import { Context as DimensionsContext } from './DimensionsContext'

const usePageWrapperStyles = makeStyles(theme => ({
  mainWrapper: {
    maxWidth: MAX_PAGE_WIDTH,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  }
}))

const ContentWrapper = withRouter(
  ({
    children,
    location: {
      pathname
    }
  }) => {
    // TODO: we should prolly be using this to get the maxHeight for all these other components
    //  that we're importing it directly for instead.
    const {dimensionsContextStyles} = useContext(DimensionsContext)

    return <Grid item
          {...(pathname === SIGN_IN_PATH) || (pathname === ROOT_PATH) ? {} : {...{
              xs: 12,
              className: `${usePageWrapperStyles().mainWrapper} ${dimensionsContextStyles.minHeight}`
            }}
          } >
      { children }
    </Grid>
  }
)

export default ContentWrapper
